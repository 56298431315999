import React from 'react';
import DarkModeDarkIcon from 'svgs/DarkModeDarkIcon';
import DarkModeIcon from 'svgs/DarkModeIcon';
import LightModeDarkIcon from 'svgs/LightModeDarkIcon';
import LightModeIcon from 'svgs/LightModeIcon';
import LoginIcon from 'svgs/LoginIcon';
import Logo from 'svgs/Logo';
import LogoLightIcon from 'svgs/LogoLightIcon';

import styles from './header.module.scss';

const Header = ({ switchToMap, modeSwitcher, setModeSwitcher }: any) => {
  return (
    <header className={`${styles['app-header']} ${styles[switchToMap ? 'show' : '']}`}>
      <div className="container">
        <div className={styles['app-header-main-panel']}>
          <div className={styles['header-left-side']}>
            <div className={styles['brand']}>{modeSwitcher ? <LogoLightIcon /> : <Logo />}</div>
            <div className={`${styles['mode-switcher']} ${styles[modeSwitcher ? 'light-mode' : '']}`}>
              <div
                onClick={() => setModeSwitcher((prev: any) => !prev)}
                className={`${styles['mode']} ${styles[modeSwitcher ? 'active-light' : '']}`}
              >
                {!modeSwitcher ? <LightModeIcon /> : <LightModeDarkIcon />}
              </div>
              <div
                onClick={() => setModeSwitcher((prev: any) => !prev)}
                className={`${styles['mode']} ${styles[!modeSwitcher ? 'active' : '']}`}
              >
                {!modeSwitcher ? <DarkModeIcon /> : <DarkModeDarkIcon />}
              </div>
            </div>
          </div>
          <div className={styles['header-actions']}>
            <a href="http://app.freightmax.com/">
              <LoginIcon /> <span>Login</span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
