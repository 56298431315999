import React, { useEffect, useState } from 'react';

import Car from './components/carAnimation/CarAnimation';
import Header from './components/header/Header';
import Map from './components/map/Map';
import NotFound from './components/not-found/NotFound';

function App() {
  const [modeSwitcher, setModeSwitcher] = useState<boolean>(false);
  const [switchToMap, setSwitchToMap] = useState<boolean>(false);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const currentURL = window.location.href;
  const pathName = new URL(currentURL).pathname;

  useEffect(() => {
    if (pathName)
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
  }, []);

  return (
    <div className={`parent map-mode`} id="wrapper">
      {pathName === '/' ? (
        <div className={'animations-overlay'}>
          {!openMap ? (
            <div>
              <Header switchToMap={!openMap} modeSwitcher={modeSwitcher} setModeSwitcher={setModeSwitcher} />
              <Car switchToMap={switchToMap} modeSwitcher={modeSwitcher} setOpenMap={setOpenMap} />
            </div>
          ) : (
            <Map switchToMap={switchToMap} setSwitchToMap={setSwitchToMap} />
          )}
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
}

export default App;
