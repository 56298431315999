type PropsType = {
  carVersion: number;
  backgroundRef: React.RefObject<SVGSVGElement>;
};

const BackgroundLight = ({ carVersion, backgroundRef }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={backgroundRef}
      data-name="ac3ca867-b46b-4e5a-a759-29cb73fe4e6d"
      viewBox="0 0 4855 974"
    >
      <defs>
        <clipPath id="b9dad796-7ae5-42ca-a250-25aa3e890e69">
          <path fill="none" d="M-1014 -1.01H1114V681.99H-1014z" opacity="0.1" />
        </clipPath>
        <clipPath id="a28356f4-0d27-47f5-b887-7ac71236c126">
          <path fill="none" d="M1767.59 -1.01H3895.59V681.99H1767.59z" opacity="0.1" />
        </clipPath>
        <clipPath id="ffbfbc20-43ec-435a-aa0e-d15929046469">
          <path fill="none" d="M1184.73 148.12H1417.73V676.12H1184.73z" />
        </clipPath>
      </defs>
      <path fill="none" d="M26 0H5378V901H26z" />
      <path fill="#fff" d="M26 0H5017.58V901H26z" />
      <path fill="none" d="M26 0H5378V901H26z" />
      <path fill="#fff" d="M26 0H5017.58V907H26z" data-name="bec286b8-512d-4e80-991f-3bd7d268bc61" />
      <path fill="none" d="M-9 -111H5439V924H-9z" />
      <path fill="#fff" d="M-6.68 -111H5017.58V927.48H-6.68z" data-name="f0c9bce4-755a-4c7b-b740-2ccda58465ac" />
      <path fill="none" d="M26 -1H5380V907H26z" />
      <g clipPath="url(#b9dad796-7ae5-42ca-a250-25aa3e890e69)" opacity="0.1">
        <g data-name="a9186240-eb72-49ab-b1c3-a9f6f64c88c2">
          <path
            d="M704.36 681.22h-111V171.68h111zm-107.68-3.8h104.43V175.5H596.67z"
            data-name="ae51f582-c30d-4cd3-ac76-ca1c6851c33e"
          />
          <path
            d="M574.78 681.22H463.84V171.68h110.94zm-107.67-3.8h104.43V175.5H467.11z"
            data-name="e09af99f-6bc6-43b3-a31d-9a7738c70767"
          />
          <path
            d="M596.67 681.22h-25.13V171.68h25.13zm-21.89-3.8h18.62V175.5h-18.62z"
            data-name="bccc555e-1482-46a6-aeb1-5b988fdfebba"
          />
          <path
            d="M502.59 216.88h-28.76V183.1h28.78zm-25.52-3.82h22.26V186.9h-22.26z"
            data-name="a5ef7565-4678-4f99-a102-3a07d586f8fa"
            id={'build'}
          />
          <path
            d="M533.69 216.88h-28.76V183.1h28.76zm-25.51-3.82h22.27V186.9h-22.27z"
            data-name="b1038776-46b2-4926-9e1f-215bfa527d1f"
          />
          <path
            d="M564.82 216.88h-28.77V183.1h28.77zm-25.52-3.82h22.25V186.9H539.3z"
            data-name="a8b82642-3222-4b7d-b06b-93070da97992"
          />
          <path
            d="M502.59 253h-28.76v-33.75h28.78zm-25.52-3.8h22.26v-26.09h-22.26z"
            data-name="abdd3036-876a-46e1-9308-0cd20429417c"
          />
          <path
            d="M533.69 253h-28.76v-33.75h28.76zm-25.51-3.8h22.27v-26.09h-22.27z"
            data-name="fa64eefe-7dd7-4ce6-8eb6-e6bdd338813f"
          />
          <path
            d="M564.82 253h-28.77v-33.75h28.77zm-25.52-3.8h22.25v-26.09H539.3z"
            data-name="a2c1baf6-4c5e-4c51-9a1d-78065461e35d"
          />
          <path
            d="M502.59 289.14h-28.76V255.4h28.78zm-25.52-3.78h22.26v-26.15h-22.26z"
            data-name="b4a0856a-26c0-49da-801e-5b28a4dc76be"
          />
          <path
            d="M533.69 289.14h-28.76V255.4h28.76zm-25.51-3.78h22.27v-26.15h-22.27z"
            data-name="a05d0035-5743-448e-bcc2-246dc412eb16"
          />
          <path
            d="M564.82 289.14h-28.77V255.4h28.77zm-25.52-3.78h22.25v-26.15H539.3z"
            data-name="a0807a49-5893-4c5a-9cbe-69d701ef8e7d"
          />
          <path
            d="M502.59 325.29h-28.76v-33.7h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
            data-name="e5774ef9-b84f-4571-9c17-8a3b012e71a5"
          />
          <path
            d="M533.69 325.29h-28.76v-33.7h28.76zm-25.51-3.82h22.27v-26.11h-22.27z"
            data-name="ac22766d-1af8-455d-b39e-b78fa705ea62"
          />
          <path
            d="M564.82 325.29h-28.77v-33.7h28.77zm-25.52-3.82h22.25v-26.11H539.3z"
            data-name="a9a5dd44-24a2-4f97-973a-4ae36d658809"
          />
          <path
            d="M502.59 361.44h-28.76V327.7h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
            data-name="fb17fb24-fd7d-44ad-a4a4-df4621fbf21a"
          />
          <path
            d="M533.69 361.44h-28.76V327.7h28.76zm-25.51-3.82h22.27v-26.11h-22.27z"
            data-name="ec7941b9-11a0-461c-8666-bb9539da7db9"
          />
          <path
            d="M564.82 361.44h-28.77V327.7h28.77zm-25.52-3.82h22.25v-26.11H539.3z"
            data-name="a569712a-0589-4b2f-8d4d-89e279690f12"
          />
          <path
            d="M502.59 397.59h-28.76v-33.75h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
            data-name="ee343b1b-2e2e-416f-8c75-4e3ecb9b9c6e"
          />
          <path
            d="M533.69 397.59h-28.76v-33.75h28.76zm-25.51-3.82h22.27v-26.11h-22.27z"
            data-name="bde70e67-c597-48a2-ab81-518c86e95f09"
          />
          <path
            d="M564.82 397.59h-28.77v-33.75h28.77zm-25.52-3.82h22.25v-26.11H539.3z"
            data-name="b6c32756-1a0c-4add-af72-b34fb280b4d3"
          />
          <path
            d="M502.59 433.74h-28.76V400h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
            data-name="a6895770-cbb1-4aea-84b0-12c8c1842b99"
          />
          <path
            d="M533.69 433.74h-28.76V400h28.76zm-25.51-3.82h22.27v-26.11h-22.27z"
            data-name="f3b1d532-3cf9-4a26-a115-5f62cbac23e1"
          />
          <path
            d="M564.82 433.74h-28.77V400h28.77zm-25.52-3.82h22.25v-26.11H539.3z"
            data-name="e5baac5a-d45b-486c-8c1e-8cb8297ee94e"
          />
          <path
            d="M502.59 469.89h-28.76v-33.73h28.78zm-25.52-3.82h22.26V440h-22.26z"
            data-name="be9027fa-fc85-4387-b335-82cba11a4dfa"
          />
          <path
            d="M533.69 469.89h-28.76v-33.73h28.76zm-25.51-3.82h22.27V440h-22.27z"
            data-name="e87e6e7e-4e3c-4ae9-bc28-29866b03400a"
          />
          <path
            d="M564.82 469.89h-28.77v-33.73h28.77zm-25.52-3.82h22.25V440H539.3z"
            data-name="f1185c2e-0761-4b77-9c36-5bb50a289093"
          />
          <path
            d="M502.59 506h-28.76v-33.69h28.78zm-25.52-3.8h22.26v-26.09h-22.26z"
            data-name="ee395b63-b1ce-46b1-8f85-3544ebae2de5"
          />
          <path
            d="M533.69 506h-28.76v-33.69h28.76zm-25.51-3.8h22.27v-26.09h-22.27z"
            data-name="f3b1701e-6d96-4405-8c56-cfa586f0a9b4"
          />
          <path
            d="M564.82 506h-28.77v-33.69h28.77zm-25.52-3.8h22.25v-26.09H539.3z"
            data-name="ac1e1b57-7f9f-4c79-a247-01dcc96336c2"
          />
          <path
            d="M502.59 542.21h-28.76v-33.75h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
            data-name="fd9cfcf2-e7db-4991-9479-9eb165e1994e"
          />
          <path
            d="M533.69 542.21h-28.76v-33.75h28.76zm-25.51-3.82h22.27v-26.11h-22.27z"
            data-name="a86a4948-a5d0-4f21-b2f0-d0fddaaf3660"
          />
          <path
            d="M564.82 542.21h-28.77v-33.75h28.77zm-25.52-3.82h22.25v-26.11H539.3z"
            data-name="edc071eb-2c03-44e1-81f9-84625399841a"
          />
          <path
            d="M502.59 578.35h-28.76v-33.74h28.78zm-25.52-3.81h22.26v-26.1h-22.26z"
            data-name="b8daeaeb-a084-45d9-85db-19ff07a8c647"
          />
          <path
            d="M533.69 578.35h-28.76v-33.74h28.76zm-25.51-3.81h22.27v-26.1h-22.27z"
            data-name="baf35050-b4f7-42ea-a086-a65161bae726"
          />
          <path
            d="M564.82 578.35h-28.77v-33.74h28.77zm-25.52-3.81h22.25v-26.1H539.3z"
            data-name="fe4038bf-34d1-4ebe-bf5c-c55efe05ab6b"
          />
          <path
            d="M632.15 216.88h-28.78V183.1h28.78zm-25.52-3.82h22.26V186.9h-22.26z"
            data-name="ac88903d-fca1-48c4-a03d-522f6a0f07db"
          />
          <path
            d="M663.25 216.88h-28.76V183.1h28.76zm-25.51-3.82H660V186.9h-22.26z"
            data-name="a00f302e-f7fa-4175-9e10-10c39ffe373f"
          />
          <path
            d="M694.38 216.88h-28.77V183.1h28.77zM668.89 213h22.26v-26.1h-22.26z"
            data-name="f8b338b6-0e19-4c5c-b253-52c71304ac83"
          />
          <path
            d="M632.15 253h-28.78v-33.75h28.78zm-25.52-3.8h22.26v-26.09h-22.26z"
            data-name="a9be8c5e-dfef-4fed-9d69-62d8a4facb1f"
          />
          <path
            d="M663.25 253h-28.76v-33.75h28.76zm-25.51-3.8H660v-26.09h-22.26z"
            data-name="bacde0f4-4daa-46c7-9f08-81d114128da8"
          />
          <path
            d="M694.38 253h-28.77v-33.75h28.77zm-25.49-3.8h22.26v-26.09h-22.26z"
            data-name="abe92976-5afa-4df7-9b54-952e24c04568"
          />
          <path
            d="M632.15 289.14h-28.78V255.4h28.78zm-25.52-3.78h22.26v-26.15h-22.26z"
            data-name="b1f3f11e-9ba3-4318-b739-951bb9c63d1e"
          />
          <path
            d="M663.25 289.14h-28.76V255.4h28.76zm-25.51-3.78H660v-26.15h-22.26z"
            data-name="fa234d65-0817-4485-87fa-1cfbb6f5801b"
          />
          <path
            d="M694.38 289.14h-28.77V255.4h28.77zm-25.49-3.78h22.26v-26.15h-22.26z"
            data-name="ff98fc70-ed3f-43cc-8335-b1821a5c1b1a"
          />
          <path
            d="M632.15 325.29h-28.78v-33.7h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
            data-name="af532de2-5ac6-4fe3-af3f-0bb349c04a91"
          />
          <path
            d="M663.25 325.29h-28.76v-33.7h28.76zm-25.51-3.82H660v-26.11h-22.26z"
            data-name="f5a89c2e-b8da-4b36-bc47-81d625c2da53"
          />
          <path
            d="M694.38 325.29h-28.77v-33.7h28.77zm-25.49-3.82h22.26v-26.11h-22.26z"
            data-name="f0f6331f-9a64-496a-8142-bc3294d19f5b"
          />
          <path
            d="M632.15 361.44h-28.78V327.7h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
            data-name="aee1e40a-3634-44c6-a8a0-865658915329"
          />
          <path
            d="M663.25 361.44h-28.76V327.7h28.76zm-25.51-3.82H660v-26.11h-22.26z"
            data-name="f52b919e-68c7-4e3a-9d6d-6d387ff43c03"
          />
          <path
            d="M694.38 361.44h-28.77V327.7h28.77zm-25.49-3.82h22.26v-26.11h-22.26z"
            data-name="ae12d1d0-a9cc-439f-a830-38bd9a137dd3"
          />
          <path
            d="M632.15 397.59h-28.78v-33.75h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
            data-name="f3423e0e-aa70-4f2e-a36e-8c212b8aa7d4"
          />
          <path
            d="M663.25 397.59h-28.76v-33.75h28.76zm-25.51-3.82H660v-26.11h-22.26z"
            data-name="a57ce428-8d9f-46df-9309-ac10e11cef0d"
          />
          <path
            d="M694.38 397.59h-28.77v-33.75h28.77zm-25.49-3.82h22.26v-26.11h-22.26z"
            data-name="a82deeed-dd7d-42ef-a6c4-11c70ec3a6ed"
          />
          <path
            d="M632.15 433.74h-28.78V400h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
            data-name="f67fc8e4-b67b-4249-b506-13fa86132b91"
          />
          <path
            d="M663.25 433.74h-28.76V400h28.76zm-25.51-3.82H660v-26.11h-22.26z"
            data-name="b1a7f55a-6cde-4f74-a5a4-5a6976b4e8ef"
          />
          <path
            d="M694.38 433.74h-28.77V400h28.77zm-25.49-3.82h22.26v-26.11h-22.26z"
            data-name="edafd547-c42d-4c33-84e3-9be4f0a54894"
          />
          <path
            d="M632.15 469.89h-28.78v-33.73h28.78zm-25.52-3.82h22.26V440h-22.26z"
            data-name="edfddcab-97f2-4dc5-abf9-bea2ea3dccca"
          />
          <path
            d="M663.25 469.89h-28.76v-33.73h28.76zm-25.51-3.82H660V440h-22.26z"
            data-name="a34f682d-0bd1-4ff3-a951-e3c32c8bcda4"
          />
          <path
            d="M694.38 469.89h-28.77v-33.73h28.77zm-25.49-3.82h22.26V440h-22.26z"
            data-name="ea6c929e-69ce-46da-b050-4786b7ea01e9"
          />
          <path
            d="M632.15 506h-28.78v-33.69h28.78zm-25.52-3.8h22.26v-26.09h-22.26z"
            data-name="bc8cb2b1-2b20-4b92-8413-76b89296777e"
          />
          <path
            d="M663.25 506h-28.76v-33.69h28.76zm-25.51-3.8H660v-26.09h-22.26z"
            data-name="e0a599b2-b2c7-498a-aa33-8362197a9e90"
          />
          <path
            d="M694.38 506h-28.77v-33.69h28.77zm-25.49-3.8h22.26v-26.09h-22.26z"
            data-name="a66d14ad-dbe2-435b-8ec6-7c14e46a5631"
          />
          <path
            d="M632.15 542.21h-28.78v-33.75h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
            data-name="a421f573-5a42-42a5-813f-62a85ecf9155"
          />
          <path
            d="M663.25 542.21h-28.76v-33.75h28.76zm-25.51-3.82H660v-26.11h-22.26z"
            data-name="adc91ec8-2ce7-40f6-859f-1da30d541e82"
          />
          <path
            d="M694.38 542.21h-28.77v-33.75h28.77zm-25.49-3.82h22.26v-26.11h-22.26z"
            data-name="f965c7b7-0f53-40c2-8234-79ef455f64e3"
          />
          <path
            d="M632.15 578.35h-28.78v-33.74h28.78zm-25.52-3.81h22.26v-26.1h-22.26z"
            data-name="a1a579bd-c9d4-4692-b349-829e68ffcd72"
          />
          <path
            d="M663.25 578.35h-28.76v-33.74h28.76zm-25.51-3.81H660v-26.1h-22.26z"
            data-name="a00d92d0-588f-409f-9008-59d8b8cd3a7a"
          />
          <path
            d="M694.38 578.35h-28.77v-33.74h28.77zm-25.49-3.81h22.26v-26.1h-22.26z"
            data-name="b31a0678-7cb9-4dac-9a2e-cf09b24ac163"
          />
          <path d="M691.22 175.5H477V37.65h3.26v134H688v-134h3.25z" data-name="f7ce8159-9df2-4a9e-9e97-010383577f1c" />
          <path
            d="M691.24 175.5H477v-11.87h214.24zm-211-3.82H688v-4.15H480.21z"
            data-name="a785618b-c085-4b8c-a496-fbe5f389dfac"
          />
          <path
            d="M674.46 83.61H493.72V53.79h180.74zM497 79.8h174.22V57.61H497z"
            data-name="b8ed0893-6fb4-48fd-92b1-853cf4a86c77"
          />
          <path
            d="M674.46 120H493.72V90.13h180.74zM497 116.13h174.22V94H497z"
            data-name="fed42a55-d775-4d1f-a1ca-2a3a4392534a"
          />
          <path
            d="M674.46 156.26H493.72v-29.8h180.74zM497 152.55h174.22v-22.29H497z"
            data-name="e2895e6b-043f-41b9-b863-e0fd818db4c2"
          />
          <path
            d="M696 39.56H472.18V22.65H696zm-220.57-3.8h217.3v-9.32H475.44z"
            data-name="ea02e289-cbd4-4bcf-a537-7f346a3c3804"
          />
          <path
            d="M564.82 628.8h-91v-39.95h91zm-87.75-3.8h84.48v-32.33h-84.48z"
            data-name="e1ca5b79-8993-4898-8ef4-9751e59d4cdd"
          />
          <path
            d="M497.8 681h-24v-45.29h24zm-20.73-3.81h17.47v-37.66h-17.47z"
            data-name="b06fae6d-1c0d-4aca-a528-380c502e6169"
          />
          <path
            d="M564.82 673.85h-64.15v-38.14h64.15zM504 670h57.64v-30.5H504z"
            data-name="acfc2dfa-64cd-4f72-b69a-9a0c064f14db"
          />
          <path
            d="M694.38 610.73h-90.87v-21.88h90.87zM606.83 607h84.34v-14.24h-84.34z"
            data-name="e19f79b1-a5cd-43ec-b447-e4aa04ee82a2"
          />
          <path
            d="M694.38 632.21h-90.87v-21.88h90.87zm-87.55-3.82h84.34v-14.24h-84.34z"
            data-name="fe194626-8932-42ff-b02f-e58d867019e1"
          />
          <path
            d="M694.38 681.22H673.1v-45.51h21.28zm-18-3.8h14.7v-37.89h-14.7z"
            data-name="a5c17c21-2740-4bc0-8e79-51ccd58ee846"
          />
          <path
            d="M671.32 673.85h-67.81v-38.14h67.81zM606.83 670h61.28v-30.5h-61.28z"
            data-name="a4ecf781-108c-4bb0-a778-750634acdc12"
          />
          <path
            d="M610.52 656.69a6.62 6.62 0 01-5-2.43 9.1 9.1 0 01-2.08-5.87v-12.76h14v12.89a9.07 9.07 0 01-2.07 5.73 6.6 6.6 0 01-4.88 2.44zm-3.69-17.16v9a5 5 0 00.2 1.77 4.77 4.77 0 00.8 1.53 3.79 3.79 0 001.25 1 3.26 3.26 0 001.5.37 3.39 3.39 0 001.5-.37 3.79 3.79 0 001.25-1 4.37 4.37 0 00.79-1.53 5.28 5.28 0 00.21-1.77v-8.95z"
            data-name="e66ccbc3-8d2e-459b-b03e-87ec7ecf5db2"
          />
          <path
            d="M621.28 656.69a6.66 6.66 0 01-5-2.46 9.12 9.12 0 01-2-5.84v-12.76h14v12.89a9 9 0 01-2.08 5.75 6.63 6.63 0 01-4.92 2.42zm-3.81-17.16v9a4.85 4.85 0 001.1 3.11 3.38 3.38 0 004.75.56 3 3 0 00.56-.56 4.76 4.76 0 001.11-3.11v-8.95z"
            data-name="b2b09f1b-a344-40ae-94dc-3ab805c97513"
          />
          <path
            d="M632 656.69a6.58 6.58 0 01-5-2.43 9.1 9.1 0 01-2.08-5.87v-12.76h14v12.89a9.08 9.08 0 01-2 5.71 6.63 6.63 0 01-4.92 2.46zm-3.76-17.16v9a5.28 5.28 0 00.21 1.77 4.24 4.24 0 00.8 1.53 3.79 3.79 0 001.25 1 3.24 3.24 0 003 0 3.79 3.79 0 001.25-1 4.57 4.57 0 00.8-1.53 5.28 5.28 0 00.21-1.77v-8.95z"
            data-name="a246476d-71c6-4121-af30-6357d0a23415"
          />
          <path
            d="M642.79 656.69a6.66 6.66 0 01-5-2.46 9.1 9.1 0 01-2-5.84v-12.76h14v12.89a9 9 0 01-2.08 5.75 6.63 6.63 0 01-4.92 2.42zm-3.74-17.16v9a4.81 4.81 0 00.21 1.77 4.37 4.37 0 00.79 1.53 3.79 3.79 0 001.25 1 3.26 3.26 0 001.5.37 3.39 3.39 0 001.5-.37 3.79 3.79 0 001.25-1 4.37 4.37 0 00.79-1.53 5.28 5.28 0 00.21-1.77v-8.95z"
            data-name="ef0d3498-b327-44ce-ba2a-aebcfaa5b118"
          />
          <path
            d="M653.55 656.69a6.58 6.58 0 01-5-2.43 9.1 9.1 0 01-2.08-5.87v-12.76h14v12.89a9.08 9.08 0 01-2.05 5.71 6.63 6.63 0 01-4.87 2.46zm-3.76-17.16v9a4.76 4.76 0 001.11 3.11 3.38 3.38 0 004.75.56 3 3 0 00.56-.56 4.85 4.85 0 001.1-3.11v-8.95z"
            data-name="a7f4c58d-702f-4ebf-8b58-e8f3d61d0d95"
          />
          <path
            d="M664.32 656.69a6.66 6.66 0 01-5-2.46 9.12 9.12 0 01-2.05-5.84v-12.76h14v12.89a9 9 0 01-2.08 5.75 6.63 6.63 0 01-4.92 2.42zm-3.76-17.16v9a4.81 4.81 0 00.21 1.77 4.37 4.37 0 00.79 1.53 3.79 3.79 0 001.25 1 3.26 3.26 0 001.5.37 3.39 3.39 0 001.5-.37 3.79 3.79 0 001.25-1 4.37 4.37 0 00.79-1.53 4.81 4.81 0 00.21-1.77v-8.95z"
            data-name="b8d05fab-ecf6-4611-8ba2-603243d12f79"
          />
          <path
            d="M1114 682H899.87V171H1114zm-209.59-5.33h205.05V176.25H904.41z"
            data-name="a70ac146-a2a0-45e7-8f79-1359206a3f30"
          />
          <path
            d="M973.48 217.59h-54.21v-35.28h54.21zm-49.65-5.34H969v-24.59h-45.17z"
            data-name="b25a5105-a548-4277-9e94-f23629aeb0b9"
          />
          <path
            d="M1034 217.59h-54.17v-35.28H1034zm-49.65-5.34h45.13v-24.59h-45.11z"
            data-name="b2f661a8-fedb-4ce8-ae3c-9b29f55d33ca"
          />
          <path
            d="M1094.6 217.59h-54.21v-35.28h54.21zm-49.6-5.34h45.13v-24.59H1045z"
            data-name="a3e4eca4-16f6-4b1c-b6f9-693895ba01bb"
          />
          <path
            d="M973.48 253.74h-54.21v-35.28h54.21zm-49.65-5.34H969v-24.59h-45.17z"
            data-name="a61ce814-9ccc-43af-a211-cf4c625aceb2"
          />
          <path
            d="M1034 253.74h-54.17v-35.28H1034zm-49.65-5.34h45.13v-24.59h-45.11z"
            data-name="b10bdd1b-ac16-4d70-ab1c-aa49fd14c1e7"
          />
          <path
            d="M1094.6 253.74h-54.21v-35.28h54.21zm-49.6-5.34h45.13v-24.59H1045z"
            data-name="f451fba2-aa95-44f7-b57a-bfa19b0e145a"
          />
          <path
            d="M973.48 289.89h-54.21v-35.28h54.21zm-49.65-5.34H969V260h-45.17z"
            data-name="a53f62cd-5bbf-444e-b499-caf7ed965b3d"
          />
          <path
            d="M1034 289.89h-54.17v-35.28H1034zm-49.65-5.34h45.13V260h-45.11z"
            data-name="ba2a08c6-4442-456f-b012-4b1dd8e5f6f5"
          />
          <path
            d="M1094.6 289.89h-54.21v-35.28h54.21zm-49.6-5.34h45.13V260H1045z"
            data-name="b0d759b2-a625-4cb6-879c-3da7823c201e"
          />
          <path
            d="M973.48 326h-54.21v-35.24h54.21zm-49.65-5.32H969v-24.57h-45.17z"
            data-name="af3d3e48-4025-4c5a-b44d-b8c9bd30a26b"
          />
          <path
            d="M1034 326h-54.17v-35.24H1034zm-49.65-5.32h45.13v-24.57h-45.11z"
            data-name="b7ebbdf6-d123-4900-a098-431f004a99b8"
          />
          <path
            d="M1094.6 326h-54.21v-35.24h54.21zm-49.6-5.28h45.13v-24.61H1045z"
            data-name="e80582eb-6edd-482f-b155-f5b3fdcaf344"
          />
          <path
            d="M973.48 362.14h-54.21v-35.28h54.21zm-49.65-5.31H969v-24.57h-45.17z"
            data-name="bb32846d-81c7-4f22-be48-4e616159c0f6"
          />
          <path
            d="M1034 362.14h-54.17v-35.28H1034zm-49.65-5.31h45.13v-24.57h-45.11z"
            data-name="a13dde83-051c-4f58-8c80-ff7dfc55a33a"
          />
          <path
            d="M1094.6 362.14h-54.21v-35.28h54.21zm-49.6-5.31h45.13v-24.57H1045z"
            data-name="e965d7c3-45a6-47c0-aca5-becae1c89ee2"
          />
          <path
            d="M973.48 398.33h-54.21v-35.27h54.21zM923.83 393H969v-24.63h-45.17z"
            data-name="b0c4de5d-e7b9-497e-8c91-e8cca7d62f33"
          />
          <path
            d="M1034 398.33h-54.17v-35.27H1034zM984.39 393h45.13v-24.63h-45.15z"
            data-name="f4d30e20-7d17-4a2c-817e-d6421a7a3e07"
          />
          <path
            d="M1094.6 398.33h-54.21v-35.27h54.21zM1045 393h45.13v-24.63H1045z"
            data-name="ac17047d-7f24-442b-864c-64eb1c088260"
          />
          <path
            d="M973.48 434.48h-54.21V399.2h54.21zm-49.65-5.31H969v-24.59h-45.17z"
            data-name="aab3f91b-078e-41ef-9fd8-e5bcac6ad4a0"
          />
          <path
            d="M1034 434.48h-54.17V399.2H1034zm-49.65-5.31h45.13v-24.59h-45.11z"
            data-name="e75f944b-2e3f-43d2-8703-5ca2a4b3d951"
          />
          <path
            d="M1094.6 434.48h-54.21V399.2h54.21zm-49.6-5.31h45.13v-24.59H1045z"
            data-name="a0950cd4-cf7e-4541-8db1-b4405829d4eb"
          />
          <path
            d="M973.48 470.65h-54.21v-35.28h54.21zm-49.65-5.33H969v-24.59h-45.17z"
            data-name="bb0c452f-759b-4664-9e0b-8608fa28052c"
          />
          <path
            d="M1034 470.65h-54.17v-35.28H1034zm-49.65-5.33h45.13v-24.59h-45.11z"
            data-name="a160a19b-493e-4264-b819-f3a48fbc09ce"
          />
          <path
            d="M1094.6 470.65h-54.21v-35.28h54.21zm-49.6-5.33h45.13v-24.59H1045z"
            data-name="fa46eddc-8021-4294-82fb-b9dd5c6271e0"
          />
          <path
            d="M973.48 506.8h-54.21v-35.28h54.21zm-49.65-5.33H969v-24.59h-45.17z"
            data-name="a8e2cc6c-0f02-466f-be4d-4949ec4a3f5b"
          />
          <path
            d="M1034 506.8h-54.17v-35.28H1034zm-49.65-5.33h45.13v-24.59h-45.11z"
            data-name="e33cc07e-5c45-44ed-b5be-28534902e992"
          />
          <path
            d="M1094.6 506.8h-54.21v-35.28h54.21zm-49.6-5.33h45.13v-24.59H1045z"
            data-name="f9b5aac8-322b-454f-83e7-78e70ded00b2"
          />
          <path
            d="M973.48 543h-54.21v-35.33h54.21zm-49.65-5.33H969V513h-45.17z"
            data-name="ef4acff6-37e2-42c2-9589-d654f87de5b0"
          />
          <path
            d="M1034 543h-54.17v-35.33H1034zm-49.65-5.33h45.13V513h-45.11z"
            data-name="f26cd164-3820-4a28-801e-9414382969db"
          />
          <path
            d="M1094.6 543h-54.21v-35.33h54.21zm-49.6-5.38h45.13V513H1045z"
            data-name="aafa4fb9-674b-4dff-b487-9cdfc98a0ff5"
          />
          <path
            d="M973.48 579.1h-54.21v-35.28h54.21zm-49.65-5.31H969v-24.61h-45.17z"
            data-name="ac8beaa8-077c-404a-92fd-bd124408775e"
          />
          <path
            d="M1034 579.1h-54.17v-35.28H1034zm-49.65-5.31h45.13v-24.61h-45.11z"
            data-name="bdc0a7fb-88cf-4647-8acb-867e7953f39e"
          />
          <path
            d="M1094.6 579.1h-54.21v-35.28h54.21zm-49.6-5.31h45.13v-24.61H1045z"
            data-name="b89771ca-0b00-49f2-ad6b-70759cf66573"
          />
          <path
            d="M1094.6 629.55H919.27v-41.5h175.33zm-170.79-5.31h166.25v-30.82H923.81z"
            data-name="bc9d988c-2302-47d7-aa50-a5b2de73f96e"
          />
          <path
            d="M948.16 681.22h-28.38v-45.65h28.38zm-24.83-4.15h21.28v-37.35h-21.28z"
            data-name="f70d7172-78d3-4143-b576-ce482a7d2c0f"
          />
          <path
            d="M1094.78 674.81H952.63v-40h142.22zm-137.35-5.73h132.45v-28.6H957.43z"
            data-name="b804f9f5-94c0-42c9-8916-6afcf044498d"
          />
          <path
            d="M829.63 681.22H700.88V97.81h3.27v579.61h122.23V97.81h3.25z"
            data-name="f17c7b6a-0a76-48fa-8ce4-342577decde5"
          />
          <path
            d="M903.77 681.22h-77.39V97.81h3.25v579.61h70.88V97.81h3.26z"
            data-name="abaac15e-6d23-4cf1-86e7-0c97b143cba8"
          />
          <path
            d="M903.61 99.72H698a9.81 9.81 0 01-7.42-3.6 13.84 13.84 0 010-17.38 9.81 9.81 0 017.42-3.6h205.61a9.81 9.81 0 017.42 3.6 13.79 13.79 0 010 17.38 9.81 9.81 0 01-7.42 3.6zM698 79a6.71 6.71 0 00-5.11 2.48 9.52 9.52 0 000 12A6.68 6.68 0 00698 95.9h205.61a6.75 6.75 0 005.12-2.48 9.52 9.52 0 000-12 6.82 6.82 0 00-5.12-2.42z"
            data-name="baf7f5aa-02ff-4413-a41f-200b401964d2"
          />
          <path
            d="M874.32 79h-85.48V9.86h85.48zm-82.23-3.81h79V13.68h-79z"
            data-name="f4fb05a2-4c58-440e-bf4f-a88928152fbe"
          />
          <path
            d="M874.32 79h-27.7V9.86h27.7zm-24.46-3.81h21.28V13.68h-21.28z"
            data-name="a760b72a-3e16-40e5-bc95-0cc2baf6df7c"
          />
          <path
            d="M873.78 13.68h-85.42a5.89 5.89 0 01-4.44-2.15 8 8 0 01-1.84-5.2 8.11 8.11 0 011.84-5.19A5.89 5.89 0 01788.36-1h85.42a5.8 5.8 0 014.44 2.15 8 8 0 011.84 5.19 8 8 0 01-1.84 5.2 5.81 5.81 0 01-4.44 2.14zM788.36 2.81a2.79 2.79 0 00-2.13 1 4 4 0 000 5 2.79 2.79 0 002.13 1h85.42a2.85 2.85 0 002.14-1 4 4 0 000-5 2.85 2.85 0 00-2.14-1z"
            data-name="babb94b7-304d-4a75-9c5f-7e0ec29f62b4"
          />
          <path
            d="M760.72 146.49H711V115h49.76zm-46.5-3.82h43.34v-23.86h-43.34z"
            data-name="f2158164-0807-4aa5-ab99-6c00a82b10a8"
          />
          <path
            d="M819.63 146.49H769.8V115h49.83zm-46.52-3.82h43.25v-23.86h-43.25z"
            data-name="f903a3b9-494f-4347-ad78-df1e317a4277"
          />
          <path
            d="M819.63 128H769.8v-13h49.83zm-46.52-3.82h43.25v-5.39h-43.25z"
            data-name="f78ca14e-5670-4963-9692-b18b279e9255"
          />
          <path
            d="M760.72 128H711v-13h49.76zm-46.5-3.82h43.34v-5.39h-43.34z"
            data-name="e704c888-f773-4b33-a567-12067bd16946"
          />
          <path
            d="M760.72 185.75H711v-31.46h49.76zm-46.5-3.82h43.34v-23.82h-43.34z"
            data-name="a61b870d-61f2-499d-b1f5-f0c9f6eb7781"
          />
          <path
            d="M819.63 185.75H769.8v-31.46h49.83zm-46.52-3.82h43.25v-23.82h-43.25z"
            data-name="afa4dc45-8722-4d2d-8303-1fe8cb5e5ff9"
          />
          <path
            d="M819.63 167.33H769.8v-13h49.83zm-46.52-3.82h43.25v-5.4h-43.25z"
            data-name="a93d31d5-b277-4397-8303-bab51cd8812e"
          />
          <path
            d="M760.72 167.33H711v-13h49.76zm-46.5-3.82h43.34v-5.4h-43.34z"
            data-name="add52800-8c5f-435b-a54c-7c2281617c0c"
          />
          <path
            d="M760.72 225.18H711V193.6h49.76zm-46.5-3.82h43.34v-23.94h-43.34z"
            data-name="f54c0231-a75b-4d92-aa3a-2e9f6d57aa06"
          />
          <path
            d="M819.63 225.18H769.8V193.6h49.83zm-46.52-3.82h43.25v-23.94h-43.25z"
            data-name="a51f2291-57de-4ccd-908a-0ce8d5c47cf6"
          />
          <path
            d="M819.63 206.63H769.8v-13h49.83zm-46.52-3.82h43.25v-5.4h-43.25z"
            data-name="b3cb31cd-6a48-42b6-92dc-f1806982b1f6"
          />
          <path
            d="M760.72 206.63H711v-13h49.76zm-46.5-3.82h43.34v-5.4h-43.34z"
            data-name="abae898a-efc7-4a8d-8c34-09e016aa44be"
          />
          <path
            d="M760.72 264.4H711v-31.5h49.76zm-46.5-3.8h43.34v-23.88h-43.34z"
            data-name="bffd8443-53e1-4487-bf77-8b242c4ae7e5"
          />
          <path
            d="M819.63 264.4H769.8v-31.5h49.83zm-46.52-3.8h43.25v-23.88h-43.25z"
            data-name="b915f40c-613b-4fab-8b4f-3060f79dcd35"
          />
          <path
            d="M819.63 245.93H769.8v-13h49.83zm-46.52-3.81h43.25v-5.4h-43.25z"
            data-name="af8109ba-e33d-4052-aad6-90fbedbce3db"
          />
          <path
            d="M760.72 245.93H711v-13h49.76zm-46.5-3.81h43.34v-5.4h-43.34z"
            data-name="a9f300f4-d880-4d00-83d9-a35f5d949f56"
          />
          <path
            d="M760.72 303.71H711V272.2h49.76zm-46.5-3.82h43.34V276h-43.34z"
            data-name="b9c1e727-40b9-477d-b997-0b6fcc83f721"
          />
          <path
            d="M819.63 303.71H769.8V272.2h49.83zM773 299.89h43.25V276H773z"
            data-name="ba7567df-0421-47aa-b124-2d21d514347e"
          />
          <path
            d="M819.63 285.24H769.8v-13h49.83zm-46.52-3.82h43.25V276h-43.25z"
            data-name="f078b429-11a7-4fe3-a23b-214f14734d29"
          />
          <path
            d="M760.72 285.24H711v-13h49.76zm-46.5-3.82h43.34V276h-43.34z"
            data-name="ba83c621-92c0-44b5-ae9b-31b651dc5356"
          />
          <path
            d="M760.72 343H711v-31.5h49.76zm-46.5-3.71h43.34v-24h-43.34z"
            data-name="e2f0754b-fcf3-4d12-8ca7-8e4a883ad4b9"
          />
          <path
            d="M819.63 343H769.8v-31.5h49.83zM773 339.32h43.25v-24H773z"
            data-name="b4f1acd7-1d77-446e-a34e-6c55f24d2783"
          />
          <path
            d="M819.63 324.54H769.8v-13h49.83zm-46.63-3.9h43.25v-5.4H773z"
            data-name="fd3eb1a4-abda-403e-92eb-6ae520da5159"
          />
          <path
            d="M760.72 324.54H711v-13h49.76zm-46.5-3.9h43.34v-5.4h-43.34z"
            data-name="a5877ac0-cb8f-4c75-a6e2-383d38a46f6c"
          />
          <path
            d="M760.72 382.33H711v-31.5h49.76zm-46.5-3.81h43.34v-23.89h-43.34z"
            data-name="a74bb16a-22bb-4cfc-8b02-e10d85102b19"
          />
          <path
            d="M819.63 382.33H769.8v-31.5h49.83zm-46.52-3.81h43.25v-23.89h-43.25z"
            data-name="e2a71c0e-381b-4314-b939-67ec2af7a072"
          />
          <path
            d="M819.63 363.85H769.8v-13h49.83zM773 360.07h43.25v-5.4H773z"
            data-name="a068df47-6302-4f04-be33-5f7a4820496c"
          />
          <path
            d="M760.72 363.85H711v-13h49.76zm-46.5-3.78h43.34v-5.4h-43.34z"
            data-name="b437efa8-b696-4ce3-b3b1-cbda04df9fe0"
          />
          <path
            d="M760.72 421.64H711v-31.5h49.76zm-46.5-3.82h43.34V394h-43.34z"
            data-name="a8ad936a-5d61-4ace-8792-e03636d5a026"
          />
          <path
            d="M819.63 421.64H769.8v-31.5h49.83zm-46.52-3.82h43.25V394h-43.25z"
            data-name="b303bfa2-9edd-43ff-9a1d-b791d7009716"
          />
          <path
            d="M819.63 403.15H769.8v-13h49.83zm-46.52-3.8h43.25V394h-43.25z"
            data-name="bc3c01db-cb3d-4f61-92b6-4b0adde0ccae"
          />
          <path
            d="M760.72 403.15H711v-13h49.76zm-46.5-3.8h43.34V394h-43.34z"
            data-name="f16324e4-10d5-4030-a16c-592346b53c31"
          />
          <path
            d="M760.72 460.94H711v-31.5h49.76zm-46.5-3.82h43.34v-23.86h-43.34z"
            data-name="b7a6c049-38f3-4eda-88a2-707d7dbf0494"
          />
          <path
            d="M819.63 460.94H769.8v-31.5h49.83zm-46.52-3.82h43.25v-23.86h-43.25z"
            data-name="ffeaf7a8-7401-48e9-98fc-299affb63c8c"
          />
          <path
            d="M819.63 442.45H769.8v-13h49.83zm-46.52-3.8h43.25v-5.39h-43.25z"
            data-name="b6c28664-8aef-481d-a6cc-e0b0ec59b652"
          />
          <path
            d="M760.72 442.45H711v-13h49.76zm-46.5-3.8h43.34v-5.39h-43.34z"
            data-name="a8764683-025a-4001-bbd9-bc7086f8de3d"
          />
          <path
            d="M760.72 500.24H711v-31.5h49.76zm-46.5-3.81h43.34v-23.87h-43.34z"
            data-name="b5615470-018d-4cfd-961e-bf6711ef5ecf"
          />
          <path
            d="M819.63 500.24H769.8v-31.5h49.83zm-46.52-3.81h43.25v-23.87h-43.25z"
            data-name="b9cb8b0f-2ff5-4711-9d7c-f2ae6ee8f74d"
          />
          <path
            d="M819.63 481.76H769.8v-13h49.83zM773.11 478h43.25v-5.4h-43.25z"
            data-name="ad9e403e-8849-49d8-8184-dbfd2aa9ffeb"
          />
          <path
            d="M760.72 481.76H711v-13h49.76zm-46.5-3.8h43.34v-5.4h-43.34z"
            data-name="bc7485de-2edd-41c2-a9e6-af26ca75c4eb"
          />
          <path
            d="M760.72 539.55H711v-31.5h49.76zm-46.5-3.82h43.34v-23.86h-43.34z"
            data-name="a39b7ea3-a9d7-4bd7-86c3-7b023f95f47f"
          />
          <path
            d="M819.63 539.55H769.8v-31.5h49.83zm-46.52-3.82h43.25v-23.86h-43.25z"
            data-name="a980434d-2ffc-428a-9c4b-042ce38d719c"
          />
          <path
            d="M819.63 521.08H769.8v-13h49.83zm-46.52-3.82h43.25v-5.4h-43.25z"
            data-name="be89322a-46dc-4097-b56b-a28f3cf1ad3f"
          />
          <path
            d="M760.72 521.08H711v-13h49.76zm-46.5-3.82h43.34v-5.4h-43.34z"
            data-name="a366d17c-dc3e-4fa4-8a9f-1f52541e2bbc"
          />
          <path
            d="M760.72 578.85H711v-31.5h49.76zm-46.5-3.85h43.34v-23.83h-43.34z"
            data-name="bf47c8ae-4656-40a1-98a1-803254e7d3b4"
          />
          <path
            d="M819.63 578.85H769.8v-31.5h49.83zM773.11 575h43.25v-23.83h-43.25z"
            data-name="a9e97eed-c96b-4641-8883-e591d3e2a733"
          />
          <path
            d="M819.63 560.38H769.8v-13h49.83zm-46.52-3.81h43.25v-5.41h-43.25z"
            data-name="a58d8759-4a25-48ce-ac20-39083a40c52b"
          />
          <path
            d="M760.72 560.38H711v-13h49.76zm-46.5-3.81h43.34v-5.41h-43.34z"
            data-name="ffa9d329-b1ce-4eb4-b872-e7cf0f271cb3"
          />
          <path
            d="M760.72 618.16H711v-31.5h49.76zm-46.5-3.8h43.34v-24h-43.34z"
            data-name="e86d49cd-4c0b-47fc-be5d-5497ba9b5b70"
          />
          <path
            d="M819.63 618.16H769.8v-31.5h49.83zm-46.52-3.8h43.25v-24h-43.25z"
            data-name="a61ee489-bd23-4740-ac26-dfb69fc76959"
          />
          <path
            d="M819.63 599.69H769.8v-13h49.83zm-46.52-3.82h43.25v-5.46h-43.25z"
            data-name="a660d786-07e3-4931-a2c0-3d0b4095949c"
          />
          <path
            d="M760.72 599.69H711v-13h49.76zm-46.5-3.82h43.34v-5.46h-43.34z"
            data-name="ae799383-d07f-44e3-ae83-d60d1943802b"
          />
          <path
            d="M857.45 146.49h-15.27V115h15.27zm-12-3.82h8.76v-23.86h-8.76z"
            data-name="aa7bcd98-cb33-42fc-807b-8bd487ef2cb1"
          />
          <path
            d="M880.33 185.75h-15.27v-31.46h15.27zm-12-3.82h8.77v-23.82h-8.8z"
            data-name="e03b5007-a932-41ec-98b6-f3e1e409f7fb"
          />
          <path
            d="M855.87 500.24h-15.26v-31.5h15.26zm-12-3.81h8.76v-23.87h-8.76z"
            data-name="edbb9d0a-b15a-42ad-a4af-0c7a809d0d1a"
          />
          <path
            d="M874.32 343h-15.27v-31.5h15.27zm-12-3.82h8.76v-23.85h-8.76z"
            data-name="e1759e68-9622-4549-b024-fadf0f030aaf"
          />
          <path
            d="M861.88 264.4h-15.26v-31.5h15.26zm-12-3.8h8.87v-23.88h-8.87z"
            data-name="bd6068c2-2ffe-4b54-8263-9c3465643b92"
          />
          <path
            d="M892.07 421.64H876.8v-31.5h15.27zm-12-3.82h8.75V394H880z"
            data-name="aadd95fd-a0f9-4dcc-b789-6a5287692747"
          />
          <path
            d="M874.32 578.85h-15.27v-31.5h15.27zm-12-3.82h8.76v-23.86h-8.76z"
            data-name="e7c5c0df-635d-4995-b468-72854b5843c8"
          />
          <path
            d="M892.07 146.49H876.8V115h15.27zm-12-3.82h8.75v-23.86H880z"
            data-name="b5178e78-4715-4630-bfb8-54792c85d277"
          />
          <path
            d="M892.07 500.24H876.8v-31.5h15.27zm-12-3.81h8.75v-23.87H880z"
            data-name="b2253b2f-7088-4fd1-b1ac-9a649d233a0b"
          />
          <path
            d="M819.63 673.54h-52.55v-27.95h52.55zm-49.3-3.82h46.1v-20.33h-46.1z"
            data-name="b801064f-1a71-4ad0-ae99-d9fca3f0a753"
          />
          <path
            d="M819.63 643.72H711v-20.11h108.63zm-105.34-3.8h102.07v-12.45H714.22z"
            data-name="e751a08a-3476-4932-99b0-e35dc0a4875a"
          />
          <path
            d="M772.74 662.5a5.35 5.35 0 01-4-2 7.31 7.31 0 01-1.68-4.74v-10.17h11.46v10.19a7.41 7.41 0 01-1.68 4.75 5.35 5.35 0 01-4 2zm-2.48-13.11v6.39a3.24 3.24 0 00.19 1.11 3.12 3.12 0 00.53.94 2.5 2.5 0 00.81.63 2.18 2.18 0 001.9 0 2.61 2.61 0 00.81-.63 3.12 3.12 0 00.53-.94 3.24 3.24 0 00.19-1.11v-6.39z"
            data-name="e3df83ed-86e3-45df-a03c-2369331f3985"
          />
          <path
            d="M781 662.5a5.34 5.34 0 01-4-2 7.31 7.31 0 01-1.68-4.74v-10.17h11.38v10.19a7.29 7.29 0 01-1.69 4.75 5.32 5.32 0 01-4.06 2zm-2.48-13.11v6.39a3.24 3.24 0 00.19 1.11 2.75 2.75 0 00.54.94 2.46 2.46 0 00.8.63 2.18 2.18 0 001.9 0 2.5 2.5 0 00.81-.63 3.12 3.12 0 00.53-.94 3.24 3.24 0 00.19-1.11v-6.39z"
            data-name="ad2aeb3c-bef8-46d6-8d44-ecc9c86c25cf"
          />
          <path
            d="M789.18 662.5a5.32 5.32 0 01-4.06-2 7.29 7.29 0 01-1.69-4.75v-10.16h11.48v10.19a7.41 7.41 0 01-1.68 4.75 5.37 5.37 0 01-4.05 1.97zm-2.48-13.11v6.39a3.35 3.35 0 00.12 1.18 3.18 3.18 0 00.52 1 2.52 2.52 0 00.83.69 2.14 2.14 0 002 0 2.63 2.63 0 00.83-.65 3.18 3.18 0 00.52-1 3.35 3.35 0 00.12-1.18v-6.39z"
            data-name="b473367e-3d24-44a8-8192-496fcabc7ee9"
          />
          <path
            d="M797.39 662.5a5.39 5.39 0 01-4.06-2 7.29 7.29 0 01-1.69-4.75v-10.16h11.48v10.19a7.41 7.41 0 01-1.68 4.75 5.37 5.37 0 01-4.05 2zm-2.48-13.11v6.39a3.19 3.19 0 00.72 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.12 3.12 0 00.73-2.05v-6.39z"
            data-name="a943c0e8-a1b4-41a8-af94-83737ef37c13"
          />
          <path
            d="M805.6 662.5a5.34 5.34 0 01-4.05-2 7.31 7.31 0 01-1.68-4.74v-10.17h11.46v10.19a7.41 7.41 0 01-1.68 4.75 5.35 5.35 0 01-4 2zm-2.48-13.11v6.39a3.19 3.19 0 00.72 2.05 2.24 2.24 0 003.15.37 2 2 0 00.36-.36 3.19 3.19 0 00.72-2.05v-6.39z"
            data-name="e801d3b9-9bac-46aa-98fb-3e0844a7f33e"
          />
          <path
            d="M813.81 662.5a5.35 5.35 0 01-4-2 7.31 7.31 0 01-1.68-4.74v-10.17h11.55v10.19a7.63 7.63 0 01-.45 2.59 7 7 0 01-1.26 2.19 5.7 5.7 0 01-2 1.44 5.13 5.13 0 01-2.22.48zm-2.48-13.11v6.39a3.19 3.19 0 00.72 2.05 2.24 2.24 0 003.15.37 2 2 0 00.36-.36 3.19 3.19 0 00.72-2.05v-6.39z"
            data-name="a5d71af0-57be-41fb-a9eb-702de33ab993"
          />
          <path
            d="M747.08 673.54H711v-27.95h36.12zm-32.86-3.82h29.6v-20.33h-29.6z"
            data-name="ae728cf9-a928-49f7-bea5-3df044a59c28"
          />
          <path
            d="M716.77 662.5a5.39 5.39 0 01-4.06-2 7.36 7.36 0 01-1.68-4.75v-10.16h11.47v10.19a7.36 7.36 0 01-1.68 4.75 5.37 5.37 0 01-4.05 2zm-2.48-13.11v6.39a3.24 3.24 0 00.19 1.11 3 3 0 00.54.94 2.46 2.46 0 00.8.63 2.24 2.24 0 00.95.22 2.15 2.15 0 001-.22 2.5 2.5 0 00.81-.63 3.15 3.15 0 00.54-.94 3.5 3.5 0 00.19-1.11v-6.39z"
            data-name="b4242768-2231-40cc-84f2-8fd3ecdee2b7"
          />
          <path
            d="M724.91 662.5a5.35 5.35 0 01-4-2 7.37 7.37 0 01-1.68-4.74v-10.17h11.46v10.19a7.36 7.36 0 01-1.68 4.75 5.37 5.37 0 01-4 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.2 3.2 0 00.73-2.05v-6.39z"
            data-name="a56e3c78-74b5-4ae9-88a6-5d54c215d3e1"
          />
          <path
            d="M733.12 662.5a5.35 5.35 0 01-4-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.48v10.19a7.81 7.81 0 01-.44 2.58 7.07 7.07 0 01-1.25 2.18 5.78 5.78 0 01-1.87 1.46 5.14 5.14 0 01-2.21.5zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.2 3.2 0 00.73-2.05v-6.39z"
            data-name="ba104285-eb9b-4b2d-a14c-ee763824c4ac"
          />
          <path
            d="M741.35 662.5a5.39 5.39 0 01-4.06-2 7.36 7.36 0 01-1.68-4.75v-10.16h11.47v10.19a7.36 7.36 0 01-1.68 4.75 5.35 5.35 0 01-4 2zm-2.48-13.11v6.39A3.37 3.37 0 00739 657a3 3 0 00.53 1 2.6 2.6 0 00.82.69 2.31 2.31 0 001 .25 2.18 2.18 0 001-.25 2.52 2.52 0 00.83-.69 3.18 3.18 0 00.52-1 3.35 3.35 0 00.13-1.18v-6.39z"
            data-name="aeda2883-987b-4686-9096-688a954e9c54"
          />
          <path
            d="M766.78 681.1h-20v-35.51h20zM750 677.28h13.53v-27.89H750z"
            data-name="f17e5162-0397-43ab-87d9-21ccb189df79"
          />
          <path
            d="M214.46 681.22H85.71V97.81H89v579.61h122.2V97.81h3.26z"
            data-name="f8f7081e-9d5f-41d8-b35a-5bd694e2d443"
          />
          <path
            d="M89 681.22H11.57V97.81h3.27v579.61h70.88V97.81H89z"
            data-name="f3416b93-6164-472a-898d-e1b72f9f1e49"
          />
          <path
            d="M217.37 99.72H11.66a9.81 9.81 0 01-7.42-3.6 13.79 13.79 0 010-17.38 9.81 9.81 0 017.42-3.6h205.71a9.81 9.81 0 017.42 3.6 13.84 13.84 0 010 17.38 9.81 9.81 0 01-7.42 3.6zM11.66 79a6.81 6.81 0 00-5.12 2.48 9.52 9.52 0 000 12A6.75 6.75 0 0011.66 96h205.71a6.71 6.71 0 005.11-2.48 9.52 9.52 0 000-12 6.71 6.71 0 00-5.11-2.52z"
            data-name="abb489b0-8f73-48bd-bc27-e1022f63289b"
          />
          <path
            d="M204.39 146.49h-49.76V115h49.76zm-46.52-3.82h43.25v-23.86h-43.25z"
            data-name="b28c5a79-8873-40ea-9a39-dbc81de62c13"
          />
          <path
            d="M145.55 146.49H95.79V115h49.76zm-46.5-3.82h43.25v-23.86H99.05z"
            data-name="aa5e021f-a5bd-4044-9d3f-1355d7e50ba1"
          />
          <path
            d="M145.55 128H95.79v-13h49.76zm-46.5-3.82h43.25v-5.39H99.05z"
            data-name="b3e9150a-ef11-419b-92aa-cec5ac9edd75"
          />
          <path
            d="M204.39 128h-49.76v-13h49.76zm-46.52-3.82h43.25v-5.39h-43.25z"
            data-name="b7353741-4a0d-421b-834f-a5f988bf75d5"
          />
          <path
            d="M204.39 185.75h-49.76v-31.46h49.76zm-46.52-3.82h43.25v-23.82h-43.25z"
            data-name="aea3edf0-da5c-4f6b-9660-535cfe620402"
          />
          <path
            d="M145.55 185.75H95.79v-31.46h49.76zm-46.5-3.82h43.25v-23.82H99.05z"
            data-name="a6706dd5-ba5a-4cfd-a1cc-7c0ae795c834"
          />
          <path
            d="M145.55 167.33H95.79v-13h49.76zm-46.5-3.82h43.25v-5.4H99.05z"
            data-name="bd0845ac-4054-49ea-8b76-0ff03a64980e"
          />
          <path
            d="M204.39 167.33h-49.76v-13h49.76zm-46.52-3.82h43.25v-5.4h-43.25z"
            data-name="a6498bfc-4d05-499b-8bbb-629459aecaa5"
          />
          <path
            d="M204.39 225.18h-49.76V193.6h49.76zm-46.52-3.82h43.25v-23.94h-43.25z"
            data-name="bd64a9b9-38f7-4fb5-91a4-6f39e01c1ea3"
          />
          <path
            d="M145.55 225.18H95.79V193.6h49.76zm-46.5-3.82h43.25v-23.94H99.05z"
            data-name="abd87e76-1b8b-4958-90a1-b8d98a72cd0e"
          />
          <path
            d="M145.55 206.63H95.79v-13h49.76zm-46.5-3.82h43.25v-5.4H99.05z"
            data-name="f1b78833-7ab1-4211-b6df-8974c3a0dc9e"
          />
          <path
            d="M204.39 206.63h-49.76v-13h49.76zm-46.52-3.82h43.25v-5.4h-43.25z"
            data-name="fc439436-125e-42a5-baec-d2f4ca7c17f2"
          />
          <path
            d="M204.39 264.4h-49.76v-31.5h49.76zm-46.52-3.8h43.25v-23.88h-43.25z"
            data-name="ec31a39c-3941-47db-b96a-ee17c865a6c0"
          />
          <path
            d="M145.55 264.4H95.79v-31.5h49.76zm-46.5-3.8h43.25v-23.88H99.05z"
            data-name="b18b0fe0-485b-46d1-9137-011d5123e4ca"
          />
          <path
            d="M145.55 245.93H95.79v-13h49.76zm-46.5-3.81h43.25v-5.4H99.05z"
            data-name="b7a03656-72f1-46ed-9b8e-f470a5f13d20"
          />
          <path
            d="M204.39 245.93h-49.76v-13h49.76zm-46.52-3.81h43.25v-5.4h-43.25z"
            data-name="f5b52a9c-0023-4f4c-8409-2ec2876a718b"
          />
          <path
            d="M204.39 303.71h-49.76V272.2h49.76zm-46.52-3.82h43.25V276h-43.25z"
            data-name="b7ea46f5-a2dd-4b9d-b396-ad283e018f23"
          />
          <path
            d="M145.55 303.71H95.79V272.2h49.76zm-46.5-3.82h43.25V276H99.05z"
            data-name="b7302b9e-86af-47e1-93dd-9e715c726e64"
          />
          <path
            d="M145.55 285.24H95.79v-13h49.76zm-46.5-3.82h43.25V276H99.05z"
            data-name="ad90ace0-f766-41c7-a930-bd708b94295e"
          />
          <path
            d="M204.39 285.24h-49.76v-13h49.76zm-46.52-3.82h43.25V276h-43.25z"
            data-name="fc59b955-2149-4141-9270-863add288823"
          />
          <path
            d="M204.39 343h-49.76v-31.5h49.76zm-46.52-3.71h43.25v-24h-43.25z"
            data-name="f10da847-808d-4eb6-a5d5-3a992522bfa0"
          />
          <path
            d="M145.55 343H95.79v-31.5h49.76zm-46.5-3.71h43.25v-24H99.05z"
            data-name="a5d1f880-ab9f-4cbb-ad01-c12e436645fa"
          />
          <path
            d="M145.55 324.54H95.79v-13h49.76zm-46.5-3.9h43.25v-5.4H99.05z"
            data-name="a9a5a018-ca32-44c6-9d19-09938abe4c56"
          />
          <path
            d="M204.39 324.54h-49.76v-13h49.76zm-46.52-3.9h43.25v-5.4h-43.25z"
            data-name="e9604b62-a9a9-4bad-aa07-dcfa5123fd02"
          />
          <path
            d="M204.39 382.33h-49.76v-31.5h49.76zm-46.52-3.81h43.25v-23.89h-43.25z"
            data-name="a6cb4df2-5908-4697-8387-3243988233dd"
          />
          <path
            d="M145.55 382.33H95.79v-31.5h49.76zm-46.5-3.81h43.25v-23.89H99.05z"
            data-name="b87dc117-a9ce-421e-962f-dbcdf630b510"
          />
          <path
            d="M145.55 363.85H95.79v-13h49.76zm-46.5-3.78h43.25v-5.4H99.05z"
            data-name="b6974b42-028c-46b1-80db-3d6c284d600a"
          />
          <path
            d="M204.39 363.85h-49.76v-13h49.76zm-46.52-3.78h43.25v-5.4h-43.25z"
            data-name="b9f408c7-f304-4cee-b7a2-8fa10d14105f"
          />
          <path
            d="M204.39 421.64h-49.76v-31.5h49.76zm-46.52-3.82h43.25V394h-43.25z"
            data-name="b4936d49-a93b-4bee-a9b1-3a2bd11c6c14"
          />
          <path
            d="M145.55 421.64H95.79v-31.5h49.76zm-46.5-3.82h43.25V394H99.05z"
            data-name="e9530f86-0bbb-43f0-9cfd-513177e3a64e"
          />
          <path
            d="M145.55 403.15H95.79v-13h49.76zm-46.5-3.8h43.25V394H99.05z"
            data-name="f0a94f35-fa55-4742-9377-3c71a101369b"
          />
          <path
            d="M204.39 403.15h-49.76v-13h49.76zm-46.52-3.8h43.25V394h-43.25z"
            data-name="a66d4c9e-e94f-40b6-91e3-3393b24a4246"
          />
          <path
            d="M204.39 460.94h-49.76v-31.5h49.76zm-46.52-3.82h43.25v-23.86h-43.25z"
            data-name="ed153992-5bfe-41c6-ba72-24a494f3754e"
          />
          <path
            d="M145.55 460.94H95.79v-31.5h49.76zm-46.5-3.82h43.25v-23.86H99.05z"
            data-name="b8110055-1da6-4f58-84f6-fa24c25e2ba8"
          />
          <path
            d="M145.55 442.45H95.79v-13h49.76zm-46.5-3.8h43.25v-5.39H99.05z"
            data-name="f8a6f48e-286c-49df-964e-5db084f5b7be"
          />
          <path
            d="M204.39 442.45h-49.76v-13h49.76zm-46.52-3.8h43.25v-5.39h-43.25z"
            data-name="b0bf75fc-3688-4fed-89e1-6e0fb8208779"
          />
          <path
            d="M204.39 500.24h-49.76v-31.5h49.76zm-46.52-3.81h43.25v-23.87h-43.25z"
            data-name="a6ef997a-31d1-4c1e-8518-622193fc456a"
          />
          <path
            d="M145.55 500.24H95.79v-31.5h49.76zm-46.5-3.81h43.25v-23.87H99.05z"
            data-name="e383a133-1068-4773-b182-00fd9787731e"
          />
          <path
            d="M145.55 481.76H95.79v-13h49.76zM99.05 478h43.25v-5.4H99.05z"
            data-name="ac7be024-6747-4dda-80a3-81957b0610d2"
          />
          <path
            d="M204.39 481.76h-49.76v-13h49.76zM157.87 478h43.25v-5.4h-43.25z"
            data-name="e0f31364-2976-4001-886f-6503fcb8cbdf"
          />
          <path
            d="M204.39 539.55h-49.76v-31.5h49.76zm-46.52-3.82h43.25v-23.86h-43.25z"
            data-name="bf77dc4c-1f4e-4c3c-a596-63c21d0fb71b"
          />
          <path
            d="M145.55 539.55H95.79v-31.5h49.76zm-46.5-3.82h43.25v-23.86H99.05z"
            data-name="ab686c00-ab45-4291-ae8a-aa5da850dc5d"
          />
          <path
            d="M145.55 521.08H95.79v-13h49.76zm-46.5-3.82h43.25v-5.4H99.05z"
            data-name="ac79eb87-e342-42fd-9712-0a1ee26ada27"
          />
          <path
            d="M204.39 521.08h-49.76v-13h49.76zm-46.52-3.82h43.25v-5.4h-43.25z"
            data-name="b56e79cd-fe25-484c-95b8-69193153c653"
          />
          <path
            d="M204.39 578.85h-49.76v-31.5h49.76zM157.87 575h43.25v-23.83h-43.25z"
            data-name="a676bc43-6ed5-4373-a408-9b68e2ebdb07"
          />
          <path
            d="M145.55 578.85H95.79v-31.5h49.76zM99.05 575h43.25v-23.83H99.05z"
            data-name="f314f00f-ff65-47e3-8ac4-0e02cf41d1a4"
          />
          <path
            d="M145.55 560.38H95.79v-13h49.76zm-46.5-3.81h43.25v-5.41H99.05z"
            data-name="a815727a-0ec3-476e-8258-2ea0cf70fa6b"
          />
          <path
            d="M204.39 560.38h-49.76v-13h49.76zm-46.52-3.81h43.25v-5.41h-43.25z"
            data-name="eb212a7d-094a-4f15-899e-cd5862004c2b"
          />
          <path
            d="M204.39 618.16h-49.76v-31.5h49.76zm-46.52-3.8h43.25v-24h-43.25z"
            data-name="fef2d8af-c58f-4062-a26f-fb965b963074"
          />
          <path
            d="M145.55 618.16H95.79v-31.5h49.76zm-46.5-3.8h43.25v-24H99.05z"
            data-name="b4da2a06-6a50-40c0-955e-9fc8e0e57e3a"
          />
          <path
            d="M145.55 599.69H95.79v-13h49.76zm-46.5-3.82h43.25v-5.46H99.05z"
            data-name="ba07e326-e2a0-40f9-b7f3-a45a24756a1f"
          />
          <path
            d="M204.39 599.69h-49.76v-13h49.76zm-46.52-3.82h43.25v-5.46h-43.25z"
            data-name="b10935e5-9d28-4562-919e-3e5fc635d620"
          />
          <path
            d="M73.16 146.49H57.89V115h15.27zm-12-3.82h8.76v-23.86h-8.77z"
            data-name="e2e5e57b-696a-4fa6-af04-6d0a96c15913"
          />
          <path
            d="M50.3 185.75H35v-31.46h15.3zm-12-3.82H47v-23.82h-8.72z"
            data-name="e7c7fd18-42b3-4c69-b184-76f240e920b7"
          />
          <path
            d="M74.83 500.24H59.56v-31.5h15.27zm-12-3.81h8.87v-23.87h-8.9z"
            data-name="b17d7428-6f27-4d34-a999-02c95d10d610"
          />
          <path
            d="M56.3 343H41v-31.5h15.3zm-12-3.71h8.76v-24h-8.77z"
            data-name="a243f76c-93e4-49c7-b908-42643b3d2da2"
          />
          <path
            d="M68.73 264.4H53.55v-31.5h15.27zm-12-3.8h8.76v-23.88h-8.77z"
            data-name="a6c0532d-2ece-4f25-b3f7-c99c09cf8ef2"
          />
          <path
            d="M38.56 421.64H23.29v-31.5h15.27zm-12-3.82h8.74V394h-8.76z"
            data-name="e57e1177-73d9-427b-a880-7ca2b9c24cb1"
          />
          <path
            d="M56.3 578.85H41v-31.5h15.3zm-12-3.85h8.76v-23.83h-8.77z"
            data-name="bd146d49-f58a-4542-8848-cf9282da00d0"
          />
          <path
            d="M38.56 146.49H23.29V115h15.27zm-12-3.82h8.74v-23.86h-8.76z"
            data-name="b6faa4ea-a88e-4c41-b865-4890fb49cdf3"
          />
          <path
            d="M38.56 500.24H23.29v-31.5h15.27zm-12-3.81h8.74v-23.87h-8.76z"
            data-name="f90f785a-bc26-4d7c-b9ba-5df9b6888d29"
          />
          <path
            d="M148.35 673.54H95.79v-27.95h52.56zm-49.3-3.82h46.11v-20.33H99.05z"
            data-name="a6e2f212-3606-43fc-a133-06da18c3b45b"
          />
          <path
            d="M204.39 643.72H95.79v-20.11h108.6zm-105.34-3.8h102.07v-12.45H99.05z"
            data-name="a51e4d8a-4021-44bb-af07-dfdb68592b56"
          />
          <path
            d="M142.6 662.5a5.35 5.35 0 01-4-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.48v10.19a7.81 7.81 0 01-.44 2.58 7.07 7.07 0 01-1.25 2.18 5.92 5.92 0 01-1.87 1.46 5.14 5.14 0 01-2.21.5zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.14.37 2.16 2.16 0 00.37-.37 3.2 3.2 0 00.73-2.05v-6.39z"
            data-name="e01b3abd-9834-4790-932d-ed0bf49a3f8d"
          />
          <path
            d="M134.39 662.5a5.35 5.35 0 01-4-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.45v10.19a7.36 7.36 0 01-1.68 4.75 5.39 5.39 0 01-4.1 1.97zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.2 3.2 0 00.73-2.05v-6.39z"
            data-name="fcd0540a-7cee-4a83-91d8-9d5b88866429"
          />
          <path
            d="M126.25 662.5a5.39 5.39 0 01-4.06-2 7.36 7.36 0 01-1.68-4.75v-10.16H132v10.19a7.36 7.36 0 01-1.68 4.75 5.37 5.37 0 01-4 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.2 3.2 0 00.73-2.05v-6.39z"
            data-name="a233baa7-b36c-4d4e-b1c8-f56180f66c76"
          />
          <path
            d="M118 662.5a5.35 5.35 0 01-4-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.37v10.19a7.36 7.36 0 01-1.68 4.75 5.34 5.34 0 01-4.02 1.97zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.14.37 2.16 2.16 0 00.37-.37 3.12 3.12 0 00.73-2.05v-6.39z"
            data-name="bcf912a2-af6c-46bb-b56c-b1599517583a"
          />
          <path
            d="M109.74 662.5a5.35 5.35 0 01-4-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.45v10.19a7.36 7.36 0 01-1.68 4.75 5.39 5.39 0 01-4.1 1.97zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.14.37 2.16 2.16 0 00.37-.37 3.2 3.2 0 00.73-2.05v-6.39z"
            data-name="b2c61911-afeb-4513-a260-d41233d82a48"
          />
          <path
            d="M101.53 662.5a5.35 5.35 0 01-4-2 7.27 7.27 0 01-1.69-4.74v-10.17h11.47v10.19a7.36 7.36 0 01-1.68 4.75 5.35 5.35 0 01-4 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.2 3.2 0 00.73-2.05v-6.39z"
            data-name="e1b5f759-6978-456a-9330-0d333d0c6e16"
          />
          <path
            d="M204.39 673.54h-36.13v-27.95h36.13zm-32.86-3.82h29.59v-20.33h-29.59z"
            data-name="a174797a-8ea6-45a6-9cad-b862f8f088ae"
          />
          <path
            d="M198.64 662.5a5.35 5.35 0 01-4-2 7.37 7.37 0 01-1.68-4.74v-10.17h11.48v10.19a7.29 7.29 0 01-1.69 4.75 5.32 5.32 0 01-4.06 2zm-2.46-13.11v6.39a3.35 3.35 0 00.12 1.18 3.18 3.18 0 00.52 1 2.52 2.52 0 00.83.69 2.14 2.14 0 002 0 2.52 2.52 0 00.83-.69 3.18 3.18 0 00.52-1 3.35 3.35 0 00.12-1.18v-6.39z"
            data-name="be4c0f61-5f46-4883-9e5f-f35b5d6c134d"
          />
          <path
            d="M190.43 662.5a5.35 5.35 0 01-4-2 7.41 7.41 0 01-1.65-4.71v-10.2h11.48v10.19a7.63 7.63 0 01-.45 2.59 7 7 0 01-1.26 2.19 5.75 5.75 0 01-1.89 1.46 5.13 5.13 0 01-2.22.48zM188 649.39v6.39a3.19 3.19 0 00.72 2.05 2.24 2.24 0 003.15.37 2 2 0 00.36-.36 3.19 3.19 0 00.72-2.05v-6.39z"
            data-name="eef86155-eb47-4f2d-a364-ff0b0bb8515c"
          />
          <path
            d="M182.22 662.5a5.34 5.34 0 01-4.05-2 7.31 7.31 0 01-1.68-4.74v-10.17H188v10.19a7.41 7.41 0 01-1.68 4.75 5.37 5.37 0 01-4 2zm-2.48-13.11v6.39a3.19 3.19 0 00.72 2.05 2.24 2.24 0 003.15.37 2 2 0 00.36-.36 3.19 3.19 0 00.72-2.05v-6.39z"
            data-name="b16c9446-2b23-48b2-9d3a-5f7016fdabb3"
          />
          <path
            d="M174 662.5a5.1 5.1 0 01-2.2-.51 5.64 5.64 0 01-1.86-1.46 6.77 6.77 0 01-1.25-2.18 7.76 7.76 0 01-.44-2.57v-10.19h11.48v10.19a7.41 7.41 0 01-1.68 4.75 5.37 5.37 0 01-4.05 1.97zm-2.48-13.11v6.39a3.19 3.19 0 00.72 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.12 3.12 0 00.73-2.05v-6.39z"
            data-name="f7ae7713-76bf-4260-81d8-90e6d040d927"
          />
          <path
            d="M168.6 681.1h-20v-35.51h20zm-16.77-3.82h13.53v-27.89h-13.53z"
            data-name="f625a635-f920-4e42-ac00-78b0e37daecf"
          />
          <path
            d="M258.93 681.22H213a1.52 1.52 0 01-1.15-.57 2.11 2.11 0 01-.47-1.34V121.59a2.11 2.11 0 01.47-1.34 1.52 1.52 0 011.15-.57h45.94a1.52 1.52 0 011.15.57 2.11 2.11 0 01.47 1.34v557.72a2.11 2.11 0 01-.47 1.34 1.54 1.54 0 01-1.16.57zm-44.33-3.8h42.7V123.5h-42.68z"
            data-name="fc203559-f6d8-4327-992b-bef10733ef16"
          />
          <path
            d="M411.46 681.22H259a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35V121.59a2 2 0 01.23-1 1.64 1.64 0 01.6-.7L410.66 19.28a1.45 1.45 0 01.81-.24 1.39 1.39 0 01.81.26 1.74 1.74 0 01.59.7 2.24 2.24 0 01.22.95v658.36a2 2 0 01-.48 1.35 1.51 1.51 0 01-1.15.56zm-150.91-3.8h149.28V24.2l-149.28 98.51z"
            data-name="ee9fb44a-1a18-4ed2-9ec6-60ad420eff11"
          />
          <path
            d="M465.4 681.22h-53.94a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35V20.94a2 2 0 01.48-1.35 1.54 1.54 0 011.15-.56h53.94a1.58 1.58 0 011.16.56 2.13 2.13 0 01.47 1.35v658.37a2.08 2.08 0 01-.47 1.35 1.54 1.54 0 01-1.16.56zm-52.31-3.8h50.68V22.85h-50.68z"
            data-name="a458717c-5ee3-4129-bd93-43d46310a75f"
          />
          <path
            d="M258.93 123.5H213a1.49 1.49 0 01-.93-.33 1.88 1.88 0 01-.6-.89 2.17 2.17 0 01-.06-1.13 1.93 1.93 0 01.49-1l92.32-100.6a1.47 1.47 0 011.1-.51h106.14a1.44 1.44 0 011 .4 2 2 0 01.58 1 2.24 2.24 0 01-.08 1.23 1.84 1.84 0 01-.69.91L259.75 123.25a1.49 1.49 0 01-.82.25zm-41.79-3.82h41.37l146.74-96.83h-99.31z"
            data-name="b6e43a88-7a16-4d87-aeb1-0852658ec604"
          />
          <path
            d="M396.15 147H274.24a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.63a2.13 2.13 0 01.47-1.35 1.58 1.58 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35V145a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65v-13.81H275.87z"
            data-name="b5c2a255-1f98-4c8b-b88a-de33ff6625e9"
          />
          <path
            d="M396.15 172.78H274.24a1.49 1.49 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35V153.3a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.53 1.53 0 01-1.16.55zM275.87 169h118.65v-13.79H275.87z"
            data-name="f9f0fd02-d80d-4dd9-83c7-b9277b694a0f"
          />
          <path
            d="M396.15 198.62H274.24a1.54 1.54 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35v-17.56a2.28 2.28 0 01.12-.73 2.25 2.25 0 01.35-.62 2.15 2.15 0 01.53-.41 1.71 1.71 0 01.63-.15h121.91a1.71 1.71 0 01.63.15 1.76 1.76 0 01.53.41 2.16 2.16 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65V181H275.87z"
            data-name="a6458072-c28d-46fd-a625-123e26f6e48c"
          />
          <path
            d="M396.15 224.48H274.24a1.58 1.58 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35V205a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65V206.9H275.87z"
            data-name="ad322325-44d3-4784-93f6-424b2e8fc4b9"
          />
          <path
            d="M396.15 250.31H274.24a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.57a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65v-13.75H275.87z"
            data-name="e39fe8ce-2dae-4f55-ac62-b02d93b4fc53"
          />
          <path
            d="M396.15 276.15H274.24a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.56a2.08 2.08 0 01.47-1.35 1.58 1.58 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65v-13.76H275.87z"
            data-name="b909acc3-76af-49dc-9732-650b9071b5ee"
          />
          <path
            d="M396.15 302H274.24a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.61a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.14 2.14 0 01-.48 1.35 1.49 1.49 0 01-1.16.6zm-120.28-3.81h118.65v-13.8H275.87z"
            data-name="b8d04e13-d07a-4914-af6a-de0149210e9e"
          />
          <path
            d="M396.15 327.84H274.24a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.58a2.13 2.13 0 01.47-1.35 1.53 1.53 0 011.16-.55h121.91a1.53 1.53 0 011.16.55 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zM275.87 324h118.65v-13.74H275.87z"
            data-name="a6826912-9bba-45c9-bb89-1c6d518cedbf"
          />
          <path
            d="M396.15 353.68H274.24a1.58 1.58 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.56a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65V336.1H275.87z"
            data-name="bf7a0bb3-6657-4027-ad8f-3ab0369d3377"
          />
          <path
            d="M396.15 379.53H274.24a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.55a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.12 2.12 0 01-.49 1.34 1.47 1.47 0 01-1.15.55zm-120.28-3.82h118.65V362H275.87z"
            data-name="a793b1e7-cd0a-480d-9c5e-134450fc1087"
          />
          <path
            d="M396.15 405.37H274.24a1.54 1.54 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35v-17.58a2.08 2.08 0 01.47-1.35 1.58 1.58 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.8h118.65v-13.76H275.87z"
            data-name="bf6e33f6-4400-4704-84a1-7ddd6546f6d7"
          />
          <path
            d="M396.15 431.2H274.24a1.54 1.54 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35v-17.55a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.53 1.53 0 01-1.16.55zm-120.28-3.82h118.65v-13.75H275.87z"
            data-name="b29efcad-06fb-4d16-a287-68c5c9e6118a"
          />
          <path
            d="M396.15 457.06H274.24a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.57a2.08 2.08 0 01.47-1.35 1.49 1.49 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65v-13.76H275.87z"
            data-name="bd11166b-5857-4f4a-8350-1575efa0bb71"
          />
          <path
            d="M396.15 482.9H274.24a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.58a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35V481a2.14 2.14 0 01-.48 1.35 1.53 1.53 0 01-1.16.55zm-120.28-3.82h118.65v-13.76H275.87z"
            data-name="a195ce83-bb85-40e9-9e40-63f2484a185c"
          />
          <path
            d="M396.15 508.73H274.24a1.54 1.54 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35v-17.55a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.55a2.14 2.14 0 01-.48 1.35 1.49 1.49 0 01-1.16.56zm-120.28-3.82h118.65v-13.73H275.87z"
            data-name="b3805952-4a3b-44f2-a559-d74e81e25f6d"
          />
          <path
            d="M396.15 534.59H274.24a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35V515.1a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65V517H275.87z"
            data-name="b15e2e6b-0b07-4ae4-b8df-52fdf5a96670"
          />
          <path
            d="M396.15 560.42H274.24a1.24 1.24 0 01-.63-.14 1.76 1.76 0 01-.53-.41 2.25 2.25 0 01-.35-.62 2.28 2.28 0 01-.12-.73v-17.58a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.16 2.16 0 01-.48 1.35 1.76 1.76 0 01-.53.41 1.24 1.24 0 01-.63.14zm-120.28-3.81h118.65v-13.76H275.87z"
            data-name="ac130e3e-81fe-4f28-b0dd-df8784040714"
          />
          <path
            d="M396.15 586.26H274.24a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35V566.8a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.53 1.53 0 01-1.16.55zm-120.28-3.82h118.65v-13.73H275.87z"
            data-name="afd8ccbe-c229-4660-beda-bcb822052ca0"
          />
          <path
            d="M396.15 118.48h-61a1.52 1.52 0 01-1.15-.57 2.11 2.11 0 01-.47-1.34V85.09a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.15-.56h61a1.52 1.52 0 01.63.14 1.64 1.64 0 01.53.42 2 2 0 01.35.62 2.24 2.24 0 01.13.73v31.48a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-59.33-3.82h57.7V87h-57.7z"
            data-name="a0a26870-70ac-40e2-b964-5e581944fbaf"
          />
          <path
            d="M238.27 574.7h-15.6a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.45a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.6a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.45a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-14-3.82h12.43v-13.67h-12.42z"
            data-name="fbed7fba-a2b9-484b-a187-aafbd0fa73ef"
          />
          <path
            d="M253.93 198.56h-15.6a1.58 1.58 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35V179.2a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h15.6a1.54 1.54 0 011.16.56 2.13 2.13 0 01.47 1.35v17.45a2.08 2.08 0 01-.47 1.35 1.54 1.54 0 01-1.16.56zm-14-3.8h12.41V181.1H240z"
            data-name="ad3fec38-eb98-4b1a-a81d-8d5dbe0d3660"
          />
          <path
            d="M253.93 327.78h-15.6a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.45a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.16-.56h15.6a1.49 1.49 0 011.16.56 2.08 2.08 0 01.47 1.35v17.45a2.13 2.13 0 01-.47 1.35 1.54 1.54 0 01-1.16.56zM240 324h12.41v-13.7H240z"
            data-name="ada59df2-934f-4509-b139-03d991bc802c"
          />
          <path
            d="M253.93 431.14h-15.6a1.55 1.55 0 01-1.15-.55 2 2 0 01-.48-1.34v-17.47a2 2 0 01.48-1.34 1.56 1.56 0 011.15-.55h15.6a1.56 1.56 0 011.15.55 2 2 0 01.48 1.34v17.47a2 2 0 01-.48 1.34 1.55 1.55 0 01-1.15.55zm-14-3.8h12.41v-13.65H240z"
            data-name="be7d581e-d5c3-4801-ae2f-133317ef7047"
          />
          <path
            d="M253.93 457h-15.6a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.45a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h15.6a1.49 1.49 0 011.16.56 2.13 2.13 0 01.47 1.35v17.45a2.13 2.13 0 01-.47 1.35 1.54 1.54 0 01-1.16.56zm-14-3.82h12.41v-13.63H240z"
            data-name="a32ad156-02e9-4456-b8ed-0abaf4852145"
          />
          <path
            d="M238.27 172.72h-15.6a1.48 1.48 0 01-.62-.14 1.64 1.64 0 01-.53-.42 1.83 1.83 0 01-.36-.62 2.24 2.24 0 01-.12-.73v-17.45a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.6a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.45a2.24 2.24 0 01-.13.73 2 2 0 01-.35.62 1.64 1.64 0 01-.53.42 1.52 1.52 0 01-.63.14zm-14-3.82h12.43v-13.63h-12.42z"
            data-name="a662415f-68cb-4b02-a899-b59101b12943"
          />
          <path
            d="M238.27 378.74h-15.6a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.45a2 2 0 01.48-1.35 1.54 1.54 0 011.15-.56h15.6a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.45a2.14 2.14 0 01-.48 1.35 1.49 1.49 0 01-1.16.56zm-14-3.81h12.43v-13.64h-12.42z"
            data-name="a7a1ae77-fd77-4968-a358-10dce92d3cf1"
          />
          <path
            d="M238.27 508.67h-15.6a1.56 1.56 0 01-1.15-.55 2.11 2.11 0 01-.48-1.34v-17.47a2.11 2.11 0 01.48-1.34 1.56 1.56 0 011.15-.55h15.6a1.55 1.55 0 011.15.55 2.12 2.12 0 01.49 1.34v17.47a2.12 2.12 0 01-.49 1.34 1.55 1.55 0 01-1.15.55zm-14-3.8h12.43v-13.65h-12.42z"
            data-name="b61595d9-162c-48e0-95fb-96a83efc0893"
          />
          <path
            d="M238.27 273.7h-15.6a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.45a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.6a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.45a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-14-3.82h12.43v-13.57h-12.42z"
            data-name="a6ac25fb-dabf-4c5f-92a7-66e82758549b"
          />
          <path
            d="M392.59 636.31h-79.11a1.54 1.54 0 01-1.14-.56 2.11 2.11 0 01-.47-1.34v-31.13a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.14-.56h79.11a1.47 1.47 0 011.15.56 2 2 0 01.48 1.35v31.12a2.24 2.24 0 01-.12.73 1.83 1.83 0 01-.36.62 1.76 1.76 0 01-.53.41 1.32 1.32 0 01-.62.15zm-77.48-3.81H391v-27.23h-75.89z"
            data-name="b125507c-8ee8-4be6-8f7f-591fe3881696"
          />
          <path
            d="M392.59 681.22h-18.67a1.54 1.54 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35v-35.67a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h18.67a1.51 1.51 0 011.15.56 2 2 0 01.48 1.35v35.67a2 2 0 01-.48 1.35 1.51 1.51 0 01-1.15.56zm-17.06-3.8H391v-31.87h-15.47z"
            data-name="a47faf77-c0ef-4f10-955d-06dabe14e873"
          />
          <path
            d="M368.38 675.06h-55a1.58 1.58 0 01-1.14-.57 2.11 2.11 0 01-.47-1.34v-29.51a2.11 2.11 0 01.47-1.34 1.5 1.5 0 011.14-.57h55a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v29.51a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-53.2-3.82h51.64v-25.69h-51.71z"
            data-name="b551a58c-3dd5-4a39-82ac-3738d1405273"
          />
          <path
            d="M305.36 673.85h-29.77a1.54 1.54 0 01-1.15-.56A2.14 2.14 0 01274 672v-68.64a2.11 2.11 0 01.47-1.34 1.52 1.52 0 011.15-.57h29.77a1.51 1.51 0 011.15.56 2 2 0 01.48 1.35V672a2 2 0 01-.48 1.34 1.47 1.47 0 01-1.18.51zM277.22 670h26.6v-64.73h-26.6z"
            data-name="a1324a77-b803-4109-9076-5eb900a03189"
          />
        </g>
      </g>
      <g clipPath="url(#a28356f4-0d27-47f5-b887-7ac71236c126)">
        <path
          d="M2160.62 681.22h-110.94V171.68h110.94zm-107.68-3.8h104.43V175.5h-104.43z"
          data-name="b0f2fe19-5b93-42fa-90a8-33a1b5993cf4"
          opacity="0.1"
        />
        <path
          d="M2031.06 681.22h-111V171.68H2031zm-107.68-3.8h104.43V175.5h-104.43z"
          data-name="ba0918dd-146b-446c-bc1b-73672e40cb3f"
          opacity="0.1"
        />
        <path
          d="M2052.94 681.22h-25.13V171.68h25.13zm-21.88-3.8h18.62V175.5h-18.62z"
          data-name="b5fd58a2-080c-4c19-a7c3-496c6f4919ab"
          opacity="0.1"
        />
        <path
          d="M1958.86 216.88h-28.76V183.1h28.76zm-25.51-3.82h22.22V186.9h-22.22z"
          data-name="ed0df0f2-3b93-4322-8774-862da2925fde"
          opacity="0.1"
        />
        <path
          d="M1990 216.88h-28.78V183.1H1990zm-25.57-3.88h22.26v-26.1h-22.26z"
          data-name="b58f889e-bd5c-4cf1-be91-9e7e99bcac25"
          opacity="0.1"
        />
        <path
          d="M2021.18 216.88h-28.85V183.1h28.85zm-25.52-3.82h22.27V186.9h-22.36z"
          data-name="ec86fa2f-12b2-4597-82be-ebd03d2ad80c"
          opacity="0.1"
        />
        <path
          d="M1958.86 253h-28.76v-33.75h28.76zm-25.51-3.8h22.22v-26.09h-22.22z"
          data-name="f46af721-15f0-4af7-a08c-892d6877d965"
          opacity="0.1"
        />
        <path
          d="M1990 253h-28.78v-33.75H1990zm-25.56-3.8h22.26v-26.09h-22.26z"
          data-name="fc65c04e-dce5-48ec-af93-0f02ee25d0e4"
          opacity="0.1"
        />
        <path
          d="M2021.18 253h-28.85v-33.75h28.85zm-25.52-3.8h22.27v-26.09h-22.36z"
          data-name="af7c4e18-95ea-4252-b883-08af733e613e"
          opacity="0.1"
        />
        <path
          d="M1958.86 289.14h-28.76V255.4h28.76zm-25.51-3.78h22.22v-26.15h-22.22z"
          data-name="e9671026-1d14-438e-a6a2-e0df207f9e61"
          opacity="0.1"
        />
        <path
          d="M1990 289.14h-28.78V255.4H1990zm-25.56-3.78h22.26v-26.15h-22.26z"
          data-name="a8dd9727-13d2-4f09-83b8-5a72ecec05f8"
          opacity="0.1"
        />
        <path
          d="M2021.18 289.14h-28.85V255.4h28.85zm-25.61-3.78h22.28v-26.15h-22.28z"
          data-name="bafc38f2-da7b-40a8-8f93-6e1eecc4e223"
          opacity="0.1"
        />
        <path
          d="M1958.86 325.29h-28.76v-33.7h28.76zm-25.51-3.82h22.22v-26.11h-22.22z"
          data-name="a3a510d4-51f2-4f30-a6e7-27542ef94dac"
          opacity="0.1"
        />
        <path
          d="M1990 325.29h-28.78v-33.7H1990zm-25.56-3.82h22.26v-26.11h-22.26z"
          data-name="b46285a8-e8d3-4625-8886-be399cd5178a"
          opacity="0.1"
        />
        <path
          d="M2021.18 325.29h-28.85v-33.7h28.85zm-25.52-3.82h22.27v-26.11h-22.36z"
          data-name="b65801fd-62e1-41bf-9160-107659f462d5"
          opacity="0.1"
        />
        <path
          d="M1958.86 361.44h-28.76V327.7h28.76zm-25.51-3.82h22.22v-26.11h-22.22z"
          data-name="b21d5a46-080d-4c74-90bd-f5192be9ac2a"
          opacity="0.1"
        />
        <path
          d="M1990 361.44h-28.78V327.7H1990zm-25.56-3.82h22.26v-26.11h-22.26z"
          data-name="e25dd844-cebc-4879-9160-072be6e478af"
          opacity="0.1"
        />
        <path
          d="M2021.18 361.44h-28.85V327.7h28.85zm-25.52-3.82h22.27v-26.11h-22.36z"
          data-name="bf2092a2-f122-43f1-9b49-919a7ab8c6ad"
          opacity="0.1"
        />
        <path
          d="M1958.86 397.59h-28.76v-33.75h28.76zm-25.51-3.82h22.22v-26.11h-22.22z"
          data-name="e1bdf3de-b048-434b-8339-a995fd542687"
          opacity="0.1"
        />
        <path
          d="M1990 397.59h-28.78v-33.75H1990zm-25.56-3.82h22.26v-26.11h-22.26z"
          data-name="bb3a4793-dd7a-4c6a-a7b9-dad71a0d0c94"
          opacity="0.1"
        />
        <path
          d="M2021.18 397.59h-28.85v-33.75h28.85zm-25.52-3.82h22.27v-26.11h-22.36z"
          data-name="a3d7a521-51ac-4a67-8dc3-977b1732c39f"
          opacity="0.1"
        />
        <path
          d="M1958.86 433.74h-28.76V400h28.76zm-25.51-3.82h22.22v-26.11h-22.22z"
          data-name="f9a53d25-d941-41c1-932b-225df4ab0eb2"
          opacity="0.1"
        />
        <path
          d="M1990 433.74h-28.78V400H1990zm-25.56-3.82h22.26v-26.11h-22.26z"
          data-name="baf98890-f5aa-456c-a61b-0b5418232cc4"
          opacity="0.1"
        />
        <path
          d="M2021.18 433.74h-28.85V400h28.85zm-25.52-3.82h22.27v-26.11h-22.36z"
          data-name="ac37fee2-4487-4f86-9cbb-c2c0889a7300"
          opacity="0.1"
        />
        <path
          d="M1958.86 469.89h-28.76v-33.73h28.76zm-25.51-3.82h22.22V440h-22.22z"
          data-name="e2405165-e7d7-4400-817e-67dc5ec8215d"
          opacity="0.1"
        />
        <path
          d="M1990 469.89h-28.78v-33.73H1990zm-25.56-3.82h22.26V440h-22.26z"
          data-name="a223b544-b8c9-4ee7-a9e6-9c36c90d71b6"
          opacity="0.1"
        />
        <path
          d="M2021.18 469.89h-28.85v-33.73h28.85zm-25.52-3.82h22.27V440h-22.36z"
          data-name="b90d03bc-ea5a-4f71-b0b7-d87a516ef1e6"
          opacity="0.1"
        />
        <path
          d="M1958.86 506h-28.76v-33.69h28.76zm-25.51-3.8h22.22v-26.09h-22.22z"
          data-name="ab8287dd-59dc-4113-b61a-c5f308882ac5"
          opacity="0.1"
        />
        <path
          d="M1990 506h-28.78v-33.69H1990zm-25.56-3.8h22.26v-26.09h-22.26z"
          data-name="b37fa80a-b20d-4f47-8621-be3b43868d39"
          opacity="0.1"
        />
        <path
          d="M2021.18 506h-28.85v-33.69h28.85zm-25.52-3.8h22.27v-26.09h-22.36z"
          data-name="a6059cdb-7730-430d-b4ff-365c5ca2522c"
          opacity="0.1"
        />
        <path
          d="M1958.86 542.21h-28.76v-33.75h28.76zm-25.51-3.82h22.22v-26.11h-22.22z"
          data-name="f31032c1-acd2-43b2-8d5e-7b52aef76461"
          opacity="0.1"
        />
        <path
          d="M1990 542.21h-28.78v-33.75H1990zm-25.56-3.82h22.26v-26.11h-22.26z"
          data-name="bcb899c8-e9bf-428a-b759-78c91cd4b6da"
          opacity="0.1"
        />
        <path
          d="M2021.18 542.21h-28.85v-33.75h28.85zm-25.52-3.82h22.27v-26.11h-22.36z"
          data-name="ebdb9427-3e9c-4289-877a-03c6192d10cb"
          opacity="0.1"
        />
        <path
          d="M1958.86 578.35h-28.76v-33.74h28.76zm-25.51-3.81h22.22v-26.1h-22.22z"
          data-name="a330637b-4726-4633-8b5e-a828ca8aba22"
          opacity="0.1"
        />
        <path
          d="M1990 578.35h-28.78v-33.74H1990zm-25.56-3.81h22.26v-26.1h-22.26z"
          data-name="b86ca31e-9582-4da9-b7ef-f1e6b0e6246e"
          opacity="0.1"
        />
        <path
          d="M2021.18 578.35h-28.85v-33.74h28.85zm-25.52-3.81h22.27v-26.1h-22.36z"
          data-name="a1d75315-9713-42b9-93b6-e7ffa8a499c1"
          opacity="0.1"
        />
        <path
          d="M2088.42 216.88h-28.78V183.1h28.78zm-25.51-3.82h22.25V186.9h-22.25z"
          data-name="f7d31dc3-55a8-4e5b-9eca-2d20429cba80"
          opacity="0.1"
        />
        <path
          d="M2119.53 216.88h-28.77V183.1h28.77zm-25.5-3.82h22.25V186.9H2094z"
          data-name="b48c87a5-a0b0-4fc1-b043-0a3cb0f7215b"
          opacity="0.1"
        />
        <path
          d="M2150.63 216.88h-28.74V183.1h28.74zm-25.52-3.82h22.28V186.9h-22.26z"
          data-name="f502b3ba-7b6e-4cf5-bc9d-a3a5d95398f1"
          opacity="0.1"
        />
        <path
          d="M2088.42 253h-28.78v-33.75h28.78zm-25.51-3.8h22.25v-26.09h-22.25z"
          data-name="a5637bfc-a297-4855-bfca-f705ed746adb"
          opacity="0.1"
        />
        <path
          d="M2119.53 253h-28.77v-33.75h28.77zm-25.5-3.8h22.25v-26.09H2094z"
          data-name="ae4b1cfb-7b4e-48e4-9cf8-670071401f4e"
          opacity="0.1"
        />
        <path
          d="M2150.63 253h-28.74v-33.75h28.74zm-25.52-3.8h22.28v-26.09h-22.26z"
          data-name="acec1f4d-df31-43ea-aea2-678819498608"
          opacity="0.1"
        />
        <path
          d="M2088.42 289.14h-28.78V255.4h28.78zm-25.51-3.78h22.25v-26.15h-22.25z"
          data-name="a1928c77-8b08-49b6-bfd0-a4dd7fad9c98"
          opacity="0.1"
        />
        <path
          d="M2119.53 289.14h-28.77V255.4h28.77zm-25.5-3.78h22.25v-26.15H2094z"
          data-name="afabc874-7b78-43f1-9ac4-cb64e3ba1e99"
          opacity="0.1"
        />
        <path
          d="M2150.63 289.14h-28.74V255.4h28.74zm-25.5-3.78h22.27v-26.15h-22.27z"
          data-name="bd60b8be-9977-4c36-bcf2-a40a0796bf75"
          opacity="0.1"
        />
        <path
          d="M2088.42 325.29h-28.78v-33.7h28.78zm-25.51-3.82h22.25v-26.11h-22.25z"
          data-name="b1551e51-3734-48a1-88cb-7b14cbb6d096"
          opacity="0.1"
        />
        <path
          d="M2119.53 325.29h-28.77v-33.7h28.77zm-25.5-3.82h22.25v-26.11H2094z"
          data-name="aebe9f07-9a92-4c99-a963-a35e567daaac"
          opacity="0.1"
        />
        <path
          d="M2150.63 325.29h-28.74v-33.7h28.74zm-25.52-3.82h22.28v-26.11h-22.26z"
          data-name="bca91706-337f-4fdd-a166-b24ebdb19cc9"
          opacity="0.1"
        />
        <path
          d="M2088.42 361.44h-28.78V327.7h28.78zm-25.51-3.82h22.25v-26.11h-22.25z"
          data-name="b4fb8f5e-3322-4a14-a13e-84a5b5cd13e4"
          opacity="0.1"
        />
        <path
          d="M2119.53 361.44h-28.77V327.7h28.77zm-25.5-3.82h22.25v-26.11H2094z"
          data-name="edba7bac-8e96-4e43-af82-bf5d227ab01d"
          opacity="0.1"
        />
        <path
          d="M2150.63 361.44h-28.74V327.7h28.74zm-25.52-3.82h22.28v-26.11h-22.26z"
          data-name="ff1a7acd-3656-4a66-b7c1-3cb5131cbb90"
          opacity="0.1"
        />
        <path
          d="M2088.42 397.59h-28.78v-33.75h28.78zm-25.51-3.82h22.25v-26.11h-22.25z"
          data-name="a0785758-dc95-40a1-935f-bea168aafe6b"
          opacity="0.1"
        />
        <path
          d="M2119.53 397.59h-28.77v-33.75h28.77zm-25.5-3.82h22.25v-26.11H2094z"
          data-name="b0949884-be06-45cb-a3f2-0dbaddd7ccd1"
          opacity="0.1"
        />
        <path
          d="M2150.63 397.59h-28.74v-33.75h28.74zm-25.52-3.82h22.28v-26.11h-22.26z"
          data-name="b57f284e-7a0b-4249-afc2-3e7f3ad5bf7a"
          opacity="0.1"
        />
        <path
          d="M2088.42 433.74h-28.78V400h28.78zm-25.51-3.82h22.25v-26.11h-22.25z"
          data-name="aa9126ef-5833-467f-804e-857e4545d113"
          opacity="0.1"
        />
        <path
          d="M2119.53 433.74h-28.77V400h28.77zm-25.5-3.82h22.25v-26.11H2094z"
          data-name="a8884b95-1278-4994-98d7-a5c681776268"
          opacity="0.1"
        />
        <path
          d="M2150.63 433.74h-28.74V400h28.74zm-25.52-3.82h22.28v-26.11h-22.26z"
          data-name="fe0bc070-323e-412f-ae08-60c881d503f4"
          opacity="0.1"
        />
        <path
          d="M2088.42 469.89h-28.78v-33.73h28.78zm-25.51-3.82h22.25V440h-22.25z"
          data-name="a3cc5aad-ed7f-4195-903b-1de5c4c546a1"
          opacity="0.1"
        />
        <path
          d="M2119.53 469.89h-28.77v-33.73h28.77zm-25.5-3.82h22.25V440H2094z"
          data-name="b4b47c95-0564-4d7c-bcf3-5964954937bb"
          opacity="0.1"
        />
        <path
          d="M2150.63 469.89h-28.74v-33.73h28.74zm-25.52-3.82h22.28V440h-22.26z"
          data-name="b8457990-eeeb-4293-a084-7869ef0094f7"
          opacity="0.1"
        />
        <path
          d="M2088.42 506h-28.78v-33.69h28.78zm-25.51-3.8h22.25v-26.09h-22.25z"
          data-name="b56a4a0e-88ad-4924-9e3d-5edf3b846091"
          opacity="0.1"
        />
        <path
          d="M2119.53 506h-28.77v-33.69h28.77zm-25.5-3.8h22.25v-26.09H2094z"
          data-name="e39c7fbb-c9e4-418d-aa0e-51001ef22f38"
          opacity="0.1"
        />
        <path
          d="M2150.63 506h-28.74v-33.69h28.74zm-25.52-3.8h22.28v-26.09h-22.26z"
          data-name="a36b4657-34cf-4bc6-ac90-f4d581484193"
          opacity="0.1"
        />
        <path
          d="M2088.42 542.21h-28.78v-33.75h28.78zm-25.51-3.82h22.25v-26.11h-22.25z"
          data-name="e0f9a193-be19-44be-86e4-39b9aa3af03b"
          opacity="0.1"
        />
        <path
          d="M2119.53 542.21h-28.77v-33.75h28.77zm-25.5-3.82h22.25v-26.11H2094z"
          data-name="eddf43dd-7172-4ffc-9029-34a897458fe6"
          opacity="0.1"
        />
        <path
          d="M2150.63 542.21h-28.74v-33.75h28.74zm-25.52-3.82h22.28v-26.11h-22.26z"
          data-name="e1d0973f-e409-4c6b-9688-bb290792f9f5"
          opacity="0.1"
        />
        <path
          d="M2088.42 578.35h-28.78v-33.74h28.78zm-25.51-3.81h22.25v-26.1h-22.25z"
          data-name="e9ddb125-a1cd-439d-a026-f5325b139b6d"
          opacity="0.1"
        />
        <path
          d="M2119.53 578.35h-28.77v-33.74h28.77zm-25.5-3.81h22.25v-26.1H2094z"
          data-name="e2c23126-d8dc-4ad8-9f6c-c4fe826c872c"
          opacity="0.1"
        />
        <path
          d="M2150.63 578.35h-28.74v-33.74h28.74zm-25.52-3.81h22.28v-26.1h-22.26z"
          data-name="af97d492-d32d-4181-a8c5-0902733ace32"
          opacity="0.1"
        />
        <path
          d="M2147.49 175.5h-214.27V37.65h3.26v134h207.77v-134h3.24z"
          data-name="b4f2e26b-f099-41f6-bc32-00fb1cfd1840"
          opacity="0.1"
        />
        <path
          d="M2147.51 175.5h-214.27v-11.87h214.27zm-211-3.82h207.76v-4.15h-207.78z"
          data-name="f3c701e3-e3d7-4fad-9a45-48daedbfe74e"
          opacity="0.1"
        />
        <path
          d="M2130.74 83.61H1950V53.79h180.74zm-177.48-3.81h174.32V57.61h-174.32z"
          data-name="aa1ee36d-07df-4f10-8b03-79f922f71329"
          opacity="0.1"
        />
        <path
          d="M2130.74 120H1950V90.13h180.74zm-177.48-3.82h174.32V94h-174.32z"
          data-name="be4f5d08-14a3-47db-8320-0b26a01f4427"
          opacity="0.1"
        />
        <path
          d="M2130.74 156.26H1950v-29.8h180.74zm-177.48-3.71h174.32v-22.29h-174.32z"
          data-name="a907c332-2865-4afa-a2d2-e281e182b0bf"
          opacity="0.1"
        />
        <path
          d="M2152.28 39.56h-223.81V22.65h223.81zm-220.57-3.8H2149v-9.32h-217.29z"
          data-name="a3b6b3ad-c6cd-4fb2-b8e6-d6962b50726a"
          opacity="0.1"
        />
        <path
          d="M2021.18 628.8h-91.08v-39.95h91.08zm-87.75-3.8h84.5v-32.33h-84.58z"
          data-name="b8eaa39e-28e6-4f79-ab34-22632b2dd299"
          opacity="0.1"
        />
        <path
          d="M1954.08 681h-24v-45.29h24zm-20.73-3.81h17.46v-37.66h-17.46z"
          data-name="baf5f22b-df9f-48c6-8d5d-f5efd7d34689"
          opacity="0.1"
        />
        <path
          d="M2021.18 673.85H1957v-38.14h64.23zm-60.9-3.81h57.65v-30.5h-57.74z"
          data-name="ec9480c5-ecb0-4ec9-8a27-9b81c85add44"
          opacity="0.1"
        />
        <path
          d="M2150.63 610.73h-90.83v-21.88h90.83zm-87.58-3.73h84.36v-14.24h-84.36z"
          data-name="aa53aee4-6881-40a3-86d2-c197eaa9efad"
          opacity="0.1"
        />
        <path
          d="M2150.63 632.21h-90.83v-21.88h90.83zm-87.6-3.82h84.36v-14.24h-84.34z"
          data-name="a410030d-f2ca-4632-ac33-73741a3c6f26"
          opacity="0.1"
        />
        <path
          d="M2150.63 681.22h-21.28v-45.51h21.28zm-17.94-3.8h14.7v-37.89h-14.7z"
          data-name="bfcc83cc-7751-411f-a236-8c6da552ce26"
          opacity="0.1"
        />
        <path
          d="M2127.58 673.85h-67.78v-38.14h67.78zM2063 670h61.29v-30.5H2063z"
          data-name="f57b3fc6-b524-4f8e-b069-ba1aeabefa4d"
          opacity="0.1"
        />
        <path
          d="M2066.81 656.69a6.66 6.66 0 01-5-2.46 9.12 9.12 0 01-2-5.84v-12.76h14v12.89a9 9 0 01-2.08 5.75 6.63 6.63 0 01-4.92 2.42zm-3.76-17.16v9a4.81 4.81 0 00.21 1.77 4.37 4.37 0 00.79 1.53 3.79 3.79 0 001.25 1 3.26 3.26 0 001.5.37 3.39 3.39 0 001.5-.37 3.79 3.79 0 001.25-1 4.37 4.37 0 00.79-1.53 5.28 5.28 0 00.21-1.77v-8.95z"
          data-name="ac340f3c-e3f7-437e-8587-41ad01f4c8fc"
          opacity="0.1"
        />
        <path
          d="M2077.55 656.69a6.66 6.66 0 01-5-2.46 9.1 9.1 0 01-2-5.84v-12.76h14v12.89a9.08 9.08 0 01-2.08 5.75 6.64 6.64 0 01-4.92 2.42zm-3.74-17.16v9a5.28 5.28 0 00.21 1.77 4.37 4.37 0 00.79 1.53 3.79 3.79 0 001.25 1 3.39 3.39 0 001.5.37 3.26 3.26 0 001.5-.37 3.79 3.79 0 001.25-1 4.77 4.77 0 00.8-1.53 5 5 0 00.2-1.77v-8.95z"
          data-name="b277b87d-c9eb-419b-a5ba-f03b2efb7134"
          opacity="0.1"
        />
        <path
          d="M2088.32 656.69a6.62 6.62 0 01-5-2.43 9.1 9.1 0 01-2.08-5.87v-12.76h14v12.89a9 9 0 01-2 5.71 6.58 6.58 0 01-4.92 2.46zm-3.76-17.16v9a4.81 4.81 0 00.21 1.77 4.37 4.37 0 00.79 1.53 3.79 3.79 0 001.25 1 3.26 3.26 0 001.5.37 3.39 3.39 0 001.5-.37 3.79 3.79 0 001.25-1 4.37 4.37 0 00.79-1.53 4.81 4.81 0 00.21-1.77v-8.95z"
          data-name="f3045cb1-c545-428a-a798-597fc12e54c7"
          opacity="0.1"
        />
        <path
          d="M2099.08 656.69a6.58 6.58 0 01-5-2.43 9.1 9.1 0 01-2.08-5.87v-12.76h14v12.89a9.09 9.09 0 01-2.06 5.72 6.64 6.64 0 01-4.86 2.45zm-3.76-17.16v9a5.28 5.28 0 00.21 1.77 4.37 4.37 0 00.79 1.53 3.79 3.79 0 001.25 1 3.39 3.39 0 001.5.37 3.26 3.26 0 001.5-.37 3.79 3.79 0 001.25-1 4.77 4.77 0 00.8-1.53 5 5 0 00.2-1.77v-8.95z"
          data-name="f14cff1c-6935-43b2-b261-162a6ba8e344"
          opacity="0.1"
        />
        <path
          d="M2109.85 656.69a6.62 6.62 0 01-5-2.43 9.1 9.1 0 01-2.08-5.87v-12.76h14v12.89a9 9 0 01-2.05 5.71 6.58 6.58 0 01-4.87 2.46zm-3.75-17.16v9a5.28 5.28 0 00.21 1.77 4.57 4.57 0 00.8 1.53 3.79 3.79 0 001.25 1 3.24 3.24 0 003 0 3.79 3.79 0 001.25-1 4.57 4.57 0 00.8-1.53 5.28 5.28 0 00.21-1.77v-8.95z"
          data-name="a7627332-59db-4d4c-a9e7-a4c7b1473212"
          opacity="0.1"
        />
        <path
          d="M2120.59 656.69a6.66 6.66 0 01-5-2.46 9.1 9.1 0 01-2-5.84v-12.76h14v12.89a9 9 0 01-2.08 5.74 6.64 6.64 0 01-4.92 2.43zm-3.76-17.16v9a5.28 5.28 0 00.21 1.77 4.24 4.24 0 00.8 1.53 3.79 3.79 0 001.25 1 3.24 3.24 0 003 0 3.79 3.79 0 001.25-1 4.77 4.77 0 00.8-1.53 5 5 0 00.2-1.77v-8.95z"
          data-name="b164a2e8-9ab3-41bc-8264-e414dc3ee49e"
          opacity="0.1"
        />
        <path
          d="M2972.59 682h-214.13V171h214.13zM2763 676.66h205.05V176.25H2763z"
          data-name="e8ee264a-1e29-4996-93b5-5c59d9e67b1b"
          opacity="0.1"
          id="animationTwoStart"
        />
        <path
          d="M2832.07 217.59h-54.21v-35.28h54.21zm-49.65-5.34h45.13v-24.59h-45.13z"
          data-name="ed5caca5-5f39-435d-a0f6-59012f39e239"
          opacity="0.1"
        />
        <path
          d="M2892.63 217.59h-54.21v-35.28h54.21zm-49.63-5.34h45.13v-24.59H2843z"
          data-name="b99d0522-15b3-4ec8-b25b-a48b33536d87"
          opacity="0.1"
        />
        <path
          d="M2953.19 217.59H2899v-35.28h54.21zm-49.65-5.34h45.13v-24.59h-45.13z"
          data-name="b28204a7-b607-446d-afd6-61c21b199dfb"
          opacity="0.1"
        />
        <path
          d="M2832.07 253.74h-54.21v-35.28h54.21zm-49.65-5.34h45.13v-24.59h-45.13z"
          data-name="aef29aff-bf96-4548-83ff-3b7e2eb54fac"
          opacity="0.1"
        />
        <path
          d="M2892.63 253.74h-54.21v-35.28h54.21zM2843 248.4h45.13v-24.59H2843z"
          data-name="b7ff206f-8a3d-4b12-bec8-735ed18b0f92"
          opacity="0.1"
        />
        <path
          d="M2953.19 253.74H2899v-35.28h54.21zm-49.65-5.34h45.13v-24.59h-45.13z"
          data-name="b5058dd5-365e-4993-9f3c-a4bb7ace77f3"
          opacity="0.1"
        />
        <path
          d="M2832.07 289.89h-54.21v-35.28h54.21zm-49.65-5.34h45.13V260h-45.13z"
          data-name="e88d5410-d65f-4ffa-a454-92d592e14946"
          opacity="0.1"
        />
        <path
          d="M2892.63 289.89h-54.21v-35.28h54.21zm-49.63-5.34h45.13V260H2843z"
          data-name="a0d7f263-4298-488e-b23b-6cd94cec6d55"
          opacity="0.1"
        />
        <path
          d="M2953.19 289.89H2899v-35.28h54.21zm-49.65-5.34h45.13V260h-45.13z"
          data-name="f6ef94e5-e733-4c2a-a45e-4880aff8f9e6"
          opacity="0.1"
        />
        <path
          d="M2832.07 326h-54.21v-35.24h54.21zm-49.65-5.32h45.13v-24.57h-45.13z"
          data-name="a9744fb0-e7d9-4c6b-bf3c-ace351999a2b"
          opacity="0.1"
        />
        <path
          d="M2892.63 326h-54.21v-35.24h54.21zm-49.63-5.28h45.13v-24.61H2843z"
          data-name="abf37d9e-cab5-4cd6-981d-c41ec816ffb5"
          opacity="0.1"
        />
        <path
          d="M2953.19 326H2899v-35.24h54.21zm-49.65-5.32h45.13v-24.57h-45.13z"
          data-name="fd281d34-36ef-42e6-aa76-bef7cd354e4a"
          opacity="0.1"
        />
        <path
          d="M2832.07 362.14h-54.21v-35.28h54.21zm-49.65-5.31h45.13v-24.57h-45.13z"
          data-name="be2794e8-92ca-4683-a322-0d88c06d2be2"
          opacity="0.1"
        />
        <path
          d="M2892.63 362.14h-54.21v-35.28h54.21zm-49.63-5.31h45.13v-24.57H2843z"
          data-name="bd141295-8563-41a3-92ba-6f355647c740"
          opacity="0.1"
        />
        <path
          d="M2953.19 362.14H2899v-35.28h54.21zm-49.65-5.31h45.13v-24.57h-45.13z"
          data-name="f7352136-d302-4102-b0c4-d466a804b281"
          opacity="0.1"
        />
        <path
          d="M2832.07 398.33h-54.21v-35.27h54.21zm-49.65-5.33h45.13v-24.63h-45.13z"
          data-name="e6aa68fb-55b7-4728-a9c1-671917125f32"
          opacity="0.1"
        />
        <path
          d="M2892.63 398.33h-54.21v-35.27h54.21zM2843 393h45.13v-24.63H2843z"
          data-name="aa60deb6-c220-4af9-bda3-609be4fede41"
          opacity="0.1"
        />
        <path
          d="M2953.19 398.33H2899v-35.27h54.21zm-49.65-5.33h45.13v-24.63h-45.13z"
          data-name="b9903c35-c7f0-4b75-be0c-192fa57e2fce"
          opacity="0.1"
        />
        <path
          d="M2832.07 434.48h-54.21V399.2h54.21zm-49.65-5.31h45.13v-24.59h-45.13z"
          data-name="a4e3e9d0-5774-42a6-97eb-f70500306204"
          opacity="0.1"
        />
        <path
          d="M2892.63 434.48h-54.21V399.2h54.21zm-49.63-5.31h45.13v-24.59H2843z"
          data-name="bc1b7c98-6304-4968-9328-362b40162607"
          opacity="0.1"
        />
        <path
          d="M2953.19 434.48H2899V399.2h54.21zm-49.65-5.31h45.13v-24.59h-45.13z"
          data-name="b8c2a756-01c6-4e2f-ab74-247dce746aea"
          opacity="0.1"
        />
        <path
          d="M2832.07 470.65h-54.21v-35.28h54.21zm-49.65-5.33h45.13v-24.59h-45.13z"
          data-name="b7d1473d-98bc-4c94-b256-735881f292e3"
          opacity="0.1"
        />
        <path
          d="M2892.63 470.65h-54.21v-35.28h54.21zm-49.63-5.33h45.13v-24.59H2843z"
          data-name="bb04db45-a5a6-4935-95e5-87ceab37ad5a"
          opacity="0.1"
        />
        <path
          d="M2953.19 470.65H2899v-35.28h54.21zm-49.65-5.33h45.13v-24.59h-45.13z"
          data-name="a1798a1d-0216-488a-9379-b01a9898140a"
          opacity="0.1"
        />
        <path
          d="M2832.07 506.8h-54.21v-35.28h54.21zm-49.65-5.33h45.13v-24.59h-45.13z"
          data-name="b3090977-3209-4650-8eb1-ddf1493efbdc"
          opacity="0.1"
        />
        <path
          d="M2892.63 506.8h-54.21v-35.28h54.21zm-49.63-5.33h45.13v-24.59H2843z"
          data-name="a8e5c3d1-2b11-46a7-9d34-28b2956b0db0"
          opacity="0.1"
        />
        <path
          d="M2953.19 506.8H2899v-35.28h54.21zm-49.65-5.33h45.13v-24.59h-45.13z"
          data-name="afb66476-9c12-44f0-811f-642d31ac1a8e"
          opacity="0.1"
        />
        <path
          d="M2832.07 543h-54.21v-35.33h54.21zm-49.65-5.33h45.13V513h-45.13z"
          data-name="ac41159b-8c54-4f81-9185-23ddd80c4066"
          opacity="0.1"
        />
        <path
          d="M2892.63 543h-54.21v-35.33h54.21zm-49.63-5.38h45.13V513H2843z"
          data-name="bac5f5f7-0ce5-445a-b4ad-d8d3f865d575"
          opacity="0.1"
        />
        <path
          d="M2953.19 543H2899v-35.33h54.21zm-49.65-5.33h45.13V513h-45.13z"
          data-name="ae2b0528-b81e-4a7f-a4ed-05bb1089b7b7"
          opacity="0.1"
        />
        <path
          d="M2832.07 579.1h-54.21v-35.28h54.21zm-49.65-5.31h45.13v-24.61h-45.13z"
          data-name="e4fbe54b-4f01-4c0d-9eb8-5ed8f7678102"
          opacity="0.1"
        />
        <path
          d="M2892.63 579.1h-54.21v-35.28h54.21zm-49.63-5.31h45.13v-24.61H2843z"
          data-name="b6191ab1-955b-4457-b6b1-76fbcbf18078"
          opacity="0.1"
        />
        <path
          d="M2953.19 579.1H2899v-35.28h54.21zm-49.65-5.31h45.13v-24.61h-45.13z"
          data-name="e3d12a47-9c33-4984-b834-2036faf789b7"
          opacity="0.1"
        />
        <path
          d="M2953.19 629.55h-175.33v-41.5h175.33zm-170.79-5.31h166.25v-30.82H2782.4z"
          data-name="baea33f0-97ce-4d7b-b218-86721fb3e6e0"
          opacity="0.1"
        />
        <path
          d="M2806.75 681.22h-28.37v-45.65h28.37zm-24.83-4.15h21.28v-37.35h-21.28z"
          data-name="a5f972d3-469e-4734-a35e-9fe69e4b7652"
          opacity="0.1"
        />
        <path
          d="M2953.37 674.81h-142.15v-40h142.22zM2816 669.08h132.45v-28.6H2816z"
          data-name="e06463dc-8f3c-4dd0-81a8-f993e87c033f"
          opacity="0.1"
        />
        <path
          d="M2688.22 681.22h-128.74V97.81h3.26v579.61H2685V97.81h3.24z"
          data-name="b0bd7889-4ad4-4170-b4c4-693dedf30b73"
          opacity="0.1"
        />
        <path
          d="M2762.36 681.22H2685V97.81h3.24v579.61h70.88V97.81h3.26z"
          data-name="b9445179-5e0a-4b26-b4d3-45d42cfcff0f"
          opacity="0.1"
        />
        <path
          d="M2762.2 99.72h-205.61a9.83 9.83 0 01-7.43-3.6 13.84 13.84 0 010-17.38 9.84 9.84 0 017.43-3.6h205.61a9.84 9.84 0 017.43 3.6 13.84 13.84 0 010 17.38 9.84 9.84 0 01-7.43 3.6zM2556.59 79a6.78 6.78 0 00-5.12 2.48 9.52 9.52 0 000 12 6.75 6.75 0 005.12 2.48h205.61a6.75 6.75 0 005.12-2.48 9.52 9.52 0 000-12 6.81 6.81 0 00-5.12-2.48z"
          data-name="a1a19bdd-4aa8-400c-b020-c9bb9edff97a"
          opacity="0.1"
        />
        <path
          d="M2732.91 79h-85.48V9.86h85.48zm-82.23-3.81h79V13.68h-79z"
          data-name="a0f1a860-dd72-47af-ae14-27fcdf62040f"
          opacity="0.1"
        />
        <path
          d="M2732.91 79h-27.7V9.86h27.7zm-24.46-3.81h21.28V13.68h-21.28z"
          data-name="ebc8abcf-c928-458c-b49b-7aa776769f50"
          opacity="0.1"
        />
        <path
          d="M2732.38 13.68H2647a5.8 5.8 0 01-4.44-2.15 8 8 0 01-1.84-5.2 8 8 0 011.84-5.19A5.84 5.84 0 012647-1h85.42a5.89 5.89 0 014.44 2.15 8 8 0 011.83 5.19 8.07 8.07 0 01-1.83 5.2 5.89 5.89 0 01-4.44 2.15zM2647 2.81a2.85 2.85 0 00-2.14 1 4 4 0 000 5 2.85 2.85 0 002.14 1h85.42a2.79 2.79 0 002.13-1 4 4 0 000-5 2.79 2.79 0 00-2.13-1z"
          data-name="ed570aff-6479-4a96-a9b2-9e2153d4b41a"
          opacity="0.1"
        />
        <path
          d="M2619.31 146.49h-49.76V115h49.76zm-46.5-3.82h43.34v-23.86h-43.34z"
          data-name="eb3272b1-fd14-4527-9f87-fb020ba91075"
          opacity="0.1"
        />
        <path
          d="M2678.22 146.49h-49.83V115h49.83zm-46.52-3.82h43.3v-23.86h-43.3z"
          data-name="a56d1a2b-7573-46c2-9e66-e202a86586d0"
          opacity="0.1"
        />
        <path
          d="M2678.22 128h-49.83v-13h49.83zm-46.52-3.82h43.3v-5.39h-43.3z"
          data-name="b7c9c39a-04ff-4ad6-a8d6-eeac856e3a04"
          opacity="0.1"
        />
        <path
          d="M2619.31 128h-49.76v-13h49.76zm-46.5-3.82h43.34v-5.39h-43.34z"
          data-name="bfc15bc3-ea1c-4983-b2cf-a6cfa173c58a"
          opacity="0.1"
        />
        <path
          d="M2619.31 185.75h-49.76v-31.46h49.76zm-46.5-3.82h43.34v-23.82h-43.34z"
          data-name="abf7bc1b-7fb1-4ac7-a0df-97294f62f793"
          opacity="0.1"
        />
        <path
          d="M2678.22 185.75h-49.83v-31.46h49.83zm-46.52-3.82h43.3v-23.82h-43.3z"
          data-name="b974d24e-e747-4cd9-9f19-e037424ef014"
          opacity="0.1"
        />
        <path
          d="M2678.22 167.33h-49.83v-13h49.83zm-46.52-3.82h43.3v-5.4h-43.3z"
          data-name="fe2d39b5-8f39-4311-a5ff-c762bf15b464"
          opacity="0.1"
        />
        <path
          d="M2619.31 167.33h-49.76v-13h49.76zm-46.5-3.82h43.34v-5.4h-43.34z"
          data-name="a5d1567e-b753-4093-93d6-c20aead2aa40"
          opacity="0.1"
        />
        <path
          d="M2619.31 225.18h-49.76V193.6h49.76zm-46.5-3.82h43.34v-23.94h-43.34z"
          data-name="ba44315e-7be4-409f-a9f2-145d3a915acc"
          opacity="0.1"
        />
        <path
          d="M2678.22 225.18h-49.83V193.6h49.83zm-46.52-3.82h43.3v-23.94h-43.3z"
          data-name="f6199dea-e01c-40cc-92e0-db3daf8546f3"
          opacity="0.1"
        />
        <path
          d="M2678.22 206.63h-49.83v-13h49.83zm-46.52-3.82h43.3v-5.4h-43.3z"
          data-name="a15873a8-a1d6-4407-b0f9-633998d14a1a"
          opacity="0.1"
        />
        <path
          d="M2619.31 206.63h-49.76v-13h49.76zm-46.5-3.82h43.34v-5.4h-43.34z"
          data-name="ab0b73a4-ecd6-4e21-bcab-7ecceba876ab"
          opacity="0.1"
        />
        <path
          d="M2619.31 264.4h-49.76v-31.5h49.76zm-46.5-3.8h43.34v-23.88h-43.34z"
          data-name="aaa97f8f-e676-4ecc-8828-6421ea96a538"
          opacity="0.1"
        />
        <path
          d="M2678.22 264.4h-49.83v-31.5h49.83zm-46.52-3.8h43.3v-23.88h-43.3z"
          data-name="adc57ba8-2b31-4f27-8c36-64e53684e4b2"
          opacity="0.1"
        />
        <path
          d="M2678.22 245.93h-49.83v-13h49.83zm-46.52-3.81h43.3v-5.4h-43.3z"
          data-name="a802608e-0096-4bcc-854b-1201baa5d2ee"
          opacity="0.1"
        />
        <path
          d="M2619.31 245.93h-49.76v-13h49.76zm-46.5-3.81h43.34v-5.4h-43.34z"
          data-name="e7a9f28e-7879-43bc-a3de-afdd8a731df6"
          opacity="0.1"
        />
        <path
          d="M2619.31 303.71h-49.76V272.2h49.76zm-46.5-3.82h43.34V276h-43.34z"
          data-name="eed25c0a-b2b4-44b8-b725-13ebd644241e"
          opacity="0.1"
        />
        <path
          d="M2678.22 303.71h-49.83V272.2h49.83zm-46.59-3.82h43.26V276h-43.26z"
          data-name="fdfc9a02-f5fe-4f96-9b67-336ad9c85a0f"
          opacity="0.1"
        />
        <path
          d="M2678.22 285.24h-49.83v-13h49.83zm-46.52-3.82h43.3V276h-43.3z"
          data-name="b4c07f41-aa45-4a2c-90cc-615fa66aa6bd"
          opacity="0.1"
        />
        <path
          d="M2619.31 285.24h-49.76v-13h49.76zm-46.5-3.82h43.34V276h-43.34z"
          data-name="bc9c6b51-6d38-430b-8b91-08e0a4caed64"
          opacity="0.1"
        />
        <path
          d="M2619.31 343h-49.76v-31.5h49.76zm-46.5-3.71h43.34v-24h-43.34z"
          data-name="eed6aaaf-8728-4810-bbe8-9c96c11580fe"
          opacity="0.1"
        />
        <path
          d="M2678.22 343h-49.83v-31.5h49.83zm-46.59-3.71h43.26v-24h-43.26z"
          data-name="e1184a85-242b-4bac-b47a-5387142c0363"
          opacity="0.1"
        />
        <path
          d="M2678.22 324.54h-49.83v-13h49.83zm-46.59-3.9h43.26v-5.4h-43.26z"
          data-name="a0a1abd9-9b35-419f-9509-5fa73b686102"
          opacity="0.1"
        />
        <path
          d="M2619.31 324.54h-49.76v-13h49.76zm-46.5-3.9h43.34v-5.4h-43.34z"
          data-name="f9e38c5d-2dbf-4e2a-8bae-658cd1b38bd0"
          opacity="0.1"
        />
        <path
          d="M2619.31 382.33h-49.76v-31.5h49.76zm-46.5-3.81h43.34v-23.89h-43.34z"
          data-name="ff40e951-e6f7-45cf-b803-d11b618a4383"
          opacity="0.1"
        />
        <path
          d="M2678.22 382.33h-49.83v-31.5h49.83zm-46.52-3.81h43.3v-23.89h-43.3z"
          data-name="acb79ae6-1ffc-4ed5-8636-ef20ba34280b"
          opacity="0.1"
        />
        <path
          d="M2678.22 363.85h-49.83v-13h49.83zm-46.59-3.78h43.26v-5.4h-43.26z"
          data-name="aaca9c3b-c1da-45ce-a9c7-2e905ae94d9c"
          opacity="0.1"
        />
        <path
          d="M2619.31 363.85h-49.76v-13h49.76zm-46.5-3.78h43.34v-5.4h-43.34z"
          data-name="a8a2ec40-cb85-4617-b47d-0dad83d9e3fa"
          opacity="0.1"
        />
        <path
          d="M2619.31 421.64h-49.76v-31.5h49.76zm-46.5-3.82h43.34V394h-43.34z"
          data-name="e6f3c90c-5ef1-4bbd-8948-eee003df9fb7"
          opacity="0.1"
        />
        <path
          d="M2678.22 421.64h-49.83v-31.5h49.83zm-46.52-3.82h43.3V394h-43.3z"
          data-name="a4828227-8ccc-4b3c-ac97-b1f57d6a6c0a"
          opacity="0.1"
        />
        <path
          d="M2678.22 403.15h-49.83v-13h49.83zm-46.52-3.8h43.3V394h-43.3z"
          data-name="aa6232f1-a2a4-43d1-80ce-e79d6397dbea"
          opacity="0.1"
        />
        <path
          d="M2619.31 403.15h-49.76v-13h49.76zm-46.5-3.8h43.34V394h-43.34z"
          data-name="b8294401-bcad-4162-9b68-94e660d3a1c1"
          opacity="0.1"
        />
        <path
          d="M2619.31 460.94h-49.76v-31.5h49.76zm-46.5-3.82h43.34v-23.86h-43.34z"
          data-name="b3361f28-7e25-4cea-801a-4060d9b0ae67"
          opacity="0.1"
        />
        <path
          d="M2678.22 460.94h-49.83v-31.5h49.83zm-46.52-3.82h43.3v-23.86h-43.3z"
          data-name="ad319193-7f2a-4829-9462-d445af1f0b3c"
          opacity="0.1"
        />
        <path
          d="M2678.22 442.45h-49.83v-13h49.83zm-46.52-3.8h43.3v-5.39h-43.3z"
          data-name="fc829a62-20dc-4923-8325-fc702cebdd2d"
          opacity="0.1"
        />
        <path
          d="M2619.31 442.45h-49.76v-13h49.76zm-46.5-3.8h43.34v-5.39h-43.34z"
          data-name="e0133a52-fef0-4586-b071-1a1704e843bf"
          opacity="0.1"
        />
        <path
          d="M2619.31 500.24h-49.76v-31.5h49.76zm-46.5-3.81h43.34v-23.87h-43.34z"
          data-name="a9dd65dd-5abc-4a45-a33b-3d873f08c228"
          opacity="0.1"
        />
        <path
          d="M2678.22 500.24h-49.83v-31.5h49.83zm-46.52-3.81h43.3v-23.87h-43.3z"
          data-name="aaf5f743-8b7c-4fab-9e9a-c3fc54bc333f"
          opacity="0.1"
        />
        <path
          d="M2678.22 481.76h-49.83v-13h49.83zM2631.7 478h43.3v-5.4h-43.3z"
          data-name="ad58e6c0-880f-4523-af53-d02e7b7e29de"
          opacity="0.1"
        />
        <path
          d="M2619.31 481.76h-49.76v-13h49.76zm-46.5-3.8h43.34v-5.4h-43.34z"
          data-name="a64bbbff-c262-4e97-b8a8-96d04ce6ae34"
          opacity="0.1"
        />
        <path
          d="M2619.31 539.55h-49.76v-31.5h49.76zm-46.5-3.82h43.34v-23.86h-43.34z"
          data-name="b639d27e-b303-4f6b-b12f-19fe8ff0e237"
          opacity="0.1"
        />
        <path
          d="M2678.22 539.55h-49.83v-31.5h49.83zm-46.52-3.82h43.3v-23.86h-43.3z"
          data-name="aed969b1-ba4b-49df-abe4-787982466961"
          opacity="0.1"
        />
        <path
          d="M2678.22 521.08h-49.83v-13h49.83zm-46.52-3.82h43.3v-5.4h-43.3z"
          data-name="f84f3e92-acb9-40c1-80dd-9c5c46c14d0b"
          opacity="0.1"
        />
        <path
          d="M2619.31 521.08h-49.76v-13h49.76zm-46.5-3.82h43.34v-5.4h-43.34z"
          data-name="e25441a6-607e-4bea-861c-2e66b15529c7"
          opacity="0.1"
        />
        <path
          d="M2619.31 578.85h-49.76v-31.5h49.76zm-46.5-3.82h43.34v-23.86h-43.34z"
          data-name="acdad299-4509-4551-bd61-7a7444cf0cb9"
          opacity="0.1"
        />
        <path
          d="M2678.22 578.85h-49.83v-31.5h49.83zM2631.7 575h43.3v-23.83h-43.3z"
          data-name="b745dbdb-55c7-417b-acd7-2407b0324d30"
          opacity="0.1"
        />
        <path
          d="M2678.22 560.38h-49.83v-13h49.83zm-46.52-3.81h43.3v-5.41h-43.3z"
          data-name="a4b60737-1c34-49cf-b1b6-66db9b062ed7"
          opacity="0.1"
        />
        <path
          d="M2619.31 560.38h-49.76v-13h49.76zm-46.5-3.81h43.34v-5.41h-43.34z"
          data-name="f24f2e3d-40d2-4ec5-bb5c-879429f12b91"
          opacity="0.1"
        />
        <path
          d="M2619.31 618.16h-49.76v-31.5h49.76zm-46.5-3.8h43.34v-24h-43.34z"
          data-name="ed24e7d2-1d58-422d-b96c-ee69a5bb1f2f"
          opacity="0.1"
        />
        <path
          d="M2678.22 618.16h-49.83v-31.5h49.83zm-46.52-3.8h43.3v-24h-43.3z"
          data-name="e517ec94-db01-4c6f-a4c3-9489cc9e994b"
          opacity="0.1"
        />
        <path
          d="M2678.22 599.69h-49.83v-13h49.83zm-46.52-3.82h43.3v-5.46h-43.3z"
          data-name="b61c7462-396d-4baa-bffe-732ca18d5994"
          opacity="0.1"
        />
        <path
          d="M2619.31 599.69h-49.76v-13h49.76zm-46.5-3.82h43.34v-5.46h-43.34z"
          data-name="ec14ccf6-fdff-49c8-a398-5625aa283653"
          opacity="0.1"
        />
        <path
          d="M2716 146.49h-15.26V115H2716zm-12-3.82h8.76v-23.86H2704z"
          data-name="e2ab5281-d88a-4a19-9c80-7ea388466696"
          opacity="0.1"
        />
        <path
          d="M2738.92 185.75h-15.27v-31.46h15.27zm-12-3.82h8.76v-23.82h-8.76z"
          data-name="f672d83c-1fb4-4bbf-b25b-b3fc1bca6022"
          opacity="0.1"
        />
        <path
          d="M2714.47 500.24h-15.27v-31.5h15.27zm-12-3.81h8.76v-23.87h-8.76z"
          data-name="b6a6ba55-f1ed-415e-819e-a4d98f47f367"
          opacity="0.1"
        />
        <path
          d="M2732.91 343h-15.27v-31.5h15.27zm-12-3.82h8.76v-23.85h-8.76z"
          data-name="e37b313e-f8e5-4f10-91c8-cc151d0b69dc"
          opacity="0.1"
        />
        <path
          d="M2720.48 264.4h-15.27v-31.5h15.27zm-12-3.8h8.87v-23.88h-8.87z"
          data-name="a8b858ec-27a2-49cb-b63b-e80d2b2144d9"
          opacity="0.1"
        />
        <path
          d="M2750.66 421.64h-15.27v-31.5h15.27zm-12-3.82h8.74V394h-8.76z"
          data-name="b5365ccd-988e-4a77-98d6-6f64c31142ff"
          opacity="0.1"
        />
        <path
          d="M2732.91 578.85h-15.27v-31.5h15.27zm-12-3.82h8.76v-23.86h-8.76z"
          data-name="a8818722-13c1-46ab-a5c8-46875167861a"
          fill="rgba(37,39,51,0.1)"
        />
        <path
          d="M2750.66 146.49h-15.27V115h15.27zm-12-3.82h8.74v-23.86h-8.76z"
          data-name="bf1e7da0-cab5-471f-9758-4616183836a6"
          opacity="0.1"
        />
        <path
          d="M2750.66 500.24h-15.27v-31.5h15.27zm-12-3.81h8.74v-23.87h-8.76z"
          data-name="a8c3d237-66c4-4e58-a7e8-ae8d3f7f4145"
          opacity="0.1"
        />
        <path
          d="M2678.22 673.54h-52.54v-27.95h52.54zm-49.3-3.82H2675v-20.33h-46.11z"
          data-name="a2edb508-1546-48af-a53b-4f168944ca8a"
          opacity="0.1"
        />
        <path
          d="M2678.22 643.72h-108.67v-20.11h108.67zm-105.34-3.8H2675v-12.45h-102.19z"
          data-name="ace93232-acba-4973-9e69-19f671a04790"
          opacity="0.1"
        />
        <path
          d="M2631.33 662.5a5.35 5.35 0 01-4-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.45v10.19a7.28 7.28 0 01-1.68 4.75 5.39 5.39 0 01-4.1 1.97zm-2.48-13.11v6.39a3.24 3.24 0 00.19 1.11 3 3 0 00.54.94 2.46 2.46 0 00.8.63 2.24 2.24 0 00.95.22 2 2 0 001-.22 2.5 2.5 0 00.81-.63 3 3 0 00.54-.94 3.5 3.5 0 00.19-1.11v-6.39z"
          data-name="e763d472-b89a-49ad-a635-e8a0fa7cd250"
          opacity="0.1"
        />
        <path
          d="M2639.54 662.5a5.3 5.3 0 01-4.05-2 7.31 7.31 0 01-1.68-4.74v-10.17h11.48v10.19a7.41 7.41 0 01-1.68 4.75 5.33 5.33 0 01-4.07 2zm-2.48-13.11v6.39a3.24 3.24 0 00.19 1.11 3 3 0 00.54.94 2.46 2.46 0 00.8.63 2.24 2.24 0 00.95.22 2.07 2.07 0 00.95-.22 2.5 2.5 0 00.81-.63 3 3 0 00.54-.94 3.49 3.49 0 00.18-1.11v-6.39z"
          data-name="ea21da3b-7779-4f42-9cb5-fea8244127e5"
          opacity="0.1"
        />
        <path
          d="M2647.77 662.5a5.39 5.39 0 01-4.06-2 7.28 7.28 0 01-1.68-4.75v-10.16h11.47v10.19a7.36 7.36 0 01-1.68 4.75 5.37 5.37 0 01-4.05 2zm-2.48-13.11v6.39a3.35 3.35 0 00.12 1.18 3.18 3.18 0 00.52 1 2.76 2.76 0 00.83.69 2.31 2.31 0 001 .25 2.18 2.18 0 001-.25 2.52 2.52 0 00.83-.69 3.18 3.18 0 00.52-1 3.6 3.6 0 00.13-1.18v-6.39z"
          data-name="e7016f68-4961-4baf-a6e0-a1e73eab9d99"
          opacity="0.1"
        />
        <path
          d="M2656 662.5a5.39 5.39 0 01-4.06-2 7.28 7.28 0 01-1.68-4.75v-10.16h11.47v10.19a7.36 7.36 0 01-1.68 4.75 5.37 5.37 0 01-4.05 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.51 0 3.19 3.19 0 00.72-2.05v-6.39z"
          data-name="ae2ea05b-766d-4cae-bedd-2d069e05ec55"
          opacity="0.1"
        />
        <path
          d="M2664.19 662.5a5.37 5.37 0 01-4.05-2 7.31 7.31 0 01-1.68-4.74v-10.17h11.46v10.19a7.36 7.36 0 01-1.68 4.75 5.35 5.35 0 01-4 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.51 0 3.25 3.25 0 00.73-2.05v-6.39z"
          data-name="aeb834f7-5bd7-4acf-b430-1335c86e9af7"
          opacity="0.1"
        />
        <path
          d="M2672.4 662.5a5.37 5.37 0 01-4.05-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.54v10.19a7.87 7.87 0 01-.44 2.59 6.69 6.69 0 01-1.27 2.19 5.75 5.75 0 01-1.89 1.46 5 5 0 01-2.22.48zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.14.37 2.16 2.16 0 00.37-.37 3.2 3.2 0 00.73-2.05v-6.39z"
          data-name="a2abf3e4-0f6d-48f3-9a25-f7e976ee367e"
          opacity="0.1"
        />
        <path
          d="M2605.67 673.54h-36.12v-27.95h36.12zm-32.86-3.82h29.6v-20.33h-29.6z"
          data-name="a644c922-9f6e-4d1d-b122-60e8bafaa2e6"
          opacity="0.1"
        />
        <path
          d="M2575.37 662.5a5.43 5.43 0 01-4.07-2 7.36 7.36 0 01-1.68-4.75v-10.16h11.47v10.19a7.35 7.35 0 01-1.67 4.75 5.37 5.37 0 01-4.05 2zm-2.49-13.11v6.39a3.5 3.5 0 00.19 1.11 3.15 3.15 0 00.54.94 2.5 2.5 0 00.81.63 2.1 2.1 0 00.95.22 2.28 2.28 0 001-.22 2.46 2.46 0 00.8-.63 3 3 0 00.54-.94 3.24 3.24 0 00.19-1.11v-6.39z"
          data-name="a3480fdd-9f29-443f-962e-c6dbfa52e7af"
          opacity="0.1"
        />
        <path
          d="M2583.51 662.5a5.36 5.36 0 01-4.06-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.45v10.19a7.35 7.35 0 01-1.67 4.75 5.35 5.35 0 01-4 2zm-2.51-13.11v6.39a3.12 3.12 0 00.73 2.05 2.23 2.23 0 003.13.38 2.16 2.16 0 00.37-.37 3.12 3.12 0 00.73-2.05v-6.39z"
          data-name="a4a47ccb-a366-479b-8588-bb610136504e"
          opacity="0.1"
        />
        <path
          d="M2591.72 662.5a5.35 5.35 0 01-4-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.47v10.19a7.81 7.81 0 01-.44 2.58 6.77 6.77 0 01-1.25 2.18A5.92 5.92 0 012594 662a5 5 0 01-2.28.5zm-2.49-13.11v6.39a3.12 3.12 0 00.73 2.05 2.23 2.23 0 003.13.38 2.16 2.16 0 00.37-.37 3.12 3.12 0 00.73-2.05v-6.39z"
          data-name="b50d30c0-0be2-4036-9267-43294fd8de50"
          opacity="0.1"
        />
        <path
          d="M2599.94 662.5a5.39 5.39 0 01-4.06-2 7.28 7.28 0 01-1.68-4.75v-10.16h11.47v10.19a7.35 7.35 0 01-1.67 4.75 5.39 5.39 0 01-4.06 2zm-2.48-13.11v6.39a3.35 3.35 0 00.13 1.18 3.18 3.18 0 00.52 1 2.31 2.31 0 00.83.69 2.14 2.14 0 002 0 2.31 2.31 0 00.83-.69 3 3 0 00.53-1 3.35 3.35 0 00.12-1.18v-6.39z"
          data-name="f18ee310-0ce1-491f-a458-b90344ff893d"
          opacity="0.1"
        />
        <path
          d="M2625.37 681.1h-20v-35.51h20zm-16.79-3.82h13.53v-27.89h-13.53z"
          data-name="a8ea6fd6-5406-421b-8872-2acebf046f60"
          opacity="0.1"
        />
        <path
          d="M2359.89 681.22H2157V95.9h202.93zm-74.13-3.8h70.93V99.72h-70.93zm-125.5 0h122.24V99.72h-122.29z"
          data-name="b6fdf86a-4710-4a1b-a7f6-d71a08b3a666"
          opacity="0.1"
        />
        <path
          d="M2216.8 146.49H2167V115h49.76zm-46.52-3.82h43.25v-23.86h-43.25z"
          data-name="adc47d86-3204-4a03-a6d6-4540927a4ab2"
          opacity="0.1"
        />
        <path
          d="M2275.62 146.49h-49.76V115h49.76zm-46.5-3.82h43.25v-23.86h-43.25z"
          data-name="bbb23b1b-2679-40f8-8e72-5e8d4bc993f8"
          opacity="0.1"
        />
        <path
          d="M2275.62 128h-49.76v-13h49.76zm-46.5-3.82h43.25v-5.39h-43.25z"
          data-name="a4c78ea7-a9bc-4699-9ba8-1edfe5c04b7b"
          opacity="0.1"
        />
        <path
          d="M2216.8 128H2167v-13h49.76zm-46.52-3.82h43.25v-5.39h-43.25z"
          data-name="ace54958-21f5-41ca-9b34-213eb0904c1a"
          opacity="0.1"
        />
        <path
          d="M2216.8 185.75H2167v-31.46h49.76zm-46.52-3.82h43.25v-23.82h-43.25z"
          data-name="ffb0f739-deb4-4017-be6f-abba1a4c44a7"
          opacity="0.1"
        />
        <path
          d="M2275.62 185.75h-49.76v-31.46h49.76zm-46.5-3.82h43.25v-23.82h-43.25z"
          data-name="a735e0b9-a84b-4dd6-b7f1-f25f40d73386"
          opacity="0.1"
        />
        <path
          d="M2275.62 167.33h-49.76v-13h49.76zm-46.5-3.82h43.25v-5.4h-43.25z"
          data-name="b3117cf2-0274-4946-abfe-540faef72951"
          opacity="0.1"
        />
        <path
          d="M2216.8 167.33H2167v-13h49.76zm-46.52-3.82h43.25v-5.4h-43.25z"
          data-name="a8249b29-1818-4327-a863-d9f614e3ad0d"
          opacity="0.1"
        />
        <path
          d="M2216.8 225.18H2167V193.6h49.76zm-46.52-3.82h43.25v-23.94h-43.25z"
          data-name="a3165a4b-0eba-4892-a5b2-45d57dedfae0"
          opacity="0.1"
        />
        <path
          d="M2275.62 225.18h-49.76V193.6h49.76zm-46.5-3.82h43.25v-23.94h-43.25z"
          data-name="ed51913c-7797-42c0-888b-86d54fdbe342"
          opacity="0.1"
        />
        <path
          d="M2275.62 206.63h-49.76v-13h49.76zm-46.5-3.82h43.25v-5.4h-43.25z"
          data-name="ef2afa16-b01d-4338-ad71-5ad4d1692abf"
          opacity="0.1"
        />
        <path
          d="M2216.8 206.63H2167v-13h49.76zm-46.52-3.82h43.25v-5.4h-43.25z"
          data-name="e8020ccf-9167-4a15-b855-fa49cc3c911a"
          opacity="0.1"
        />
        <path
          d="M2216.8 264.4H2167v-31.5h49.76zm-46.52-3.8h43.25v-23.88h-43.25z"
          data-name="a8a68a45-f8ad-400d-83fb-dfa68fc7419f"
          opacity="0.1"
        />
        <path
          d="M2275.62 264.4h-49.76v-31.5h49.76zm-46.5-3.8h43.25v-23.88h-43.25z"
          data-name="b2f5d044-34e7-4467-a47a-047479884516"
          opacity="0.1"
        />
        <path
          d="M2275.62 245.93h-49.76v-13h49.76zm-46.5-3.81h43.25v-5.4h-43.25z"
          data-name="a55bacf3-dcc4-4a81-9353-e3be49805b11"
          opacity="0.1"
        />
        <path
          d="M2216.8 245.93H2167v-13h49.76zm-46.52-3.81h43.25v-5.4h-43.25z"
          data-name="bd20f710-080a-4da3-b8d5-fa52f9cf7b68"
          opacity="0.1"
        />
        <path
          d="M2216.8 303.71H2167V272.2h49.76zm-46.52-3.82h43.25V276h-43.25z"
          data-name="b847ffed-c346-4699-a96d-23c8bcd2eb9d"
          opacity="0.1"
        />
        <path
          d="M2275.62 303.71h-49.76V272.2h49.76zm-46.5-3.82h43.25V276h-43.25z"
          data-name="b1565604-79c2-4809-82da-033ebda36126"
          opacity="0.1"
        />
        <path
          d="M2275.62 285.24h-49.76v-13h49.76zm-46.5-3.82h43.25V276h-43.25z"
          data-name="b430fb7e-3fd1-433d-9f84-902b830bfc9e"
          opacity="0.1"
        />
        <path
          d="M2216.8 285.24H2167v-13h49.76zm-46.52-3.82h43.25V276h-43.25z"
          data-name="bd34cc43-02ac-497f-9f07-d566d4b452f5"
          opacity="0.1"
        />
        <path
          d="M2216.8 343H2167v-31.5h49.76zm-46.52-3.71h43.25v-24h-43.25z"
          data-name="a9d9437e-fb91-4f71-9051-c6bfb809af01"
          opacity="0.1"
        />
        <path
          d="M2275.62 343h-49.76v-31.5h49.76zm-46.5-3.71h43.25v-24h-43.25z"
          data-name="b62f93cf-fb41-4493-b2f3-2d379173a845"
          opacity="0.1"
        />
        <path
          d="M2275.62 324.54h-49.76v-13h49.76zm-46.5-3.9h43.25v-5.4h-43.25z"
          data-name="b96f1c8a-7ca3-4abc-a25b-d02b55667624"
          opacity="0.1"
        />
        <path
          d="M2216.8 324.54H2167v-13h49.76zm-46.52-3.9h43.25v-5.4h-43.25z"
          data-name="be42308c-7661-4ea4-a5d8-b62ea63401d0"
          opacity="0.1"
        />
        <path
          d="M2216.8 382.33H2167v-31.5h49.76zm-46.52-3.81h43.25v-23.89h-43.25z"
          data-name="e2c4c941-3d19-453e-8ebe-cb9cf1c31b24"
          opacity="0.1"
        />
        <path
          d="M2275.62 382.33h-49.76v-31.5h49.76zm-46.5-3.81h43.25v-23.89h-43.25z"
          data-name="a0acc417-1cc8-45ea-b60d-81b9bf7e595a"
          opacity="0.1"
        />
        <path
          d="M2275.62 363.85h-49.76v-13h49.76zm-46.5-3.78h43.25v-5.4h-43.25z"
          data-name="fd801628-0db3-4ee0-9661-6b395f19f2f3"
          opacity="0.1"
        />
        <path
          d="M2216.8 363.85H2167v-13h49.76zm-46.52-3.78h43.25v-5.4h-43.25z"
          data-name="b3a18357-4001-4b8c-99fd-af95e364de28"
          opacity="0.1"
        />
        <path
          d="M2216.8 421.64H2167v-31.5h49.76zm-46.52-3.82h43.25V394h-43.25z"
          data-name="a898f908-09f0-4658-b396-eb33c8604786"
          opacity="0.1"
        />
        <path
          d="M2275.62 421.64h-49.76v-31.5h49.76zm-46.5-3.82h43.25V394h-43.25z"
          data-name="a78b092f-7418-4163-8ab2-369d6313fe9e"
          opacity="0.1"
        />
        <path
          d="M2275.62 403.15h-49.76v-13h49.76zm-46.5-3.8h43.25V394h-43.25z"
          data-name="f2eadb8e-8d8a-4506-9dad-5218fbe414cf"
          opacity="0.1"
        />
        <path
          d="M2216.8 403.15H2167v-13h49.76zm-46.52-3.8h43.25V394h-43.25z"
          data-name="fe27d2da-d48c-488c-b051-e6e4a1969b28"
          opacity="0.1"
        />
        <path
          d="M2216.8 460.94H2167v-31.5h49.76zm-46.52-3.82h43.25v-23.86h-43.25z"
          data-name="aaae7733-aeb3-4ec7-b9ac-171658c40579"
          opacity="0.1"
        />
        <path
          d="M2275.62 460.94h-49.76v-31.5h49.76zm-46.5-3.82h43.25v-23.86h-43.25z"
          data-name="bf73eed4-bd4a-46bd-8998-4292919ff224"
          opacity="0.1"
        />
        <path
          d="M2275.62 442.45h-49.76v-13h49.76zm-46.5-3.8h43.25v-5.39h-43.25z"
          data-name="fb9c365d-5954-488c-9536-d1fb1a8bb758"
          opacity="0.1"
        />
        <path
          d="M2216.8 442.45H2167v-13h49.76zm-46.52-3.8h43.25v-5.39h-43.25z"
          data-name="a8dad734-3b72-4f20-97db-4de41fc16c7f"
          opacity="0.1"
        />
        <path
          d="M2216.8 500.24H2167v-31.5h49.76zm-46.52-3.81h43.25v-23.87h-43.25z"
          data-name="a6f58d7c-dad8-4d68-a004-1ff04c89d28e"
          opacity="0.1"
        />
        <path
          d="M2275.62 500.24h-49.76v-31.5h49.76zm-46.5-3.81h43.25v-23.87h-43.25z"
          data-name="b3104aa9-c914-4c91-823d-6a913a5d24aa"
          opacity="0.1"
        />
        <path
          d="M2275.62 481.76h-49.76v-13h49.76zm-46.5-3.8h43.25v-5.4h-43.25z"
          data-name="ab74e53a-fad4-4ffc-a122-ee3f7299f402"
          opacity="0.1"
        />
        <path
          d="M2216.8 481.76H2167v-13h49.76zm-46.52-3.8h43.25v-5.4h-43.25z"
          data-name="e3c548eb-7887-43eb-bdba-1fb1bee3496e"
          opacity="0.1"
        />
        <path
          d="M2216.8 539.55H2167v-31.5h49.76zm-46.52-3.82h43.25v-23.86h-43.25z"
          data-name="e67eafac-971f-474c-990b-a4d4eda68a48"
          opacity="0.1"
        />
        <path
          d="M2275.62 539.55h-49.76v-31.5h49.76zm-46.5-3.82h43.25v-23.86h-43.25z"
          data-name="a2b5509b-2e40-4a7a-be59-8f6bf5e514f7"
          opacity="0.1"
        />
        <path
          d="M2275.62 521.08h-49.76v-13h49.76zm-46.5-3.82h43.25v-5.4h-43.25z"
          data-name="f0b9d182-0607-45a7-bb42-648e58d73feb"
          opacity="0.1"
        />
        <path
          d="M2216.8 521.08H2167v-13h49.76zm-46.52-3.82h43.25v-5.4h-43.25z"
          data-name="bc896c4b-7e87-4d48-972f-4accf1811f98"
          opacity="0.1"
        />
        <path
          d="M2216.8 578.85H2167v-31.5h49.76zm-46.52-3.85h43.25v-23.83h-43.25z"
          data-name="b9b95e34-ac13-474b-9fb1-3fcf054d0fbc"
          opacity="0.1"
        />
        <path
          d="M2275.62 578.85h-49.76v-31.5h49.76zm-46.5-3.82h43.25v-23.86h-43.25z"
          data-name="a64b3056-5594-45cf-8ec0-a988322d97d1"
          opacity="0.1"
        />
        <path
          d="M2275.62 560.38h-49.76v-13h49.76zm-46.5-3.81h43.25v-5.41h-43.25z"
          data-name="f750d81f-ddcf-4b70-b476-8124c4f1f751"
          opacity="0.1"
        />
        <path
          d="M2216.8 560.38H2167v-13h49.76zm-46.52-3.81h43.25v-5.41h-43.25z"
          data-name="a658ce10-9cbc-4b75-98af-c15408686298"
          opacity="0.1"
        />
        <path
          d="M2216.8 618.16H2167v-31.5h49.76zm-46.52-3.8h43.25v-24h-43.25z"
          data-name="e3ffa6d9-9a4a-403c-99b0-b8c14a43eb06"
          opacity="0.1"
        />
        <path
          d="M2275.62 618.16h-49.76v-31.5h49.76zm-46.5-3.8h43.25v-24h-43.25z"
          data-name="abe05725-27b1-474e-bbd7-bdb603d5c5ea"
          opacity="0.1"
        />
        <path
          d="M2275.62 599.69h-49.76v-13h49.76zm-46.5-3.82h43.25v-5.46h-43.25z"
          data-name="ac0d588c-b110-45c7-9b87-12872f26fc51"
          opacity="0.1"
        />
        <path
          d="M2216.8 599.69H2167v-13h49.76zm-46.52-3.82h43.25v-5.46h-43.25z"
          data-name="eaf6481a-ad58-493e-86f9-2fc8d44f68ff"
          opacity="0.1"
        />
        <path
          d="M2313.53 146.49h-15.27V115h15.27zm-12-3.82h8.72v-23.86h-8.76z"
          data-name="be0400a1-e3ad-47ce-9c2a-54d9b9741b07"
          opacity="0.1"
        />
        <path
          d="M2336.39 185.75h-15.27v-31.46h15.27zm-12-3.77h8.76v-23.87h-8.76z"
          data-name="be05bf95-2b31-4469-b31e-acdcf9927530"
          opacity="0.1"
        />
        <path
          d="M2312 500.24h-15.27v-31.5H2312zm-12-3.81h8.76v-23.87H2300z"
          data-name="e275b3a6-a7ad-4d5a-9288-038d0d554639"
          opacity="0.1"
        />
        <path
          d="M2330.38 343h-15.27v-31.5h15.27zm-12-3.71h8.76v-24h-8.76z"
          data-name="addc2a77-f563-49ab-b168-39d1cfa5260b"
          opacity="0.1"
        />
        <path
          d="M2318 264.4h-15.3v-31.5h15.3zm-12-3.8h8.76v-23.88H2306z"
          data-name="f547fab1-ad06-4bed-a17b-5507172a78a7"
          opacity="0.1"
        />
        <path
          d="M2348.13 421.64h-15.27v-31.5h15.27zm-12-3.82h8.76V394h-8.76z"
          data-name="a1712f5f-a5ed-4949-bd2e-1787f8ea53d9"
          opacity="0.1"
        />
        <path
          d="M2330.38 578.85h-15.27v-31.5h15.27zm-12-3.82h8.76v-23.86h-8.76z"
          data-name="ba2acc26-ec16-4229-aa34-f97b5211c715"
          opacity="0.1"
        />
        <path
          d="M2348.13 146.49h-15.27V115h15.27zm-12-3.82h8.76v-23.86h-8.76z"
          data-name="baf434e3-cd32-412f-907c-c3dc58f65d46"
          opacity="0.1"
        />
        <path
          d="M2348.13 500.24h-15.27v-31.5h15.27zm-12-3.81h8.76v-23.87h-8.76z"
          data-name="bb4f0372-b18d-4852-82c9-b46a1f801c04"
          opacity="0.1"
        />
        <path
          d="M2275.62 673.54h-52.55v-27.95h52.55zm-49.28-3.82h46.1v-20.33h-46.1z"
          data-name="b804cc51-6f66-4fe6-baf4-193b8f7838b9"
          opacity="0.1"
        />
        <path
          d="M2275.62 643.72H2167v-20.11h108.58zm-105.34-3.8h102.09v-12.45h-102.09z"
          data-name="b070181b-7bff-4184-8c9f-9cfcebb5ea98"
          opacity="0.1"
        />
        <path
          d="M2228.8 662.5a5.34 5.34 0 01-4.05-2 7.31 7.31 0 01-1.68-4.74v-10.17h11.48v10.19a7.29 7.29 0 01-1.69 4.75 5.29 5.29 0 01-4.06 1.97zm-2.46-13.11v6.39a3.35 3.35 0 00.12 1.18 3.18 3.18 0 00.52 1 2.52 2.52 0 00.83.69 2.14 2.14 0 002 0 2.52 2.52 0 00.83-.69 3.18 3.18 0 00.52-1 3.35 3.35 0 00.12-1.18v-6.39z"
          data-name="a97016c1-1589-4ccf-a79c-09266aff9f05"
          opacity="0.1"
        />
        <path
          d="M2237 662.5a5.39 5.39 0 01-4.06-2 7.29 7.29 0 01-1.69-4.75v-10.16h11.57v10.19a7.63 7.63 0 01-.45 2.59 7 7 0 01-1.26 2.19 5.75 5.75 0 01-1.89 1.46 5.13 5.13 0 01-2.22.48zm-2.48-13.11v6.39a3.19 3.19 0 00.72 2.05 2.24 2.24 0 003.15.37 2 2 0 00.36-.36 3.19 3.19 0 00.72-2.05v-6.39z"
          data-name="bd812e4b-cb37-473b-b375-6891bcd3eded"
          opacity="0.1"
        />
        <path
          d="M2245.24 662.5a5.35 5.35 0 01-4-2 7.31 7.31 0 01-1.68-4.74v-10.17H2251v10.19a7.36 7.36 0 01-1.68 4.75 5.37 5.37 0 01-4.05 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.19 3.19 0 00.72-2.05v-6.39z"
          data-name="b3de42b4-c860-467c-9563-568f7cd9322b"
          opacity="0.1"
        />
        <path
          d="M2253.49 662.5a5.36 5.36 0 01-4.06-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.47v10.19a7.36 7.36 0 01-1.68 4.75 5.39 5.39 0 01-4.06 2zm-2.49-13.11v6.39a3.12 3.12 0 00.73 2.05 2.23 2.23 0 003.13.38 2.16 2.16 0 00.37-.37 3.12 3.12 0 00.73-2.05v-6.39z"
          data-name="b7ad8abe-34a7-49ed-9504-2a026ae9d6f0"
          opacity="0.1"
        />
        <path
          d="M2261.68 662.5a5.39 5.39 0 01-4.06-2 7.29 7.29 0 01-1.69-4.75v-10.16h11.48v10.19a7.36 7.36 0 01-1.68 4.75 5.37 5.37 0 01-4.05 2zm-2.48-13.11v6.39a3.19 3.19 0 00.72 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.12 3.12 0 00.73-2.05v-6.39z"
          data-name="ba309447-b6b3-479d-95dd-a561d38cc096"
          opacity="0.1"
        />
        <path
          d="M2269.89 662.5a4.93 4.93 0 01-2.2-.51 5.65 5.65 0 01-1.87-1.45 6.46 6.46 0 01-1.25-2.19 7.76 7.76 0 01-.44-2.57v-10.19h11.45v10.19a7.34 7.34 0 01-1.66 4.73 5.37 5.37 0 01-4.03 1.99zm-2.48-13.11v6.39a3.19 3.19 0 00.72 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.12 3.12 0 00.73-2.05v-6.39z"
          data-name="bfb5925a-c12d-4db7-a416-6e555a4fa664"
          opacity="0.1"
        />
        <path
          d="M2203.14 673.54H2167v-27.95h36.1zm-32.86-3.82h29.62v-20.33h-29.62z"
          data-name="e2158e72-c2b7-4c27-9315-a205ea430bd3"
          opacity="0.1"
        />
        <path
          d="M2172.76 662.5a5.37 5.37 0 01-4.05-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.47v10.19a7.36 7.36 0 01-1.68 4.75 5.33 5.33 0 01-4.07 1.97zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.14.37 2.16 2.16 0 00.37-.37 3.12 3.12 0 00.73-2.05v-6.39z"
          data-name="b872e34a-ae27-483b-8722-bf5e3bc00159"
          opacity="0.1"
        />
        <path
          d="M2181 662.5a5.35 5.35 0 01-4-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.47v10.19a7.28 7.28 0 01-1.68 4.75 5.33 5.33 0 01-4.07 2zm-2.46-13.11v6.39a3.35 3.35 0 00.12 1.18 3 3 0 00.53 1 2.52 2.52 0 00.83.69 2.14 2.14 0 002 0 2.31 2.31 0 00.83-.69 3.18 3.18 0 00.52-1 3.35 3.35 0 00.13-1.18v-6.39z"
          data-name="a64083c0-c724-4eab-ae46-34ebcd59f2a6"
          opacity="0.1"
        />
        <path
          d="M2189.2 662.5a5.39 5.39 0 01-4.06-2 7.36 7.36 0 01-1.68-4.75v-10.16H2195v10.19a7.81 7.81 0 01-.44 2.58 7.07 7.07 0 01-1.25 2.18 5.78 5.78 0 01-1.87 1.46 5.14 5.14 0 01-2.21.5zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.2 3.2 0 00.73-2.05v-6.39z"
          data-name="b1f2c859-7121-4898-9918-a2256758d06f"
          opacity="0.1"
        />
        <path
          d="M2197.41 662.5a5.35 5.35 0 01-4-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.45v10.19a7.36 7.36 0 01-1.68 4.75 5.37 5.37 0 01-4.05 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2.05 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.2 3.2 0 00.73-2.05v-6.39z"
          data-name="ba8743cd-a3d7-49b7-845d-1325611643ec"
          opacity="0.1"
        />
        <path
          d="M2222.84 681.1h-20v-35.51h20zm-16.77-3.82h13.53v-27.89h-13.53z"
          data-name="b90463c9-8815-4d4f-8969-03bc84d8b29b"
          opacity="0.1"
        />
        <path
          d="M2561 681.22h-49.47a1.64 1.64 0 01-1.24-.56 2 2 0 01-.52-1.35V121.59a2 2 0 01.52-1.35 1.64 1.64 0 011.24-.56H2561a1.7 1.7 0 011.25.56 2 2 0 01.51 1.35v557.72a2 2 0 01-.51 1.35 1.7 1.7 0 01-1.25.56zm-47.75-3.8h45.95V123.5h-45.92z"
          data-name="b9292154-fd09-4e56-abf6-e14331ac9b17"
          opacity="0.1"
        />
        <path
          d="M2511.38 681.22h-152.5a1.52 1.52 0 01-1.15-.57 2.11 2.11 0 01-.47-1.34V20.94a2.15 2.15 0 01.21-.94 1.55 1.55 0 01.59-.7 1.4 1.4 0 011.61 0L2512.18 120a1.87 1.87 0 01.61.7 2.15 2.15 0 01.22 1v557.61a2.28 2.28 0 01-.12.73 2 2 0 01-.35.62 2 2 0 01-.53.42 1.52 1.52 0 01-.63.14zm-150.91-3.8h149.28V122.71L2360.47 24.2z"
          data-name="a3b069b0-d92d-405e-89c0-04d9176da177"
          opacity="0.1"
          id="cargoPointTwo"
        />
        <path
          d="M2557.77 123.18h-46a1.44 1.44 0 01-.8-.24L2358.08 22.59a1.79 1.79 0 01-.7-.92 2.2 2.2 0 01-.08-1.22 2 2 0 01.58-1 1.54 1.54 0 011-.4h106.39a1.56 1.56 0 011.12.52l92.61 100.3a2 2 0 01.48 1 2.2 2.2 0 01-.07 1.11 2 2 0 01-.62.93 1.48 1.48 0 01-1.02.27zm-45.61-3.8h41.47l-89.06-96.54h-99.52z"
          data-name="bd7783f7-7f1e-42a0-aa8b-aa1ef56c24c2"
          opacity="0.1"
        />
        <path
          d="M2496.08 147h-121.92a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.63a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h121.92a1.58 1.58 0 011.14.57 2.11 2.11 0 01.47 1.34V145a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.23-3.82h118.67v-13.81h-118.67z"
          data-name="ae2aedca-2fc8-4c55-af36-a7a743350207"
          opacity="0.1"
        />
        <path
          d="M2496.08 172.78h-121.92a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35V153.3a2 2 0 01.47-1.3 1.51 1.51 0 011.15-.56h121.92a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.56zM2375.85 169h118.67v-13.79h-118.67z"
          data-name="a35c319d-c8ce-46dd-b719-34edd998620e"
          opacity="0.1"
        />
        <path
          d="M2496.08 198.62h-121.92a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.56a2.28 2.28 0 01.12-.73 2.57 2.57 0 01.36-.62 1.76 1.76 0 01.53-.41 1.71 1.71 0 01.62-.15h121.92a1.53 1.53 0 011.14.57 2 2 0 01.47 1.34v17.56a2 2 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.23-3.82h118.67V181h-118.67z"
          data-name="ba30762d-d0fa-4ba6-a570-fc0747ff12e9"
          opacity="0.1"
        />
        <path
          d="M2496.08 224.48h-121.92a1.54 1.54 0 01-1.15-.56 2 2 0 01-.48-1.35V205a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h121.92a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.58 1.58 0 01-1.14.57zm-120.23-3.82h118.67V206.9h-118.67z"
          data-name="b2339699-0ecd-49c6-834c-86609202d529"
          opacity="0.1"
        />
        <path
          d="M2496.08 250.31h-121.92a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.57a2 2 0 01.48-1.35 1.47 1.47 0 011.15-.56h121.92a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.57a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.23-3.82h118.67v-13.75h-118.67z"
          data-name="ad4477c4-9eef-4bae-808b-e35b8833cef5"
          opacity="0.1"
        />
        <path
          d="M2496.08 276.15h-121.92a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.56a2 2 0 01.48-1.35 1.54 1.54 0 011.15-.56h121.92a1.58 1.58 0 011.14.57 2.11 2.11 0 01.47 1.34v17.56a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.23-3.82h118.67v-13.76h-118.67z"
          data-name="a63630a2-8339-4391-b8a1-a0456ed5f52c"
          opacity="0.1"
        />
        <path
          d="M2496.08 302h-121.92a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.61a2 2 0 01.48-1.35 1.47 1.47 0 011.15-.56h121.92a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.57a2.13 2.13 0 01-.47 1.35 1.51 1.51 0 01-1.14.6zm-120.23-3.77h118.67v-13.8h-118.67z"
          data-name="ed2ba3ae-9f1b-40d4-8000-9b3ed7728d7d"
          opacity="0.1"
        />
        <path
          d="M2496.08 327.84h-121.92a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.58a2 2 0 01.47-1.35 1.47 1.47 0 011.15-.55h121.92a1.54 1.54 0 011.14.56 2.11 2.11 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zM2375.85 324h118.67v-13.74h-118.67z"
          data-name="b574da92-76f4-44e3-aa06-f864c420080e"
          opacity="0.1"
        />
        <path
          d="M2496.08 353.68h-121.92a1.54 1.54 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.56a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h121.92a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.56a2.11 2.11 0 01-.47 1.34 1.58 1.58 0 01-1.14.57zm-120.23-3.82h118.67V336.1h-118.67z"
          data-name="b0a1351c-69ce-44fa-80e1-fc2460cbbc44"
          opacity="0.1"
        />
        <path
          d="M2496.08 379.53h-121.92a1.48 1.48 0 01-1.16-.53 2 2 0 01-.48-1.35v-17.58a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h121.92a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.57a2 2 0 01-.48 1.33 1.47 1.47 0 01-1.13.56zm-120.23-3.82h118.67V362h-118.67z"
          data-name="e4318747-14b7-4f90-8786-0317ae248f13"
          opacity="0.1"
        />
        <path
          d="M2496.08 405.37h-121.92a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.58a2 2 0 01.48-1.35 1.54 1.54 0 011.15-.56h121.92a1.58 1.58 0 011.14.57 2.11 2.11 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.23-3.8h118.67v-13.76h-118.67z"
          data-name="b73e4532-0dc8-4522-b4ed-7d58f2d07f97"
          opacity="0.1"
        />
        <path
          d="M2496.08 431.2h-121.92a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.55a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h121.92a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.56a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.56zm-120.23-3.82h118.67v-13.75h-118.67z"
          data-name="bd1427a6-54c0-4a4d-a944-01ac958f6dac"
          opacity="0.1"
        />
        <path
          d="M2496.08 457.06h-121.92a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.57a2 2 0 01.48-1.35 1.47 1.47 0 011.15-.56h121.92a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.57a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.23-3.82h118.67v-13.76h-118.67z"
          data-name="a87d7acb-374f-4d56-a913-b81ca37454f3"
          opacity="0.1"
        />
        <path
          d="M2496.08 482.9h-121.92a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.58a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h121.92a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34V481a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.23-3.82h118.67v-13.76h-118.67z"
          data-name="b94c01f0-108e-4e70-8f7e-c0e04583516e"
          opacity="0.1"
        />
        <path
          d="M2496.08 508.73h-121.92a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.55a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h121.92a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.55a2.13 2.13 0 01-.47 1.35 1.54 1.54 0 01-1.14.56zm-120.23-3.82h118.67v-13.73h-118.67z"
          data-name="b12d9ddf-25d1-4b28-8ecc-8f2e1a9b2cee"
          opacity="0.1"
        />
        <path
          d="M2496.08 534.59h-121.92a1.51 1.51 0 01-1.16-.59 2 2 0 01-.48-1.35V515.1a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h121.92a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.23-3.82h118.67V517h-118.67z"
          data-name="f2a9afa3-d73b-4cba-a65b-bf454abb6108"
          opacity="0.1"
        />
        <path
          d="M2496.08 560.42h-121.92a1.48 1.48 0 01-.62-.14 1.76 1.76 0 01-.53-.41 2.57 2.57 0 01-.36-.62 2.28 2.28 0 01-.12-.73v-17.58a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h121.92a1.51 1.51 0 011.14.57 2.16 2.16 0 01.47 1.34v17.58a2.16 2.16 0 01-.47 1.34 1.58 1.58 0 01-1.14.57zm-120.23-3.81h118.67v-13.76h-118.67z"
          data-name="f1a60cd4-8978-4b56-953c-de8fda5faea9"
          opacity="0.1"
        />
        <path
          d="M2496.08 586.26h-121.92a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35V566.8a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h121.92a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.56a2.11 2.11 0 01-.47 1.34 1.54 1.54 0 01-1.14.56zm-120.23-3.82h118.67v-13.73h-118.67z"
          data-name="b72492f9-9deb-427f-ac81-b3c15282c142"
          opacity="0.1"
        />
        <path
          d="M2435.11 118.48h-61a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35V85.09a2 2 0 01.48-1.35 1.64 1.64 0 01.53-.42 1.48 1.48 0 01.62-.14h61a1.52 1.52 0 01.63.14 1.92 1.92 0 01.52.42 1.83 1.83 0 01.36.62 2.24 2.24 0 01.12.73v31.48a2 2 0 01-.48 1.35 1.51 1.51 0 01-1.15.56zm-59.26-3.82h57.63V87h-57.63z"
          data-name="bc176129-fb23-43c5-9240-a81f17aaf260"
          opacity="0.1"
        />
        <path
          d="M2547.65 574.7H2532a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.45a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.61a1.54 1.54 0 011.15.56 2 2 0 01.48 1.35v17.45a2 2 0 01-.48 1.35 1.51 1.51 0 01-1.11.56zm-14-3.82h12.42v-13.67h-12.42z"
          data-name="b44c9fb4-044a-4c68-b986-fb2c320000dc"
          opacity="0.1"
        />
        <path
          d="M2532 198.56h-15.62a1.58 1.58 0 01-1.14-.57 2.11 2.11 0 01-.48-1.34V179.2a2.11 2.11 0 01.48-1.34 1.5 1.5 0 011.14-.57H2532a1.5 1.5 0 011.14.57 2 2 0 01.48 1.34v17.45a2 2 0 01-.48 1.34 1.5 1.5 0 01-1.14.57zm-14-3.8h12.41V181.1H2518z"
          data-name="f6499e60-2eec-4a49-92b9-b06b389d9742"
          opacity="0.1"
        />
        <path
          d="M2532 327.78h-15.62a1.5 1.5 0 01-1.14-.57 2 2 0 01-.48-1.34v-17.45a2 2 0 01.48-1.35 1.54 1.54 0 011.14-.56H2532a1.54 1.54 0 011.14.56 2 2 0 01.48 1.35v17.45a2 2 0 01-.48 1.34 1.5 1.5 0 01-1.14.57zm-14-3.78h12.41v-13.7H2518z"
          data-name="a2a535e9-08fa-48a4-93b8-03d2b2205d8d"
          opacity="0.1"
        />
        <path
          d="M2532 431.14h-15.62a1.52 1.52 0 01-1.14-.55 2 2 0 01-.48-1.34v-17.47a2.07 2.07 0 01.48-1.33 1.5 1.5 0 011.14-.56H2532a1.5 1.5 0 011.14.56 2 2 0 01.48 1.33v17.47a2 2 0 01-.48 1.34 1.52 1.52 0 01-1.14.55zm-14-3.8h12.41v-13.65H2518z"
          data-name="ba03fbf2-9d11-4030-90f8-82eca21dfee2"
          opacity="0.1"
        />
        <path
          d="M2532 457h-15.62a1.5 1.5 0 01-1.14-.57 2 2 0 01-.48-1.34v-17.45a2 2 0 01.48-1.35 1.54 1.54 0 011.14-.56H2532a1.54 1.54 0 011.14.56 2 2 0 01.48 1.35v17.45a2 2 0 01-.48 1.34 1.5 1.5 0 01-1.14.57zm-14-3.82h12.41v-13.63H2518z"
          data-name="bcc826c1-6b93-4159-84a4-40a0c51c6e5a"
          opacity="0.1"
        />
        <path
          d="M2547.65 172.72H2532a1.48 1.48 0 01-.62-.14 1.46 1.46 0 01-.53-.42 1.83 1.83 0 01-.36-.62 2.24 2.24 0 01-.12-.73v-17.45a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.61a1.51 1.51 0 011.15.56 2 2 0 01.48 1.35v17.45a2.14 2.14 0 01-.48 1.35 1.64 1.64 0 01-.53.42 1.48 1.48 0 01-.62.14zm-14-3.82h12.42v-13.63h-12.42z"
          data-name="a1c02715-3eb7-44d1-851e-cb3136bf52fc"
          opacity="0.1"
        />
        <path
          d="M2547.65 378.74H2532a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.45a2 2 0 01.48-1.35 1.54 1.54 0 011.15-.56h15.61a1.54 1.54 0 011.15.56 2 2 0 01.48 1.35v17.45a2 2 0 01-.48 1.35 1.51 1.51 0 01-1.15.56zm-14-3.86h12.42v-13.63h-12.42z"
          data-name="bc0dcff0-cb54-4c13-8b99-4c88bfdab143"
          opacity="0.1"
        />
        <path
          d="M2547.65 508.67H2532a1.56 1.56 0 01-1.15-.55 2.11 2.11 0 01-.48-1.34v-17.47a2.11 2.11 0 01.48-1.34 1.55 1.55 0 011.15-.55h15.61a1.52 1.52 0 011.14.55 2.12 2.12 0 01.49 1.34v17.47a2.12 2.12 0 01-.49 1.34 1.51 1.51 0 01-1.1.55zm-14-3.8h12.42v-13.65h-12.42z"
          data-name="bed24837-4926-400d-a728-bc3b174b932e"
          opacity="0.1"
        />
        <path
          d="M2547.65 273.7H2532a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.45a2.14 2.14 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.61a1.51 1.51 0 011.15.56 2 2 0 01.48 1.35v17.45a2.11 2.11 0 01-.48 1.34 1.52 1.52 0 01-1.11.57zm-14-3.82h12.42v-13.57h-12.42z"
          data-name="ea758677-c1fd-4df5-a847-0a425d54d940"
          opacity="0.1"
        />
        <path
          d="M2456.82 636.31h-79.11a1.52 1.52 0 01-.63-.14 2.15 2.15 0 01-.53-.41 2.25 2.25 0 01-.35-.62 2.28 2.28 0 01-.12-.73v-31.13a2.08 2.08 0 01.47-1.35 1.49 1.49 0 011.16-.56h79.11a1.51 1.51 0 011.15.56 2 2 0 01.48 1.35v31.12a2.14 2.14 0 01-.48 1.35 1.76 1.76 0 01-.53.41 1.32 1.32 0 01-.62.15zm-77.43-3.81h75.85v-27.23h-75.85z"
          data-name="ee51647d-f6e1-4666-9201-1840ffcf62c9"
          opacity="0.1"
        />
        <path
          d="M2396.4 681.22h-18.69a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-35.67a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h18.69a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v35.67a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-17-3.8h15.43v-31.87h-15.43z"
          data-name="e232ab80-ccbd-482e-b3cf-40c31a76c71a"
          opacity="0.1"
        />
        <path
          d="M2456.82 675.06h-55a1.54 1.54 0 01-1.15-.56 2 2 0 01-.48-1.35v-29.51a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h55a1.51 1.51 0 011.15.56 2.14 2.14 0 01.48 1.35v29.51a2 2 0 01-.48 1.35 1.54 1.54 0 01-1.15.56zm-53.2-3.82h51.64v-25.69h-51.71z"
          data-name="b176961a-39ae-4092-b7fe-1835e5ce7790"
          opacity="0.1"
        />
        <path
          d="M2494.66 673.85h-29.72a1.49 1.49 0 01-1.16-.56 2.11 2.11 0 01-.47-1.34v-68.59a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h29.72a1.32 1.32 0 01.62.15 1.76 1.76 0 01.53.41 2.57 2.57 0 01.36.62 2.24 2.24 0 01.12.73V672a2 2 0 01-.48 1.34 1.64 1.64 0 01-.53.42 1.48 1.48 0 01-.62.14zm-28.14-3.85H2493v-64.73h-26.46z"
          data-name="fab89156-a733-48c8-9b24-33c13fe1917e"
          opacity="0.1"
        />
        <path
          d="M1921.75 681.22h-152.53a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35V20.94a2.15 2.15 0 01.21-.94 1.72 1.72 0 01.59-.69 1.4 1.4 0 011.63 0L1922.53 120a1.87 1.87 0 01.61.7 2.28 2.28 0 01.22 1v557.7a2.13 2.13 0 01-.47 1.35 1.54 1.54 0 01-1.14.56zm-150.91-3.8h149.26V122.71L1770.84 24.2z"
          data-name="e8063699-b725-40a7-a934-3bd1fb27bd06"
          opacity="0.1"
        />
        <path
          d="M1906.44 147h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.63a2.07 2.07 0 01.48-1.33 1.51 1.51 0 011.15-.56h121.91a1.58 1.58 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.12 2.12 0 01-.49 1.34 1.55 1.55 0 01-1.15.6zm-120.28-3.82h118.65v-13.81h-118.65z"
          data-name="e0382152-6efa-4198-a3bf-51eb59e1f340"
          opacity="0.1"
        />
        <path
          d="M1906.44 172.78h-121.91a1.49 1.49 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35V153.3a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.53 1.53 0 01-1.16.55zM1786.16 169h118.65v-13.79h-118.65z"
          data-name="a7e7514d-ad0d-4291-96ea-a7992fa074e2"
          opacity="0.1"
        />
        <path
          d="M1906.44 198.62h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.56a2.28 2.28 0 01.12-.73 2.25 2.25 0 01.35-.62 2.15 2.15 0 01.53-.41 1.71 1.71 0 01.63-.15h121.91a1.71 1.71 0 01.63.15 1.76 1.76 0 01.53.41 2.16 2.16 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65V181h-118.65z"
          data-name="f8dfe15d-fc0b-4df1-9b1f-dfb79d82307d"
          opacity="0.1"
        />
        <path
          d="M1906.44 224.48h-121.91a1.58 1.58 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35V205a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.58 1.58 0 01-1.16.56zm-120.28-3.82h118.65V206.9h-118.65z"
          data-name="b51a5c7d-46d7-440f-b11f-580e1d48cf69"
          opacity="0.1"
        />
        <path
          d="M1906.44 250.31h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.57a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65v-13.75h-118.65z"
          data-name="a0160124-5abb-407f-94f9-7dba1fc5a374"
          opacity="0.1"
        />
        <path
          d="M1906.44 276.15h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.56a2.13 2.13 0 01.47-1.35 1.58 1.58 0 011.16-.56h121.91a1.58 1.58 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65v-13.76h-118.65z"
          data-name="a0f3e07b-8d49-41ea-977b-72b57ea36831"
          opacity="0.1"
        />
        <path
          d="M1906.44 302h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.61a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.14 2.14 0 01-.48 1.35 1.49 1.49 0 01-1.16.56zm-120.28-3.81h118.65v-13.8h-118.65z"
          data-name="a3ffa74e-9db3-46b1-8ee4-612cc631045c"
          opacity="0.1"
        />
        <path
          d="M1906.44 327.84h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.58a2.13 2.13 0 01.47-1.35 1.53 1.53 0 011.16-.55h121.91a1.53 1.53 0 011.16.55 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zM1786.16 324h118.65v-13.74h-118.65z"
          data-name="bf3f4be0-6df5-4708-9162-f8e5d53d665f"
          opacity="0.1"
        />
        <path
          d="M1906.44 353.68h-121.91a1.58 1.58 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.56a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.58 1.58 0 01-1.16.56zm-120.28-3.82h118.65V336.1h-118.65z"
          data-name="bc8e279b-df32-4739-a6be-b827503fbf26"
          opacity="0.1"
        />
        <path
          d="M1906.44 379.53h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.55a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.12 2.12 0 01-.49 1.34 1.47 1.47 0 01-1.15.55zm-120.28-3.82h118.65V362h-118.65z"
          data-name="ff331962-ffc8-4c65-a71d-1efead0662de"
          opacity="0.1"
        />
        <path
          d="M1906.44 405.37h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.58a2.13 2.13 0 01.47-1.35 1.58 1.58 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.8h118.65v-13.76h-118.65z"
          data-name="f2bb4f9c-35f3-41d4-a6cb-c23c30d4764f"
          opacity="0.1"
        />
        <path
          d="M1906.44 431.2h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.55a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.53 1.53 0 01-1.16.55zm-120.28-3.82h118.65v-13.75h-118.65z"
          data-name="e8adc9c7-1e84-43c3-9e89-daf0996382fa"
          opacity="0.1"
        />
        <path
          d="M1906.44 457.06h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.57a2.08 2.08 0 01.47-1.35 1.49 1.49 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65v-13.76h-118.65z"
          data-name="b5b033bc-da8c-4d43-b039-14f01ec350a6"
          opacity="0.1"
        />
        <path
          d="M1906.44 482.9h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.58a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35V481a2.14 2.14 0 01-.48 1.35 1.57 1.57 0 01-1.16.55zm-120.28-3.82h118.65v-13.76h-118.65z"
          data-name="b3c0ffcd-eb7f-4388-987e-9e4d4a331402"
          opacity="0.1"
        />
        <path
          d="M1906.44 508.73h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35v-17.55a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.55a2.14 2.14 0 01-.48 1.35 1.49 1.49 0 01-1.16.56zm-120.28-3.82h118.65v-13.73h-118.65z"
          data-name="b0d0bbba-ca91-45f2-bcec-452703356c68"
          opacity="0.1"
        />
        <path
          d="M1906.44 534.59h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35V515.1a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.28-3.82h118.65V517h-118.65z"
          data-name="ef5f6d2c-73b7-4a98-a046-769b6bffa1eb"
          opacity="0.1"
        />
        <path
          d="M1906.44 560.42h-121.91a1.38 1.38 0 01-.63-.14 1.76 1.76 0 01-.53-.41 2.25 2.25 0 01-.35-.62 2.28 2.28 0 01-.12-.73v-17.58a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.16 2.16 0 01-.48 1.35 1.76 1.76 0 01-.53.41 1.38 1.38 0 01-.63.14zm-120.28-3.81h118.65v-13.76h-118.65z"
          data-name="a3495ed8-ad36-4226-860e-f283058b4d26"
          opacity="0.1"
        />
        <path
          d="M1906.44 586.26h-121.91a1.54 1.54 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35V566.8a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.53 1.53 0 01-1.16.55zm-120.28-3.82h118.65v-13.73h-118.65z"
          data-name="f5add119-bab2-4f69-b2a0-cf05111bb8ad"
          opacity="0.1"
        />
        <path
          d="M1845.48 118.48h-61a1.58 1.58 0 01-1.16-.56 2.13 2.13 0 01-.47-1.35V85.09a2.24 2.24 0 01.12-.73 2 2 0 01.35-.62 2 2 0 01.53-.42 1.52 1.52 0 01.63-.14h61a1.48 1.48 0 01.62.14 1.46 1.46 0 01.53.42 2.14 2.14 0 01.48 1.35v31.48a2 2 0 01-.48 1.35 1.54 1.54 0 01-1.15.56zm-59.32-3.82h57.69V87h-57.69z"
          data-name="b1755369-18e1-4047-8aa1-1ef06dbbce5b"
          opacity="0.1"
        />
        <path
          d="M1867.2 636.31h-79.13a1.54 1.54 0 01-1.14-.56 2.11 2.11 0 01-.47-1.34v-31.13a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.14-.56h79.13a1.54 1.54 0 011.14.56 2.08 2.08 0 01.47 1.35v31.12a2 2 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-77.49-3.81h75.86v-27.23h-75.86z"
          data-name="bbc02513-fd9c-4341-b0f6-a4e57ad5f4c3"
          opacity="0.1"
        />
        <path
          d="M1806.77 681.22h-18.7a1.5 1.5 0 01-1.14-.57 2.11 2.11 0 01-.47-1.34v-35.67a2.11 2.11 0 01.47-1.34 1.5 1.5 0 011.14-.57h18.7a1.51 1.51 0 011.15.56 2 2 0 01.48 1.35v35.67a2 2 0 01-.48 1.35 1.51 1.51 0 01-1.15.56zm-17.06-3.8h15.42v-31.87h-15.42z"
          data-name="e1251b64-2153-497d-aa1b-8b6ed5ce89cd"
          opacity="0.1"
        />
        <path
          d="M1867.2 675.06h-55a1.54 1.54 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35v-29.51a2.13 2.13 0 01.47-1.35 1.54 1.54 0 011.16-.56h55a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v29.51a2.11 2.11 0 01-.47 1.34 1.58 1.58 0 01-1.14.57zm-53.2-3.82h51.57v-25.69h-51.66z"
          data-name="fc5a6acc-c3a5-4366-a522-6f023785d85a"
          opacity="0.1"
        />
        <path
          d="M1905.08 673.85h-29.77a1.54 1.54 0 01-1.15-.56 2.11 2.11 0 01-.47-1.34v-68.59a2.11 2.11 0 01.47-1.34 1.52 1.52 0 011.15-.57h29.77a1.51 1.51 0 011.15.56 2 2 0 01.48 1.35V672a2 2 0 01-.48 1.34 1.49 1.49 0 01-1.15.51zm-28.14-3.85h26.6v-64.73h-26.6z"
          data-name="bfbe8174-7c72-4a0f-a064-12a918b2b0b7"
          opacity="0.1"
        />
        {carVersion <= 1 ? (
          <g>
            <path
              fill="#fed700"
              d="M2679.9 658.76h82.55v-63.2h-82.55z"
              data-name="a8cb1251-8ccd-401c-9b4d-5491782d9991"
            />
            <path fill="#ebc400" d="M2737 658.76h25.49v-63.2H2737z" data-name="a41d40f6-4638-491c-8287-fb81ecb99c43" />
            <path
              fill="#fff"
              d="M2710.19 613.65h20.39v-11.23h-20.39z"
              data-name="e655e9bd-dcb4-448a-b73a-cc3224b80460"
            />
            <path
              fill="#fff"
              d="M2714.85 654.52h-9.37v-9.34h9.37zm-8.67-.69h8v-8h-8z"
              data-name="b27fa440-c5b9-4872-b62c-c9dfbf3a9d7a"
            />
            <path fill="#fff" d="M2712.84 652.32h-5.38v.68h5.38z" data-name="a523abd1-b9c7-45d8-aaf4-3b958b7bfc6e" />
            <path fill="#fff" d="M2711.63 647l1.21 3h-2.44z" data-name="a239eb2e-2c1f-4872-9f2a-98e69078f47c" />
            <path fill="#fff" d="M2712 649.5h-.69v2.57h.69z" data-name="aa04ffb6-a164-4f31-a649-6dafd8ae8788" />
            <path fill="#fff" d="M2708.68 647l1.22 3h-2.44z" data-name="a95d10b9-f2a6-4520-bc62-df27c3933ea2" />
            <path fill="#fff" d="M2709 649.5h-.69v2.57h.69z" data-name="f39f6285-1f4b-44ec-83c6-ef4d47f6321b" />
            <path
              fill="#fff"
              d="M2703.89 654.4h-9.36v-9.31h9.36zm-8.66-.69h8v-8h-8z"
              data-name="b516306c-b36f-4f1e-9c3f-c21229805cfd"
            />
            <path
              fill="#fff"
              d="M2701.58 647.9v1.1a.53.53 0 010 .25.63.63 0 01-.35.35.61.61 0 01-.69-.15.63.63 0 01-.18-.45v-1.1zm.69-.69h-2.63V649a1.28 1.28 0 00.06.55 1.5 1.5 0 00.28.47 1.16 1.16 0 00.44.32 1.33 1.33 0 001.07 0 1.16 1.16 0 00.44-.32 1.5 1.5 0 00.28-.47 1.28 1.28 0 00.06-.55v-1.79z"
              data-name="b9574b3a-bb15-464b-b183-31a697be7edf"
            />
            <path fill="#fff" d="M2701.31 650.3h-.69v2h.69z" data-name="ec03aa4f-ddd1-44cb-be25-daa8e066d6d9" />
            <path fill="#fff" d="M2702.29 652h-2.65v.69h2.65z" data-name="eb30690a-de18-4ad0-b2ae-0c789efe2880" />
            <path
              fill="#fff"
              d="M2697.46 650.3a1.33 1.33 0 00.94-.39 1.42 1.42 0 00.29-.43 1.13 1.13 0 00.09-.51v-1.71h-2.63V649a1.33 1.33 0 001.31 1.28z"
              data-name="a2919769-6d74-41ac-9b19-4acb8dc20677"
            />
            <path fill="#fff" d="M2697.82 650.3h-.69v2h.69z" data-name="ed7df112-86f4-4b1c-b8a0-7fce37c2c984" />
            <path fill="#fff" d="M2698.8 652h-2.65v.69h2.65z" data-name="e3bd3604-2df0-4328-9c1d-9b750b2e2f61" />
            <path
              fill="#fff"
              d="M2692.94 654.4h-9.34v-9.31h9.34zm-8.65-.69h7.94v-8h-8z"
              data-name="be4ee126-e0e2-466d-82fe-7217a48d6e13"
            />
            <path
              fill="#fff"
              d="M2688.93 652.64a1 1 0 01-1-1v-4.37h.72v4.42a.33.33 0 000 .14.27.27 0 00.06.12.31.31 0 00.11.09h.14a.3.3 0 00.25-.12.27.27 0 00.06-.12.33.33 0 000-.14v-.34h.7v.34a1.1 1.1 0 01-.33.7 1.07 1.07 0 01-.72.26z"
              data-name="e0d04f0d-242c-41ce-ab70-f09db866cc86"
            />
            <path
              fill="#fff"
              d="M2685.94 649.72h4.67a2.27 2.27 0 00-2.25-2.25h-.17a2.27 2.27 0 00-2.25 2.25z"
              data-name="aec9fa65-c94d-4cb6-98e1-876dae6ce3ba"
            />
            <path fill="#fed700" d="M2597 658.76h82.54v-63.2H2597z" data-name="b3270f01-4962-4b9f-86af-b38a4baf6dcc" />
            <path
              fill="#ebc400"
              d="M2654.05 658.76h25.48v-63.2h-25.48z"
              data-name="e4ab10ef-d175-4a2b-bb1c-01a9e7000d77"
            />
            <path
              fill="#fff"
              d="M2627.26 613.65h20.39v-11.23h-20.39z"
              data-name="b59c856d-caef-4c76-8ff6-4e95c7e59a15"
            />
            <path
              fill="#fff"
              d="M2631.91 654.52h-9.35v-9.34h9.35zm-8.67-.69h8v-8h-8z"
              data-name="b291ee58-aa4b-441e-b9f5-8f045681fdbf"
            />
            <path fill="#fff" d="M2629.92 652.32h-5.38v.68h5.38z" data-name="e22fc0d0-9c42-4320-8134-37a1517f1d25" />
            <path fill="#fff" d="M2628.7 647l1.22 3h-2.43z" data-name="e16477a4-c0d3-4ff2-b0e9-4ba88557a0b2" />
            <path fill="#fff" d="M2629.05 649.5h-.69v2.57h.69z" data-name="a4270db5-6658-403a-b10e-1492d2d15afd" />
            <path fill="#fff" d="M2625.76 647l1.22 3h-2.44z" data-name="ebab4c29-f377-432b-9dc7-46379f6589b5" />
            <path fill="#fff" d="M2626.11 649.5h-.69v2.57h.69z" data-name="e5a5fe02-da57-4557-ac8b-103e2b1aa49e" />
            <path
              fill="#fff"
              d="M2621 654.4h-9.35v-9.31h9.35zm-8.67-.69h8v-8h-8z"
              data-name="af8e5259-1f81-4bc3-a858-05a486fae08f"
            />
            <path
              fill="#fff"
              d="M2618.67 647.9v1.1a.64.64 0 01-.19.45.6.6 0 01-.44.19.64.64 0 01-.45-.19.63.63 0 01-.18-.45v-1.1zm.69-.69h-2.64V649a1.31 1.31 0 001.3 1.33 1.33 1.33 0 001.32-1.33v-1.76z"
              data-name="e36c104e-bd42-422c-aab7-7d9cb94743cd"
            />
            <path fill="#fff" d="M2618.38 650.3h-.69v2h.69z" data-name="ae3be7c0-1a9e-4864-aa1c-098fb7f76f3c" />
            <path fill="#fff" d="M2619.36 652h-2.64v.69h2.64z" data-name="fc86cd22-d19c-4d68-8c66-5605486a0ff7" />
            <path
              fill="#fff"
              d="M2614.55 650.3a1.33 1.33 0 001.32-1.33v-1.71h-2.64V649a1.27 1.27 0 00.4.9 1.38 1.38 0 00.92.4z"
              data-name="f8e4f803-9e3c-4a76-ad34-ab2826d8e1fc"
            />
            <path fill="#fff" d="M2614.9 650.3h-.7v2h.7z" data-name="bc138010-f23a-4bca-a150-6d95326ea763" />
            <path fill="#fff" d="M2615.87 652h-2.64v.69h2.64z" data-name="e35d86d6-6951-432f-b0ab-7861159d8d0c" />
            <path
              fill="#fff"
              d="M2610.09 654.4h-9.41v-9.31h9.41zm-8.67-.69h8v-8h-8z"
              data-name="a19961bd-fae0-4468-be25-78751df5ad7a"
            />
            <path
              fill="#fff"
              d="M2606 652.64a1 1 0 01-1-1v-4.37h.69v4.56a.27.27 0 00.06.12.32.32 0 00.39.09.31.31 0 00.11-.09.27.27 0 00.06-.12v-.48h.69v.34a1.07 1.07 0 01-.31.68 1 1 0 01-.69.28z"
              data-name="b13ad0f8-8646-4cfd-aee8-164bc656e001"
            />
            <path
              fill="#fff"
              d="M2603 649.72h4.69a2.31 2.31 0 00-.17-.86 2.26 2.26 0 00-2.08-1.39h-.14a2.31 2.31 0 00-.86.17 2.4 2.4 0 00-.73.49 2.23 2.23 0 00-.49.73 2.11 2.11 0 00-.18.86z"
              data-name="a8bbd29f-e05f-4fd2-b576-042a7884a5ce"
            />
            <path
              fill="#fed700"
              d="M2651.32 596.18h82.55V533h-82.55z"
              data-name="b3602fab-89b5-4e99-a501-0c0dfdf0cd4a"
            />
            <path
              fill="#ebc400"
              d="M2708.38 596.18h25.49V533h-25.49z"
              data-name="aa6355ca-71e5-492d-bb9f-a0c060bf7462"
            />
            <path
              fill="#fff"
              d="M2681.61 551.06H2702v-11.24h-20.39z"
              data-name="b5e96dd5-13ec-431e-9136-514469068130"
            />
            <path
              fill="#fff"
              d="M2686.27 591.94h-9.38v-9.34h9.38zm-8.69-.63h8v-8h-8z"
              data-name="a96e2ba8-223e-4504-81d4-a606959c69fe"
            />
            <path fill="#fff" d="M2684.26 589.74h-5.38v.69h5.38z" data-name="afc5ea53-b3b4-4d14-93a1-b936313150bf" />
            <path fill="#fff" d="M2683.05 584.45l1.21 3h-2.44z" data-name="b243556a-7205-4ced-8775-f12b2caf722f" />
            <path fill="#fff" d="M2683.39 586.91h-.69v2.57h.69z" data-name="be9cda4f-1f17-4eb3-92ac-428279fa1767" />
            <path fill="#fff" d="M2680.09 584.45l1.23 3h-2.44z" data-name="aab52a70-0db0-456d-9d5e-85f112b16421" />
            <path fill="#fff" d="M2680.45 586.91h-.69v2.57h.69z" data-name="b5c2874d-06a9-47c8-a71e-17ecbfe7eea9" />
            <path
              fill="#fff"
              d="M2675.31 591.85H2666v-9.35h9.36zm-8.67-.69h8v-8h-8z"
              data-name="bd5ce681-6780-4b87-beeb-00b5b9aa9079"
            />
            <path
              fill="#fff"
              d="M2673 585.36v1.07a.63.63 0 01-.21.4.64.64 0 01-.9-.05.66.66 0 01-.16-.35v-1.07zm.68-.69h-2.63v1.76a1.24 1.24 0 00.06.54 1.33 1.33 0 001.67.84h.12a1.16 1.16 0 00.44-.32 1.18 1.18 0 00.28-.47 1.24 1.24 0 00.06-.54v-1.76z"
              data-name="b979ff95-e67e-45b8-85a7-1352540fbad5"
            />
            <path fill="#fff" d="M2672.73 587.71h-.7v2h.7z" data-name="afb4e325-f6a4-4fa1-bc60-9c555587a49b" />
            <path fill="#fff" d="M2673.7 589.37h-2.64v.69h2.64z" data-name="e744cef2-850d-4136-98b2-3f06d346995e" />
            <path
              fill="#fff"
              d="M2668.89 587.74a1.29 1.29 0 00.51-.1 1.37 1.37 0 00.42-.29 1.24 1.24 0 00.38-.93v-1.78h-2.63v1.76a1.48 1.48 0 00.09.51 1.3 1.3 0 001.23.83z"
              data-name="a9b7d172-9bb5-46ff-8a35-1452294d5212"
            />
            <path fill="#fff" d="M2669.24 587.71h-.69v2h.69z" data-name="e302da16-d5d3-4540-bcd1-280dc200f8d0" />
            <path fill="#fff" d="M2670.21 589.37h-2.64v.69h2.64z" data-name="bcdf1ced-9f1f-4acc-91b9-20d6d7bb9ab8" />
            <path
              fill="#fff"
              d="M2664.37 591.85H2655v-9.35h9.36zm-8.67-.69h8v-8h-8z"
              data-name="f23981e2-d934-402e-9396-4f7b978f0485"
            />
            <path
              fill="#fff"
              d="M2660.35 590.05a1 1 0 01-.39-.08 1 1 0 01-.32-.21 1.19 1.19 0 01-.22-.33 1 1 0 01-.07-.38v-4.42h.74v4.42a.33.33 0 000 .14.27.27 0 00.06.12.31.31 0 00.11.09.34.34 0 00.28 0 .31.31 0 00.11-.09.27.27 0 00.06-.12.33.33 0 000-.14v-.34h.69v.34a1.1 1.1 0 01-.08.4 1.12 1.12 0 01-.24.33.92.92 0 01-.34.21.91.91 0 01-.4.06z"
              data-name="be6635fb-76e4-4cc0-952c-98688ec0f86f"
            />
            <path
              fill="#fff"
              d="M2657.36 587.14h4.64a2.1 2.1 0 00-.17-.86 2.28 2.28 0 00-1.22-1.22 2.31 2.31 0 00-.86-.17h-.16a2.26 2.26 0 00-2.23 2.25z"
              data-name="b8ebe33c-6eae-40b9-b857-2588ddea0e05"
            />
          </g>
        ) : (
          <></>
        )}
      </g>
      <path
        fill="none"
        stroke="#fff"
        strokeDasharray="4,4"
        d="M1489.47 217.59L1489.47 452.15"
        data-name="e96ecf0b-fb2e-42d3-8771-320f755c36dd"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeDasharray="4,4"
        d="M1775.95 453.14L1488.47 453.14"
        data-name="a1df35c6-3c2d-4701-ac7b-55fd93a0c794"
      />
      <path fill="none" d="M4142.46 -4H6270.46V679H4142.46z" />
      <g data-name="f18915c2-8a83-412e-89e5-4729c5992bed">
        <path
          d="M3820.49 677.06h-110.94V167.53h110.94zm-107.67-3.8h104.43V171.34h-104.43z"
          data-name="e4d62f7f-0a2c-438a-9176-021da8f0fce0"
          opacity="0.1"
        />
        <path
          d="M3690.93 677.06H3580V167.53h110.9zm-107.67-3.8h104.43V171.34h-104.43z"
          data-name="a08caacc-f14b-47df-9fcc-7012835d2ee1"
          opacity="0.1"
        />
        <path
          d="M3712.82 677.06h-25.13V167.53h25.13zm-21.89-3.8h18.62V171.34h-18.62z"
          data-name="ad2c1543-5046-4d60-a789-203ec980f420"
          opacity="0.1"
        />
        <path
          d="M3618.74 212.72H3590v-33.78h28.76zm-25.52-3.82h22.22v-26.17h-22.22z"
          data-name="eee66d8f-6b4a-4eb5-a817-0db750096b99"
          opacity="0.1"
        />
        <path
          d="M3649.87 212.72h-28.79v-33.78h28.79zm-25.56-3.88h22.26v-26.11h-22.26z"
          data-name="a319b600-ad62-407f-8c1f-18749634f2d5"
          opacity="0.1"
        />
        <path
          d="M3681.06 212.72h-28.85v-33.78h28.85zm-25.52-3.82h22.27v-26.17h-22.36z"
          data-name="eb530553-7a15-4551-9043-17ed2bc5408f"
          opacity="0.1"
        />
        <path
          d="M3618.74 248.81H3590v-33.72h28.76zm-25.52-3.8h22.22V219h-22.22z"
          data-name="ed9f8980-8377-4891-a0c6-f6ba79ecca32"
          opacity="0.1"
        />
        <path
          d="M3649.87 248.81h-28.79v-33.72h28.79zm-25.56-3.8h22.26V219h-22.26z"
          data-name="ab4bea3f-0c64-4a47-ae73-59f2cb2f0c1d"
          opacity="0.1"
        />
        <path
          d="M3681.06 248.81h-28.85v-33.72h28.85zm-25.52-3.8h22.27V219h-22.36z"
          data-name="a43b10f7-e677-4a5e-bdf6-90089a893671"
          opacity="0.1"
        />
        <path
          d="M3618.74 285H3590v-33.76h28.76zm-25.52-3.78h22.22v-26.17h-22.22z"
          data-name="ab519c1f-1690-40ba-99c0-111408393ab0"
          opacity="0.1"
        />
        <path
          d="M3649.87 285h-28.79v-33.76h28.79zm-25.56-3.78h22.26v-26.17h-22.26z"
          data-name="aafe4c60-dec7-44f4-bb14-389a22c3f133"
          opacity="0.1"
        />
        <path
          d="M3681.06 285h-28.85v-33.76h28.85zm-25.61-3.78h22.27v-26.17h-22.27z"
          data-name="ae873649-d60d-4695-a6a2-555156b3d8aa"
          opacity="0.1"
        />
        <path
          d="M3618.74 321.13H3590v-33.7h28.76zm-25.52-3.82h22.22V291.2h-22.22z"
          data-name="e9cb9a51-3cd6-4875-8cf6-6caac641a9d7"
          opacity="0.1"
        />
        <path
          d="M3649.87 321.13h-28.79v-33.7h28.79zm-25.56-3.82h22.26V291.2h-22.26z"
          data-name="ea785f44-d755-42f3-851d-5a48f00ed415"
          opacity="0.1"
        />
        <path
          d="M3681.06 321.13h-28.85v-33.7h28.85zm-25.52-3.82h22.27V291.2h-22.36z"
          data-name="f14c6c1a-87bb-481b-8764-cbd222433286"
          opacity="0.1"
        />
        <path
          d="M3618.74 357.28H3590v-33.74h28.76zm-25.52-3.82h22.22v-26.11h-22.22z"
          data-name="be3ac8b5-d88d-4a34-86a7-86249d9f7391"
          opacity="0.1"
        />
        <path
          d="M3649.87 357.28h-28.79v-33.74h28.79zm-25.56-3.82h22.26v-26.11h-22.26z"
          data-name="b7576a08-42a7-4bbc-a063-3cfa79c997c1"
          opacity="0.1"
        />
        <path
          d="M3681.06 357.28h-28.85v-33.74h28.85zm-25.52-3.82h22.27v-26.11h-22.36z"
          data-name="e3dca55a-9e63-433e-b08b-b91fdbf3ceb1"
          opacity="0.1"
        />
        <path
          d="M3618.74 393.43H3590v-33.75h28.76zm-25.52-3.82h22.22V363.5h-22.22z"
          data-name="a8d88c37-5b63-47c1-bfc9-b6ba89a01f4a"
          opacity="0.1"
        />
        <path
          d="M3649.87 393.43h-28.79v-33.75h28.79zm-25.56-3.82h22.26V363.5h-22.26z"
          data-name="a698f8a2-1da7-4b8e-9359-c5b34428d58d"
          opacity="0.1"
        />
        <path
          d="M3681.06 393.43h-28.85v-33.75h28.85zm-25.52-3.82h22.27V363.5h-22.36z"
          data-name="a9e2b3c9-914b-4dde-858f-df163c28b997"
          opacity="0.1"
        />
        <path
          d="M3618.74 429.58H3590v-33.75h28.76zm-25.52-3.82h22.22v-26.1h-22.22z"
          data-name="b6ac4d71-f117-42d6-ade2-cd62f3ff87cb"
          opacity="0.1"
        />
        <path
          d="M3649.87 429.58h-28.79v-33.75h28.79zm-25.56-3.82h22.26v-26.1h-22.26z"
          data-name="b6da914c-eed9-41e0-b858-96d2adefb6a2"
          opacity="0.1"
        />
        <path
          d="M3681.06 429.58h-28.85v-33.75h28.85zm-25.52-3.82h22.27v-26.1h-22.36z"
          data-name="a88ecd2e-3f10-4961-90ae-e60e16e794e1"
          opacity="0.1"
        />
        <path
          d="M3618.74 465.73H3590V432h28.76zm-25.52-3.82h22.22V435.8h-22.22z"
          data-name="b804528e-c2c5-4e10-bbe8-56e844e6cb46"
          opacity="0.1"
        />
        <path
          d="M3649.87 465.73h-28.79V432h28.79zm-25.56-3.82h22.26V435.8h-22.26z"
          data-name="bbdec93b-9d04-472a-a243-fca3cdb08b47"
          opacity="0.1"
        />
        <path
          d="M3681.06 465.73h-28.85V432h28.85zm-25.52-3.82h22.27V435.8h-22.36z"
          data-name="bf93985d-661e-4807-9072-b45b1bb99dcf"
          opacity="0.1"
        />
        <path
          d="M3618.74 501.87H3590v-33.72h28.76zm-25.52-3.8h22.22V472h-22.22z"
          data-name="e8b8b66f-00a4-41b5-96e6-fe7b87d80ea8"
          opacity="0.1"
        />
        <path
          d="M3649.87 501.87h-28.79v-33.72h28.79zm-25.56-3.8h22.26V472h-22.26z"
          data-name="b5b66783-45e6-4648-a6de-4d4c8b81bd71"
          opacity="0.1"
        />
        <path
          d="M3681.06 501.87h-28.85v-33.72h28.85zm-25.52-3.8h22.27V472h-22.36z"
          data-name="b701c800-3498-4abe-bc9e-71afba6d1bec"
          opacity="0.1"
        />
        <path
          d="M3618.74 538H3590v-33.7h28.76zm-25.52-3.81h22.22v-26.1h-22.22z"
          data-name="feafe9a9-b209-4493-aac4-c88d9c32535c"
          opacity="0.1"
        />
        <path
          d="M3649.87 538h-28.79v-33.7h28.79zm-25.56-3.81h22.26v-26.1h-22.26z"
          data-name="adacac23-eb72-4ef9-93f8-3fe3d7e5113d"
          opacity="0.1"
        />
        <path
          d="M3681.06 538h-28.85v-33.7h28.85zm-25.52-3.81h22.27v-26.1h-22.36z"
          data-name="ba18c93f-a5b9-4b59-bf4a-381d26a72e66"
          opacity="0.1"
        />
        <path
          d="M3618.74 574.19H3590v-33.74h28.76zm-25.52-3.81h22.22v-26.11h-22.22z"
          data-name="b2ef2663-f9dd-4010-b845-a1a4c7d3a7b4"
          opacity="0.1"
        />
        <path
          d="M3649.87 574.19h-28.79v-33.74h28.79zm-25.56-3.81h22.26v-26.11h-22.26z"
          data-name="af5fba15-24ed-4c20-9036-f1ee13251d6b"
          opacity="0.1"
        />
        <path
          d="M3681.06 574.19h-28.85v-33.74h28.85zm-25.52-3.81h22.27v-26.11h-22.36z"
          data-name="ef083c2b-13b4-47a0-a369-e2589f7ef785"
          opacity="0.1"
        />
        <path
          d="M3748.3 212.72h-28.78v-33.78h28.78zm-25.52-3.82H3745v-26.17h-22.26z"
          data-name="e55e556a-255a-400f-b09a-ecc4088cb8ff"
          opacity="0.1"
        />
        <path
          d="M3779.41 212.72h-28.77v-33.78h28.77zm-25.5-3.82h22.25v-26.17h-22.25z"
          data-name="b6a0cea5-d69f-4c24-8c1b-41727a593f62"
          opacity="0.1"
        />
        <path
          d="M3810.51 212.72h-28.75v-33.78h28.75zM3785 208.9h22.27v-26.17H3785z"
          data-name="b42f3746-6c1e-420c-a5cc-96988e36f809"
          opacity="0.1"
        />
        <path
          d="M3748.3 248.81h-28.78v-33.72h28.78zm-25.52-3.8H3745V219h-22.26z"
          data-name="f62ac819-012e-4ea5-ab46-fc25de4fbd6e"
          opacity="0.1"
        />
        <path
          d="M3779.41 248.81h-28.77v-33.72h28.77zm-25.5-3.8h22.25V219h-22.25z"
          data-name="becfecd7-c53a-446d-9598-cbc6bc83d29f"
          opacity="0.1"
        />
        <path
          d="M3810.51 248.81h-28.75v-33.72h28.75zM3785 245h22.27v-26H3785z"
          data-name="b72d6acd-417b-4a0c-8549-c815c1147571"
          opacity="0.1"
        />
        <path
          d="M3748.3 285h-28.78v-33.76h28.78zm-25.52-3.78H3745v-26.17h-22.26z"
          data-name="aa513d82-3135-4240-8c09-aa64c81c7da8"
          opacity="0.1"
        />
        <path
          d="M3779.41 285h-28.77v-33.76h28.77zm-25.5-3.78h22.25v-26.17h-22.25z"
          data-name="a97f2388-50eb-465b-9451-d51f41be499c"
          opacity="0.1"
        />
        <path
          d="M3810.51 285h-28.75v-33.76h28.75zm-25.51-3.8h22.27v-26.15H3785z"
          data-name="afa6ea03-2eed-4459-884c-11d6d9ac6fe3"
          opacity="0.1"
        />
        <path
          d="M3748.3 321.13h-28.78v-33.7h28.78zm-25.52-3.82H3745V291.2h-22.26z"
          data-name="e8737ab8-fe5d-4e50-bbd5-39c5a13fdb48"
          opacity="0.1"
        />
        <path
          d="M3779.41 321.13h-28.77v-33.7h28.77zm-25.5-3.82h22.25V291.2h-22.25z"
          data-name="bcee3120-2558-4799-87c7-20daf99bb485"
          opacity="0.1"
        />
        <path
          d="M3810.51 321.13h-28.75v-33.7h28.75zm-25.51-3.82h22.27V291.2H3785z"
          data-name="aebc7ebd-b4ea-4a32-8f86-2d5f185b1ac7"
          opacity="0.1"
        />
        <path
          d="M3748.3 357.28h-28.78v-33.74h28.78zm-25.52-3.82H3745v-26.11h-22.26z"
          data-name="bcf88071-1978-4fea-8ad3-f730f26f8eb5"
          opacity="0.1"
        />
        <path
          d="M3779.41 357.28h-28.77v-33.74h28.77zm-25.5-3.82h22.25v-26.11h-22.25z"
          data-name="b331375b-c1ad-4dcb-94db-86a1eefdcaee"
          opacity="0.1"
        />
        <path
          d="M3810.51 357.28h-28.75v-33.74h28.75zm-25.51-3.82h22.27v-26.11H3785z"
          data-name="ab0ec73a-c788-4526-a502-e6db10473666"
          opacity="0.1"
        />
        <path
          d="M3748.3 393.43h-28.78v-33.75h28.78zm-25.52-3.82H3745V363.5h-22.26z"
          data-name="b0960943-0243-4093-9521-d85274aeeeb2"
          opacity="0.1"
        />
        <path
          d="M3779.41 393.43h-28.77v-33.75h28.77zm-25.5-3.82h22.25V363.5h-22.25z"
          data-name="bfe5917e-0fa1-4294-9860-e23f27be48e4"
          opacity="0.1"
        />
        <path
          d="M3810.51 393.43h-28.75v-33.75h28.75zm-25.51-3.82h22.27V363.5H3785z"
          data-name="adc6dd36-6b91-4ef4-bbba-c47221d147d8"
          opacity="0.1"
        />
        <path
          d="M3748.3 429.58h-28.78v-33.75h28.78zm-25.52-3.82H3745v-26.1h-22.26z"
          data-name="a84eaef4-3cf7-4303-84c4-7bd10edc0dc3"
          opacity="0.1"
        />
        <path
          d="M3779.41 429.58h-28.77v-33.75h28.77zm-25.5-3.82h22.25v-26.1h-22.25z"
          data-name="ebeadb41-6c38-4220-b921-be4d0c1f0e5b"
          opacity="0.1"
        />
        <path
          d="M3810.51 429.58h-28.75v-33.75h28.75zm-25.51-3.82h22.27v-26.1H3785z"
          data-name="f9116e8f-317a-4d10-b1f3-baf123791f4c"
          opacity="0.1"
        />
        <path
          d="M3748.3 465.73h-28.78V432h28.78zm-25.52-3.82H3745V435.8h-22.26z"
          data-name="b8a3eeff-fe33-44f7-b801-987369fd89d0"
          opacity="0.1"
        />
        <path
          d="M3779.41 465.73h-28.77V432h28.77zm-25.5-3.82h22.25V435.8h-22.25z"
          data-name="f590a299-6ac2-41f4-956d-6bb2796a58ee"
          opacity="0.1"
        />
        <path
          d="M3810.51 465.73h-28.75V432h28.75zm-25.51-3.82h22.27V435.8H3785z"
          data-name="f8a6ecca-288e-49b5-aade-f35a5b949733"
          opacity="0.1"
        />
        <path
          d="M3748.3 501.87h-28.78v-33.72h28.78zm-25.52-3.8H3745V472h-22.26z"
          data-name="f73b7c28-5d31-42fd-936c-1428d422266c"
          opacity="0.1"
        />
        <path
          d="M3779.41 501.87h-28.77v-33.72h28.77zm-25.5-3.8h22.25V472h-22.25z"
          data-name="bc450a10-7701-4a92-a079-96c49aa302f9"
          opacity="0.1"
        />
        <path
          d="M3810.51 501.87h-28.75v-33.72h28.75zm-25.52-3.8h22.27V472H3785z"
          data-name="b27532b9-96cb-4dd3-b1fa-bf93af752161"
          opacity="0.1"
        />
        <path
          d="M3748.3 538h-28.78v-33.7h28.78zm-25.52-3.81H3745v-26.1h-22.26z"
          data-name="b24c714b-7b4f-4cf2-86a7-f9c8caa0dd93"
          opacity="0.1"
        />
        <path
          d="M3779.41 538h-28.77v-33.7h28.77zm-25.5-3.81h22.25v-26.1h-22.25z"
          data-name="f1e1b33a-b4d1-4672-9fff-7fe337c96132"
          opacity="0.1"
        />
        <path
          d="M3810.51 538h-28.75v-33.7h28.75zm-25.51-3.77h22.27v-26.1H3785z"
          data-name="f5c67c5e-436a-4a65-a439-164c2336022b"
          opacity="0.1"
        />
        <path
          d="M3748.3 574.19h-28.78v-33.74h28.78zm-25.52-3.81H3745v-26.11h-22.26z"
          data-name="a289a438-2781-48a9-9737-41042100eb63"
          opacity="0.1"
        />
        <path
          d="M3779.41 574.19h-28.77v-33.74h28.77zm-25.5-3.81h22.25v-26.11h-22.25z"
          data-name="ebbf78d9-c8ee-4b9b-b6d5-8e4d5839dd16"
          opacity="0.1"
        />
        <path
          d="M3810.51 574.19h-28.75v-33.74h28.75zm-25.51-3.81h22.27v-26.11H3785z"
          data-name="a975ed36-4304-40c8-b444-acb00b24005f"
          opacity="0.1"
        />
        <path
          d="M3807.37 171.34H3593.1V33.49h3.26v134h207.77v-134h3.24z"
          data-name="ba8cd993-15bd-4e36-9c9d-fee09fae7bc3"
          opacity="0.1"
        />
        <path
          d="M3807.39 171.34h-214.27v-11.87h214.27zm-211-3.81h207.77v-4.15h-207.8z"
          data-name="ab5a699f-c82e-4f09-be28-0591f0f576e7"
          opacity="0.1"
        />
        <path
          d="M3790.61 79.45h-180.73V49.63h180.73zm-177.47-3.81h174.32V53.45h-174.32z"
          data-name="bb553f6f-7588-44ef-bc38-66fd12f8eb2d"
          opacity="0.1"
        />
        <path
          d="M3790.61 115.79h-180.73V86h180.73zM3613.14 112h174.32V89.79h-174.32z"
          data-name="a62cf70d-d16a-4e4f-80bf-1d5d24945232"
          opacity="0.1"
        />
        <path
          d="M3790.61 152.1h-180.73v-29.8h180.73zm-177.47-3.71h174.32V126.1h-174.32z"
          data-name="bbee8aa6-8ab1-4833-b618-51f63a293c17"
          opacity="0.1"
        />
        <path
          d="M3812.16 35.4h-223.81V18.49h223.81zm-220.57-3.8h217.33v-9.32h-217.33z"
          data-name="e865a7a5-8c5a-4988-abcc-5b6c41b10438"
          opacity="0.1"
        />
        <path
          d="M3681.06 624.64H3590v-39.95h91.08zm-87.75-3.82h84.5v-32.31h-84.59z"
          data-name="a037c618-6dac-405a-989b-ea492a22688f"
          opacity="0.1"
        />
        <path
          d="M3614 676.87h-24v-45.32h24zm-20.73-3.82h17.47v-37.68h-17.47z"
          data-name="b4d1b80d-790d-44d6-bebe-e0b76959a375"
          opacity="0.1"
        />
        <path
          d="M3681.06 669.69h-64.23v-38.14h64.23zm-60.9-3.81h57.65v-30.51h-57.74z"
          data-name="b336534f-5950-4407-8fe8-63ebb67fca1c"
          opacity="0.1"
        />
        <path
          d="M3810.51 606.57h-90.83v-21.88h90.83zm-87.58-3.72h84.35V588.6h-84.35z"
          data-name="bf022878-ac62-46aa-94f6-88d7edf74a72"
          opacity="0.1"
        />
        <path
          d="M3810.51 628h-90.83v-21.83h90.83zm-87.6-3.81h84.35V610h-84.33z"
          data-name="a7a901af-aff0-4abe-91c6-c54496d68d30"
          opacity="0.1"
        />
        <path
          d="M3810.51 677.06h-21.28v-45.51h21.28zm-18-3.8h14.71v-37.89h-14.71z"
          data-name="e533079a-b3c1-47cd-a424-f745183dd3fe"
          opacity="0.1"
        />
        <path
          d="M3787.46 669.69h-67.78v-38.14h67.78zm-64.55-3.81h61.28v-30.51h-61.26z"
          data-name="bc92950d-f1a0-4046-a1c3-bca6bbbc90cd"
          opacity="0.1"
        />
        <path
          d="M3726.68 652.53a6.64 6.64 0 01-4.95-2.47 9 9 0 01-2.05-5.83v-12.76h14v12.88a9.11 9.11 0 01-2.08 5.76 6.66 6.66 0 01-4.93 2.42zm-3.75-17.16v9a5 5 0 00.2 1.77 4.72 4.72 0 00.8 1.54 3.69 3.69 0 001.25 1 3.24 3.24 0 003 0 3.79 3.79 0 001.25-1 4.72 4.72 0 00.8-1.54 5.28 5.28 0 00.21-1.77v-8.94z"
          data-name="a35a97b6-b95b-406c-ad8a-7e0a7acdb815"
          opacity="0.1"
        />
        <path
          d="M3737.43 652.53a6.67 6.67 0 01-5-2.47 9 9 0 01-2-5.83v-12.76h14v12.88a9.12 9.12 0 01-2.09 5.76 6.64 6.64 0 01-4.92 2.42zm-3.74-17.16v9a4.81 4.81 0 00.21 1.77 4.51 4.51 0 00.79 1.54 4.06 4.06 0 001.25 1 3.31 3.31 0 003 0 4.18 4.18 0 001.25-1 4.89 4.89 0 00.79-1.54 4.81 4.81 0 00.21-1.77v-8.94z"
          data-name="f792acad-37ea-4d89-9478-a5d4d3b5ec7e"
          opacity="0.1"
        />
        <path
          d="M3748.2 652.53a6.62 6.62 0 01-5-2.43 9 9 0 01-2.08-5.87v-12.76h14v12.88a9.08 9.08 0 01-2 5.72 6.67 6.67 0 01-4.92 2.46zm-3.76-17.16v9a4.81 4.81 0 00.2 1.77 4.72 4.72 0 00.8 1.54 3.69 3.69 0 001.25 1 3.24 3.24 0 003 0 3.79 3.79 0 001.25-1 4.72 4.72 0 00.8-1.54 5.28 5.28 0 00.21-1.77v-8.94z"
          data-name="f2a12d8e-4264-415d-b48f-0838f99b494d"
          opacity="0.1"
        />
        <path
          d="M3759 652.53a6.62 6.62 0 01-5-2.43 9 9 0 01-2.07-5.87v-12.76h14v12.88a9 9 0 01-2.07 5.73 6.58 6.58 0 01-4.86 2.45zm-3.76-17.16v9a4.81 4.81 0 00.21 1.77 4.51 4.51 0 00.79 1.54 3.79 3.79 0 001.25 1 3.31 3.31 0 003 0 3.79 3.79 0 001.25-1 4.69 4.69 0 00.79-1.54 4.81 4.81 0 00.21-1.77v-8.94z"
          data-name="b40d7176-f85a-4062-9b20-f1997658084f"
          opacity="0.1"
        />
        <path
          d="M3769.72 652.53a6.58 6.58 0 01-5-2.43 9 9 0 01-2.08-5.87v-12.76h14v12.88a9.08 9.08 0 01-2 5.72 6.65 6.65 0 01-4.92 2.46zm-3.72-17.16v9a4.81 4.81 0 00.21 1.77 4.28 4.28 0 00.8 1.54 3.69 3.69 0 001.25 1 3.31 3.31 0 003 0 4.06 4.06 0 001.25-1 5.13 5.13 0 00.8-1.54 5 5 0 00.2-1.77v-8.94z"
          data-name="b7f0ca43-5880-448f-a7f5-996346f044bc"
          opacity="0.1"
        />
        <path
          d="M3780.47 652.53a6.62 6.62 0 01-5-2.47 9 9 0 01-2-5.83v-12.76h14v12.88a9.14 9.14 0 01-2.08 5.75 6.65 6.65 0 01-4.91 2.43zm-3.76-17.16v9a4.81 4.81 0 00.21 1.77 4.51 4.51 0 00.79 1.54 3.69 3.69 0 001.25 1 3.31 3.31 0 003 0 4.18 4.18 0 001.25-1 4.89 4.89 0 00.79-1.54 4.81 4.81 0 00.21-1.77v-8.94z"
          data-name="a36cc2c4-3162-411c-b154-34ab04e01216"
          opacity="0.1"
        />
        <path
          d="M4456.31 677.06h-110.94V167.53h110.94zm-107.68-3.8H4453V171.34h-104.37z"
          data-name="ac41e27b-86ee-419b-be2e-378e6caae7f7"
          opacity="0.1"
        />
        <path
          d="M4326.75 677.06h-110.94V167.53h110.94zm-107.68-3.8h104.43V171.34h-104.43z"
          data-name="a7ef3dd8-cbe6-4bf6-81b9-df11fe1ca1eb"
          opacity="0.1"
        />
        <path
          d="M4348.63 677.06h-25.13V167.53h25.13zm-21.88-3.8h18.62V171.34h-18.62z"
          data-name="bc552555-6440-4eee-882b-e19fbca03173"
          opacity="0.1"
        />
        <path
          d="M4254.55 212.72h-28.78v-33.78h28.78zm-25.55-3.88h22.25v-26.11H4229z"
          data-name="ed84c256-09b3-41b7-9319-b7fa16afa003"
          opacity="0.1"
        />
        <path
          d="M4285.66 212.72h-28.77v-33.78h28.77zm-25.52-3.82h22.27v-26.17h-22.27z"
          data-name="b386167e-dae5-4ea3-b85b-80f0ec1a069c"
          opacity="0.1"
        />
        <path
          d="M4316.78 212.72H4288v-33.78h28.76zm-25.52-3.82h22.26v-26.17h-22.26z"
          data-name="a0b3f237-f893-4715-bd61-ec452faa4e1b"
          opacity="0.1"
        />
        <path
          d="M4254.55 248.81h-28.78v-33.72h28.78zM4229 245h22.25v-26H4229z"
          data-name="ae21fd2f-e667-46c4-b554-5b6973e7bbcf"
          opacity="0.1"
        />
        <path
          d="M4285.66 248.81h-28.77v-33.72h28.77zm-25.52-3.8h22.27V219h-22.27z"
          data-name="b305b282-bf40-4fc1-9f35-c197018658c7"
          opacity="0.1"
        />
        <path
          d="M4316.78 248.81H4288v-33.72h28.76zm-25.52-3.8h22.26V219h-22.26z"
          data-name="bad5c9ff-b068-4a68-9595-f20523f94637"
          opacity="0.1"
        />
        <path
          d="M4254.55 285h-28.78v-33.76h28.78zm-25.55-3.8h22.25v-26.15H4229z"
          data-name="a4cdc7aa-9c6b-411a-ba2a-b3dd96fca11f"
          opacity="0.1"
        />
        <path
          d="M4285.66 285h-28.77v-33.76h28.77zm-25.52-3.78h22.27v-26.17h-22.27z"
          data-name="ed47d1b7-9546-40d8-9e41-dafc6c06c5ec"
          opacity="0.1"
        />
        <path
          d="M4316.78 285H4288v-33.76h28.76zm-25.52-3.78h22.26v-26.17h-22.26z"
          data-name="aa14ddb0-e6cb-405c-a937-f73fccda3763"
          opacity="0.1"
        />
        <path
          d="M4254.55 321.13h-28.78v-33.7h28.78zm-25.55-3.82h22.25V291.2H4229z"
          data-name="b7592bc4-b194-40b9-bbdf-d10baebe3364"
          opacity="0.1"
        />
        <path
          d="M4285.66 321.13h-28.77v-33.7h28.77zm-25.52-3.82h22.27V291.2h-22.27z"
          data-name="b0c8fc9a-c754-45a8-88e4-a91ff3a2db19"
          opacity="0.1"
        />
        <path
          d="M4316.78 321.13H4288v-33.7h28.76zm-25.52-3.82h22.26V291.2h-22.26z"
          data-name="bcdf7753-7d18-41f5-9a1a-a21c59bab720"
          opacity="0.1"
        />
        <path
          d="M4254.55 357.28h-28.78v-33.74h28.78zm-25.55-3.82h22.25v-26.11H4229z"
          data-name="b7319997-36a5-4015-9132-0b69fc48ffc4"
          opacity="0.1"
        />
        <path
          d="M4285.66 357.28h-28.77v-33.74h28.77zm-25.52-3.82h22.27v-26.11h-22.27z"
          data-name="a4fa2bad-ee86-4f8c-bb1a-a90465603399"
          opacity="0.1"
        />
        <path
          d="M4316.78 357.28H4288v-33.74h28.76zm-25.52-3.82h22.26v-26.11h-22.26z"
          data-name="a69b10c0-a5eb-4f51-9368-7b7e7fd5ab00"
          opacity="0.1"
        />
        <path
          d="M4254.55 393.43h-28.78v-33.75h28.78zm-25.55-3.82h22.25V363.5H4229z"
          data-name="ab28baa3-0863-4c41-9f76-47bc51c216b5"
          opacity="0.1"
        />
        <path
          d="M4285.66 393.43h-28.77v-33.75h28.77zm-25.52-3.82h22.27V363.5h-22.27z"
          data-name="ec32d1af-2636-4695-85fe-889eba5567c1"
          opacity="0.1"
        />
        <path
          d="M4316.78 393.43H4288v-33.75h28.76zm-25.52-3.82h22.26V363.5h-22.26z"
          data-name="ae4b2657-da50-4ed0-8d13-46904955d3f7"
          opacity="0.1"
        />
        <path
          d="M4254.55 429.58h-28.78v-33.75h28.78zm-25.55-3.82h22.25v-26.1H4229z"
          data-name="f086702b-8c8d-4763-b0cf-1391dce20405"
          opacity="0.1"
        />
        <path
          d="M4285.66 429.58h-28.77v-33.75h28.77zm-25.52-3.82h22.27v-26.1h-22.27z"
          data-name="b7c71113-1fb1-497c-993a-d80d115bb0fd"
          opacity="0.1"
        />
        <path
          d="M4316.78 429.58H4288v-33.75h28.76zm-25.52-3.82h22.26v-26.1h-22.26z"
          data-name="a807e9d5-455c-4f23-addd-3e2c21e59770"
          opacity="0.1"
        />
        <path
          d="M4254.55 465.73h-28.78V432h28.78zm-25.55-3.82h22.25V435.8H4229z"
          data-name="e9b6a51a-36e0-455b-939b-17ebc5a5b1fa"
          opacity="0.1"
        />
        <path
          d="M4285.66 465.73h-28.77V432h28.77zm-25.52-3.82h22.27V435.8h-22.27z"
          data-name="fb014a19-f3e9-40fa-bd8b-5179e3044c66"
          opacity="0.1"
        />
        <path
          d="M4316.78 465.73H4288V432h28.76zm-25.52-3.82h22.26V435.8h-22.26z"
          data-name="b066b78d-ce0b-4e28-b814-4b6b8967c5eb"
          opacity="0.1"
        />
        <path
          d="M4254.55 501.87h-28.78v-33.72h28.78zm-25.53-3.8h22.25V472H4229z"
          data-name="ad9e1339-1593-4fe2-9e22-b137f5bf5c27"
          opacity="0.1"
        />
        <path
          d="M4285.66 501.87h-28.77v-33.72h28.77zm-25.52-3.8h22.27V472h-22.27z"
          data-name="bd1332a4-7a51-4ba1-b176-2ecaddae8a4d"
          opacity="0.1"
        />
        <path
          d="M4316.78 501.87H4288v-33.72h28.76zm-25.52-3.8h22.26V472h-22.26z"
          data-name="f0364147-1ac5-4411-801e-0afb5d928ef4"
          opacity="0.1"
        />
        <path
          d="M4254.55 538h-28.78v-33.7h28.78zm-25.55-3.77h22.25v-26.1H4229z"
          data-name="e8586154-3ac7-492f-87e8-1ca3acdaa21a"
          opacity="0.1"
        />
        <path
          d="M4285.66 538h-28.77v-33.7h28.77zm-25.52-3.81h22.27v-26.1h-22.27z"
          data-name="eda37d08-bd4b-46be-9fb3-229597cd7e1d"
          opacity="0.1"
        />
        <path
          d="M4316.78 538H4288v-33.7h28.76zm-25.52-3.81h22.26v-26.1h-22.26z"
          data-name="b7dc22c4-4b32-483b-97d5-34947a27cad6"
          opacity="0.1"
        />
        <path
          d="M4254.55 574.19h-28.78v-33.74h28.78zm-25.55-3.81h22.25v-26.11H4229z"
          data-name="bcdf81c8-cda1-4bd3-bd1f-866fe091b933"
          opacity="0.1"
        />
        <path
          d="M4285.66 574.19h-28.77v-33.74h28.77zm-25.52-3.81h22.27v-26.11h-22.27z"
          data-name="a5c6795f-87eb-44cd-a0ab-318e85ab75db"
          opacity="0.1"
        />
        <path
          d="M4316.78 574.19H4288v-33.74h28.76zm-25.52-3.81h22.26v-26.11h-22.26z"
          data-name="f606eefc-d793-4df4-a9d5-0aa48803c660"
          opacity="0.1"
        />
        <path
          d="M4384.11 212.72h-28.78v-33.78h28.78zm-25.51-3.82h22.25v-26.17h-22.25z"
          data-name="fbd22a56-7a18-4433-bc1d-19706b7ef6b5"
          opacity="0.1"
        />
        <path
          d="M4415.22 212.72h-28.77v-33.78h28.77zm-25.52-3.82h22.3v-26.17h-22.3z"
          data-name="a3a429ed-c685-4b15-b849-eed3d1abb8c1"
          opacity="0.1"
        />
        <path
          d="M4446.34 212.72h-28.78v-33.78h28.78zm-25.52-3.82h22.26v-26.17h-22.26z"
          data-name="fd34b11e-41f9-47ce-a866-c7bd518755ba"
          opacity="0.1"
        />
        <path
          d="M4384.11 248.81h-28.78v-33.72h28.78zM4358.6 245h22.25v-26h-22.25z"
          data-name="e891dc99-c7ef-4c04-a1d1-2a09d4156ead"
          opacity="0.1"
        />
        <path
          d="M4415.22 248.81h-28.77v-33.72h28.77zM4389.7 245h22.3v-26h-22.3z"
          data-name="a1672c75-6148-4c4e-8266-66a79bfcdad4"
          opacity="0.1"
        />
        <path
          d="M4446.34 248.81h-28.78v-33.72h28.78zm-25.52-3.8h22.26V219h-22.26z"
          data-name="ad89200b-b3cf-43fd-84aa-257be2836da3"
          opacity="0.1"
        />
        <path
          d="M4384.11 285h-28.78v-33.76h28.78zm-25.51-3.78h22.25v-26.17h-22.25z"
          data-name="a03966a1-fd57-46bd-b808-92896ddbf363"
          opacity="0.1"
        />
        <path
          d="M4415.22 285h-28.77v-33.76h28.77zm-25.52-3.78h22.3v-26.17h-22.3z"
          data-name="ad1c89af-5d30-4ec0-8c39-658c3c4bcfec"
          opacity="0.1"
        />
        <path
          d="M4446.34 285h-28.78v-33.76h28.78zm-25.52-3.78h22.26v-26.17h-22.26z"
          data-name="a4616a72-735c-47a1-8492-af16cb5f5697"
          opacity="0.1"
        />
        <path
          d="M4384.11 321.13h-28.78v-33.7h28.78zm-25.51-3.82h22.25V291.2h-22.25z"
          data-name="afbddfe3-5406-420b-9f16-3c5080db80cf"
          opacity="0.1"
        />
        <path
          d="M4415.22 321.13h-28.77v-33.7h28.77zm-25.52-3.82h22.3V291.2h-22.3z"
          data-name="a11cb156-8074-477a-bffb-abcd31e8315a"
          opacity="0.1"
        />
        <path
          d="M4446.34 321.13h-28.78v-33.7h28.78zm-25.52-3.82h22.26V291.2h-22.26z"
          data-name="ad6c9c35-da86-452e-9fd7-3abae1ff36fb"
          opacity="0.1"
        />
        <path
          d="M4384.11 357.28h-28.78v-33.74h28.78zm-25.51-3.82h22.25v-26.11h-22.25z"
          data-name="e182aac6-83f6-48ab-8838-fa5b69551110"
          opacity="0.1"
        />
        <path
          d="M4415.22 357.28h-28.77v-33.74h28.77zm-25.52-3.82h22.3v-26.11h-22.3z"
          data-name="a4a16dd8-c284-42c2-9827-2ff3ef386b04"
          opacity="0.1"
        />
        <path
          d="M4446.34 357.28h-28.78v-33.74h28.78zm-25.52-3.82h22.26v-26.11h-22.26z"
          data-name="b4523b9c-7fb9-4711-99f5-fafdc56beb79"
          opacity="0.1"
        />
        <path
          d="M4384.11 393.43h-28.78v-33.75h28.78zm-25.51-3.82h22.25V363.5h-22.25z"
          data-name="a7cff800-dd22-4a12-902d-588b148907be"
          opacity="0.1"
        />
        <path
          d="M4415.22 393.43h-28.77v-33.75h28.77zm-25.52-3.82h22.3V363.5h-22.3z"
          data-name="ad313bc6-d145-4dcc-8c59-4872821a013f"
          opacity="0.1"
        />
        <path
          d="M4446.34 393.43h-28.78v-33.75h28.78zm-25.52-3.82h22.26V363.5h-22.26z"
          data-name="a509a59d-e79d-44ed-9587-895040f16ffa"
          opacity="0.1"
        />
        <path
          d="M4384.11 429.58h-28.78v-33.75h28.78zm-25.51-3.82h22.25v-26.1h-22.25z"
          data-name="b6b49e96-252e-4504-9f9f-ca0bff7ef0d2"
          opacity="0.1"
        />
        <path
          d="M4415.22 429.58h-28.77v-33.75h28.77zm-25.52-3.82h22.3v-26.1h-22.3z"
          data-name="b5dbb987-1698-404f-99b9-0aab3a7407a4"
          opacity="0.1"
        />
        <path
          d="M4446.34 429.58h-28.78v-33.75h28.78zm-25.52-3.82h22.26v-26.1h-22.26z"
          data-name="f177d302-e57a-407a-8546-cbbc4b61ef70"
          opacity="0.1"
        />
        <path
          d="M4384.11 465.73h-28.78V432h28.78zm-25.51-3.82h22.25V435.8h-22.25z"
          data-name="b733b1c4-2bd7-4464-9636-658abef63451"
          opacity="0.1"
        />
        <path
          d="M4415.22 465.73h-28.77V432h28.77zm-25.52-3.82h22.3V435.8h-22.3z"
          data-name="f5ea6a98-8a4f-403f-ba41-32ed23a93639"
          opacity="0.1"
        />
        <path
          d="M4446.34 465.73h-28.78V432h28.78zm-25.52-3.82h22.26V435.8h-22.26z"
          data-name="ab929521-08df-49c0-80fd-7810abc04492"
          opacity="0.1"
        />
        <path
          d="M4384.11 501.87h-28.78v-33.72h28.78zm-25.51-3.8h22.25V472h-22.25z"
          data-name="aa4354e4-9f70-43f0-84f2-3da4b09e57d6"
          opacity="0.1"
        />
        <path
          d="M4415.22 501.87h-28.77v-33.72h28.77zm-25.52-3.8h22.3V472h-22.3z"
          data-name="bc508c50-5c0a-41fa-a6c0-36e74723fe1f"
          opacity="0.1"
        />
        <path
          d="M4446.34 501.87h-28.78v-33.72h28.78zm-25.52-3.8h22.26V472h-22.26z"
          data-name="b1774c88-555b-4b8c-93ea-dbbdda908d50"
          opacity="0.1"
        />
        <path
          d="M4384.11 538h-28.78v-33.7h28.78zm-25.51-3.81h22.25v-26.1h-22.25z"
          data-name="a268c848-9593-4819-9d00-50ad5b6e3333"
          opacity="0.1"
        />
        <path
          d="M4415.22 538h-28.77v-33.7h28.77zm-25.52-3.81h22.3v-26.1h-22.3z"
          data-name="b0114dd2-e0c9-49fb-9c14-33f9bedbf64b"
          opacity="0.1"
        />
        <path
          d="M4446.34 538h-28.78v-33.7h28.78zm-25.52-3.81h22.26v-26.1h-22.26z"
          data-name="b3c4057a-3c4e-48a5-b5ff-da24246cea88"
          opacity="0.1"
        />
        <path
          d="M4384.11 574.19h-28.78v-33.74h28.78zm-25.51-3.81h22.25v-26.11h-22.25z"
          data-name="b994a86a-1376-4deb-b4d2-81d9e6aee527"
          opacity="0.1"
        />
        <path
          d="M4415.22 574.19h-28.77v-33.74h28.77zm-25.52-3.81h22.3v-26.11h-22.3z"
          data-name="f26a0245-62ae-4877-a3cd-b83be04d6834"
          opacity="0.1"
        />
        <path
          d="M4446.34 574.19h-28.78v-33.74h28.78zm-25.52-3.81h22.26v-26.11h-22.26z"
          data-name="b178cd28-11bc-4c0e-9e18-2f5665721981"
          opacity="0.1"
        />
        <path
          d="M4316.78 624.64h-91v-39.95h91zm-87.78-3.82h84.48v-32.31H4229z"
          data-name="a99ea259-b7cd-415a-a8b2-867f05ff4019"
          opacity="0.1"
        />
        <path
          d="M4249.75 676.87h-24v-45.32h24zm-20.75-3.82h17.46v-37.68H4229z"
          data-name="b462eca9-2d19-49a0-9b41-d5e82ab375b7"
          opacity="0.1"
        />
        <path
          d="M4316.78 669.69h-64.14v-38.14h64.14zm-60.9-3.81h57.64v-30.51h-57.64z"
          data-name="a4ecf1aa-2a1c-4f80-9b55-462b1d43283e"
          opacity="0.1"
        />
        <path
          d="M4446.34 606.57h-90.87v-21.88h90.87zm-87.6-3.72h84.34V588.6h-84.34z"
          data-name="ab35b398-81e5-44ab-9369-66c7ce57bfad"
          opacity="0.1"
        />
        <path
          d="M4446.34 628h-90.87v-21.83h90.87zm-87.6-3.81h84.34V610h-84.34z"
          data-name="abf2711d-1a62-428c-b704-3ed4a908efa4"
          opacity="0.1"
        />
        <path
          d="M4446.34 677.06h-21.28v-45.51h21.28zm-18-3.8H4443v-37.89h-14.7z"
          data-name="a2c28d3e-9388-401c-85ab-2a0fffa991e5"
          opacity="0.1"
        />
        <path
          d="M4423.29 669.69h-67.82v-38.14h67.82zm-64.55-3.81H4420v-30.51h-61.28z"
          data-name="a3db3905-817e-454a-a2f1-b8d2db47267b"
          opacity="0.1"
        />
        <path
          d="M4362.48 652.53a6.62 6.62 0 01-5-2.43 9 9 0 01-2.08-5.87v-12.76h14v12.88a9 9 0 01-2 5.72 6.65 6.65 0 01-4.86 2.46zm-3.74-17.16v9a4.81 4.81 0 00.2 1.77 4.72 4.72 0 00.8 1.54 3.79 3.79 0 001.25 1 3.3 3.3 0 003 0 4.18 4.18 0 001.25-1 4.69 4.69 0 00.79-1.54 5.28 5.28 0 00.21-1.77v-8.94z"
          data-name="b06d8559-6d31-4ff0-a26c-7efcbc1bb62b"
          opacity="0.1"
        />
        <path
          d="M4373.24 652.53a6.58 6.58 0 01-5-2.43 9 9 0 01-2.08-5.87v-12.76h14v12.88a9.08 9.08 0 01-2.05 5.72 6.67 6.67 0 01-4.87 2.46zm-3.76-17.16v9a4.85 4.85 0 001.1 3.11 3.39 3.39 0 004.76.56 3.71 3.71 0 00.56-.56 4.83 4.83 0 001.1-3.11v-8.94z"
          data-name="a7763221-bef7-4e34-bf8a-dec6a245dda9"
          opacity="0.1"
        />
        <path
          d="M4384 652.53a6.62 6.62 0 01-5-2.47 9 9 0 01-2.05-5.83v-12.76h14v12.88a9.11 9.11 0 01-2.08 5.76 6.64 6.64 0 01-4.87 2.42zm-3.76-17.16v9a4.81 4.81 0 00.21 1.77 4.51 4.51 0 00.79 1.54 3.79 3.79 0 001.25 1 3.25 3.25 0 001.5.36 3.41 3.41 0 001.5-.36 4.18 4.18 0 001.25-1 4.89 4.89 0 00.79-1.54 5.28 5.28 0 00.21-1.77v-8.94z"
          data-name="effe55ae-9d4b-4056-b0de-13bda66ae4d3"
          opacity="0.1"
        />
        <path
          d="M4394.75 652.53a6.58 6.58 0 01-5-2.43 9 9 0 01-2.08-5.87v-12.76h14v12.88a9.08 9.08 0 01-2.05 5.72 6.67 6.67 0 01-4.87 2.46zm-3.75-17.16v9a5.37 5.37 0 00.21 1.78 4.46 4.46 0 00.79 1.53 3.79 3.79 0 001.25 1 3.39 3.39 0 001.5.37 3.26 3.26 0 001.5-.37 3.79 3.79 0 001.25-1 5.2 5.2 0 00.8-1.53 5.11 5.11 0 00.2-1.78v-8.94z"
          data-name="f643e39a-cda3-4b34-95d8-fe1798597e98"
          opacity="0.1"
        />
        <path
          d="M4405.52 652.53a6.62 6.62 0 01-5-2.47 9 9 0 01-2.05-5.83v-12.76h14v12.88a9.11 9.11 0 01-2.08 5.76 6.63 6.63 0 01-4.92 2.42zm-3.76-17.16v9a4.81 4.81 0 00.2 1.77 4.72 4.72 0 00.8 1.54 3.79 3.79 0 001.25 1 3.3 3.3 0 003 0 4.18 4.18 0 001.25-1 4.89 4.89 0 00.79-1.54 5.28 5.28 0 00.21-1.77v-8.94z"
          data-name="e2640339-8de5-44de-8048-2445f0f2e139"
          opacity="0.1"
        />
        <path
          d="M4416.26 652.53a6.58 6.58 0 01-5-2.43 9 9 0 01-2.08-5.87v-12.76h14v12.88a9.14 9.14 0 01-2.06 5.73 6.64 6.64 0 01-4.88 2.45zm-3.74-17.16v9a5.28 5.28 0 00.21 1.77 4.51 4.51 0 00.79 1.54 4.06 4.06 0 001.25 1 3.3 3.3 0 003 0 3.79 3.79 0 001.25-1 4.72 4.72 0 00.8-1.54 5 5 0 00.2-1.77v-8.94z"
          data-name="f5d8e3c3-0dd0-481e-9b6e-d9c4bdb60f6f"
          opacity="0.1"
        />
        <path
          d="M4019.76 677.06h-202.92V91.74h202.92zm-74.12-3.8h70.93V95.56h-70.93zm-125.5 0h122.24V95.56h-122.29z"
          data-name="b5e21e22-f45b-4f9e-b8cd-8c615d88ff7c"
          opacity="0.1"
        />
        <path
          d="M3876.67 142.33h-49.76v-31.5h49.76zm-46.51-3.82h43.25v-23.86h-43.25z"
          data-name="e1a7fb1d-775d-4bbb-adea-989d7a5ca033"
          opacity="0.1"
        />
        <path
          d="M3935.5 142.33h-49.76v-31.5h49.76zm-46.5-3.82h43.25v-23.86H3889z"
          data-name="b86c68a0-2eb5-43c9-9178-6cbb82174aa4"
          opacity="0.1"
        />
        <path
          d="M3935.5 123.86h-49.76v-13h49.76zM3889 120h43.25v-5.4H3889z"
          data-name="b3457b75-9411-4a28-b51f-dc4316e57f8c"
          opacity="0.1"
        />
        <path
          d="M3876.67 123.86h-49.76v-13h49.76zm-46.51-3.86h43.25v-5.4h-43.25z"
          data-name="e8507f95-d025-4a69-8c7c-ac96e805f0e1"
          opacity="0.1"
        />
        <path
          d="M3876.67 181.59h-49.76v-31.46h49.76zm-46.51-3.81h43.25V154h-43.25z"
          data-name="a1c7d2b4-69d0-48c1-9709-319a63f3e753"
          opacity="0.1"
        />
        <path
          d="M3935.5 181.59h-49.76v-31.46h49.76zm-46.5-3.81h43.25V154H3889z"
          data-name="a4fbfe50-a92a-4842-afcc-827275dacf93"
          opacity="0.1"
        />
        <path
          d="M3935.5 163.17h-49.76v-13h49.76zm-46.5-3.82h43.25V154H3889z"
          data-name="b3b94c95-a003-428b-8890-9de859973cbd"
          opacity="0.1"
        />
        <path
          d="M3876.67 163.17h-49.76v-13h49.76zm-46.51-3.82h43.25V154h-43.25z"
          data-name="aefeb072-503a-404e-82c6-bc400dd26381"
          opacity="0.1"
        />
        <path
          d="M3876.67 221h-49.76v-31.56h49.76zm-46.51-3.82h43.25v-23.92h-43.25z"
          data-name="ef9c4da9-1a72-47a2-86cd-03e3a615deab"
          opacity="0.1"
        />
        <path
          d="M3935.5 221h-49.76v-31.56h49.76zm-46.5-3.8h43.25v-23.94H3889z"
          data-name="fa63ac9f-f082-460c-808e-0d8dcee95674"
          opacity="0.1"
        />
        <path
          d="M3935.5 202.47h-49.76v-13h49.76zm-46.5-3.82h43.25v-5.4H3889z"
          data-name="fad2853b-3bfe-4aaa-bb7b-3cfe0f09b433"
          opacity="0.1"
        />
        <path
          d="M3876.67 202.47h-49.76v-13h49.76zm-46.51-3.82h43.25v-5.4h-43.25z"
          data-name="a57e7a5c-9cb6-494f-92c1-c2bd3be9d060"
          opacity="0.1"
        />
        <path
          d="M3876.67 260.24h-49.76v-31.5h49.76zm-46.51-3.8h43.25v-23.88h-43.25z"
          data-name="a1740dbc-1eab-4411-826d-b3ec565aa45b"
          opacity="0.1"
        />
        <path
          d="M3935.5 260.24h-49.76v-31.5h49.76zm-46.5-3.8h43.25v-23.88H3889z"
          data-name="b54fca1d-7e7e-4000-b023-38ef849d315e"
          opacity="0.1"
        />
        <path
          d="M3935.5 241.77h-49.76v-13h49.76zM3889 238h43.25v-5.4H3889z"
          data-name="e32a337d-3eba-4b00-bd15-1e12d02d733f"
          opacity="0.1"
        />
        <path
          d="M3876.67 241.77h-49.76v-13h49.76zm-46.51-3.77h43.25v-5.4h-43.25z"
          data-name="a25b9c27-db76-4ee3-8972-386bde857da8"
          opacity="0.1"
        />
        <path
          d="M3876.67 299.55h-49.76v-31.5h49.76zm-46.51-3.82h43.25v-23.87h-43.25z"
          data-name="fdf5ee42-d7f1-4a35-9a49-0ecf57684f19"
          opacity="0.1"
        />
        <path
          d="M3935.5 299.55h-49.76v-31.5h49.76zm-46.5-3.82h43.25v-23.87H3889z"
          data-name="bb9d466e-0603-44dc-94de-546adb6b2ff7"
          opacity="0.1"
        />
        <path
          d="M3935.5 281.08h-49.76v-13h49.76zm-46.5-3.82h43.25v-5.4H3889z"
          data-name="a3b2282f-73f1-4d21-8b86-d01e5c603836"
          opacity="0.1"
        />
        <path
          d="M3876.67 281.08h-49.76v-13h49.76zm-46.51-3.82h43.25v-5.4h-43.25z"
          data-name="fa7efd6a-c941-4849-b1b7-7cf08a8a28be"
          opacity="0.1"
        />
        <path
          d="M3876.67 338.87h-49.76v-31.5h49.76zm-46.51-3.71h43.25v-24h-43.25z"
          data-name="a3268023-16d9-47ff-8aa6-fab79397da31"
          opacity="0.1"
        />
        <path
          d="M3935.5 338.87h-49.76v-31.5h49.76zm-46.5-3.71h43.25v-24H3889z"
          data-name="ed708338-a908-4d21-b140-7f667536bcf1"
          opacity="0.1"
        />
        <path
          d="M3935.5 320.38h-49.76v-13h49.76zm-46.5-3.9h43.25v-5.39H3889z"
          data-name="bcbea9ce-b674-4944-ad0a-c5c22e108503"
          opacity="0.1"
        />
        <path
          d="M3876.67 320.38h-49.76v-13h49.76zm-46.51-3.9h43.25v-5.39h-43.25z"
          data-name="e8ebffde-e274-4f26-92ac-07659cc81b7f"
          opacity="0.1"
        />
        <path
          d="M3876.67 378.17h-49.76v-31.5h49.76zm-46.51-3.81h43.25v-23.89h-43.25z"
          data-name="b08f54f0-5874-4020-b2cf-a05de1fd29e9"
          opacity="0.1"
        />
        <path
          d="M3935.5 378.17h-49.76v-31.5h49.76zm-46.5-3.81h43.25v-23.89H3889z"
          data-name="aa5b2783-cb0d-4d57-8274-6042162543f8"
          opacity="0.1"
        />
        <path
          d="M3935.5 359.68h-49.76v-13h49.76zm-46.5-3.77h43.25v-5.4H3889z"
          data-name="eb7a3d96-436c-4d69-a947-3c3c122d5110"
          opacity="0.1"
        />
        <path
          d="M3876.67 359.68h-49.76v-13h49.76zm-46.51-3.77h43.25v-5.4h-43.25z"
          data-name="a82b32ea-184b-4a51-99ca-1823c953fa5e"
          opacity="0.1"
        />
        <path
          d="M3876.67 417.48h-49.76V386h49.76zm-46.51-3.82h43.25V389.8h-43.25z"
          data-name="a4754e53-5656-469a-aea7-3385da52fff6"
          opacity="0.1"
        />
        <path
          d="M3935.5 417.48h-49.76V386h49.76zm-46.5-3.82h43.25V389.8H3889z"
          data-name="aeba38f0-767a-4fa0-bf0f-5b6318fd123f"
          opacity="0.1"
        />
        <path
          d="M3935.5 399h-49.76v-13h49.76zm-46.5-3.8h43.25v-5.4H3889z"
          data-name="b047d572-f48f-4213-96f9-ed1d0512f1ff"
          opacity="0.1"
        />
        <path
          d="M3876.67 399h-49.76v-13h49.76zm-46.51-3.8h43.25v-5.4h-43.25z"
          data-name="b8a279f5-b726-46c5-b897-8f641abd6d89"
          opacity="0.1"
        />
        <path
          d="M3876.67 456.78h-49.76v-31.5h49.76zm-46.51-3.78h43.25v-23.9h-43.25z"
          data-name="e21916ed-f131-47c7-a51f-e473fbd67eee"
          opacity="0.1"
        />
        <path
          d="M3935.5 456.78h-49.76v-31.5h49.76zM3889 453h43.25v-23.9H3889z"
          data-name="a4b847bb-fc03-4747-9734-656a8cf43dba"
          opacity="0.1"
        />
        <path
          d="M3935.5 438.29h-49.76v-13h49.76zm-46.5-3.8h43.25v-5.4H3889z"
          data-name="a92f1a6e-b600-4acc-b8bd-71f03202c1c1"
          opacity="0.1"
        />
        <path
          d="M3876.67 438.29h-49.76v-13h49.76zm-46.51-3.8h43.25v-5.4h-43.25z"
          data-name="e71b153b-de2a-4530-8497-2cce31902382"
          opacity="0.1"
        />
        <path
          d="M3876.67 496.08h-49.76v-31.5h49.76zm-46.51-3.81h43.25V468.4h-43.25z"
          data-name="bce02ff9-0034-4311-ba71-00413b4bc20b"
          opacity="0.1"
        />
        <path
          d="M3935.5 496.08h-49.76v-31.5h49.76zm-46.5-3.81h43.25V468.4H3889z"
          data-name="a0103419-3ce7-4c86-9e4a-af63e9734882"
          opacity="0.1"
        />
        <path
          d="M3935.5 477.6h-49.76v-13h49.76zm-46.5-3.8h43.25v-5.4H3889z"
          data-name="f45274a6-f848-42dc-b457-3a2f96f20462"
          opacity="0.1"
        />
        <path
          d="M3876.67 477.6h-49.76v-13h49.76zm-46.51-3.8h43.25v-5.4h-43.25z"
          data-name="e2a42659-3d14-46d0-b40c-49be5ae7af9c"
          opacity="0.1"
        />
        <path
          d="M3876.67 535.39h-49.76v-31.5h49.76zm-46.51-3.82h43.25v-23.86h-43.25z"
          data-name="bf4e5f74-6d85-4041-bfca-054ccefe9540"
          opacity="0.1"
        />
        <path
          d="M3935.5 535.39h-49.76v-31.5h49.76zm-46.5-3.82h43.25v-23.86H3889z"
          data-name="a7205e46-f3cc-47f7-9561-142db56c7f36"
          opacity="0.1"
        />
        <path
          d="M3935.5 516.92h-49.76v-13h49.76zm-46.5-3.82h43.25v-5.4H3889z"
          data-name="e5e4c195-3ead-48bc-9697-b6f13af7ec12"
          opacity="0.1"
        />
        <path
          d="M3876.67 516.92h-49.76v-13h49.76zm-46.51-3.82h43.25v-5.4h-43.25z"
          data-name="a6e06074-f25a-44fb-bed9-beedb80ecab7"
          opacity="0.1"
        />
        <path
          d="M3876.67 574.69h-49.76v-31.5h49.76zm-46.51-3.82h43.25V547h-43.25z"
          data-name="b67f677e-4c46-4ae0-989b-2978051cd277"
          opacity="0.1"
        />
        <path
          d="M3935.5 574.69h-49.76v-31.5h49.76zm-46.5-3.82h43.25V547H3889z"
          data-name="a74fc77a-ee82-457d-840c-a28488956e76"
          opacity="0.1"
        />
        <path
          d="M3935.5 556.23h-49.76v-13h49.76zm-46.5-3.82h43.25V547H3889z"
          data-name="b9ea06d4-9d42-4bb5-897d-d45297ad76df"
          opacity="0.1"
        />
        <path
          d="M3876.67 556.23h-49.76v-13h49.76zm-46.51-3.82h43.25V547h-43.25z"
          data-name="f53cf6ba-3e21-4751-a162-7eb2db9483fa"
          opacity="0.1"
        />
        <path
          d="M3876.67 614h-49.76v-31.5h49.76zm-46.51-3.8h43.25v-24h-43.25z"
          data-name="b64a4bcd-f332-4a35-825c-bd9a77d41b04"
          opacity="0.1"
        />
        <path
          d="M3935.5 614h-49.76v-31.5h49.76zm-46.5-3.8h43.25v-24H3889z"
          data-name="fd86e93e-54a1-43e2-844c-8effe65ae311"
          opacity="0.1"
        />
        <path
          d="M3935.5 595.53h-49.76v-13h49.76zm-46.5-3.82h43.25v-5.46H3889z"
          data-name="e0555a9a-3389-496e-b418-b55d7cf2263d"
          opacity="0.1"
        />
        <path
          d="M3876.67 595.53h-49.76v-13h49.76zm-46.51-3.82h43.25v-5.46h-43.25z"
          data-name="b58a6117-b239-4c2a-bcc9-a20b807df915"
          opacity="0.1"
        />
        <path
          d="M3973.41 142.33h-15.27v-31.5h15.27zm-12-3.82h8.72v-23.86h-8.76z"
          data-name="adf35757-7c90-492f-9d04-53ab2013b7dd"
          opacity="0.1"
        />
        <path
          d="M3996.27 181.59H3981v-31.46h15.27zm-12-3.77h8.73V154h-8.76z"
          data-name="ad933af0-72d5-4d2a-8d6c-d946948b762d"
          opacity="0.1"
        />
        <path
          d="M3971.88 496.08h-15.26v-31.5h15.26zm-12-3.81h8.76V468.4h-8.76z"
          data-name="a255f4d4-e23c-4664-a4dc-12bfa6a52335"
          opacity="0.1"
        />
        <path
          d="M3990.26 338.87H3975v-31.5h15.27zm-12-3.71h8.74v-24h-8.76z"
          data-name="f31fd1a9-c655-4358-8549-440ace1e91e1"
          opacity="0.1"
        />
        <path
          d="M3977.84 260.24h-15.27v-31.5h15.27zm-12-3.8h8.76v-23.88h-8.76z"
          data-name="b7376a51-369c-42ce-b0d9-501ba35b0451"
          opacity="0.1"
        />
        <path
          d="M4008 417.48h-15.27V386H4008zm-12-3.82h8.76V389.8H3996z"
          data-name="a56b1c14-71a6-4868-a694-1ff41d81ef6f"
          opacity="0.1"
        />
        <path
          d="M3990.26 574.69H3975v-31.5h15.27zm-12-3.82h8.74V547h-8.76z"
          data-name="e0c1303e-20d1-4779-b7a1-3983b9801cb7"
          opacity="0.1"
        />
        <path
          d="M4008 142.33h-15.27v-31.5H4008zm-12-3.82h8.76v-23.86H3996z"
          data-name="addb67f9-f1d6-430f-96bb-98ed36888130"
          opacity="0.1"
        />
        <path
          d="M4008 496.08h-15.27v-31.5H4008zm-12-3.81h8.76V468.4H3996z"
          data-name="b8423f1c-6eed-4896-8358-a45df50fed2d"
          opacity="0.1"
        />
        <path
          d="M3935.5 669.38H3883v-28h52.55zm-49.29-3.82h46.11v-20.33h-46.11z"
          data-name="eb97e64c-b0d4-4d81-abf4-1e774d5ed6db"
          opacity="0.1"
        />
        <path
          d="M3935.5 639.56h-108.59v-20.11h108.59zm-105.34-3.8h102.09v-12.45h-102.09z"
          data-name="b7f0d0a2-26a7-44c8-b652-648c9c7077e8"
          opacity="0.1"
        />
        <path
          d="M3888.68 658.34a5.35 5.35 0 01-4-2 7.28 7.28 0 01-1.68-4.75v-10.16h11.47v10.19a7.36 7.36 0 01-1.68 4.75 5.39 5.39 0 01-4.06 2zm-2.47-13.11v6.39a3.35 3.35 0 00.13 1.18 3 3 0 00.52 1 2.45 2.45 0 00.83.7 2.17 2.17 0 001 .24 2.3 2.3 0 001-.24 2.79 2.79 0 00.83-.7 3.18 3.18 0 00.52-1 3.35 3.35 0 00.12-1.18v-6.39z"
          data-name="e954b509-bc68-444f-8839-874558f635ac"
          opacity="0.1"
        />
        <path
          d="M3896.91 658.34a5.29 5.29 0 01-4.06-2 7.29 7.29 0 01-1.69-4.75v-10.16h11.56v10.19a7.87 7.87 0 01-.44 2.59 7 7 0 01-1.26 2.19 5.83 5.83 0 01-1.89 1.45 5 5 0 01-2.22.49zm-2.48-13.11v6.39a3.11 3.11 0 00.72 2 2.24 2.24 0 003.15.36 2.45 2.45 0 00.36-.36 3.17 3.17 0 00.73-2v-6.39z"
          data-name="f2c09c59-f3cb-46cf-834e-995040dde68b"
          opacity="0.1"
        />
        <path
          d="M3905.12 658.34a5.35 5.35 0 01-4-2 7.28 7.28 0 01-1.68-4.75v-10.16h11.46v10.19a7.37 7.37 0 01-1.68 4.74 5.37 5.37 0 01-4 2zm-2.48-13.11v6.39a3.11 3.11 0 00.72 2 2.23 2.23 0 003.13.37 2.16 2.16 0 00.37-.37 3.12 3.12 0 00.73-2v-6.39z"
          data-name="e93d67bb-40c8-4b77-9899-225ba3531ff5"
          opacity="0.1"
        />
        <path
          d="M3913.36 658.34a5.34 5.34 0 01-4.05-2 7.35 7.35 0 01-1.67-4.75v-10.16h11.47v10.19a7.41 7.41 0 01-1.68 4.75 5.33 5.33 0 01-4.07 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.26 2.26 0 001.75.85 2.32 2.32 0 001.76-.85 3.25 3.25 0 00.73-2v-6.39z"
          data-name="b70e4ed4-af87-4dc6-8b79-1f9b5029c983"
          opacity="0.1"
        />
        <path
          d="M3921.56 658.34a5.33 5.33 0 01-4.07-2 7.36 7.36 0 01-1.68-4.75v-10.16h11.47v10.19a7.43 7.43 0 01-1.67 4.74 5.37 5.37 0 01-4.05 2zm-2.49-13.11v6.39a3.12 3.12 0 00.73 2 2.22 2.22 0 003.12.38 2.16 2.16 0 00.37-.37 3.12 3.12 0 00.73-2v-6.39z"
          data-name="e1c86548-4109-4f5f-9d74-fc1d7f9eb72c"
          opacity="0.1"
        />
        <path
          d="M3929.77 658.34a5.14 5.14 0 01-2.21-.5 5.78 5.78 0 01-1.87-1.46 7.07 7.07 0 01-1.25-2.18 7.81 7.81 0 01-.44-2.58v-10.19h11.46v10.19a7.42 7.42 0 01-1.66 4.73 5.37 5.37 0 01-4.03 1.99zm-2.49-13.11v6.39a3.12 3.12 0 00.73 2 2.29 2.29 0 001.75.85 2.26 2.26 0 001.75-.85 3.12 3.12 0 00.73-2v-6.39z"
          data-name="afc3955a-2b67-44ae-8943-cbdf47c28d95"
          opacity="0.1"
        />
        <path
          d="M3863 669.38h-36.11v-28H3863zm-32.86-3.82h29.61v-20.33h-29.61z"
          data-name="bfd659d2-0d3a-4223-b7fe-13a6b247fc0d"
          opacity="0.1"
        />
        <path
          d="M3832.64 658.34a5.35 5.35 0 01-4-2 7.36 7.36 0 01-1.68-4.75v-10.16h11.48v10.19a7.42 7.42 0 01-1.69 4.75 5.32 5.32 0 01-4.06 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.23 2.23 0 003.13.37 2.16 2.16 0 00.37-.37 3.11 3.11 0 00.72-2v-6.39z"
          data-name="bf8095d2-28a5-49ae-8587-e1feaecf0f6c"
          opacity="0.1"
        />
        <path
          d="M3840.85 658.34a5.35 5.35 0 01-4-2 7.35 7.35 0 01-1.67-4.75v-10.16h11.47v10.19a7.41 7.41 0 01-1.68 4.75 5.33 5.33 0 01-4.07 2zm-2.46-13.11v6.39a3.35 3.35 0 00.12 1.18 3 3 0 00.52 1 2.52 2.52 0 00.83.69 2.18 2.18 0 001 .25 2.06 2.06 0 001-.25 2.62 2.62 0 00.83-.69 3.18 3.18 0 00.52-1 3.6 3.6 0 00.13-1.18v-6.39z"
          data-name="e92a93f9-64f7-4800-83fe-7e76a1371a19"
          opacity="0.1"
        />
        <path
          d="M3849.08 658.34a5.36 5.36 0 01-4.06-2 7.28 7.28 0 01-1.68-4.75v-10.16h11.51v10.19a7.76 7.76 0 01-.44 2.57 7.12 7.12 0 01-1.25 2.19 5.75 5.75 0 01-1.87 1.45 5 5 0 01-2.21.51zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.26 2.26 0 001.75.85 2.29 2.29 0 001.75-.85 3.23 3.23 0 00.73-2v-6.39z"
          data-name="ff5db1cd-b21c-4424-9a19-f6bf22f8d7b8"
          opacity="0.1"
        />
        <path
          d="M3857.29 658.34a5.34 5.34 0 01-4.05-2 7.36 7.36 0 01-1.68-4.75v-10.16H3863v10.19a7.32 7.32 0 01-1.69 4.74 5.33 5.33 0 01-4 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.2 2.2 0 003.1.4 2.52 2.52 0 00.4-.4 3.23 3.23 0 00.73-2v-6.39z"
          data-name="b6e94d46-5690-47c9-af5f-53c3d90a12a4"
          opacity="0.1"
        />
        <path
          d="M3882.72 676.94h-20v-35.51h20zm-16.72-3.82h13.53v-27.89H3866z"
          data-name="a70032f7-3825-4c3f-961b-80aa25f581dd"
          opacity="0.1"
        />
        <path
          d="M4655.93 677.06h-128.74V93.65h3.24v579.61h122.24V93.65h3.26z"
          data-name="ff95cd6e-547d-40ae-b559-654f4f00d807"
          opacity="0.1"
        />
        <path
          d="M4530.43 677.06H4453V93.65h3.27v579.61h70.88V93.65h3.24z"
          data-name="b42904c7-f833-4a6f-b07d-f61b69c1fbbc"
          opacity="0.1"
        />
        <path
          d="M4658.84 95.56h-205.71a9.81 9.81 0 01-7.42-3.6 13.79 13.79 0 010-17.38 9.81 9.81 0 017.42-3.6h205.71a9.82 9.82 0 017.42 3.6 13.82 13.82 0 010 17.38 9.82 9.82 0 01-7.42 3.6zm-205.71-20.75a6.68 6.68 0 00-5.11 2.48 9.49 9.49 0 000 12 6.71 6.71 0 005.11 2.48h205.71a6.73 6.73 0 005.11-2.48 9.54 9.54 0 000-12 6.71 6.71 0 00-5.11-2.48z"
          data-name="f8a38e12-3d5a-4752-bc76-d6b27e592a51"
          opacity="0.1"
        />
        <path
          d="M4645.86 142.33h-49.76v-31.5h49.76zm-46.52-3.82h43.25v-23.86h-43.25z"
          data-name="e1df5e66-5c84-4b05-b32d-40da3450da98"
          opacity="0.1"
        />
        <path
          d="M4587 142.33h-49.76v-31.5H4587zm-46.5-3.82h43.25v-23.86h-43.25z"
          data-name="b2b18ffa-a3af-40bc-891e-2b416691f883"
          opacity="0.1"
        />
        <path
          d="M4587 123.86h-49.76v-13H4587zm-46.5-3.86h43.25v-5.4h-43.25z"
          data-name="e55fb3e0-537a-4ca1-b70d-b473a433e00a"
          opacity="0.1"
        />
        <path
          d="M4645.86 123.86h-49.76v-13h49.76zm-46.52-3.86h43.25v-5.4h-43.25z"
          data-name="f75e3f1f-a36b-4ff0-b8a4-72e2a3e97979"
          opacity="0.1"
        />
        <path
          d="M4645.86 181.59h-49.76v-31.46h49.76zm-46.52-3.81h43.25V154h-43.25z"
          data-name="ae478e28-d798-43e9-8706-92904dece693"
          opacity="0.1"
        />
        <path
          d="M4587 181.59h-49.76v-31.46H4587zm-46.5-3.81h43.25V154h-43.25z"
          data-name="b2125c78-4c8d-43cb-989f-d89467ca1b7d"
          opacity="0.1"
        />
        <path
          d="M4587 163.17h-49.76v-13H4587zm-46.5-3.82h43.25V154h-43.25z"
          data-name="f8706bbb-71ab-4e47-9be3-6cba15f7775b"
          opacity="0.1"
        />
        <path
          d="M4645.86 163.17h-49.76v-13h49.76zm-46.52-3.82h43.25V154h-43.25z"
          data-name="b58c6c5f-1d55-44ed-b147-520601b25007"
          opacity="0.1"
        />
        <path
          d="M4645.86 221h-49.76v-31.56h49.76zm-46.52-3.82h43.25v-23.92h-43.25z"
          data-name="b88e531f-9ca9-441c-8485-dbbbd58f4e26"
          opacity="0.1"
        />
        <path
          d="M4587 221h-49.76v-31.56H4587zm-46.5-3.82h43.25v-23.92h-43.25z"
          data-name="a1e24be2-0904-4ed7-b674-924bfdc0b97e"
          opacity="0.1"
        />
        <path
          d="M4587 202.47h-49.76v-13H4587zm-46.5-3.82h43.25v-5.4h-43.25z"
          data-name="ff16fa79-8246-4b5b-b329-b6b38220025d"
          opacity="0.1"
        />
        <path
          d="M4645.86 202.47h-49.76v-13h49.76zm-46.52-3.82h43.25v-5.4h-43.25z"
          data-name="abca24ad-fe8c-4b14-8ce7-0048ecebf503"
          opacity="0.1"
        />
        <path
          d="M4645.86 260.24h-49.76v-31.5h49.76zm-46.52-3.8h43.25v-23.88h-43.25z"
          data-name="a07f91a5-3562-4aeb-8e27-940bda4bf301"
          opacity="0.1"
        />
        <path
          d="M4587 260.24h-49.76v-31.5H4587zm-46.5-3.8h43.25v-23.88h-43.25z"
          data-name="a3eb991e-2bca-442b-9a7b-2854caa15945"
          opacity="0.1"
        />
        <path
          d="M4587 241.77h-49.76v-13H4587zm-46.5-3.77h43.25v-5.4h-43.25z"
          data-name="e7195227-3925-466d-b1f7-fe49d93f4a69"
          opacity="0.1"
        />
        <path
          d="M4645.86 241.77h-49.76v-13h49.76zm-46.52-3.77h43.25v-5.4h-43.25z"
          data-name="b87863e1-5903-47f8-9393-a6bb4c53e989"
          opacity="0.1"
        />
        <path
          d="M4645.86 299.55h-49.76v-31.5h49.76zm-46.52-3.82h43.25v-23.87h-43.25z"
          data-name="f5d7c55a-468a-4305-8a63-1abb1b8e5204"
          opacity="0.1"
        />
        <path
          d="M4587 299.55h-49.76v-31.5H4587zm-46.5-3.82h43.25v-23.87h-43.25z"
          data-name="b81225c9-481e-4771-8d6b-4a04a3252a84"
          opacity="0.1"
        />
        <path
          d="M4587 281.08h-49.76v-13H4587zm-46.5-3.82h43.25v-5.4h-43.25z"
          data-name="a110d05f-f00b-4e7f-b8d4-a9f7a0a299c9"
          opacity="0.1"
        />
        <path
          d="M4645.86 281.08h-49.76v-13h49.76zm-46.52-3.82h43.25v-5.4h-43.25z"
          data-name="bcf8e448-b8b8-4c3e-af3c-5c7c712c3a3a"
          opacity="0.1"
        />
        <path
          d="M4645.86 338.87h-49.76v-31.5h49.76zm-46.52-3.71h43.25v-24h-43.25z"
          data-name="bb81e626-f17a-47db-964c-2a3a23709da3"
          opacity="0.1"
        />
        <path
          d="M4587 338.87h-49.76v-31.5H4587zm-46.5-3.71h43.25v-24h-43.25z"
          data-name="e7b2cbde-75f4-4070-9a7b-fb4aaf13b2f5"
          opacity="0.1"
        />
        <path
          d="M4587 320.38h-49.76v-13H4587zm-46.5-3.9h43.25v-5.39h-43.25z"
          data-name="a067d92b-e002-4c27-8d65-bcd3c8d35de7"
          opacity="0.1"
        />
        <path
          d="M4645.86 320.38h-49.76v-13h49.76zm-46.52-3.9h43.25v-5.39h-43.25z"
          data-name="aba67875-2034-46a7-8163-a6165d27fa37"
          opacity="0.1"
        />
        <path
          d="M4645.86 378.17h-49.76v-31.5h49.76zm-46.52-3.81h43.25v-23.89h-43.25z"
          data-name="b99fad31-5d7c-4976-8967-c7df42c4deb2"
          opacity="0.1"
        />
        <path
          d="M4587 378.17h-49.76v-31.5H4587zm-46.5-3.81h43.25v-23.89h-43.25z"
          data-name="e65d30aa-70eb-4244-a9be-ff04cc993fd6"
          opacity="0.1"
        />
        <path
          d="M4587 359.68h-49.76v-13H4587zm-46.5-3.77h43.25v-5.4h-43.25z"
          data-name="b2147fd3-348e-4751-a64d-50548c6f535a"
          opacity="0.1"
        />
        <path
          d="M4645.86 359.68h-49.76v-13h49.76zm-46.52-3.77h43.25v-5.4h-43.25z"
          data-name="aa53c9f3-e11b-41dd-9ece-39e027c2d7bd"
          opacity="0.1"
        />
        <path
          d="M4645.86 417.48h-49.76V386h49.76zm-46.52-3.82h43.25V389.8h-43.25z"
          data-name="bb86e6b5-5b2c-4cfb-bfad-b984bb382585"
          opacity="0.1"
        />
        <path
          d="M4587 417.48h-49.76V386H4587zm-46.5-3.82h43.25V389.8h-43.25z"
          data-name="aa585475-2f05-48e2-9c02-f0b0bc61f907"
          opacity="0.1"
        />
        <path
          d="M4587 399h-49.76v-13H4587zm-46.5-3.8h43.25v-5.4h-43.25z"
          data-name="a2a70d4c-0e18-4148-bf1c-c17ea226c840"
          opacity="0.1"
        />
        <path
          d="M4645.86 399h-49.76v-13h49.76zm-46.52-3.8h43.25v-5.4h-43.25z"
          data-name="e94d2a65-544c-45ee-9794-0e2fe5e7120b"
          opacity="0.1"
        />
        <path
          d="M4645.86 456.78h-49.76v-31.5h49.76zm-46.52-3.78h43.25v-23.9h-43.25z"
          data-name="e76ac476-cf73-48d2-9b67-27c208340d8a"
          opacity="0.1"
        />
        <path
          d="M4587 456.78h-49.76v-31.5H4587zm-46.5-3.78h43.25v-23.9h-43.25z"
          data-name="f19ef723-b8d0-426f-96fc-3f97aa8cb3ae"
          opacity="0.1"
        />
        <path
          d="M4587 438.29h-49.76v-13H4587zm-46.5-3.8h43.25v-5.4h-43.25z"
          data-name="f5a82d81-6646-4f87-aa6b-4b0ba69a2c70"
          opacity="0.1"
        />
        <path
          d="M4645.86 438.29h-49.76v-13h49.76zm-46.52-3.8h43.25v-5.4h-43.25z"
          data-name="f6d8ed6a-a266-4931-ad0a-edd34ddb19e7"
          opacity="0.1"
        />
        <path
          d="M4645.86 496.08h-49.76v-31.5h49.76zm-46.52-3.81h43.25V468.4h-43.25z"
          data-name="bf0c6779-805a-4fac-a60b-c9f167f6d1d8"
          opacity="0.1"
        />
        <path
          d="M4587 496.08h-49.76v-31.5H4587zm-46.5-3.81h43.25V468.4h-43.25z"
          data-name="f2840762-51f5-4e7b-8f2d-96c48a85968b"
          opacity="0.1"
        />
        <path
          d="M4587 477.6h-49.76v-13H4587zm-46.5-3.8h43.25v-5.4h-43.25z"
          data-name="bc97031a-bbb0-4fd7-af1c-d379e8590b39"
          opacity="0.1"
        />
        <path
          d="M4645.86 477.6h-49.76v-13h49.76zm-46.52-3.8h43.25v-5.4h-43.25z"
          data-name="b0c8ec35-0a78-4e8a-98e9-f19e0e3c6592"
          opacity="0.1"
        />
        <path
          d="M4645.86 535.39h-49.76v-31.5h49.76zm-46.52-3.82h43.25v-23.86h-43.25z"
          data-name="a3ec84e5-fa2e-4d77-9c6d-ac1667ec39f4"
          opacity="0.1"
        />
        <path
          d="M4587 535.39h-49.76v-31.5H4587zm-46.5-3.82h43.25v-23.86h-43.25z"
          data-name="a37f0388-b205-4cc8-8794-57e5973bea76"
          opacity="0.1"
        />
        <path
          d="M4587 516.92h-49.76v-13H4587zm-46.5-3.82h43.25v-5.4h-43.25z"
          data-name="e61b9f7d-eb37-4ab8-8da1-48f324c913ac"
          opacity="0.1"
        />
        <path
          d="M4645.86 516.92h-49.76v-13h49.76zm-46.52-3.82h43.25v-5.4h-43.25z"
          data-name="b25c2e40-9235-4a79-b400-1569a0dda2da"
          opacity="0.1"
        />
        <path
          d="M4645.86 574.69h-49.76v-31.5h49.76zm-46.52-3.82h43.25V547h-43.25z"
          data-name="e2e6eab6-b75c-41f0-8e6e-d0b045daef2e"
          opacity="0.1"
        />
        <path
          d="M4587 574.69h-49.76v-31.5H4587zm-46.5-3.82h43.25V547h-43.25z"
          data-name="ff37db95-d902-40bf-9207-5d9996dcc1df"
          opacity="0.1"
        />
        <path
          d="M4587 556.23h-49.76v-13H4587zm-46.5-3.82h43.25V547h-43.25z"
          data-name="fc0636f2-27e2-41ce-a320-2a95952d7e1f"
          opacity="0.1"
        />
        <path
          d="M4645.86 556.23h-49.76v-13h49.76zm-46.52-3.82h43.25V547h-43.25z"
          data-name="b226b60d-f929-4055-b4b9-6c5562495f26"
          opacity="0.1"
        />
        <path
          d="M4645.86 614h-49.76v-31.5h49.76zm-46.52-3.8h43.25v-24h-43.25z"
          data-name="bef22e48-fb2a-4a56-91c0-0a4bfa1382cc"
          opacity="0.1"
        />
        <path
          d="M4587 614h-49.76v-31.5H4587zm-46.5-3.8h43.25v-24h-43.25z"
          data-name="e875fac4-dca4-4854-ad4d-abd2df4f6359"
          opacity="0.1"
        />
        <path
          d="M4587 595.53h-49.76v-13H4587zm-46.5-3.82h43.25v-5.46h-43.25z"
          data-name="e0531235-506e-4bd3-ac45-2bd58a8d6401"
          opacity="0.1"
        />
        <path
          d="M4645.86 595.53h-49.76v-13h49.76zm-46.52-3.82h43.25v-5.46h-43.25z"
          data-name="e5be358e-8894-4276-9658-40ce532faa00"
          opacity="0.1"
        />
        <path
          d="M4514.63 142.33h-15.27v-31.5h15.27zm-12-3.82h8.76v-23.86h-8.76z"
          data-name="adfd3b5b-e2dd-4952-8e99-9e83e6765c36"
          opacity="0.1"
        />
        <path
          d="M4491.77 181.59h-15.27v-31.46h15.27zm-12-3.81h8.76V154h-8.76z"
          data-name="a2a40946-fea2-4c6a-a9c4-c2b77594f929"
          opacity="0.1"
        />
        <path
          d="M4516.3 496.08H4501v-31.5h15.27zm-12-3.81h8.87V468.4h-8.87z"
          data-name="f5b28835-2946-4903-aedc-7a5707b024fd"
          opacity="0.1"
        />
        <path
          d="M4497.77 338.87h-15.27v-31.5h15.27zm-12-3.71h8.76v-24h-8.76z"
          data-name="be633d0f-d627-4a71-92cd-a3d4b38a55fc"
          opacity="0.1"
        />
        <path
          d="M4510.2 260.24H4495v-31.5h15.27zm-12-3.8h8.8v-23.88h-8.76z"
          data-name="b52a84a0-dd29-4710-ab1a-f6cb69390850"
          opacity="0.1"
        />
        <path
          d="M4480 417.48h-15.27V386H4480zm-12-3.82h8.76V389.8H4468z"
          data-name="e28029d8-7922-4b52-8467-e678c88a069a"
          opacity="0.1"
        />
        <path
          d="M4497.77 574.69h-15.27v-31.5h15.27zm-12-3.82h8.76V547h-8.76z"
          data-name="bd2e727a-41c7-4e9c-8362-4dfe36fe3199"
          opacity="0.1"
        />
        <path
          d="M4480 142.33h-15.27v-31.5H4480zm-12-3.82h8.76v-23.86H4468z"
          data-name="e254bc34-c801-419d-9ab9-f8b2af3c41b8"
          opacity="0.1"
        />
        <path
          d="M4480 496.08h-15.27v-31.5H4480zm-12-3.81h8.76V468.4H4468z"
          data-name="ae60a928-6759-4521-8790-8d4fa36b4eb2"
          opacity="0.1"
        />
        <path
          d="M4589.82 669.38h-52.56v-28h52.56zm-49.3-3.82h46.11v-20.33h-46.11z"
          data-name="bad0472f-725b-4e8d-acec-1adac509dded"
          opacity="0.1"
        />
        <path
          d="M4645.86 639.56h-108.6v-20.11h108.6zm-105.34-3.8h102.07v-12.45h-102.07z"
          data-name="f7a0750f-af0e-41c5-98c8-2972ff5e2809"
          opacity="0.1"
        />
        <path
          d="M4584.07 658.34a5.35 5.35 0 01-4-2 7.3 7.3 0 01-1.67-4.74v-10.17h11.48v10.19a7.76 7.76 0 01-.44 2.57 7.12 7.12 0 01-1.25 2.19 5.75 5.75 0 01-1.87 1.45 5 5 0 01-2.21.51zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.22 2.22 0 003.12.38 1.9 1.9 0 00.38-.37 3.25 3.25 0 00.73-2v-6.39z"
          data-name="f60b5e0d-ee90-4f3a-96b3-e89f8e5e9075"
          opacity="0.1"
        />
        <path
          d="M4575.86 658.34a5.35 5.35 0 01-4-2 7.35 7.35 0 01-1.67-4.75v-10.16h11.45v10.19a7.31 7.31 0 01-1.68 4.74 5.37 5.37 0 01-4.05 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.26 2.26 0 001.75.85 2.29 2.29 0 001.75-.85 3.12 3.12 0 00.73-2v-6.39z"
          data-name="bdfdce03-737b-4ae1-8228-7edd638824be"
          opacity="0.1"
        />
        <path
          d="M4567.72 658.34a5.36 5.36 0 01-4.06-2 7.28 7.28 0 01-1.68-4.75v-10.16h11.47v10.19a7.43 7.43 0 01-1.67 4.74 5.39 5.39 0 01-4.06 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.22 2.22 0 003.12.38 1.9 1.9 0 00.38-.37 3.23 3.23 0 00.73-2v-6.39z"
          data-name="bc2c04f2-76ba-4c76-b948-4c0694695d07"
          opacity="0.1"
        />
        <path
          d="M4559.43 658.34a5.36 5.36 0 01-4.06-2 7.35 7.35 0 01-1.67-4.75v-10.16h11.47v10.19a7.36 7.36 0 01-1.68 4.75 5.39 5.39 0 01-4.06 2zm-2.49-13.11v6.39a3.12 3.12 0 00.73 2 2.22 2.22 0 003.12.38 1.62 1.62 0 00.37-.37 3.12 3.12 0 00.73-2v-6.39z"
          data-name="e2216d80-e554-4afa-86e0-7bda067128bb"
          opacity="0.1"
        />
        <path
          d="M4551.21 658.34a5.34 5.34 0 01-4.05-2 7.35 7.35 0 01-1.67-4.75v-10.16h11.45v10.19a7.3 7.3 0 01-1.67 4.74 5.34 5.34 0 01-4.06 1.98zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.22 2.22 0 003.12.38 1.9 1.9 0 00.38-.37 3.12 3.12 0 00.73-2v-6.39z"
          data-name="afaacf2f-8b1b-4932-b156-bbf3ee92fee4"
          opacity="0.1"
        />
        <path
          d="M4543 658.34a5.34 5.34 0 01-4.05-2 7.29 7.29 0 01-1.69-4.75v-10.16h11.47v10.19a7.31 7.31 0 01-1.68 4.74 5.37 5.37 0 01-4.05 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.22 2.22 0 003.12.38 1.85 1.85 0 00.37-.37 3.12 3.12 0 00.73-2v-6.39z"
          data-name="b6814a2c-053e-479d-8aab-0178f015b31f"
          opacity="0.1"
        />
        <path
          d="M4645.86 669.38h-36.13v-28h36.13zm-32.86-3.82h29.59v-20.33H4613z"
          data-name="f4085419-c25a-4b72-9b75-be0b4ed4b3ad"
          opacity="0.1"
        />
        <path
          d="M4640.11 658.34a5.35 5.35 0 01-4-2 7.35 7.35 0 01-1.67-4.75v-10.16h11.47v10.19a7.41 7.41 0 01-1.68 4.75 5.33 5.33 0 01-4.07 2zm-2.46-13.11v6.39a3.35 3.35 0 00.12 1.18 3 3 0 00.52 1 2.52 2.52 0 00.83.69 2.18 2.18 0 001 .25 2.06 2.06 0 001-.25 2.52 2.52 0 00.83-.69 3 3 0 00.52-1 3.6 3.6 0 00.13-1.18v-6.39z"
          data-name="f46f4efd-e560-440b-96b7-285c7728bb8e"
          opacity="0.1"
        />
        <path
          d="M4631.9 658.34a5.33 5.33 0 01-4-2 7.31 7.31 0 01-1.66-4.72v-10.19h11.48v10.19a7.63 7.63 0 01-.45 2.59 6.69 6.69 0 01-1.27 2.19 5.59 5.59 0 01-1.89 1.45 5 5 0 01-2.22.49zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.24 2.24 0 003.51 0 3.24 3.24 0 00.72-2v-6.39z"
          data-name="b5719c70-514a-4dbe-ad8f-6cb139ba2ebb"
          opacity="0.1"
        />
        <path
          d="M4623.69 658.34a5.35 5.35 0 01-4-2 7.36 7.36 0 01-1.68-4.75v-10.16h11.46v10.19a7.31 7.31 0 01-1.68 4.74 5.37 5.37 0 01-4.05 2zm-2.48-13.11v6.39a3.12 3.12 0 00.73 2 2.3 2.3 0 001.75.86 2.34 2.34 0 001.75-.86 3.23 3.23 0 00.73-2v-6.39z"
          data-name="bcaf5476-0988-454d-b203-cab8848c3b60"
          opacity="0.1"
        />
        <path
          d="M4615.48 658.34a4.93 4.93 0 01-2.2-.51 5.64 5.64 0 01-1.86-1.46 6.77 6.77 0 01-1.25-2.18 7.76 7.76 0 01-.44-2.57v-10.19h11.48v10.19a7.31 7.31 0 01-1.68 4.74 5.32 5.32 0 01-4.05 1.98zm-2.48-13.11v6.39a3.11 3.11 0 00.72 2 2.24 2.24 0 003.13.39 2.16 2.16 0 00.37-.37 3.16 3.16 0 00.72-2v-6.39z"
          data-name="f1449a27-4cff-45ef-82c6-9a6af0ed0c47"
          opacity="0.1"
        />
        <path
          d="M4610.07 676.94h-20v-35.51h20zm-16.77-3.82h13.53v-27.89h-13.53z"
          data-name="bb025b8e-7915-48a2-84f3-ddcabe5ff416"
          opacity="0.1"
        />
        <path
          d="M4700.4 677.06h-45.94a1.48 1.48 0 01-1.14-.57 2 2 0 01-.48-1.34V117.43a2 2 0 01.48-1.35 1.54 1.54 0 011.14-.56h45.94a1.59 1.59 0 011.15.56 2.19 2.19 0 01.47 1.35v557.72a2.16 2.16 0 01-.47 1.34 1.52 1.52 0 01-1.15.57zm-44.33-3.8h42.7V119.34h-42.68z"
          data-name="a5eb4700-41ae-4bde-8ea5-ccd8cfdc3800"
          opacity="0.1"
        />
        <path
          d="M4700.41 119.34h-45.95a1.41 1.41 0 01-.92-.34 1.69 1.69 0 01-.6-.88 2.07 2.07 0 01-.07-1.13 2.09 2.09 0 01.49-1l92.34-100.6a1.51 1.51 0 011.1-.52h106.13a1.54 1.54 0 011 .4 2 2 0 01.58 1 2.2 2.2 0 01-.07 1.22 1.74 1.74 0 01-.7.92l-152.52 100.68a1.46 1.46 0 01-.81.25zm-41.8-3.82H4700l146.74-96.83h-99.3z"
          data-name="bfe28f75-b2d7-441e-a16a-ac7796f223f8"
          opacity="0.1"
        />
        <path
          d="M4679.75 570.54h-15.61a1.48 1.48 0 01-1.14-.54 2 2 0 01-.48-1.35v-17.47a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.61a1.54 1.54 0 011.15.56 2.14 2.14 0 01.48 1.35v17.45a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.15.56zm-14-3.82h12.41v-13.67h-12.41z"
          data-name="fe65006f-f7a6-4cb9-a2b8-451311b64c8e"
          opacity="0.1"
        />
        <path
          d="M4679.75 168.56h-15.61a1.5 1.5 0 01-1.14-.56 1.83 1.83 0 01-.36-.62 2.24 2.24 0 01-.12-.73V149.2a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.61a1.54 1.54 0 011.15.56 2.14 2.14 0 01.48 1.35v17.45a2.14 2.14 0 01-.48 1.35 1.64 1.64 0 01-.53.42 1.52 1.52 0 01-.63.14zm-14-3.82h12.41v-13.63h-12.41z"
          data-name="fda22dd1-4ae1-4894-9eed-1e1bf9bbe9e1"
          opacity="0.1"
        />
        <path
          d="M4679.75 374.58h-15.61a1.49 1.49 0 01-1.14-.58 2 2 0 01-.48-1.35v-17.43a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.61a1.59 1.59 0 011.15.56 2.14 2.14 0 01.48 1.35v17.45a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.15.56zm-14-3.81h12.41v-13.64h-12.41z"
          data-name="a30f3ff0-bef9-482f-b6fc-a9d5210bc55e"
          opacity="0.1"
        />
        <path
          d="M4679.75 504.51h-15.61a1.55 1.55 0 01-1.14-.51 2.11 2.11 0 01-.48-1.34v-17.51a2 2 0 01.48-1.34 1.52 1.52 0 011.14-.55h15.61a1.56 1.56 0 011.15.55 2.11 2.11 0 01.48 1.34v17.47a2 2 0 01-.49 1.34 1.49 1.49 0 01-1.14.55zm-14-3.8h12.41v-13.65h-12.41z"
          data-name="f8428a94-ecde-4118-8f28-4f4c620cf30e"
          opacity="0.1"
        />
        <path
          d="M4679.75 269.54h-15.61a1.48 1.48 0 01-1.14-.54 2 2 0 01-.48-1.35v-17.47a2 2 0 01.48-1.35 1.47 1.47 0 011.15-.56h15.61a1.54 1.54 0 011.15.56 2.14 2.14 0 01.48 1.35v17.45a2.14 2.14 0 01-.48 1.35 1.58 1.58 0 01-1.16.56zm-14-3.82h12.41v-13.57h-12.41z"
          data-name="b4456b88-e24d-4ce7-a5bb-fab335f7f328"
          opacity="0.1"
        />
        <path
          d="M4217.19 677.06h-45.93a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35V117.43a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h45.93a1.54 1.54 0 011.15.56 2.14 2.14 0 01.48 1.35v557.72a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.15.56zm-44.33-3.8h42.66V119.34h-42.63z"
          data-name="b7bcd486-0b30-45b2-86a0-2559269e308a"
          opacity="0.1"
        />
        <path
          d="M4171.26 677.06h-152.51a1.48 1.48 0 01-1.14-.57 2.11 2.11 0 01-.48-1.34V16.78a2.15 2.15 0 01.21-.94 1.79 1.79 0 01.58-.7 1.51 1.51 0 01.82-.27 1.46 1.46 0 01.81.25l152.51 100.65a2 2 0 01.61.7 2.43 2.43 0 01.22 1v557.68a2.24 2.24 0 01-.13.73 2 2 0 01-.35.62 2 2 0 01-.53.42 1.48 1.48 0 01-.62.14zm-150.91-3.8h149.28V118.55L4020.35 20z"
          data-name="b7c9d373-5a59-4339-9ad1-0f180b3e9c3f"
          opacity="0.1"
        />
        <path
          d="M4217.19 119.34h-45.93a1.4 1.4 0 01-.8-.25L4018 18.44a1.75 1.75 0 01-.7-.91 2.15 2.15 0 01-.08-1.23 1.85 1.85 0 01.58-1 1.4 1.4 0 011-.4h106.14a1.47 1.47 0 011.11.51L4218.38 116a2 2 0 01.47 1 2.32 2.32 0 01-.06 1.11 1.9 1.9 0 01-.63.94 1.47 1.47 0 01-.97.29zm-45.5-3.82h41.37l-88.85-96.83h-99.3z"
          data-name="bbde9f13-8bfc-495c-aed8-81eb4484432e"
          opacity="0.1"
        />
        <path
          d="M4156 142.79h-122a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35V123.3a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zM4035.72 139h118.68v-13.79h-118.68z"
          data-name="b32b8c48-13ae-4f12-8c02-9167df3028ad"
          opacity="0.1"
        />
        <path
          d="M4156 168.62h-122a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.57a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.57a2.13 2.13 0 01-.47 1.35 1.54 1.54 0 01-1.14.56zm-120.24-3.81h118.64v-13.76h-118.68z"
          data-name="bea62145-0498-445f-b942-04b0c2135492"
          opacity="0.1"
        />
        <path
          d="M4156 194.46h-122a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35V175a2.28 2.28 0 01.12-.73 1.83 1.83 0 01.36-.62 1.64 1.64 0 01.53-.41 1.35 1.35 0 01.62-.15h122a1.58 1.58 0 011.14.57 2.11 2.11 0 01.47 1.34v17.56a2 2 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.24-3.82h118.64v-13.76h-118.68z"
          data-name="b9b151d6-55d7-43d5-af44-4d16e5566bef"
          opacity="0.1"
        />
        <path
          d="M4156 220.32h-122a1.54 1.54 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.58a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.57a2.13 2.13 0 01-.47 1.35 1.58 1.58 0 01-1.14.57zm-120.24-3.82h118.64v-13.76h-118.68z"
          data-name="fb0f8813-8b84-4a84-88f0-48eb7c778108"
          opacity="0.1"
        />
        <path
          d="M4156 246.15h-122a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.57a2 2 0 01.48-1.35 1.47 1.47 0 011.15-.56h122a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.57a2.13 2.13 0 01-.47 1.35 1.54 1.54 0 01-1.14.56zm-120.24-3.82h118.64v-13.76h-118.68z"
          data-name="ae36be7b-59d4-41ef-b01f-ab7c4f207036"
          opacity="0.1"
        />
        <path
          d="M4156 272h-122a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.57a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.58 1.58 0 011.14.57 2.11 2.11 0 01.47 1.34v17.56a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.24-3.82h118.64v-13.77h-118.68z"
          data-name="af00ac64-c6d5-4066-8a10-4b02b865ae4c"
          opacity="0.1"
        />
        <path
          d="M4156 297.8h-122a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.57a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.57a2.13 2.13 0 01-.47 1.35 1.54 1.54 0 01-1.14.56zm-120.28-3.8h118.68v-13.73h-118.68z"
          data-name="a6553631-9ff9-4227-8e0f-84ae98eb0889"
          opacity="0.1"
        />
        <path
          d="M4156 323.68h-122a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.58a2 2 0 01.48-1.35 1.47 1.47 0 011.15-.55h122a1.5 1.5 0 011.14.56 2.11 2.11 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.24-3.82h118.64V306.1h-118.68z"
          data-name="a9902146-2fd9-4abb-a2b4-6172a9bc5cce"
          opacity="0.1"
        />
        <path
          d="M4156 349.52h-122a1.54 1.54 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.56a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.56a2.11 2.11 0 01-.47 1.34 1.58 1.58 0 01-1.14.57zm-120.24-3.82h118.64v-13.76h-118.68z"
          data-name="f88c2b18-b8c6-4412-9456-bcb58e190c93"
          opacity="0.1"
        />
        <path
          d="M4156 375.37h-122a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.55a2 2 0 01.48-1.35 1.47 1.47 0 011.15-.56h122a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.57a2.07 2.07 0 01-.48 1.33 1.51 1.51 0 01-1.13.56zm-120.24-3.82h118.64V357.8h-118.68z"
          data-name="fa101e7e-caeb-4e2a-8de2-149f90948151"
          opacity="0.1"
        />
        <path
          d="M4156 401.21h-122a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.58a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.58 1.58 0 011.14.57 2.16 2.16 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.24-3.8h118.64v-13.76h-118.68z"
          data-name="b64480e2-8d06-443d-a88b-69af758ae602"
          opacity="0.1"
        />
        <path
          d="M4156 427h-122a1.47 1.47 0 01-1.15-.55 2 2 0 01-.48-1.35v-17.52a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.56a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.56zm-120.24-3.81h118.64v-13.72h-118.68z"
          data-name="eaede7d5-d7be-486d-9129-6f7eb8adf9ca"
          opacity="0.1"
        />
        <path
          d="M4156 452.9h-122a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.57a2 2 0 01.48-1.35 1.47 1.47 0 011.15-.56h122a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35V451a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.24-3.82h118.64v-13.75h-118.68z"
          data-name="bc0ab683-4c73-42bc-9253-373006596226"
          opacity="0.1"
        />
        <path
          d="M4156 478.74h-122a1.54 1.54 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.58a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.58 1.58 0 01-1.14.57zm-120.24-3.82h118.64v-13.76h-118.68z"
          data-name="ba034066-0d42-4849-aff0-ccf79cfe4b61"
          opacity="0.1"
        />
        <path
          d="M4156 504.57h-122a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.55a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.55a2.13 2.13 0 01-.47 1.35 1.54 1.54 0 01-1.14.56zm-120.24-3.82h118.64V487h-118.68z"
          data-name="be3b3148-3092-4e22-ad35-e1445373bddc"
          opacity="0.1"
        />
        <path
          d="M4156 530.43h-122a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.58a2 2 0 01.48-1.35 1.54 1.54 0 011.15-.59h122a1.58 1.58 0 011.14.57 2.11 2.11 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.24-3.82h118.64v-13.76h-118.68z"
          data-name="f86b1b58-2959-46a9-bfd7-c6fa92f3c3f6"
          opacity="0.1"
        />
        <path
          d="M4156 556.27h-122a1.66 1.66 0 01-.62-.15 1.64 1.64 0 01-.53-.41 1.83 1.83 0 01-.36-.62 2.28 2.28 0 01-.12-.73v-17.58a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h122a1.5 1.5 0 011.14.57 2.16 2.16 0 01.47 1.34v17.58a2.11 2.11 0 01-.47 1.34 1.58 1.58 0 01-1.14.57zm-120.24-3.82h118.64v-13.76h-118.68z"
          data-name="e2945ee9-8015-41cc-8f6a-ce11032e4109"
          opacity="0.1"
        />
        <path
          d="M4156 582.1h-122a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.55a2 2 0 01.48-1.35 1.47 1.47 0 011.15-.56h122a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.55a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-120.24-3.82h118.64v-13.74h-118.68z"
          data-name="a04b8c2f-d353-4162-8a09-0580e4b05a3b"
          opacity="0.1"
        />
        <path
          d="M4095 114.32h-61a1.54 1.54 0 01-1.15-.56 2 2 0 01-.48-1.35V80.93a2.24 2.24 0 01.12-.73 1.68 1.68 0 01.36-.62A1.48 1.48 0 014034 79h61a1.48 1.48 0 01.62.14 1.64 1.64 0 01.53.42 2 2 0 01.48 1.35v31.48a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.15.56zm-59.27-3.82h57.64V82.84h-57.64z"
          data-name="b06d04c9-e122-4b12-b217-d2778494b592"
          opacity="0.1"
        />
        <path
          d="M4207.52 570.54h-15.6a1.47 1.47 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.45a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.6a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.45a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-14-3.82H4206v-13.67h-12.41z"
          data-name="facec00b-2136-41d4-8123-7584c76c0f40"
          opacity="0.1"
        />
        <path
          d="M4191.92 194.4h-15.62a1.52 1.52 0 01-1.15-.57 2 2 0 01-.47-1.34V175a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.15-.56h15.62a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.45a2.11 2.11 0 01-.47 1.34 1.58 1.58 0 01-1.14.57zm-14-3.8h12.41V177h-12.41z"
          data-name="a9284f4a-722a-4e4b-bc66-ce806c3b1c2d"
          opacity="0.1"
        />
        <path
          d="M4191.92 323.62h-15.62a1.52 1.52 0 01-1.15-.57 2 2 0 01-.47-1.34v-17.45a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.15-.56h15.62a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.45a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-14-3.82h12.41v-13.7h-12.41z"
          data-name="ab4890b7-250b-4b02-8cd4-10e590c62201"
          opacity="0.1"
        />
        <path
          d="M4191.92 427h-15.62a1.55 1.55 0 01-1.15-.55 2 2 0 01-.47-1.34v-17.49a2 2 0 01.47-1.33 1.51 1.51 0 011.15-.56h15.62a1.5 1.5 0 011.14.56 2 2 0 01.47 1.33v17.47a2.16 2.16 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-14-3.8h12.41v-13.67h-12.41z"
          data-name="a6beb8ab-c49b-429a-9498-06d0147078cc"
          opacity="0.1"
        />
        <path
          d="M4191.92 452.84h-15.62a1.52 1.52 0 01-1.15-.57 2 2 0 01-.47-1.34v-17.45a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.15-.56h15.62a1.54 1.54 0 011.14.56 2.13 2.13 0 01.47 1.35v17.45a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-14-3.82h12.41v-13.63h-12.41z"
          data-name="b165ee15-c764-4620-88b9-b4daa67ec8a4"
          opacity="0.1"
        />
        <path
          d="M4207.52 168.56h-15.6a1.52 1.52 0 01-.63-.14 1.92 1.92 0 01-.52-.42 1.68 1.68 0 01-.36-.62 2.24 2.24 0 01-.12-.73V149.2a2 2 0 01.48-1.35 1.5 1.5 0 011.14-.56h15.6a1.54 1.54 0 011.16.56 2.1 2.1 0 01.49 1.35v17.45a2.61 2.61 0 01-.13.73 2 2 0 01-.35.62 1.54 1.54 0 01-.53.42 1.52 1.52 0 01-.63.14zm-14-3.82H4206v-13.63h-12.41z"
          data-name="afe8d066-909c-489c-8a35-caab6c7b97d1"
          opacity="0.1"
        />
        <path
          d="M4207.52 374.58h-15.6a1.47 1.47 0 01-1.15-.55 2 2 0 01-.48-1.35v-17.46a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h15.6a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.45a2.14 2.14 0 01-.48 1.35 1.49 1.49 0 01-1.16.56zm-14-3.86H4206v-13.63h-12.41z"
          data-name="b06f3fbb-b3d3-47e7-8384-6726649cdd8d"
          opacity="0.1"
        />
        <path
          d="M4207.52 504.51h-15.6a1.56 1.56 0 01-1.15-.55 2.11 2.11 0 01-.48-1.34v-17.47a2.11 2.11 0 01.48-1.34 1.56 1.56 0 011.15-.55h15.6a1.56 1.56 0 011.15.55 2.12 2.12 0 01.49 1.34v17.47a2.12 2.12 0 01-.49 1.34 1.56 1.56 0 01-1.15.55zm-14-3.8H4206v-13.65h-12.41z"
          data-name="b64c6c74-3ab0-440d-b638-42888d3287f3"
          opacity="0.1"
        />
        <path
          d="M4207.52 269.54h-15.6a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-17.45a2 2 0 01.48-1.35 1.47 1.47 0 011.15-.56h15.6a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.45a2.18 2.18 0 01-.49 1.34 1.52 1.52 0 01-1.15.57zm-14-3.82H4206v-13.57h-12.41z"
          data-name="b37b9f60-3d10-4d2f-9865-25b8b6c5c475"
          opacity="0.1"
        />
        <path
          d="M4116.7 632.15h-79.11a1.52 1.52 0 01-.63-.14 1.46 1.46 0 01-.53-.42 1.66 1.66 0 01-.35-.62 2.24 2.24 0 01-.12-.73v-31.12a2.08 2.08 0 01.47-1.35 1.49 1.49 0 011.16-.56h79.11a1.54 1.54 0 011.15.56 2.14 2.14 0 01.48 1.35v31.12a2.61 2.61 0 01-.13.73 1.83 1.83 0 01-.36.62 1.44 1.44 0 01-1.15.56zm-77.43-3.81h75.85v-27.23h-75.85z"
          data-name="b9a33f20-fdc3-4926-983f-03f9b367437a"
          opacity="0.1"
        />
        <path
          d="M4056.28 677.06h-18.69a1.54 1.54 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35v-35.67a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.16-.56h18.69a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34v35.67a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-17-3.8h15.43v-31.87h-15.43z"
          data-name="f077a1fb-b5b0-4b71-bfbd-26a98d785db3"
          opacity="0.1"
        />
        <path
          d="M4116.69 670.9h-55a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35v-29.51a2 2 0 01.48-1.35 1.51 1.51 0 011.15-.56h55a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35V669a2.14 2.14 0 01-.48 1.35 1.57 1.57 0 01-1.16.55zm-53.2-3.82h51.64v-25.69h-51.71z"
          data-name="b527b22e-0442-4722-9887-cfd8178af936"
          opacity="0.1"
        />
        <path
          d="M4154.54 669.69h-29.72a1.49 1.49 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35V599.2a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.16-.56h29.72a1.32 1.32 0 01.62.15 1.76 1.76 0 01.53.41 2.57 2.57 0 01.36.62 2.28 2.28 0 01.12.73v68.58a2 2 0 01-.48 1.35 1.64 1.64 0 01-.53.42 1.48 1.48 0 01-.62.14zm-28.14-3.81h26.51v-64.77h-26.46z"
          data-name="a43b10ec-d5b5-4d30-8087-e9333b75b8c3"
          opacity="0.1"
        />
        <path
          d="M3581.63 677.06H3429.1a1.51 1.51 0 01-1.15-.56 2 2 0 01-.48-1.35V16.78a1.91 1.91 0 01.21-.94 1.74 1.74 0 01.59-.7 1.43 1.43 0 011.63 0l152.51 100.65a1.87 1.87 0 01.61.7 2.28 2.28 0 01.22 1v557.7a2.11 2.11 0 01-.47 1.34 1.5 1.5 0 01-1.14.57zm-150.91-3.8H3580V118.55L3430.72 20z"
          data-name="a73c5e42-d4cf-4215-bd3c-a67ac0b06e51"
          opacity="0.1"
          id="cargoPointThree"
        />
        <path
          d="M3566.32 142.79h-121.91a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35V123.3a2.07 2.07 0 01.48-1.33 1.54 1.54 0 011.15-.56h121.91a1.54 1.54 0 011.16.56 2 2 0 01.48 1.35v17.58a2 2 0 01-.49 1.34 1.52 1.52 0 01-1.14.55zM3446 139h118.65v-13.79H3446z"
          data-name="f5c65dfa-b032-480d-92cf-e359982a2026"
          opacity="0.1"
        />
        <path
          d="M3566.32 168.62h-121.91a1.49 1.49 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.57a2.14 2.14 0 01.48-1.35 1.49 1.49 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.14 2.14 0 01-.48 1.35 1.49 1.49 0 01-1.16.56zM3446 164.81h118.65v-13.76H3446z"
          data-name="abac1b09-ecb2-4fe9-a58b-ae5e20efcb7b"
          opacity="0.1"
        />
        <path
          d="M3566.32 194.46h-121.91a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35V175a2 2 0 01.13-.73 1.66 1.66 0 01.35-.62 1.64 1.64 0 01.53-.41 1.49 1.49 0 01.62-.15h121.91a1.36 1.36 0 01.63.15 1.64 1.64 0 01.53.41 1.66 1.66 0 01.35.62 2 2 0 01.13.73v17.56a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zM3446 190.64h118.65v-13.76H3446z"
          data-name="b83f9c53-9894-49f7-9c64-f85e456bf4c6"
          opacity="0.1"
        />
        <path
          d="M3566.32 220.32h-121.91a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.58a2.14 2.14 0 01.48-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.19 2.19 0 01-.48 1.36 1.58 1.58 0 01-1.16.56zM3446 216.5h118.65v-13.76H3446z"
          data-name="ba60ab51-84e4-43cd-a3fd-4be4f257b1ac"
          opacity="0.1"
        />
        <path
          d="M3566.32 246.15h-121.91a1.49 1.49 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35v-17.57a2.08 2.08 0 01.47-1.35 1.49 1.49 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.14 2.14 0 01-.48 1.35 1.49 1.49 0 01-1.16.56zM3446 242.33h118.65v-13.76H3446z"
          data-name="b435e72d-3d8d-47af-bb40-eaa8ead0cf22"
          opacity="0.1"
        />
        <path
          d="M3566.32 272h-121.91a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.57a2.14 2.14 0 01.48-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.57zM3446 268.17h118.65v-13.76H3446z"
          data-name="b14e22a1-7e8b-49c7-ab87-0b4f6a8373c9"
          opacity="0.1"
        />
        <path
          d="M3566.32 297.8h-121.91a1.49 1.49 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.57a2.19 2.19 0 01.47-1.35 1.53 1.53 0 011.17-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.14 2.14 0 01-.48 1.35 1.49 1.49 0 01-1.16.56zM3446 294h118.65v-13.77H3446z"
          data-name="a23395eb-e45d-4123-89a6-4c2acb2c2f86"
          opacity="0.1"
        />
        <path
          d="M3566.32 323.68h-121.91a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.58a2.14 2.14 0 01.48-1.35 1.5 1.5 0 011.16-.55h121.91a1.53 1.53 0 011.16.55 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zM3446 319.86h118.65V306.1H3446z"
          data-name="e8d68cd1-6ff7-49c4-bb57-cd5b5e7535e1"
          opacity="0.1"
        />
        <path
          d="M3566.32 349.52h-121.91a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.56a2.14 2.14 0 01.48-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.58 1.58 0 01-1.16.56zM3446 345.7h118.65v-13.76H3446z"
          data-name="b5426c75-494f-4f6a-92c6-534e358259e5"
          opacity="0.1"
        />
        <path
          d="M3566.32 375.37h-121.91a1.49 1.49 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.55a2.14 2.14 0 01.48-1.35 1.49 1.49 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.57a2.18 2.18 0 01-.49 1.34 1.55 1.55 0 01-1.15.55zM3446 371.55h118.65V357.8H3446z"
          data-name="f59ad1d8-3010-4d31-93a4-e5ba75e260bd"
          opacity="0.1"
        />
        <path
          d="M3566.32 401.21h-121.91a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.58a2.14 2.14 0 01.48-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zm-120.32-3.8h118.65v-13.76H3446z"
          data-name="b3646690-2464-4c45-93d5-615e98395d48"
          opacity="0.1"
        />
        <path
          d="M3566.32 427h-121.91a1.5 1.5 0 01-1.16-.55 2.14 2.14 0 01-.48-1.35v-17.52a2.14 2.14 0 01.48-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.56a2.14 2.14 0 01-.48 1.35 1.51 1.51 0 01-1.16.51zM3446 423.23h118.65v-13.76H3446z"
          data-name="febb0515-c2b0-44a7-9844-899d3ff69065"
          opacity="0.1"
        />
        <path
          d="M3566.32 452.9h-121.91a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.57a2.14 2.14 0 01.48-1.35 1.49 1.49 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35V451a2.14 2.14 0 01-.48 1.35 1.57 1.57 0 01-1.16.55zM3446 449.08h118.65v-13.75H3446z"
          data-name="ad4bc085-e191-4083-bb47-e2d441350b45"
          opacity="0.1"
        />
        <path
          d="M3566.32 478.74h-121.91a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.58a2.14 2.14 0 01.48-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zM3446 474.92h118.65v-13.76H3446z"
          data-name="bca5e4be-7355-48c8-9088-7c88f02e77b0"
          opacity="0.1"
        />
        <path
          d="M3566.32 504.57h-121.91a1.49 1.49 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.55a2.14 2.14 0 01.48-1.35 1.49 1.49 0 011.16-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.55a2.14 2.14 0 01-.48 1.35 1.49 1.49 0 01-1.16.56zM3446 500.75h118.65V487H3446z"
          data-name="bae3375b-63e9-4902-8654-c4420060e00b"
          opacity="0.1"
        />
        <path
          d="M3566.32 530.43h-121.91a1.54 1.54 0 01-1.16-.56 2.08 2.08 0 01-.47-1.35v-17.58a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.58 1.58 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zM3446 526.61h118.65v-13.76H3446z"
          data-name="e25c2b49-58ac-442e-ae27-b7ff48633023"
          opacity="0.1"
        />
        <path
          d="M3566.32 556.27h-121.91a1.71 1.71 0 01-.63-.15 1.64 1.64 0 01-.53-.41 2 2 0 01-.35-.62 2.28 2.28 0 01-.12-.73v-17.58a2.08 2.08 0 01.47-1.35 1.54 1.54 0 011.16-.56h121.91a1.54 1.54 0 011.16.56 2.14 2.14 0 01.48 1.35v17.58a2.16 2.16 0 01-.48 1.35 1.76 1.76 0 01-.53.41 1.71 1.71 0 01-.63.15zM3446 552.45h118.65v-13.76H3446z"
          data-name="f846d9b9-8000-4c2b-9b5d-e5a7c448f1a0"
          opacity="0.1"
        />
        <path
          d="M3566.32 582.1h-121.91a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35v-17.55a2.08 2.08 0 01.47-1.35 1.53 1.53 0 011.17-.56h121.91a1.49 1.49 0 011.16.56 2.14 2.14 0 01.48 1.35v17.55a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.16.56zM3446 578.28h118.65v-13.74H3446z"
          data-name="a9f58d98-ae4d-4d65-888e-86bba1b6e348"
          opacity="0.1"
        />
        <path
          d="M3505.36 114.32h-61a1.54 1.54 0 01-1.16-.56 2.14 2.14 0 01-.48-1.35V80.93a2 2 0 01.13-.73 1.66 1.66 0 01.35-.62 1.44 1.44 0 011.15-.56h61a1.44 1.44 0 011.14.56 1.83 1.83 0 01.36.62 2.24 2.24 0 01.13.73v31.48a2.14 2.14 0 01-.48 1.35 1.56 1.56 0 01-1.15.56zM3446 110.5h57.69V82.84H3446z"
          data-name="af4dd99b-0411-4e69-97c8-18d9cac7453f"
          opacity="0.1"
        />
        <path
          d="M3527.08 632.15H3448a1.5 1.5 0 01-1.14-.56 2.14 2.14 0 01-.48-1.35v-31.12a2.14 2.14 0 01.48-1.35 1.5 1.5 0 011.14-.56h79.13a1.54 1.54 0 011.14.56 2.19 2.19 0 01.47 1.35v31.12a2.13 2.13 0 01-.47 1.35 1.54 1.54 0 01-1.14.56zm-77.49-3.81h75.86v-27.23h-75.86z"
          data-name="b668da87-0275-4f9d-91cc-3fde3c6804bc"
          opacity="0.1"
        />
        <path
          d="M3466.64 677.06H3448a1.48 1.48 0 01-1.14-.57 2.11 2.11 0 01-.48-1.34v-35.67a2.16 2.16 0 01.47-1.34 1.5 1.5 0 011.14-.57h18.69a1.54 1.54 0 011.16.56 2 2 0 01.48 1.35v35.67a2 2 0 01-.48 1.35 1.51 1.51 0 01-1.15.56zm-17-3.8H3465v-31.87h-15.42z"
          data-name="e4b416f5-4485-4d27-81b8-5c3d51aa191e"
          opacity="0.1"
        />
        <path
          d="M3527.08 670.9h-55a1.54 1.54 0 01-1.16-.56 2 2 0 01-.48-1.35v-29.51a2 2 0 01.48-1.35 1.54 1.54 0 011.16-.56h55a1.5 1.5 0 011.14.57 2.11 2.11 0 01.47 1.34V669a2.11 2.11 0 01-.47 1.34 1.58 1.58 0 01-1.14.57zm-53.2-3.82h51.57v-25.69h-51.66z"
          data-name="e26412b6-a14a-43cb-b272-bf7f49f7d1e6"
          opacity="0.1"
        />
        <path
          d="M3565 669.69h-29.78a1.54 1.54 0 01-1.14-.56 2.08 2.08 0 01-.47-1.35V599.2a2 2 0 01.47-1.34 1.5 1.5 0 011.14-.57H3565a1.54 1.54 0 011.15.56 2.14 2.14 0 01.48 1.35v68.58a2.14 2.14 0 01-.48 1.35 1.54 1.54 0 01-1.15.56zm-28.15-3.81h26.6v-64.77h-26.6z"
          data-name="a1dd8347-1dee-418a-a603-182c0fd64033"
          opacity="0.1"
        />
      </g>
      <path
        fill="#9e9e9e"
        d="M0 661.93H4855V907.1199999999999H0z"
        id="rectCar"
        data-name="a864e698-9158-4c2a-b4b8-7a7e99a38e46"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeDasharray="24,24"
        d="M0 782.53h4850.47"
        data-name="b0460a6f-eae3-4efd-93a1-4bbe259550a0"
      />
      <g clipPath="url(#ffbfbc20-43ec-435a-aa0e-d15929046469)">
        <g data-name="eb7d821d-666d-47e1-ba47-bdfa89aa904c">
          <path
            fill="#272e33"
            d="M1341.93 662.36s25.27 3.4 22.66 5-24-2.52-24-2.52z"
            data-name="b322a235-a247-4f95-9885-856ceef4b352"
          />
          <path
            fill="#272e33"
            d="M1281 666.89s-9.73 10.78-12.17 9.06 7.16-9.35 7.16-9.35z"
            data-name="a4ce7758-8938-4fdc-9228-f307f1916808"
          />
          <path
            fill="#434954"
            d="M1326.48 418.91s.72 89.56 5.52 122.44c5.43 37.75 16.12 121.64 16.12 121.64h-6.19s-43-68.7-38.15-109.81c4.21-34.86-9.26-91.52-9.26-91.52s-15 157.54-12.64 205.52h-7.83a307.45 307.45 0 01-18.74-75.91c-1.72-13.76 5.35-35.07 3.92-49.79-4.21-43-8.42-86.87-3.83-113.25l1.81-12.59z"
            data-name="ab16fec5-31c9-4a39-905a-0ec30ee57788"
            id="animationStart"
          />
          <path
            fill="#32383f"
            d="M1345.6 662.91v-.59l-.51-1.76c-.42-1.51-1.09-3.73-1.93-6.63l-1.39-4.82c-.47-1.77-1-3.69-1.56-5.75s-1.14-4.19-1.77-6.59c-.34-1.17-.59-2.39-.89-3.64s-.63-2.52-.92-3.82c-.63-2.6-1.35-5.33-2-8.39s-1.27-5.87-1.94-8.93c-.34-1.56-.67-3.15-1-4.74s-.63-3.23-1-4.87q-.93-4.95-2-10.19c-2.53-14-5.06-29.36-7.2-45.68s-3.71-31.79-4.93-46.14c-.3-3.52-.55-7-.84-10.36 0-1.68-.26-3.31-.34-4.95a29.89 29.89 0 00-.34-4.82c0-3.15-.38-6.21-.54-9.1s-.3-5.75-.43-8.39a23.55 23.55 0 00-.21-3.94 23.35 23.35 0 010-3.74c0-2.43 0-4.7-.21-6.83s0-4.2-.21-6a41.7 41.7 0 010-5v10.95c0 2.13 0 4.44.21 6.83v7.68c0 2.68.21 5.49.34 8.39s.34 6 .55 9.14l.25 4.82.38 4.95c.25 3.4.5 6.84.76 10.4 1.18 14.18 2.73 29.74 4.88 46.14s4.34 32 7 46.14c.72 3.48 1.39 6.88 2.07 10.19.33 1.64.67 3.28 1 4.87s.72 3.15 1.06 4.7q1 4.65 2 8.93l2.06 8.39c.3 1.3.63 2.56.93 3.82s.63 2.43 1 3.61c.63 2.35 1.26 4.53 1.81 6.58l1.64 5.75q.76 2.64 1.47 4.78l2.07 6.59.59 1.72z"
            data-name="f5e7e0f0-b383-43e7-a791-314e705db2cc"
            id="cargoPointOne"
          />
          <path
            fill="#32383f"
            d="M1274 667.06v-2.35c0-1.55-.38-3.82-.67-6.75 0-1.47-.34-3.06-.51-4.87s-.34-3.69-.51-5.79c-.37-4.19-.84-8.89-1.13-14.13 0-2.6-.38-5.33-.59-8.39v-4.19a34.27 34.27 0 00-.21-4.53l-.26-4.7v-4.87c0-3.32-.25-6.67-.33-10.15-.34-13.88-.42-29.36 0-45.18s.8-31.29 1.39-45.13c.29-7 .59-13.55.88-19.71s.63-11.91.89-17.16c.54-10.48 1-19 1.26-24.87 0-2.89.25-5.2.34-6.75 0 1.55-.3 3.81-.47 6.75-.42 5.87-1 14.34-1.55 24.83-.34 5.24-.68 11-1 17.16s-.68 12.58-1 19.71c-.59 13.88-1.18 29.36-1.44 45.17s0 31.3 0 45.22c0 3.44.26 6.84.38 10.11v4.87a27.39 27.39 0 00.26 4.7l.25 4.57a22.73 22.73 0 00.25 4.2c0 2.89.42 5.62.59 8.39.38 5.24.89 10 1.26 14.13.21 2.1.38 4.2.59 5.79s.42 3.4.59 4.86c.38 2.9.68 5.16.85 6.72a4.79 4.79 0 00.25 1.76c-.44.37-.36.58-.36.58z"
            data-name="a2c1f76c-a3f7-4eae-ab36-71ad373a6ff8"
          />
          <path
            fill="#fccab1"
            d="M1387.67 255.37c4.8 1.8 5.93 5.7 6.57 4.49 7.45-14.47 6.14-23 9.26-22.19s-1.81 12.59-.25 14.77c0 0 3.66-17.08 7-16.78s-2.4 13.38-2.27 15.94c0 0 5-15.1 6.9-13s-2 9.6-3.75 15.82c0 0 5.27-12.59 6.36-10s-2 6.84-5.34 16.78c-.85 2.48-6.78 18.25-7.21 18.37s-6.94-.37-6.94-.37 0-7-7.75-16.36a10 10 0 00-5.9-3.48 6.1 6.1 0 00-2 0c-2.52.04.01-5.75 5.32-3.99z"
            data-name="be1dcee3-8a6a-4604-9d64-9d4d5600a39e"
            id="handOne"
          />
          <path
            fill="#e0e0e0"
            d="M1330.1 260.66l-19.16-2.94-40.3.42-10.78 1.93 24.47 96 30.19-3.61z"
            data-name="bad4b3ac-744e-48d9-b43e-c1c345fe6ee9"
          />
          <path
            fill="#f9ac30"
            d="M1293.09 268.17s13 86.86-1.35 117.78l-10 9-9.54-11.95a193.93 193.93 0 0016-65.1 442.29 442.29 0 00.59-49.79z"
            data-name="b8a17170-2b3a-44b7-9350-7b6cd95534fa"
          />
          <path
            fill="#494f5e"
            d="M1242.64 262.12s-60.14 49.58-57.86 77.65c1.85 22.6 66.87 53.43 66.87 53.43l5.35-12.08s-48.51-30.66-44.26-46.85c4.21-15.1 35.71-31.37 35.71-31.37 1.55-15.48 1.22-30.2-5.81-40.78z"
            data-name="ed838d8e-4628-493b-819b-e728c7562b09"
          />
          <path
            fill="#fccab1"
            d="M1307.28 239.26v18.17a17.86 17.86 0 01-23.58 5l-7.92-5.54v-19z"
            data-name="ac1b047e-149a-4676-ac64-0076b88da131"
          />
          <path
            fill="#eaeaea"
            d="M1312 257.34l-.54-6.54-17.28 8.2.84 9.52z"
            data-name="f8cf29e9-cf7a-4b23-be01-1774c6181897"
          />
          <path fill="#cecece" d="M1294.18 259l-2.61 6.12 3.24.88z" data-name="a93034f1-be08-4b85-91aa-1802a9ddcb16" />
          <path
            fill="#cecece"
            d="M1291.57 265.1l-2.19-6.12-2.36 7.46z"
            data-name="e89058f4-78ac-48aa-8204-d4fbec398eb5"
          />
          <path
            fill="#eaeaea"
            d="M1273.5 251.22c0 .25 15.88 7.76 15.88 7.76l-2.19 9-16.38-9.86z"
            data-name="bc1a1047-9274-4b5e-9a5b-9b4e49923d74"
          />
          <path
            fill="#494f5e"
            d="M1335.87 317.83s24.05 41.35 46.91 41.35c33.06 0 28.47-81.58 28.47-81.58l-18.32-.88s-6.32 47-11.71 49.29-32.46-42.2-39.29-60.15l-1.3-3.4-25-4.2-20.85 85.31-1.18 72.1 8 23.16 33.69-2.06-7.12-45.68z"
            data-name="e90ef683-99e7-4b15-9a96-742f9c0a9a27"
          />
          <path
            fill="#494f5e"
            d="M1248.36 303.32a113.85 113.85 0 00-5.72-41.36l28-4 24.17 85.61v75l-4.88 16.78-40.93-2.76s9.93-79.32 10.06-80.95-10.7-48.32-10.7-48.32z"
            data-name="f3c63488-a932-4623-8284-82dac8de8cb5"
          />
          <path
            fill="#3c424c"
            d="M1340.17 266a6.33 6.33 0 00-.3 1.93c0 1.3-.34 3.1-.59 5.37-.46 4.53-1.18 10.74-2 17.66s-1.43 13.13-1.77 17.66c0 2.26-.25 4.19-.29 5.41a6.7 6.7 0 000 2 10.49 10.49 0 00.29-2c0-1.26.34-3.1.55-5.37.46-4.49 1.22-10.74 2-17.62s1.43-13.17 1.77-17.7c0-2.26.25-4.19.25-5.36a11 11 0 00.09-1.98z"
            data-name="f757d0c2-119b-4c4a-9db1-330e26a74903"
          />
          <path
            fill="#3c424c"
            d="M1320.37 259c-.5 0-4.8 48.4-4.8 48.4l-8.42 10.23 1.43 6.67-13.77 25.17v-5.87l20.76-85.44z"
            data-name="b6245686-33f0-4c35-afea-882e3a188ae0"
          />
          <path
            fill="#e8900f"
            d="M1294.77 264.35l-7.07-.3-1.48 4 8.68.63z"
            data-name="ba51bfda-42d3-4880-ae34-803a5c768401"
          />
          <path
            fill="#f9ac30"
            d="M1293 264.05l.38 6.3-4.46.75-.21-6.92z"
            data-name="bd79af9b-423e-43df-888f-199fb7ad1390"
          />
          <path
            fill="#3c424c"
            d="M1242.43 262.67s.54 2.31 1.47 6l.72 3c.25 1.09.5 2.22.75 3.44.51 2.47 1 5.15 1.48 8s.76 5.58 1.05 8a13.9 13.9 0 00.3 3.52c.29 1.09 0 2.14.25 3.1a56.28 56.28 0 00.42 6.09 6.43 6.43 0 000-1.64v-7.59a17.77 17.77 0 00-.25-3.57c-.21-2.47-.51-5.24-1-8.1s-1-5.58-1.56-8c-.25-1.22-.55-2.35-.84-3.44s-.55-2.1-.84-3-.51-1.76-.76-2.47-.42-1.34-.59-1.8a8.39 8.39 0 00-.63-1.55z"
            data-name="a0af5de0-2621-412a-899e-177bbda782d9"
          />
          <path
            fill="#3c424c"
            d="M1294.73 348.49l-13.86-28 1.94-5.29-9.1-9.06-6.71-47.41 3.66-.59 24.17 85.44z"
            data-name="a9abb8d3-3490-45e0-aa01-2cba24c77ad4"
          />
          <path
            fill="#e0e0e0"
            d="M1271.4 310.28l-6.53-9.61-1.73.63a56.12 56.12 0 00-.63 8.39z"
            data-name="aa79340b-ff44-4fe3-9d02-1d2718c8f9c8"
          />
          <path
            fill="#3c424c"
            d="M1272.79 309.52h-11.08a3.89 3.89 0 000 2.69h11.46z"
            data-name="a2abf9b2-5814-482f-b796-b0afdffa1e59"
          />
          <path
            fill="#3c424c"
            d="M1294.14 415.64a12 12 0 00.29-2.6c0-1.72.3-4.2.42-7.17.3-6.08.55-14.47.76-23.7s0-17.62-.29-23.7a47.74 47.74 0 00-.3-7.17 22.65 22.65 0 00-.25-2.6 12.94 12.94 0 000 2.64v30.83c0 9.22-.25 17.61-.38 23.65V413a26.18 26.18 0 00-.25 2.64z"
            data-name="a0f9aba4-ddd2-4aab-af7c-5c471113db46"
          />
          <path
            fill="#3c424c"
            d="M1288.66 368.24a1.06 1.06 0 00.31.75 1.09 1.09 0 00.75.3 1.29 1.29 0 00.48 0 1.15 1.15 0 00.43-.21 1.33 1.33 0 00.29-.38 1 1 0 000-.93 1.1 1.1 0 00-.29-.38 1.15 1.15 0 00-.43-.21 1 1 0 00-.48 0 1 1 0 00-.76.33 1 1 0 00-.23.35 1.2 1.2 0 00-.07.42z"
            data-name="e3e73985-ef9c-4be8-ab51-869b2e752a01"
          />
          <path
            fill="#3c424c"
            d="M1289.72 383.22a1.05 1.05 0 111-1.1v.05a1 1 0 01-.31.74 1 1 0 01-.69.31z"
            data-name="bb437053-1e52-4c5a-b374-19c8b6fe1ae0"
          />
          <path
            fill="#3c424c"
            d="M1269.59 358.93c0 .21 8.76 1.93 19.71 3.86s19.79 3.23 19.83 3-8.76-2-19.71-3.86-19.79-3.25-19.83-3z"
            data-name="b3ca7ca6-3b96-4f51-9d52-2a2ec24cd181"
          />
          <path
            fill="#3c424c"
            d="M1270.85 397.52l-17.1-4.4-3 5.83 18.62 3.95z"
            data-name="bc713500-d98a-4b90-a517-2dc7af9bfcc6"
          />
          <path fill="#3c424c" d="M1329 397l-11.7 2.64V406l15-3.15z" data-name="a55de453-7dc5-43f1-9a9e-87beb8838022" />
          <path
            fill="#fccab1"
            d="M1256.07 383.22a71.45 71.45 0 018.09 8.68l1.43 4.2-9.52-2.44-2.86-4.19z"
            data-name="f81a4f24-e9d0-4626-acb3-93309ccf0738"
          />
          <path
            fill="#eab69b"
            d="M1318.73 201.1c1.52.5 9.77 2.27 7 12.92s-10 8.14-10 8.14z"
            data-name="a751aeec-4fad-472a-a94a-fac54ac0bdcf"
          />
          <path
            fill="#d68772"
            d="M1318.35 218.17h.59a4.12 4.12 0 001.48-.5 6.22 6.22 0 001.76-1.55 5.48 5.48 0 00.72-1.18 6 6 0 00.17-4.32 5.55 5.55 0 00-1.18-2 4.69 4.69 0 00-1.22-1h-.55a12.07 12.07 0 011.31 1.55 5.84 5.84 0 01.88 1.85 6.24 6.24 0 010 2.51 4.61 4.61 0 01-.34 1.26 5.59 5.59 0 01-.59 1 5.41 5.41 0 01-1.43 1.51c-.84.61-1.6.7-1.6.87z"
            data-name="bc7a091a-79c0-4c73-912b-9bab63c2d136"
          />
          <path
            fill="#d68772"
            d="M1317.64 213.34s.33-.29.8-.63a2.83 2.83 0 012-.62 2.48 2.48 0 011.3.5 2.3 2.3 0 01.8.75l.42-.25c-.25-.29-.46-.42-.5-.38a3.32 3.32 0 000 .63l.42-.25a2.51 2.51 0 00-.76-1.09 3.12 3.12 0 00-1.68-.76 2.87 2.87 0 00-1.52.3 2.64 2.64 0 00-1 .75c-.37.55-.37 1.01-.28 1.05z"
            data-name="b2967f84-88aa-4867-bb81-e65d6042d072"
          />
          <path
            fill="#eab69b"
            d="M1264.28 199.54c-1.51.5-9.73 2.27-7 12.88s10 8.14 10 8.14z"
            data-name="e8beed72-8294-4972-9d73-ad460cf78f72"
          />
          <path
            fill="#d68772"
            d="M1264.66 216.62a8.55 8.55 0 01-1.73-1 6 6 0 01-1.47-1.47 5.57 5.57 0 01-.55-1.09 6.53 6.53 0 01-.38-3.73 8.3 8.3 0 01.93-1.89c.67-1 1.39-1.38 1.31-1.51s-.26 0-.55 0a3.68 3.68 0 00-1.22 1 5.63 5.63 0 00-1.18 2 6.27 6.27 0 00-.3 2.85 7.48 7.48 0 00.42 1.47 9.19 9.19 0 00.72 1.21 5.59 5.59 0 001.77 1.51 4.09 4.09 0 001.47.55c.55.18.76.14.76.1z"
            data-name="a9e72f4e-06fc-4005-8963-816b15f333f1"
          />
          <path
            fill="#d68772"
            d="M1265.42 211.75a2.09 2.09 0 00-.38-1.05 3.06 3.06 0 00-1-.75 2.93 2.93 0 00-1.51-.3 3.09 3.09 0 00-1.73.76 3.22 3.22 0 00-.76 1.13l.42.21q.26-.5 0-.63c-.25-.13-.25 0-.5.38l.38.25a5.75 5.75 0 01.8-.76 2.9 2.9 0 011.34-.46 2.75 2.75 0 012 .63c.6.34.81.63.94.59z"
            data-name="b4b09138-5744-4af1-b073-4c8319ab9cf8"
          />
          <path
            fill="#fccab1"
            d="M1271.57 244.59c8.42 8.64 38.49 2.48 42.66-1.34s6.65-53.44 6.65-53.44c1.73-25.58-13-27.14-27.12-30.79s-29.23 4.53-30.62 25.63c-.14 0 1.69 52.89 8.43 59.94z"
            data-name="b3bdbef7-db4c-4fa7-bf21-86ed043f9af2"
          />
          <path
            fill="#b76a2b"
            d="M1318.52 220.81c-21 .59-39.33-1-54-6.17 0 0 2.7 21.73 3.42 24 1.64 5.24 2.44 12 24.67 10s21.82-3.69 23.63-8.68a142 142 0 002.27-19.17z"
            data-name="bc500252-dc7b-42d7-8260-7fdafc510723"
          />
          <path
            fill="#ece9de"
            d="M1302.94 227.14a15.35 15.35 0 01-12.63 2.94 16.13 16.13 0 01-10.45-7.84"
            data-name="fce69a92-d15d-44ab-bae1-2283fcc817ff"
          />
          <path
            fill="#e2a893"
            d="M1286.64 213.64a6.39 6.39 0 001.14 1 30.23 30.23 0 003.37 2.23 16.73 16.73 0 002.61 1.13l.76.21h.84a2.18 2.18 0 00.51 0 1 1 0 00.54-.42 1.89 1.89 0 000-1 7.74 7.74 0 000-1.8 8.85 8.85 0 00-.42-1.76c-.12-.59-.76-2.39-1.18-3.65-.88-2.43-1.81-4.74-2.65-6.79l-.76-1.39v-1a1 1 0 010-.34 25 25 0 01.34-2.72 44.83 44.83 0 00.88-5.41 7.33 7.33 0 00-.5 1.38c-.26.92-.59 2.27-1 3.9 0 .84-.34 1.76-.46 2.81v1.64l.63 1.55c.8 2.1 1.68 4.19 2.57 6.79.42 1.22.84 2.44 1.13 3.57s.59 1.13.59 1.64a11.09 11.09 0 010 1.63 1.34 1.34 0 010 .63h-1l-.71-.21a13.73 13.73 0 01-2.53-1 36.57 36.57 0 01-3.49-2 7.7 7.7 0 00-1.27-.67z"
            data-name="b7fdfd71-820a-4dc5-bb53-3b84ff54ddaa"
          />
          <path
            fill="#b9682b"
            d="M1285.46 186l-16.88 1.59-.68-3.86 17.48-4.28z"
            data-name="be308914-93e2-4613-9b2d-1dc62808829b"
          />
          <path
            fill="#efa98e"
            d="M1284.75 200.13s-2.32-8.85-8.72-8-7 8.39-7 8.39z"
            data-name="a0d759a7-61f1-45ab-af8f-d4653e30e8ed"
          />
          <path
            fill="#ece9de"
            d="M1284.75 200.13a7.62 7.62 0 00-7.67-5.87 8 8 0 00-8.08 6.5s12.17-.42 15.75-.63z"
            data-name="bbb70e49-9628-4a24-baa5-bd6d301e4526"
          />
          <path
            fill="#603934"
            d="M1280.79 200.3s0-4.65-4.21-4.82-4.21 5.24-4.21 5.24a27.06 27.06 0 008.42-.42z"
            data-name="fcf14fa2-e2e5-47a1-a1ba-1ac92c07a040"
          />
          <path
            fill="#443949"
            d="M1269 200.72a14.71 14.71 0 011.47-2.77 8.52 8.52 0 012.36-2.35 7.24 7.24 0 013.87-1.09 7.89 7.89 0 013.88 1.05 9 9 0 012.53 2.18c.59.87 1.14 1.76 1.64 2.68v-.88a8.37 8.37 0 00-3-4.21 8.51 8.51 0 00-4.91-1.66 7.51 7.51 0 00-4.21 1.26 8.45 8.45 0 00-2.44 2.68 5.94 5.94 0 00-1.18 3.1z"
            data-name="ae09c57d-c528-4f49-8baa-e4dfe99c89e5"
          />
          <path
            fill="#fff"
            d="M1270.85 198.45c0 .26.76.5 1.73.5s1.72-.25 1.72-.5-.8-.33-1.72-.33-1.69.04-1.73.33z"
            data-name="a2115bdd-d3c7-43a1-8038-d63422c3ff0b"
          />
          <path
            fill="#efa98e"
            d="M1312.46 199.59s-2.32-8.85-8.76-8-7 8.65-7 8.65z"
            data-name="a49b038f-a0ee-46b4-967a-6df89b62f5ab"
          />
          <path
            fill="#ece9de"
            d="M1312.46 199.59a7.63 7.63 0 00-7.67-5.92 8.17 8.17 0 00-5.24 1.8 8 8 0 00-2.84 4.74s12.08-.37 15.75-.62z"
            data-name="bd100f5f-e7ba-4fd4-a147-3856e14d435e"
          />
          <path
            fill="#603934"
            d="M1307.57 199.79s0-4.69-4.42-4.86-4.21 5.28-4.21 5.28a28.33 28.33 0 008.42-.42"
            data-name="b2bc80f7-917a-471c-8b6a-139be146e522"
          />
          <path
            fill="#443949"
            d="M1296.66 200.21a16 16 0 011.48-2.81 8.94 8.94 0 012.36-2.35 7.72 7.72 0 013.83-1.09 7.83 7.83 0 013.87 1 8.58 8.58 0 012.53 2.19 23.16 23.16 0 011.68 2.68 2.17 2.17 0 000-.88 7.35 7.35 0 00-1.05-2.14 8.44 8.44 0 00-6.9-3.73 7.83 7.83 0 00-4.22 1.3 8.22 8.22 0 00-2.44 2.64 5.7 5.7 0 00-1.14 3.15z"
            data-name="b1862e36-d053-4cda-81a2-a3a1138ce007"
          />
          <path
            fill="#fff"
            d="M1298.52 197.87c0 .21.76.46 1.72.46s1.73-.25 1.69-.46-.76-.38-1.69-.38-1.68.12-1.72.38z"
            data-name="bbd2fa18-9c7d-4cbd-beed-00e6598aca50"
          />
          <path
            fill="#b9682b"
            d="M1314.14 187.63s-17.73-.25-17.85-.59 0-6.75 0-6.75l17.85 3.65z"
            data-name="b8572033-9b2a-4bb1-a37f-0bd5f173848d"
          />
          <path
            fill="#b76a2b"
            d="M1318.44 205.92s3.87-30-3.29-37.75c0 0-34.36 10.48-40.21 6.17s-6.36-6.67-6.36-6.67-2.45 13.21-3.92 18.2a51.63 51.63 0 000 15.1l-2.32-1s-2.18-34.4.51-36 15.5-14.34 27.58-10.61 33 13.8 33.14 21.18-3.83 31.71-3.83 31.71z"
            data-name="e6fecb5d-5195-4bd6-8bf9-585cc4dfa74f"
          />
          <path
            fill="#b76a2b"
            d="M1297.17 155.79s-20.47-10.61-25-6.87a11.93 11.93 0 00-2.93 4.93 12.18 12.18 0 00-.32 5.72 4.42 4.42 0 00-2.54-.8 4.48 4.48 0 00-2.55.8c-2.4 1.89-2.11 9.65-2.11 9.65l14.74-5z"
            data-name="a9b44c0f-c785-44fd-958e-09e61228f641"
          />
          <path
            fill="#d17933"
            d="M1315.15 168s9.06 6.29 8.42 10.87l-.84 6.37-3.54-4.19a51.36 51.36 0 00-4-13.05z"
            data-name="ba9c6aa3-e554-4c21-bb61-a91d023cdbd4"
          />
          <path
            fill="#d17933"
            d="M1301.42 171.78c-32.67-8.39-23.66-23.4-23.66-23.4l6.61 1.84c-7.41 12.67 26.74 18.88 26.74 18.88z"
            data-name="f0852316-debb-4ba6-90ea-a7fd2017c6c0"
          />
          <path
            fill="#d17933"
            d="M1285 174.92s-15.79-8.18-16.09-15.35c0 0-1.93-1.51-3.49-.76 0 0-1 8.85 14.32 16.4z"
            data-name="a9e3e845-4869-43f0-9a4b-64378d4e87a0"
          />
          <path
            fill="#d17933"
            d="M1267.9 171.15s-6 .33-6.19 1.84l-.21 6.5 5.56-4.2z"
            data-name="b7d457c0-1980-4fda-890a-e98ea50cf9f2"
          />
          <path
            fill="#d17933"
            d="M1282.35 186.33l1.3-6.42-3.91 1-1.35 5.83z"
            data-name="f018dfff-95f1-456b-9971-493fca4500e2"
          />
          <path
            fill="#d17933"
            d="M1275.61 187l.55-5.24-1 .21-1.31 5.16z"
            data-name="f5ee1439-e0dd-45b8-8391-f2699a933d8c"
          />
          <path
            fill="#d17933"
            d="M1297.89 180.63l1.43 6.5 3.16.25-1.9-6.21z"
            data-name="f6c26b08-615b-4969-a244-1e999eb596b5"
          />
          <path
            fill="#d17933"
            d="M1305.76 187.46l-1.22-5.49 1.56.71.84 3.36.42-3.32 2 .25-.46 4.49z"
            data-name="ba7f55b7-8d5e-47f2-953c-d4be60db7a95"
          />
        </g>
      </g>
      <path
        fill="#fccab1"
        d="M3073.93 251.9c5 .93 6.43 4.66 6.88 3.4 5.1-14.9 2.67-22.87 5.75-22.51s0 12.43 1.78 14.13c0 0 1.13-16.76 4.41-16.84s-.44 13.12 0 15.54c0 0 2.8-15.14 4.82-13.44s-.61 9.48-1.42 15.63c0 0 3.28-12.75 4.7-10.36s-.93 6.8-2.79 16.64c-.45 2.47-4 18.38-4.3 18.58s-6.68.57-6.68.57a26.53 26.53 0 00-9.63-14.58 9.85 9.85 0 00-6.07-2.55 8.36 8.36 0 00-2 0c-2.13.72-.55-5.11 4.55-4.21z"
        data-name="b15b7d59-75b9-4a36-ba22-10167e1971dc"
        id="handTwo"
      />
      <path
        fill="#434954"
        d="M3042.23 439.83s-23.56 196.67-117.41 226.71v-11.13s54.45-60.25 54.45-167l31.87-50.12z"
        data-name="b35b52bc-3909-46fc-9543-cb0f0095fe1a"
      />
      <path
        fill="#434954"
        d="M2980.13 430.76s-28 35.38 0 100S3075.19 681 3075.19 681l7.65-11.13s-56.6-99-57.49-173.68l-5.26-57.93z"
        data-name="be6d21bf-f69b-4494-bd69-ae54e3a37b59"
      />
      <path
        fill="#32383f"
        d="M3022.59 454.28a10.21 10.21 0 010 2.59v17.53c0 4 .41 8.1.77 12.75.73 9.07 1.78 17.21 2.59 23.12.41 3 .73 5.34.93 7a17.25 17.25 0 01.29 2.59 10.13 10.13 0 01-.57-2.55c-.33-1.62-.77-4-1.22-7-.93-5.87-2.1-14.05-2.83-23.12-.36-4.53-.61-8.87-.73-12.83s0-7.49 0-10.49v-7a17.92 17.92 0 01.77-2.59z"
        data-name="b6df8f73-c05d-4ea8-83f5-aed3fd77cec7"
      />
      <path
        fill="#32383f"
        d="M3004.74 578a13.52 13.52 0 01-1.42-1.78l-.73-1-.77-1.18c-.62-.86-1.26-1.83-1.94-2.91l-1.09-1.7c-.33-.61-.73-1.26-1.1-1.9-.81-1.3-1.57-2.72-2.42-4-1.62-3-3.32-6.36-5-9.92-3.41-7.13-6.16-13.68-8.1-18.46-1-2.39-1.74-4.33-2.27-5.71-.28-.69-.52-1.39-.73-2.1a8 8 0 011 2l2.47 5.59c2.11 4.74 4.94 11.26 8.3 18.38 1.7 3.52 3.36 6.88 5 9.88.81 1.5 1.54 2.92 2.31 4l1.09 1.9 1 1.74c.64 1.09 1.29 2.06 1.82 3l.73 1.22.69 1a13.53 13.53 0 011.13 2z"
        data-name="b2d9995c-94f0-431d-8431-e501f43e0995"
      />
      <path
        fill="#272e33"
        d="M3079.31 674.8s24.5-8.91 25.71-6.15-29 10.76-29 10.76z"
        data-name="af4dee68-8959-414d-9cc0-7a595c942e93"
      />
      <path
        fill="#272e33"
        d="M2924.7 659.94l-4.61 3.12s0 18.1 2.75 18 12.35-1.13 8.82-1.94-7.73-4-6.84-7.61a46.66 46.66 0 00-.12-11.57z"
        data-name="f87baddd-1ec6-41d2-95d9-2a3a44274e62"
      />
      <path
        fill="#32383f"
        d="M3079.31 674.8l-.44-.49-1.26-1.5-2-2.43-1.3-1.57L3073 667l-3.08-4.29-4.05-5.22c-.69-.93-1.33-1.94-2-3l-2.11-3.15-1.13-1.66-1.09-1.7c-.73-1.18-1.46-2.39-2.27-3.6a322.4 322.4 0 01-19.07-36c-.81-1.75-1.54-3.57-2.35-5.35-.36-.89-.77-1.82-1.17-2.71s-.73-1.86-1.13-2.79-.73-1.87-1.14-2.8-.77-1.86-1.13-2.83l-2.19-5.79q-4.37-11.79-8.09-24.54c-2.47-8.5-4.58-16.88-6.4-25.06-.45-2-.85-4-1.3-6.07s-.77-4.05-1.13-6c-.2-1-.41-2-.57-3l-.48-2.92-.49-2.91a22.94 22.94 0 00-.44-2.84 356.84 356.84 0 01-4.05-40.48c-.21-5.79-.29-11.06-.25-15.67v-16a14.19 14.19 0 01.25-3.12 5.27 5.27 0 000-1.94 3.53 3.53 0 010-.65v21.86c0 4.57 0 9.84.36 15.58a376.25 376.25 0 004 40.49l.49 2.83.48 2.92c.16.92.33 1.89.49 2.91a15.21 15.21 0 00.56 2.92c.37 2 .73 4 1.14 5.95s.89 4.05 1.29 6.07c1.83 8.1 4 16.52 6.4 25s5.22 16.68 8.1 24.29l2.18 5.79c.37 1 .77 1.9 1.14 2.84l1.13 2.79 1.1 2.85c.41.89.77 1.78 1.18 2.71l2.31 5.3a321.86 321.86 0 0019.23 36.12c.72 1.25 1.49 2.43 2.22 3.6l1.06 1.74 1.09 1.66 2.1 3.16 2 3 3.68 5.22 3.2 4.33 1.38 1.86 1.21 1.58 1.94 2.51c.53.65.93 1.18 1.18 1.54a4.34 4.34 0 01.52.53z"
        data-name="b3200388-1de6-4549-a871-d901d04f1b41"
      />
      <path
        fill="#32383f"
        d="M2995.83 566.83a3.83 3.83 0 010 1.17 4.26 4.26 0 01-.2 1.46c-.15.62-.27 1.26-.36 1.9s-.29 1.54-.45 2.43-.4 1.82-.65 2.83l-.81 3.28c-.29 1.18-.64 2.35-1 3.64s-.81 2.59-1.21 4.05c-.21.65-.49 1.38-.73 2.07s-.49 1.41-.77 2.14l-.85 2.23-.41 1.13-.48 1.14-1 2.34c-.37.77-.69 1.54-1.06 2.35s-.72 1.58-1.13 2.39-.77 1.66-1.17 2.47l-1.3 2.47-1.34 2.47c-1.86 3.32-4 6.68-6.15 10a96.81 96.81 0 01-7 9.47l-1.78 2.19-1.83 2.1-1.82 2-1.78 2-1.82 1.86-1.73 1.79-.89.85a9.71 9.71 0 01-.89.81l-1.74 1.62-1.7 1.54-1.66 1.46c-1.14.89-2.15 1.82-3.2 2.59s-2 1.62-3 2.3l-2.71 2-2.43 1.66-2.07 1.33-1.66 1.06-1.21.73a6.33 6.33 0 01-1 .52l.93-.69 1.21-.81a17.64 17.64 0 001.62-1.09l2-1.38 2.4-1.7 2.67-2c1-.73 1.9-1.5 3-2.35l3.16-2.63 1.62-1.46L2953 645l1.7-1.62.89-.85.85-.85 1.78-1.78 1.78-1.9c.61-.61 1.18-1.3 1.79-2s1.21-1.33 1.78-2l1.82-2.1 1.78-2.19c2.35-3 4.7-6.07 6.92-9.39s4.29-6.68 6.16-10l1.33-2.47c.45-.81.85-1.62 1.26-2.43s.81-1.62 1.21-2.43.77-1.58 1.14-2.39.72-1.58 1-2.34.69-1.54 1-2.31.28-.73.45-1.1a9.11 9.11 0 00.44-1.13l.89-2.18c.24-.73.53-1.46.77-2.15s.53-1.42.73-2.07l1.3-4c.36-1.3.72-2.47 1.05-3.6s.6-2.23.85-3.24.48-2 .73-2.84.36-1.7.52-2.39.29-1.37.41-1.94.2-1 .28-1.38a6.48 6.48 0 01.22-1.1z"
        data-name="e967430b-a389-4e90-b098-1b1a6529c7d3"
      />
      <path
        fill="#494f5e"
        d="M2999.48 281s35.63 43.08 59.79 56.68c14.7 8.1 21.18-28.87 25.47-52.35l.93-14.73 10.77-2.47s13.52 93.11-28.34 97.16c-21 2.14-28.34-11.5-28.34-11.5z"
        data-name="a3c107c1-b9c8-4612-b2c9-07538c4ff666"
      />
      <path
        fill="#e0e0e0"
        d="M2954.86 294.85c20.48-10.57 23.32-9.07 43.07-14.41l22.84 34.73-2.51 57-50-15.66-26.48-50.57z"
        data-name="a391c4ea-9efc-4764-b2a7-18d11683ee53"
      />
      <path
        fill="#fccab1"
        d="M2964.62 292.66l-2.35-16.19 22.43.28 4.05 7.69s-10.08 14.21-24.13 8.22z"
        data-name="a6e82330-540c-461b-bc95-1315e4c79645"
      />
      <path
        fill="#eaeaea"
        d="M2992.88 282.14l-3.81-5.27-8.7 10.12 4.29 7.86z"
        data-name="a4eb32d2-1fd8-4fde-8ffe-88065f231f33"
      />
      <path fill="#cecece" d="M2980.37 287v6.28l3.11-.48z" data-name="ac069b33-19a1-4422-ada7-f00f20b0348a" />
      <path fill="#cecece" d="M2980.41 293.27l-4.17-4.45.81 7.28z" data-name="ef9017c4-8d0f-4b59-8aad-8bc85daa4ae0" />
      <path
        fill="#eaeaea"
        d="M2959.76 288.17l16.48.65 1.41 8.62-17.73-2.31z"
        data-name="ac6775b3-ddfc-4157-91dc-1b6720f0f394"
      />
      <path
        fill="#ea9315"
        d="M2976.76 293.39l2.19-.2a39.8 39.8 0 013.36-2.43l1.42 2.59-3 1.38-3.36 1.38z"
        data-name="f74bbdaf-3c44-4a62-b8a4-8782482868f1"
      />
      <path
        fill="#f9ac30"
        d="M2977.86 291.53s26.64 52.63 28 61.21l13 32.39 13-8.1-51.14-87.16z"
        data-name="b2b2aaf6-3676-4bf4-bf33-98466895ba31"
      />
      <path
        fill="#494f5e"
        d="M2994.62 281.17s29.76 55.46 23.64 91c-.2 1-1.21 16.93-2.39 35.75-1.29 20.73-2.79 44.54-2.79 44.54l33-9.11s-.53-71.18-11.95-108.83a133.79 133.79 0 00-32.39-53.35 9 9 0 00-7.16 0z"
        data-name="bd3986ad-e0d5-4917-87ec-3fcd428f24a8"
      />
      <path
        fill="#3c424c"
        d="M3018.26 372.17s7.37-13.92 6.44-22.18l-4.33-3.56v-17l-23.36-49-2.47.73s29.07 67.05 23.72 91.01z"
        data-name="bd928697-c1a9-46d7-b354-47a63d8cb2af"
      />
      <path
        fill="#3c424c"
        d="M3013.44 449.14a3.52 3.52 0 01.21-.77 11.21 11.21 0 01.64-2.19c.57-1.9 1.3-4.65 2.07-8.1a196.44 196.44 0 004-27.2 161.3 161.3 0 000-27.45q-.43-4.08-1.26-8.1a1.83 1.83 0 00-.28-1.25c-.29-.37 0-.69 0-.93v-.81a5.66 5.66 0 01.28.72l.29.94a4.59 4.59 0 00.36 1.25 74 74 0 011.5 8.1 144.72 144.72 0 01.36 27.61 176.93 176.93 0 01-4 27.29c-.85 3.4-1.7 6.15-2.34 8.1-.33.93-.57 1.66-.77 2.14a2.39 2.39 0 01-1.06.65z"
        data-name="bb2610ce-9e64-44dc-8518-c40ed71caf93"
      />
      <path
        fill="#3c424c"
        d="M3025.59 383.83a1.26 1.26 0 11-1.27-1.25 1.26 1.26 0 01.9.37 1.22 1.22 0 01.37.88z"
        data-name="aaee151c-faf3-4d87-b306-3b6324faceda"
      />
      <path
        fill="#3c424c"
        d="M3026 400.84a1.26 1.26 0 01-1.24 1.26 1.09 1.09 0 01-.26 0 1.26 1.26 0 010-2.47 1.27 1.27 0 011.14.34 1.62 1.62 0 01.27.41 1.27 1.27 0 01.09.47z"
        data-name="fa57ef34-1409-4921-9a4b-035a7c9264ae"
      />
      <path
        fill="#494f5e"
        d="M3013.2 451.12l-48.38-7.81a269.42 269.42 0 0015.18-47.77c4.46-20.05-47.24-85.39-47.24-85.39l22.14-15.3 63.4 77.33s2.15 36-1.9 55.75z"
        data-name="f457aa71-1ebf-4e3a-9d6a-b367ebd37878"
      />
      <path
        fill="#3c424c"
        d="M2951.3 296.75l34.53 54.5 15 6.07 4.46 11 13 3.88-63.36-77.32z"
        data-name="fcfe93f8-ecff-4804-88fe-c7aad3acc23b"
      />
      <path
        fill="#3c424c"
        d="M3035.1 390.27a29.69 29.69 0 01-4 .33l-2 .2a6.92 6.92 0 00-2.35.28 26.33 26.33 0 00-5.22.93c-1.86.4-3.56.85-5.14 1.34l-2.23.73a17.87 17.87 0 00-1.9.68 26.39 26.39 0 01-3.77 1.34 15.11 15.11 0 013.57-1.9l1.9-.77 2.19-.81c1.58-.48 3.36-1 5.22-1.42s3.68-.68 5.3-.89h4.42a13.82 13.82 0 014.01-.04z"
        data-name="e6b13818-0884-4b31-be1e-ec8fe7358ac7"
      />
      <path
        fill="#af8a71"
        d="M2998 444.48s4.41 2.11 3.48 9.6a31.11 31.11 0 01-1.7 8.91h-9.23s4-13.6 1.86-14.78z"
        data-name="faf778a2-a326-42bc-bf6e-1b7d5253d235"
      />
      <path
        fill="#494441"
        d="M3006.28 461.2a12.4 12.4 0 01-3 1.66c-.93.37-2.1.77-3.4 1.17a37.73 37.73 0 01-4.33 1 36.33 36.33 0 01-4.41.52 27.79 27.79 0 01-3.65 0c-2.06 0-3.32-.32-3.32-.52s5.07-.61 11.1-1.7 10.81-2.57 11.01-2.13z"
        data-name="a1c3754c-d7bc-4396-a8a9-0807a514d9a4"
      />
      <path
        fill="#494f5e"
        d="M2932.72 309.75s-16.2 76.23-2.59 94.21c14.25 19.06 62.3 44.25 62.3 44.25l7.05-4.7s-37.73-37.57-43.4-57.37c-3.08-10.69 6.84-43 6.84-43s-9.68-39.26-30.2-33.39z"
        data-name="b1b2b1e6-8c8f-4e7c-bbec-8c7e2af953ea"
      />
      <path
        fill="#3c424c"
        d="M2961.1 350a17.26 17.26 0 01-.25-2.15c0-1.37-.24-3.4-.48-5.87a49 49 0 00-1.5-8.5 42.25 42.25 0 00-1.7-4.77 36.76 36.76 0 00-2.47-4.74 30.53 30.53 0 00-3.16-4.41 27.91 27.91 0 00-3.64-3.57 26.85 26.85 0 00-7.57-4.45 28 28 0 00-5.63-1.42 9.16 9.16 0 01-2.15-.32 10 10 0 012.19 0 24.86 24.86 0 015.79 1.21 26.69 26.69 0 017.89 4.26 29.84 29.84 0 013.77 3.6 32.44 32.44 0 013.28 4.53 38.69 38.69 0 012.47 5 44.33 44.33 0 011.7 4.9 45.34 45.34 0 011.36 8.7c0 2.51.21 4.5.25 5.91a9.49 9.49 0 01-.15 2.09z"
        data-name="be9ee8be-2323-4542-994c-d1fce6c4748b"
      />
      <path
        fill="#3c424c"
        d="M2974.82 417s1.34 1.42 3.48 3.65 5.14 5.3 8.46 8.7 6.28 6.47 8.34 8.82a26.88 26.88 0 013.16 4.05 35.19 35.19 0 01-3.56-3.56l-8.54-8.62c-3.32-3.4-6.28-6.52-8.3-8.87a22.39 22.39 0 01-3-4.17z"
        data-name="e74cf5b3-87fb-4af6-a5f1-d9f5e12705ef"
      />
      <path
        fill="#3c424c"
        d="M2991.3 448.37a15.47 15.47 0 01-3.44-1.5 74.09 74.09 0 01-14.9-10.12 12.85 12.85 0 01-2.63-2.68 22.43 22.43 0 013 2.23c1.82 1.42 4.33 3.32 7.21 5.3s5.63 3.6 7.57 4.78a21.67 21.67 0 013.2 2z"
        data-name="f3c832a6-3849-4a3e-b7e6-1259cef95159"
      />
      <path
        fill="#3c424c"
        d="M3040.73 366.43a9.16 9.16 0 01-.4-1.87 8.1 8.1 0 00-.37-2.18c-.2-.85-.4-1.78-.61-2.88l-.36-1.66a7.27 7.27 0 00-.45-1.78c-.16-.61-.32-1.25-.48-1.94s-.37-1.34-.57-2-.36-1.38-.57-2.11-.48-1.46-.68-2.19c-.45-1.5-1-3-1.54-4.61-.25-.77-.53-1.54-.81-2.31s-.57-1.5-.81-2.23l-.85-2.14a3.64 3.64 0 00-.41-1c-.12-.33-.28-.69-.4-1s-.53-1.33-.81-1.94-.53-1.26-.77-1.82-1-2.27-1.42-3.28c-.89-2-1.62-3.56-2.1-4.7a9.15 9.15 0 01-.73-1.74 7.94 7.94 0 011 1.62c.57 1.05 1.42 2.63 2.35 4.57.48 1 1 2.07 1.54 3.24s.52 1.22.81 1.82l.81 2c0 .32.28.64.44 1s.29.69.41 1.05l.85 2.15.85 2.22c.28.77.56 1.54.81 2.35.52 1.58 1.09 3.12 1.54 4.62l.64 2.22c.21.73.41 1.42.57 2.11s.36 1.42.53 2.06.32 1.3.44 2l.41 1.82a7.27 7.27 0 00.32 1.66c.12.52.32 2.06.49 2.91a16.7 16.7 0 00.24 2.23 6.44 6.44 0 01.08 1.91z"
        data-name="ebf765d3-fb84-4e42-a034-c0311f8cef07"
      />
      <path
        fill="#eab69b"
        d="M2944.38 236.11c-1.42.6-9.19 2.83-5.83 12.87s10.12 7.21 10.12 7.21z"
        data-name="b10f2f57-368c-48ba-94ab-d1707ccf268d"
      />
      <path
        fill="#d68772"
        d="M2945.87 252.54h-.52a5 5 0 01-1.46-.4 5.45 5.45 0 01-1.82-1.09 6.92 6.92 0 01-.77-1.09 8.72 8.72 0 01-.49-1.38 6 6 0 010-2.79 6.26 6.26 0 011-2 5.18 5.18 0 011.14-1c.28-.2.48-.24.52-.2s-.56.57-1.17 1.54a7.32 7.32 0 00-.73 1.9 5.36 5.36 0 000 2.39 5.75 5.75 0 00.41 1.21 4.05 4.05 0 00.64 1 4.92 4.92 0 001.46 1.34c1.06.33 1.83.45 1.79.57z"
        data-name="a8a00b71-dc5e-4d95-93dc-58d0f4f332df"
      />
      <path
        fill="#d68772"
        d="M2946.28 247.81c-.28-.17-.55-.34-.81-.53a2.84 2.84 0 00-1.94-.28 3 3 0 00-1.22.56 2.82 2.82 0 00-.73.77l-.4-.2c0-.28.4-.44.45-.4a3.11 3.11 0 010 .61l-.41-.2a2.5 2.5 0 01.61-1.13 2.63 2.63 0 011.58-.85 2.34 2.34 0 011.45.2 2.15 2.15 0 011 .65c.42.35.5.76.42.8z"
        data-name="a84253a0-a2fc-4a7b-909f-55dd421ad44a"
      />
      <path
        fill="#eab69b"
        d="M2996.52 231.05c1.5.36 9.52 1.53 7.53 11.94s-9 8.5-9 8.5z"
        data-name="b45c37c7-752a-4568-ab0e-f613530175a0"
      />
      <path
        fill="#d68772"
        d="M2997.29 247.52a7.41 7.41 0 001.58-1.09 5.67 5.67 0 001.3-1.54 3.84 3.84 0 00.48-1 4.25 4.25 0 00.28-1.22 5.3 5.3 0 00-.36-2.43 5.93 5.93 0 00-1-1.74c-.73-.89-1.42-1.25-1.34-1.37s.21 0 .53 0a4.25 4.25 0 011.25.89 6.18 6.18 0 011.26 1.86 5.85 5.85 0 01.44 2.71 4.58 4.58 0 01-.32 1.42 5.57 5.57 0 01-.61 1.22 6.05 6.05 0 01-1.58 1.61 4.24 4.24 0 01-1.37.57c-.34.15-.54.15-.54.11z"
        data-name="ec5c9a9f-9c32-4616-bcfa-e08d7e381662"
      />
      <path
        fill="#d68772"
        d="M2996.28 243a2.5 2.5 0 01.28-1 2.63 2.63 0 01.85-.81 3.23 3.23 0 011.46-.37 2.77 2.77 0 011.66.61 2.55 2.55 0 01.81 1l-.36.28c0-.32-.25-.56 0-.6s.24 0 .52.32l-.44.16a4.12 4.12 0 00-.85-.64 2.51 2.51 0 00-1.3-.4 3 3 0 00-1.86.76c-.49.27-.69.69-.77.69z"
        data-name="a95b4c26-b95d-4a30-a2e4-d4f434452819"
      />
      <path
        fill="#fccab1"
        d="M2992.51 274.85c-7.36 8.87-36.76 4.94-41 1.5s-9.75-51-9.75-51c-3.32-24.53 10.73-27 24-31.42s28.34 2.43 31.06 22.68c.18.02 1.69 51.07-4.31 58.24z"
        data-name="bb1863a5-520b-40b2-b670-39051167dbd2"
      />
      <path
        fill="#b76a2b"
        d="M2945.92 255.09c20.24-.81 37.65-3.56 51.33-9.51 0 0-1.09 21.09-1.66 23.4-1.25 5.14-1.54 11.66-23 11.21s-21.13-2.14-23.19-6.84a130.27 130.27 0 01-3.44-18.26z"
        data-name="e61ca250-96c0-4f96-8aa2-63721deec7f9"
      />
      <path
        fill="#e2a893"
        d="M2976 246.11a6.75 6.75 0 01-1 1 28.49 28.49 0 01-3.11 2.35 15.74 15.74 0 01-2.43 1.3 4.56 4.56 0 01-.81.28h-.81a2 2 0 01-.49 0 .87.87 0 01-.52-.36 2.36 2.36 0 01-.29-1 8.43 8.43 0 010-1.74 3.85 3.85 0 01.29-1.74c.28-.61.52-2.35.89-3.56.64-2.39 1.37-4.66 2.1-6.72l.49-1.5v-1.13a12.12 12.12 0 00-.53-2.6 41.71 41.71 0 01-1.17-5.18 5.84 5.84 0 01.56 1.34c.29.85.73 2.1 1.18 3.68a16 16 0 00.65 2.63v1.62l-.49 1.54c-.65 2.07-1.38 4.33-2 6.72-.33 1.18-.65 2.35-.9 3.52a3.37 3.37 0 01-.28 1.66 10.42 10.42 0 000 1.58 1.37 1.37 0 000 .57h1.67a17.15 17.15 0 002.35-1.17c1.42-.81 2.47-1.58 3.24-2.11a5.41 5.41 0 011.46-1z"
        data-name="f9e1cd34-0c87-4c30-b33c-460d3f24c004"
      />
      <path
        fill="#b9682b"
        d="M2975.31 219.43l16.31.44.41-3.76-17.05-3z"
        data-name="b325c7ec-3241-4292-a52e-241cf39b77a2"
      />
      <path
        fill="#efa98e"
        d="M2950.37 234.24s1.62-8.66 7.86-8.1 7.28 7.86 7.28 7.86c-3.92-8.34-11.94-7.77-15.14.24z"
        data-name="ed5de86c-b2d2-49a3-b517-70b3d8f3edbc"
      />
      <path
        fill="#443949"
        d="M2965.51 233.8a15.6 15.6 0 00-1.58-2.59 8.44 8.44 0 00-2.43-2.1 6.81 6.81 0 00-3.76-.77 7.24 7.24 0 00-3.65 1.26 8.23 8.23 0 00-2.26 2.22c-1.06 1.54-1.3 2.76-1.46 2.71a2.43 2.43 0 010-.85 7.19 7.19 0 01.89-2.14 7.91 7.91 0 016.35-4 7.26 7.26 0 016.56 3.4 5.22 5.22 0 011.34 2.91z"
        data-name="edd3577a-8d19-45d6-8bab-3207cd581cf5"
      />
      <path
        fill="#b9682b"
        d="M2947.94 222.71s17-1.42 17.08-1.75-.4-6.35-.4-6.35l-16.92 4.7z"
        data-name="a8104579-ae76-49df-9895-ec1cb856d401"
      />
      <path
        fill="#b76a2b"
        d="M2945 240.72s-5.66-28.66.69-36.68c0 0 33.65 7.86 38.95 3.28s5.67-6.8 5.67-6.8 3.32 12.67 5 17.53a49.25 49.25 0 011 14.58l2.14-1.1s0-33.28-2.83-34.61-15.83-13-27.21-8.58-30.72 15.46-30.36 22.55 5.75 30.28 5.75 30.28z"
        data-name="a7b6f410-bca9-443d-968f-cd7743904b6e"
      />
      <path
        fill="#b76a2b"
        d="M2962.11 191.05s18.9-11.54 23.52-8.1a11.74 11.74 0 013.85 10 4 4 0 014.85-.33c2.43 1.66 2.68 9.15 2.68 9.15l-14.5-4.05z"
        data-name="a2eb9159-90a8-4733-ac38-b27a4964883c"
      />
      <path
        fill="#d17933"
        d="M2945.67 204s-8.26 6.52-7.37 11l1.26 6.08 3.12-4a49.32 49.32 0 012.99-13.08z"
        data-name="b110bba6-148d-4ae9-9542-c971de262e37"
      />
      <path
        fill="#d17933"
        d="M2959.07 206.71c30.81-10.24 21.18-24 21.18-24l-6.2 2.18c8.1 11.7-24.29 20-24.29 20z"
        data-name="a4056e8a-7d5e-43f0-aa5b-c4fdefc8dff6"
      />
      <path
        fill="#d17933"
        d="M2975.06 208.7s14.58-8.91 14.42-15.83c0 0 1.74-1.58 3.32-1 0 0 1.49 8.46-12.67 16.76z"
        data-name="f0b8e5cc-514d-4244-b7bd-418d68364b1a"
      />
      <path
        fill="#d17933"
        d="M2991.22 203.92s5.79 0 6 1.38l.61 6.27-5.59-3.68z"
        data-name="f92ca5aa-88f6-4e96-bf5c-390b602a57e9"
      />
      <path
        fill="#d17933"
        d="M2978.34 219.51l-1.7-6.08 3.85.65 1.66 5.55z"
        data-name="f3728880-4a42-460f-a186-e08dad17411f"
      />
      <path
        fill="#d17933"
        d="M2984.86 219.71l-.89-5 1 .16 1.58 4.9z"
        data-name="f4f6df0b-0d1c-4e03-8506-7bc5bc92f176"
      />
      <path fill="#d17933" d="M2963.08 215l-1 6.4-3 .45 1.42-6.11z" data-name="e1fba938-2018-40e8-8a1f-c8cbb59cb67f" />
      <path
        fill="#d17933"
        d="M2956 222.14l.81-5.34-1.45.72-.57 3.32-.65-3.2-1.9.4.73 4.29z"
        data-name="b2d9901a-8136-4c99-9e6d-e7f8b946e6c9"
      />
      <path
        fill="#efa98e"
        d="M2976.28 233.48s1.62-8.71 7.85-8.3 7.29 7.85 7.29 7.85c-3.93-8.14-11.94-7.61-15.14.45z"
        data-name="bbdd96bf-2565-49dd-942a-772b2a07116e"
      />
      <path
        fill="#443949"
        d="M2991.31 233.16a15 15 0 00-1.59-2.63 7.58 7.58 0 00-2.43-2.07 6.74 6.74 0 00-3.76-.81 7.72 7.72 0 00-3.65 1.26 7.94 7.94 0 00-2.25 2.26c-1.06 1.54-1.3 2.76-1.42 2.68s0-.29 0-.85a8.42 8.42 0 013.57-5 8.19 8.19 0 013.68-1.24 7.11 7.11 0 014 1 7.55 7.55 0 012.51 2.39 5.4 5.4 0 011.3 3z"
        data-name="e334a97c-6362-4faa-b22f-a07bda49a610"
      />
      <path
        fill="#d68772"
        d="M2984.86 252.91a5.5 5.5 0 01-1.66 1.7 19.66 19.66 0 01-2.19 1.25 19 19 0 01-6 1.7 13.16 13.16 0 01-2.51 0c-1.46 0-2.35-.32-2.35-.56s3.49-.57 7.49-1.75a65.18 65.18 0 017.21-2.34z"
        data-name="ff752a7d-8098-4d02-8d43-cddf3e752efe"
      />
      {carVersion < 1 ? (
        <g>
          <path
            fill="#fed700"
            d="M1615.79 658.37h82.55v-63.2h-82.55z"
            data-name="a45d4ade-c73e-42c0-a8cc-973ead21faf2"
          />
          <path
            fill="#ebc400"
            d="M1672.85 658.37h25.49v-63.2h-25.49z"
            data-name="ba04fc7b-60cb-4873-9282-3669482db716"
          />
          <path fill="#fff" d="M1646.08 613.27h20.39V602h-20.39z" data-name="f1b42c94-55cb-45c8-a9eb-803c5f558672" />
          <path
            fill="#fff"
            d="M1650.73 654.14h-9.37v-9.35h9.37zm-8.66-.69h8v-8h-8z"
            data-name="b96a0ca9-b7eb-4831-bf00-7f28938e0404"
          />
          <path fill="#fff" d="M1648.73 651.93h-5.38v.69h5.38z" data-name="e6f821d9-ae93-4fc7-8df5-3e51eda1086e" />
          <path fill="#fff" d="M1647.52 646.65l1.21 3h-2.44z" data-name="f395fe67-842a-4f5c-bc75-1331bac4f116" />
          <path fill="#fff" d="M1647.86 649.11h-.69v2.57h.69z" data-name="ba3e3a86-31ba-43aa-a065-da71e8909357" />
          <path fill="#fff" d="M1644.57 646.65l1.22 3h-2.44z" data-name="be42783e-a6fe-4009-9b4b-d96a48fe65c4" />
          <path fill="#fff" d="M1644.92 649.11h-.69v2.57h.69z" data-name="fa85e57a-69aa-49bc-b809-ea8085a232b7" />
          <path
            fill="#fff"
            d="M1639.78 654h-9.36v-9.3h9.36zm-8.66-.69h8v-8h-8z"
            data-name="b0e87908-11b5-4ded-806d-10d6d6a020e1"
          />
          <path
            fill="#fff"
            d="M1637.47 647.52v1.07a1 1 0 010 .24.67.67 0 01-.35.35.69.69 0 01-.24.05.64.64 0 01-.45-.19.63.63 0 01-.18-.45v-1.07zm.69-.69h-2.63v1.76a1.24 1.24 0 00.06.54 1.33 1.33 0 001.67.84l.12-.05a1.16 1.16 0 00.44-.32 1.18 1.18 0 00.28-.47 1.24 1.24 0 00.06-.54v-1.76z"
            data-name="b511f9bd-31ab-4dab-9d32-343deed6934d"
          />
          <path fill="#fff" d="M1637.2 649.91h-.69v2h.69z" data-name="b4a9c18a-8294-45ba-b3fc-c1df4d4033e7" />
          <path fill="#fff" d="M1638.17 651.56h-2.64v.69h2.64z" data-name="fcf8aace-e070-4720-9d00-3c8729c5defa" />
          <path
            fill="#fff"
            d="M1633.35 649.91a1.32 1.32 0 00.94-.38 1.28 1.28 0 00.38-.94v-1.72H1632v1.77a1.32 1.32 0 001.35 1.27z"
            data-name="bffd6e6c-594d-4e9d-9763-e18f2ceffe4b"
          />
          <path fill="#fff" d="M1633.71 649.91h-.71v2h.69z" data-name="add2fec0-8a2f-4d1c-80c9-4ae394e351e0" />
          <path fill="#fff" d="M1634.68 651.56H1632v.69h2.64z" data-name="fe3e1548-31cc-44c7-a7ec-c0964dea5d22" />
          <path
            fill="#fff"
            d="M1628.83 654h-9.35v-9.3h9.35zm-8.66-.69h7.94v-8h-8z"
            data-name="b115ca3b-472f-4182-9d37-4b2b82989425"
          />
          <path
            fill="#fff"
            d="M1624.82 652.25a1 1 0 01-1-1v-4.37h.72v4.42a.17.17 0 000 .14.31.31 0 00.06.13l.11.08h.27a.36.36 0 00.12-.08.31.31 0 00.06-.13.33.33 0 000-.14v-.3h.69v.33a1 1 0 01-1 1z"
            data-name="f155c81d-77b7-4ef9-8b21-b2a30a7ccf2c"
          />
          <path
            fill="#fff"
            d="M1621.83 649.34h4.67a2.25 2.25 0 00-2.25-2.25h-.17a2.25 2.25 0 00-2.25 2.25z"
            data-name="f3bc7e4d-4cf2-4a1b-99d8-e10153f7a5bd"
          />
          <path
            fill="#fed700"
            d="M1532.88 658.37h82.54v-63.2h-82.54z"
            data-name="e678be4f-26a4-48af-bf5c-32082fe366a2"
          />
          <path
            fill="#ebc400"
            d="M1589.94 658.37h25.48v-63.2h-25.48z"
            data-name="b2d93d44-40cd-4b64-a31d-0dc5ff8d84b1"
          />
          <path fill="#fff" d="M1563.15 613.27h20.39V602h-20.39z" data-name="b12ae02e-b97a-4532-8c7e-ea1fa3c9ba7f" />
          <path
            fill="#fff"
            d="M1567.8 654.14h-9.35v-9.35h9.35zm-8.67-.69h8v-8h-8z"
            data-name="a29f8ea6-fd42-400c-9973-9239056476ab"
          />
          <path fill="#fff" d="M1565.81 651.93h-5.38v.69h5.38z" data-name="ad1405a4-f05f-4119-97b3-39728b35155f" />
          <path fill="#fff" d="M1564.59 646.65l1.22 3h-2.43z" data-name="b73d2a92-20d6-4ea0-b809-001480442cff" />
          <path fill="#fff" d="M1564.94 649.11h-.69v2.57h.69z" data-name="a0ee256b-8a97-41d2-8a33-abe590c91a1f" />
          <path fill="#fff" d="M1561.65 646.65l1.22 3h-2.44z" data-name="f3f655f0-bddf-419b-bb8a-a00f5b832b1a" />
          <path fill="#fff" d="M1562 649.11h-.69v2.57h.69z" data-name="ad6cad34-9154-4fa0-a7f0-e8b077d4c5c0" />
          <path
            fill="#fff"
            d="M1556.86 654h-9.35v-9.3h9.35zm-8.67-.69h8v-8h-8z"
            data-name="f0c1fea9-dfe8-4e12-b199-a0e1f6071efe"
          />
          <path
            fill="#fff"
            d="M1554.56 647.52v1.07a.65.65 0 01-.63.63.64.64 0 01-.45-.19.6.6 0 01-.18-.44v-1.07zm.69-.69h-2.64v1.76a1.31 1.31 0 001.3 1.32 1.32 1.32 0 001.32-1.32v-1.76z"
            data-name="e286ab47-a274-4817-be37-09c54ba02fed"
          />
          <path fill="#fff" d="M1554.27 649.91h-.69v2h.69z" data-name="b5504d4f-1342-4485-9ea8-572f7ba981df" />
          <path fill="#fff" d="M1555.25 651.56h-2.64v.69h2.64z" data-name="b3b1e3c5-bb8a-48db-85a2-b044605b43b6" />
          <path
            fill="#fff"
            d="M1550.44 649.91a1.32 1.32 0 001.32-1.32v-1.72h-2.64v1.77a1.29 1.29 0 00.4.9 1.37 1.37 0 00.92.37z"
            data-name="fbab9e83-c90e-4f28-8315-5ab4aab3a6d0"
          />
          <path fill="#fff" d="M1550.78 649.91h-.69v2h.69z" data-name="aeb0338b-473e-4f47-b274-00c150d419de" />
          <path fill="#fff" d="M1551.76 651.56h-2.64v.69h2.64z" data-name="a2ddb7da-0953-4b42-aa16-1ca5de957ce6" />
          <path
            fill="#fff"
            d="M1546 654h-9.4v-9.3h9.4zm-8.66-.69h8v-8h-8z"
            data-name="bb3bed83-d035-42c6-b7ec-90c94de985a1"
          />
          <path
            fill="#fff"
            d="M1541.9 652.25a1 1 0 01-1-1v-4.37h.69v4.42a.17.17 0 000 .14.31.31 0 00.06.13l.11.08h.27a.36.36 0 00.12-.08.31.31 0 00.06-.13.33.33 0 000-.14v-.3h.69v.33a1 1 0 01-1 1z"
            data-name="f031e5d0-54a6-4c73-8d67-a24f4a7fc3b0"
          />
          <path
            fill="#fff"
            d="M1538.9 649.34h4.69a2.12 2.12 0 00-.16-.86 2.17 2.17 0 00-.49-.74 2.22 2.22 0 00-1.6-.65h-.13a2.24 2.24 0 00-2.26 2.25z"
            data-name="b5608246-3930-466a-bc4e-8aaad7e7e042"
          />
          <path
            fill="#fed700"
            d="M1587.21 595.79h82.55v-63.2h-82.55z"
            data-name="e5653392-bab8-42bc-9a9a-d3b6386ec422"
          />
          <path
            fill="#ebc400"
            d="M1644.27 595.79h25.49v-63.2h-25.49z"
            data-name="e1f04122-828d-4f14-9d96-0835004fbb52"
          />
          <path fill="#fff" d="M1617.5 550.67h20.39v-11.23h-20.39z" data-name="b1ee82ad-5158-46f9-bd91-22f3e1ebeb34" />
          <path
            fill="#fff"
            d="M1622.16 591.55h-9.38v-9.34h9.38zm-8.69-.63h8v-8h-8z"
            data-name="b31c392d-1e69-44b5-a62d-26dd77a7e239"
          />
          <path fill="#fff" d="M1620.15 589.35h-5.38v.65h5.38z" data-name="ec77b71f-1df8-4eff-ad46-8832bf883922" />
          <path fill="#fff" d="M1618.94 584.07l1.21 3h-2.44z" data-name="bf941747-1211-4328-8bb5-c7084f037c06" />
          <path fill="#fff" d="M1619.28 586.52h-.69v2.57h.69z" data-name="e62a6e79-eaa9-457d-8423-398ca5feb172" />
          <path fill="#fff" d="M1616 584.07l1.23 3h-2.44z" data-name="e037c8d8-dec5-41a1-9ee9-a6324c8d63f7" />
          <path fill="#fff" d="M1616.34 586.52h-.69v2.57h.69z" data-name="e6a59e85-6d5e-40be-8df3-6f905eef888a" />
          <path
            fill="#fff"
            d="M1611.2 591.46h-9.36v-9.35h9.36zm-8.67-.69h8v-8h-8z"
            data-name="fdd7245f-db6e-4c79-bab9-871cd7cd82b7"
          />
          <path
            fill="#fff"
            d="M1608.9 585v1a.64.64 0 01-.71.56.66.66 0 01-.35-.16.61.61 0 01-.21-.4v-1zm.68-.69H1607V586a1.28 1.28 0 00.06.55 1.37 1.37 0 00.72.79 1.33 1.33 0 001.07 0 1.43 1.43 0 00.44-.32 1.5 1.5 0 00.28-.47 1.28 1.28 0 00.06-.55v-1.76z"
            data-name="b67a98cd-4d01-4947-9edf-c5b973707294"
          />
          <path fill="#fff" d="M1608.61 587.33h-.69v2h.69z" data-name="f7b98304-705d-4b5e-bd77-3089ff162276" />
          <path fill="#fff" d="M1609.59 589H1607v.69h2.64z" data-name="bd5a6a6c-e2fc-4b38-8dcd-f467271b2a9a" />
          <path
            fill="#fff"
            d="M1604.78 587.35a1.25 1.25 0 00.51-.1 1.37 1.37 0 00.71-.71 1.48 1.48 0 00.09-.51v-1.78h-2.63V586a1.48 1.48 0 00.09.51 1.3 1.3 0 001.23.83z"
            data-name="b0245990-39a8-4de5-b05c-19809400ba96"
          />
          <path fill="#fff" d="M1605.13 587.33h-.69v2h.69z" data-name="f4401a4a-a1ef-4535-b866-0b5fdf5c3d31" />
          <path fill="#fff" d="M1606.1 589h-2.64v.69h2.64z" data-name="af17f080-0cd5-499b-b542-7482aca97229" />
          <path
            fill="#fff"
            d="M1600.26 591.46h-9.36v-9.35h9.36zm-8.67-.69h8v-8h-8z"
            data-name="f0189b79-2e12-44dc-9f7f-210d2a7f8e67"
          />
          <path
            fill="#fff"
            d="M1596.24 589.66a.88.88 0 01-.39-.08.85.85 0 01-.32-.21 1.06 1.06 0 01-.22-.33 1 1 0 01-.07-.38v-4.42h.73v4.42a.33.33 0 000 .14.31.31 0 00.06.13l.11.08a.17.17 0 00.14 0 .3.3 0 00.25-.11.31.31 0 00.06-.13.33.33 0 000-.14v-.33h.69v.33a.78.78 0 01-.08.4.86.86 0 01-.23.33 1 1 0 01-.34.22.9.9 0 01-.4 0z"
            data-name="aea0cd7f-bf4f-40d0-8ead-32436c4ab9b2"
          />
          <path
            fill="#fff"
            d="M1593.25 586.76h4.66a2.1 2.1 0 00-.17-.86 2.28 2.28 0 00-1.22-1.22 2.1 2.1 0 00-.86-.17h-.16a2.25 2.25 0 00-2.25 2.25z"
            data-name="a1b6341b-c7e0-4224-a538-036f92c4b25a"
          />
        </g>
      ) : (
        <></>
      )}
      {carVersion === 3 ? (
        <g>
          <path fill="#fed700" d="M3761.84 658.28h82.56v-63.2h-82.54z" />
          <path fill="#ebc400" d="M3818.89 658.28h25.51v-63.2h-25.49z" />
          <path fill="#fff" d="M3792.12 613.18h20.4v-11.24h-20.4zM3796.78 653.87h-9.38v-9.3h9.37zm-8.67-.69h8v-8h-8z" />
          <path fill="#fff" d="M3794.78 651.68h-5.38v.69h5.38zM3793.56 646.4l1.22 3h-2.44z" />
          <path fill="#fff" d="M3793.91 648.87h-.7v2.57h.69zM3790.61 646.4l1.23 3h-2.44z" />
          <path fill="#fff" d="M3791 648.87h-.69v2.57h.69zM3785.83 653.76h-9.36v-9.32h9.36zm-8.67-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3783.52 647.26v1.08a1 1 0 010 .24.6.6 0 01-.14.21.48.48 0 01-.2.14.53.53 0 01-.25 0 .73.73 0 01-.44-.19.64.64 0 01-.19-.45v-1.08zm.69-.69h-2.64v1.77a1.41 1.41 0 00.07.54 1.47 1.47 0 00.27.47 1.42 1.42 0 00.45.32 1.3 1.3 0 001.06 0 1.42 1.42 0 00.45-.32 1.47 1.47 0 00.27-.47 1.41 1.41 0 00.07-.54v-1.77z"
          />
          <path fill="#fff" d="M3783.21 649.66h-.69v2h.69z" />
          <path
            fill="#fff"
            d="M3784.21 651.31h-2.64v.69h2.64zM3779.4 649.66a1.24 1.24 0 00.5-.1 1.41 1.41 0 00.72-.72 1.24 1.24 0 00.1-.5v-1.72h-2.63v1.76a1.3 1.3 0 001.31 1.28z"
          />
          <path fill="#fff" d="M3779.75 649.66h-.69v2h.69z" />
          <path fill="#fff" d="M3780.73 651.31h-2.64v.69h2.64zM3774.87 653.76h-9.34v-9.32h9.34zm-8.65-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3770.86 652a1 1 0 01-1-1v-4.37h.73v4.42a.33.33 0 000 .14.23.23 0 00.06.12.31.31 0 00.11.09h.28a.31.31 0 00.11-.09.23.23 0 00.06-.12.33.33 0 000-.14v-.33h.69v.33a1 1 0 01-.33.7 1 1 0 01-.71.25z"
          />
          <path
            fill="#fff"
            d="M3767.87 649.09h4.67a2.29 2.29 0 00-.66-1.6 2.24 2.24 0 00-1.59-.65h-.17a2.24 2.24 0 00-1.59.65 2.29 2.29 0 00-.66 1.6z"
          />
          <path fill="#fed700" d="M3678.92 658.12h82.55v-63.2h-82.55z" />
          <path fill="#ebc400" d="M3736 658.12h25.49v-63.2H3736z" />
          <path fill="#fff" d="M3709.21 613h20.39v-11.22h-20.39zM3713.84 653.87h-9.34v-9.3h9.34zm-8.67-.69h8v-8h-8z" />
          <path fill="#fff" d="M3711.85 651.68h-5.38v.69h5.38zM3710.64 646.4l1.21 3h-2.45z" />
          <path fill="#fff" d="M3711 648.87h-.69v2.57h.69zM3707.7 646.4l1.21 3h-2.44z" />
          <path fill="#fff" d="M3708 648.87h-.64v2.57h.69zM3702.9 653.76h-9.34v-9.32h9.34zm-8.67-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3700.6 647.26v1.08a.6.6 0 01-.18.44.64.64 0 01-.45.19.65.65 0 01-.63-.63v-1.08zm.69-.69h-2.64v1.77a1.32 1.32 0 002.64 0v-1.77z"
          />
          <path fill="#fff" d="M3700.32 649.66h-.69v2h.69z" />
          <path
            fill="#fff"
            d="M3701.29 651.31h-2.64v.69h2.64zM3696.48 649.66a1.31 1.31 0 001.32-1.3v-1.74h-2.59v1.76a1.36 1.36 0 00.4.91 1.28 1.28 0 00.87.37z"
          />
          <path fill="#fff" d="M3696.83 649.66h-.69v2h.69z" />
          <path fill="#fff" d="M3697.8 651.31h-2.59v.69h2.64zM3692 653.76h-9.41v-9.32h9.41zm-8.67-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3688 652a1 1 0 01-1-1v-4.37h.69v4.42a.33.33 0 000 .14.23.23 0 00.06.12.31.31 0 00.11.09h.28a.31.31 0 00.11-.09.23.23 0 00.06-.12.33.33 0 000-.14v-.33h.69v.33a1 1 0 01-1 1z"
          />
          <path
            fill="#fff"
            d="M3685 649.09h4.69a2.36 2.36 0 00-.17-.87 2.23 2.23 0 00-.49-.73 2.4 2.4 0 00-.73-.49 2.12 2.12 0 00-.86-.16h-.13a2.17 2.17 0 00-.87.16 2.4 2.4 0 00-.73.49 2.23 2.23 0 00-.49.73 2.18 2.18 0 00-.17.87z"
          />
          <path fill="#fed700" d="M3733.26 595.73h82.54v-63.2h-82.54z" />
          <path fill="#ebc400" d="M3790.31 595.73h25.49v-63.2h-25.49z" />
          <path fill="#fff" d="M3763.54 550.42h20.39v-11.23h-20.39zM3768.21 591.3h-9.38V582h9.38zm-8.69-.63h8v-8h-8z" />
          <path fill="#fff" d="M3766.21 589.1h-5.38v.69h5.38zM3765 583.81l1.21 3h-2.44z" />
          <path fill="#fff" d="M3765.32 586.27h-.69v2.6h.69zM3762 583.81l1.22 3h-2.44z" />
          <path fill="#fff" d="M3762.4 586.27h-.69v2.6h.69zM3757.21 591.21h-9.35v-9.34h9.35zm-8.66-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3755 584.72v1.07a.64.64 0 01-.71.56.66.66 0 01-.35-.16.62.62 0 01-.22-.4v-1.07zm.67-.69H3753v1.76a1.66 1.66 0 00.06.54 1.18 1.18 0 00.28.47 1.31 1.31 0 001.85.1 1.25 1.25 0 00.37-.56 1.24 1.24 0 00.06-.54V584z"
          />
          <path fill="#fff" d="M3754.66 587.08h-.66v2h.69z" />
          <path
            fill="#fff"
            d="M3755.64 588.73H3753v.69h2.65zM3750.83 587.1a1.24 1.24 0 00.5-.1 1.13 1.13 0 00.43-.29 1.34 1.34 0 00.38-.93V584h-2.63v1.77a1.13 1.13 0 00.09.51 1.37 1.37 0 00.28.44 1.6 1.6 0 00.44.29 1.13 1.13 0 00.51.09z"
          />
          <path fill="#fff" d="M3751.21 587.08h-.69v2h.69z" />
          <path fill="#fff" d="M3752.15 588.73h-2.64v.69h2.64zM3746.3 591.21h-9.3v-9.34h9.35zm-8.66-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3742.28 589.41a1 1 0 01-.38-.08.9.9 0 01-.54-.54 1 1 0 01-.08-.38V584h.74v4.42a.33.33 0 000 .14.19.19 0 00.07.12.31.31 0 00.11.09h.27a.31.31 0 00.11-.09.23.23 0 00.06-.12.33.33 0 000-.14v-.33h.69v.33a1.1 1.1 0 01-.08.4 1.5 1.5 0 01-.23.33 1.13 1.13 0 01-.35.21.86.86 0 01-.39.05z"
          />
          <path
            fill="#fff"
            d="M3739.29 586.51h4.71a2.36 2.36 0 00-.17-.87 2.4 2.4 0 00-.49-.73 2.37 2.37 0 00-.73-.48 2.1 2.1 0 00-.86-.17h-.17a2.24 2.24 0 00-1.59.65 2.27 2.27 0 00-.7 1.6z"
          />
          <path fill="#fed700" d="M3958.84 658.61h82.56v-63.2h-82.54z" />
          <path fill="#ebc400" d="M4015.89 658.61h25.51v-63.2h-25.49z" />
          <path fill="#fff" d="M3989.12 613.51h20.4v-11.24h-20.4zM3993.78 654.36h-9.38v-9.3h9.37zm-8.67-.69h8v-8h-8z" />
          <path fill="#fff" d="M3991.78 652.17h-5.38v.69h5.38zM3990.56 646.89l1.22 3h-2.44z" />
          <path fill="#fff" d="M3990.91 649.36h-.7v2.57h.69zM3987.61 646.89l1.23 3h-2.44z" />
          <path fill="#fff" d="M3988 649.36h-.69v2.57h.69zM3982.83 654.25h-9.36v-9.32h9.36zm-8.67-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3980.52 647.75v1.08a1 1 0 010 .24.68.68 0 01-.34.35h-.25a.73.73 0 01-.44-.19.68.68 0 01-.19-.45v-1.08zm.69-.69h-2.64v1.77a1.64 1.64 0 00.07.54 1.69 1.69 0 00.27.47 1.6 1.6 0 00.45.32 1.3 1.3 0 001.06 0 1.28 1.28 0 00.72-.79 1.24 1.24 0 00.07-.54v-1.77z"
          />
          <path fill="#fff" d="M3980.21 650.15h-.69v2h.69z" />
          <path
            fill="#fff"
            d="M3981.21 651.8h-2.64v.69h2.64zM3976.4 650.15a1.24 1.24 0 00.5-.1 1.41 1.41 0 00.72-.72 1.09 1.09 0 00.1-.5v-1.72h-2.63v1.76a1.32 1.32 0 001.31 1.28z"
          />
          <path fill="#fff" d="M3976.75 650.15h-.69v2h.69z" />
          <path fill="#fff" d="M3977.73 651.8h-2.64v.69h2.64zM3971.87 654.25h-9.34v-9.32h9.34zm-8.65-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3967.86 652.49a1 1 0 01-1-1v-4.37h.73v4.42a.33.33 0 000 .14.34.34 0 00.06.12.31.31 0 00.11.09h.28a.2.2 0 00.11-.09.23.23 0 00.06-.12.33.33 0 000-.14v-.33h.69v.33a1 1 0 01-.33.7 1 1 0 01-.71.25z"
          />
          <path
            fill="#fff"
            d="M3964.87 649.58h4.67a2.24 2.24 0 00-2.23-2.25h-.19a2.26 2.26 0 00-1.59.65 2.33 2.33 0 00-.66 1.6z"
          />
          <path fill="#fed700" d="M3875.92 658.61h82.55v-63.2h-82.55z" />
          <path fill="#ebc400" d="M3933 658.61h25.49v-63.2H3933z" />
          <path
            fill="#fff"
            d="M3906.21 613.51h20.39v-11.24h-20.39zM3910.84 654.36h-9.34v-9.3h9.34zm-8.67-.69h8v-8h-8z"
          />
          <path fill="#fff" d="M3908.85 652.17h-5.38v.69h5.38zM3907.64 646.89l1.21 3h-2.45z" />
          <path fill="#fff" d="M3908 649.36h-.69v2.57h.69zM3904.7 646.89l1.21 3h-2.44z" />
          <path fill="#fff" d="M3905 649.36h-.64v2.57h.69zM3899.9 654.25h-9.34v-9.32h9.34zm-8.67-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3897.6 647.75v1.08a.55.55 0 01-.18.44.64.64 0 01-.45.19.61.61 0 01-.44-.19.66.66 0 01-.19-.44v-1.08zm.69-.69h-2.64v1.77a1.35 1.35 0 001.32 1.32 1.31 1.31 0 001.32-1.3v-1.79z"
          />
          <path fill="#fff" d="M3897.32 650.15h-.69v2h.69z" />
          <path
            fill="#fff"
            d="M3898.29 651.8h-2.64v.69h2.64zM3893.48 650.15a1.31 1.31 0 001.32-1.3v-1.74h-2.59v1.76a1.41 1.41 0 00.4.91 1.42 1.42 0 00.87.37z"
          />
          <path fill="#fff" d="M3893.83 650.15h-.69v2h.69z" />
          <path fill="#fff" d="M3894.8 651.8h-2.59v.69h2.64zM3889 654.25h-9.41v-9.32h9.41zm-8.67-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3885 652.49a1 1 0 01-1-1v-4.37h.69v4.42a.33.33 0 000 .14.34.34 0 00.06.12.31.31 0 00.11.09h.28a.2.2 0 00.11-.09.23.23 0 00.06-.12.33.33 0 000-.14v-.33h.69v.33a1 1 0 01-1 1z"
          />
          <path
            fill="#fff"
            d="M3882 649.58h4.69a2.18 2.18 0 00-.17-.87 2.28 2.28 0 00-1.22-1.22 2.12 2.12 0 00-.86-.16h-.13a2.17 2.17 0 00-.87.16 2.6 2.6 0 00-.73.49 2.4 2.4 0 00-.49.73 2.36 2.36 0 00-.17.87z"
          />
          <path fill="#fed700" d="M3930.26 596.06h82.54v-63.2h-82.54z" />
          <path fill="#ebc400" d="M3987.31 596.06h25.49v-63.2h-25.49z" />
          <path
            fill="#fff"
            d="M3960.54 550.91h20.39v-11.23h-20.39zM3965.21 591.79h-9.38v-9.34h9.38zm-8.69-.63h8v-8h-8z"
          />
          <path fill="#fff" d="M3963.21 589.59h-5.38v.69h5.38zM3962 584.3l1.21 3h-2.44z" />
          <path fill="#fff" d="M3962.32 586.76h-.69v2.6h.69zM3959 584.3l1.22 3h-2.44z" />
          <path fill="#fff" d="M3959.4 586.76h-.69v2.6h.69zM3954.21 591.7h-9.35v-9.34h9.35zm-8.66-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3952 585.21v1.07a.64.64 0 01-.71.56.75.75 0 01-.35-.16.62.62 0 01-.22-.4v-1.07zm.67-.69H3950v1.76a2.48 2.48 0 00.06.54 1.5 1.5 0 00.28.47 1.33 1.33 0 001.87.09 1.29 1.29 0 00.36-.56 1.24 1.24 0 00.06-.54v-1.76z"
          />
          <path fill="#fff" d="M3951.66 587.57h-.66v2h.69z" />
          <path
            fill="#fff"
            d="M3952.64 589.22H3950v.69h2.65zM3947.83 587.59a1.24 1.24 0 00.5-.1 1.33 1.33 0 00.81-1.22v-1.79h-2.63v1.77a1.48 1.48 0 00.09.51 1.57 1.57 0 00.28.44 1.6 1.6 0 00.44.29 1.29 1.29 0 00.51.1z"
          />
          <path fill="#fff" d="M3948.21 587.57h-.69v2h.69z" />
          <path fill="#fff" d="M3949.15 589.22h-2.64v.69h2.64zM3943.3 591.7h-9.3v-9.34h9.35zm-8.66-.69h8v-8h-8z" />
          <path
            fill="#fff"
            d="M3939.28 589.9a1 1 0 01-.38-.08.9.9 0 01-.54-.54 1.23 1.23 0 01-.08-.38v-4.42h.74v4.42a.33.33 0 000 .14.75.75 0 00.07.12.31.31 0 00.11.09h.27a.2.2 0 00.11-.09.14.14 0 00.06-.12.33.33 0 000-.14v-.33h.69v.33a.92.92 0 01-.08.4 1.5 1.5 0 01-.23.33 1.13 1.13 0 01-.35.21.86.86 0 01-.39.06z"
          />
          <path
            fill="#fff"
            d="M3936.29 587h4.71a2.18 2.18 0 00-.17-.87 2.23 2.23 0 00-.49-.73 2.21 2.21 0 00-.73-.48 2 2 0 00-.86-.17h-.17a2.26 2.26 0 00-1.59.65 2.35 2.35 0 00-.7 1.6z"
          />
        </g>
      ) : (
        <></>
      )}
    </svg>
  );
};
export default BackgroundLight;
