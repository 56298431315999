import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import BackgroundLight from 'svgs/BackgroundLight';
import CarVersionLightMode from 'svgs/CarVersionLightMode';
import Circle from 'svgs/Circle';
import Logo from 'svgs/Logo';
import LogoLightIcon from 'svgs/LogoLightIcon';
import Typography from 'ui/typography/Typography';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import upDatesImg from '../../assets/images/global/upDates.jpg';
import music from '../../assets/sounds/vuvu.mp3';
import BackGround from '../../svgs/Background';
import CarVersion from '../../svgs/CarVersion';
import Modal from '../modal/Modal';
import RegisterModalContent from '../registerModalContent/RegisterModalContent';
import SignUpContent from '../signUpContent/SignUpContent';

import styles from './car.module.scss';

type CargoTypes = {
  cargo1: number;
  cargo2: number;
  cargo3: number;
};

const Car = ({ modeSwitcher, setOpenMap }: any) => {
  const spaceHolderRef = useRef<HTMLDivElement>(null);
  const horizontalRef = useRef<HTMLDivElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);
  const carRef = useRef<SVGSVGElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const [carVersion, setCarversion] = useState<number>(0);
  const backgroundRef = useRef<SVGSVGElement>(null);
  const [animationStart, setAnimationStart] = useState(false);
  const [animationTwoStart, setAnimationTwoStart] = useState(false);
  const [activeCircle, setActiveCircle] = useState('');
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openSignUpModal, setOpenSignUpModal] = useState(false);

  const onClose = () => {
    setOpenRegisterModal(false);
    setOpenSignUpModal(false);
    setActiveCircle('');
  };

  const onChoose = (key: string) => {
    setOpenSignUpModal(false);
    setActiveCircle(key);
    setOpenRegisterModal(true);
  };

  useEffect(() => {
    if (openRegisterModal || openSignUpModal) {
      const body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'hidden';
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'auto';
    }
  }, [openRegisterModal, openSignUpModal]);

  const onClick = () => {
    if (activeCircle === 'Carrier') {
      setOpenSignUpModal(false);
      setActiveCircle('');
      window.location.href = 'http://app.freightmax.com/carrierotp';
    } else {
      setOpenSignUpModal(true);
    }
    setOpenRegisterModal(false);
  };

  const [cargoCordinates, setCargoCordinates] = useState<CargoTypes>({
    cargo1: 0,
    cargo2: 0,
    cargo3: 0,
  });

  useEffect(() => {
    const audioElement = new Audio(music);
    let isPlaying = false;

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !isPlaying) {
        isPlaying = true;
        audioElement.pause();
        audioElement.currentTime = 0;
        audioElement.play();
      }
    };

    const handleEnded = () => {
      isPlaying = false;
    };

    window.addEventListener('keypress', handleKeyPress);
    audioElement.addEventListener('ended', handleEnded);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
      audioElement.removeEventListener('ended', handleEnded);
    };
  }, []);

  useEffect(() => {
    const rect = document.getElementById('rectCar');

    const cargoPointOne: HTMLElement | null = document.getElementById('cargoPointOne');
    const cargoPointTwo: HTMLElement | null = document.getElementById('cargoPointTwo');
    const cargoPointThree: HTMLElement | null = document.getElementById('cargoPointThree');
    const animationStart: HTMLElement | null = document.getElementById('animationStart');
    const animationTwoStart: HTMLElement | null = document.getElementById('animationTwoStart');
    const handOne: HTMLElement | null = document.getElementById('handOne');
    const handTwo: HTMLElement | null = document.getElementById('handTwo');
    const handOneMessage: HTMLElement | null = document.getElementById('handOneMessage');
    const youtube: HTMLElement | null = document.getElementById('youtube');
    const handTwoMessage: HTMLElement | null = document.getElementById('handTwoMessage');
    const carVersion = document.getElementById('carVersion');
    const youtubeHeight: number | undefined = youtube?.getBoundingClientRect().height;
    const handOneMessageHeight: number | undefined = handOneMessage?.getBoundingClientRect().height;
    const handTwoMessageHeight: number | undefined = handTwoMessage?.getBoundingClientRect().height;
    const build: HTMLElement | null = document.getElementById('build');

    if (spaceHolderRef.current) {
      const calcDynamicHeight = (ref: React.RefObject<HTMLDivElement>) => {
        const vw = window.innerWidth;
        const vh = window.innerHeight;
        const objectWidth = ref.current?.scrollWidth;
        return objectWidth ? vw + vh : 1;
      };
      spaceHolderRef.current.style.height = `${calcDynamicHeight(horizontalRef)}px`;
    }

    const handleSizes = () => {
      if (rect !== null && carVersion !== null) {
        carVersion.style.top = `${rect.getBoundingClientRect().top - rect.getBoundingClientRect().height / 2}`;
        carVersion.style.height = `${rect.getBoundingClientRect().height}`;
        carVersion.style.width = `${rect.getBoundingClientRect().height * 3}`;
        const cargoPointOne = document.getElementById('cargoPointOne');
        const cargoPointTwo = document.getElementById('cargoPointTwo');
        const cargoPointThree = document.getElementById('cargoPointThree');
        if (cargoPointOne && cargoPointTwo && cargoPointThree) {
          setCargoCordinates({
            cargo1: cargoPointOne.getBoundingClientRect().x,
            cargo2: cargoPointTwo.getBoundingClientRect().x,
            cargo3: cargoPointThree.getBoundingClientRect().x,
          });
        }
      }
    };

    if (rect !== null && carVersion !== null) {
      carVersion.style.top = `${rect.getBoundingClientRect().top - rect.getBoundingClientRect().height / 2}`;
      carVersion.style.height = `${rect.getBoundingClientRect().height}`;
      carVersion.style.width = `${rect.getBoundingClientRect().height * 3}`;
    }

    if (spaceHolderRef.current && overlayRef.current && carRef.current) {
      spaceHolderRef.current.style.height = `${overlayRef.current?.scrollWidth + overlayRef.current?.clientWidth}px`;
    }

    window.addEventListener('scroll', function () {
      const handOneCordinateTop: number | undefined = handOne?.getBoundingClientRect().top;
      const handOneCordinateLeft: number | undefined = handOne?.getBoundingClientRect().left;
      const handTwoCordinateTop: number | undefined = handTwo?.getBoundingClientRect().top;
      const handTwoCordinateLeft: number | undefined = handTwo?.getBoundingClientRect().left;
      const animaTionStartCordinate = animationStart?.getBoundingClientRect().x;
      const animationTwoStartCordinate = animationTwoStart?.getBoundingClientRect().x;
      const buildTop: number | undefined = build?.getBoundingClientRect().top;
      const buildLeft: number | undefined = build?.getBoundingClientRect().left;

      const carVersionCordinate = carVersion?.getBoundingClientRect().x;
      const carVersionWidth = carVersion?.getBoundingClientRect().width;
      if (Math.round(animaTionStartCordinate!) <= Math.round(carVersionCordinate!) + carVersionWidth!) {
        setAnimationStart(true);
      }
      if (Math.round(animationTwoStartCordinate!) <= Math.round(carVersionCordinate!) + carVersionWidth!) {
        setAnimationTwoStart(true);
      }

      if (buildTop !== undefined && buildLeft !== undefined && youtubeHeight !== undefined && youtube !== null) {
        const messageTop: number | undefined = buildTop - 140;
        const messageleft: number | undefined = buildLeft - 240;
        youtube.style.top = `${messageTop}px`;
        youtube.style.left = `${messageleft}px`;
      }
      if (
        handOneCordinateTop !== undefined &&
        handOneMessageHeight !== undefined &&
        handOneCordinateLeft !== undefined &&
        handOneMessage !== null
      ) {
        const messageTop: number | undefined = handOneCordinateTop - handOneMessageHeight - 20;
        const messageleft: number | undefined = handOneCordinateLeft - 30;
        handOneMessage.style.top = `${messageTop}px`;
        handOneMessage.style.left = `${messageleft}px`;
      }

      if (
        handTwoCordinateTop !== undefined &&
        handTwoMessageHeight !== undefined &&
        handTwoCordinateLeft !== undefined &&
        handTwoMessage !== null
      ) {
        const messageTop: number | undefined = handTwoCordinateTop - handTwoMessageHeight;
        const messageleft: number | undefined = handTwoCordinateLeft - 30;

        handTwoMessage.style.top = `${messageTop}px`;
        handTwoMessage.style.left = `${messageleft}px`;
      }

      if (cargoPointOne && cargoPointTwo && cargoPointThree) {
        setCargoCordinates({
          cargo1: cargoPointOne.getBoundingClientRect().x,
          cargo2: cargoPointTwo.getBoundingClientRect().x,
          cargo3: cargoPointThree.getBoundingClientRect().x,
        });
      }
    });

    setAnimationStart(false);
    setAnimationTwoStart(false);

    window.addEventListener('resize', handleSizes);
    handleSizes();
  }, [modeSwitcher]);

  useEffect(() => {
    const scrollHelper = (cord: number | undefined) => {
      if (carRef.current && stickyRef.current && backgroundRef.current && overlayRef.current) {
        const carX = carRef.current.getBoundingClientRect().x;
        const cargo1X = cargoCordinates.cargo1;
        const cargo2X = cargoCordinates.cargo2;
        const cargo3X = cargoCordinates.cargo3;
        const scrollIsAtBottom =
          window.devicePixelRatio >= 2
            ? window.innerHeight + window.scrollY >= document.body.offsetHeight
            : window.innerHeight + window.scrollY >= document.body.offsetHeight - 0.8;
        const speedFactor = 2;

        if (carX !== undefined) {
          if (carX >= cargo1X && carX <= cargo2X) {
            setCarversion(1);
          } else if (carX <= cargo1X) {
            setCarversion(0);
          } else if (carX >= cargo2X && carX <= cargo3X) {
            setCarversion(2);
          } else if (carX >= cargo3X) {
            setCarversion(3);
          }
          if (scrollIsAtBottom) {
            setOpenMap(true);
          }

          if (overlayRef.current?.offsetWidth + stickyRef.current.offsetTop < overlayRef.current?.scrollWidth) {
            backgroundRef.current.style.transform = `translateX(${-stickyRef.current.offsetTop}px)`;
            carRef.current.style.transform = `translateX(${
              (stickyRef.current.offsetTop / (overlayRef.current?.scrollWidth + carRef?.current.clientWidth)) * 100
            }%)`;
          } else {
            const speedAdjustedTranslateX =
              (stickyRef.current.offsetTop / (overlayRef.current?.scrollWidth + carRef?.current.clientWidth)) *
              100 *
              speedFactor;
            carRef.current.style.transform = `translateX(${speedAdjustedTranslateX}%)`;
            carRef.current.style.transition = '0.5s';
          }
        }
      }
    };

    const handleScroll = () => {
      scrollHelper(carRef.current?.getBoundingClientRect().x);
    };

    if (stickyRef.current && overlayRef.current) {
      if (
        carRef.current &&
        cargoCordinates.cargo1 &&
        (stickyRef.current.offsetTop / overlayRef.current?.scrollWidth) * 100 !== 100
      ) {
        window.addEventListener('scroll', handleScroll);
      }
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [carRef, cargoCordinates, modeSwitcher]);

  return (
    <>
      <div
        className={clsx([styles.carComp], {
          [styles.lightMode]: modeSwitcher,
          [styles.animatedStart]: true,
        })}
      >
        <div className={styles['space-holder']} ref={spaceHolderRef}>
          <div className={styles['sticky']} ref={stickyRef}>
            <div
              className={clsx([styles.horizontal], {
                [styles.lightMode]: modeSwitcher,
              })}
              ref={horizontalRef}
            >
              {modeSwitcher ? (
                <CarVersionLightMode carVersion={carVersion} ref={carRef} />
              ) : (
                <CarVersion carVersion={carVersion} ref={carRef} />
              )}
              <div ref={overlayRef} className={`${styles['background-overlay']}`}>
                <div className={`${styles['youtube']}`} id="youtube">
                  <img src={upDatesImg} alt={'upDatesImg'} />
                  {/*<iframe width="811px" height="535" src="https://www.youtube.com/embed/tgbNymZ7vqY" />*/}
                </div>
                {modeSwitcher ? (
                  <BackgroundLight backgroundRef={backgroundRef} carVersion={carVersion} />
                ) : (
                  <BackGround backgroundRef={backgroundRef} carVersion={carVersion} />
                )}

                <div
                  className={`${styles['message-overlay']} ${styles[animationStart ? 'show' : '']} ${
                    styles[modeSwitcher ? 'dark' : '']
                  }`}
                  id="handOneMessage"
                >
                  {animationStart && (
                    <>
                      <div className={`${styles['message']}`}>
                        <Typography variant="paragraph" children="Welcome to FreightMax!" />
                        <Typography
                          variant="paragraph"
                          children="Please tell us who you are, so we may serve you best !"
                        />
                      </div>
                      <div className={styles['dashed-line']}></div>
                      <div className={styles['dashed-line-right']}></div>
                      <div className={styles['circle-animation']}>
                        <Circle strokeWidth={'4'} />
                        <div className={styles['brand']}>{modeSwitcher ? <LogoLightIcon /> : <Logo />}</div>
                      </div>
                      {(activeCircle === 'Carrier' || activeCircle === '') && (
                        <>
                          <div className={styles['dashed-line-right-top']}></div>
                          <div onClick={() => onChoose('Carrier')} className={styles['circle-animation-right-top']}>
                            <Circle strokeWidth={'2'} />
                            <div className={styles['status']}>
                              <p>Carrier</p>
                            </div>
                          </div>
                        </>
                      )}
                      {(activeCircle === 'Broker' || activeCircle === '') && (
                        <>
                          <div className={styles['dashed-line-right-bottom']}></div>
                          <div
                            onClick={() => onChoose('Broker')}
                            className={`${styles['circle-animation-right-top']} ${styles['right-bottom']}`}
                          >
                            <Circle strokeWidth={'2'} />
                            <div className={styles['status']}>
                              <p>Broker</p>
                            </div>
                          </div>
                        </>
                      )}

                      {(activeCircle === 'Dispatcher' || activeCircle === '') && (
                        <>
                          <div className={styles['dashed-line-right-dispatcher']}></div>
                          <div
                            onClick={() => onChoose('Dispatcher')}
                            className={`${styles['circle-animation-right-top']} ${styles['dispatcher-bottom']}`}
                          >
                            <Circle strokeWidth={'2'} />
                            <div className={styles['status']}>
                              <p>Dispatcher</p>
                            </div>
                          </div>
                        </>
                      )}

                      {(activeCircle === 'Shipper' || activeCircle === '') && (
                        <>
                          <div className={styles['dashed-line-right-shipper']}></div>
                          <div
                            onClick={() => onChoose('Shipper')}
                            className={`${styles['circle-animation-right-top']} ${styles['shipper-bottom']}`}
                          >
                            <Circle strokeWidth={'2'} />
                            <div className={styles['status']}>
                              <p>Shipper</p>
                            </div>
                          </div>
                        </>
                      )}

                      {(activeCircle === 'Factoring company' || activeCircle === '') && (
                        <>
                          <div className={styles['dashed-line-right-company']}></div>
                          <div
                            onClick={() => onChoose('Factoring company')}
                            className={`${styles['circle-animation-right-top']} ${styles['company-bottom']}`}
                          >
                            <Circle strokeWidth={'2'} />
                            <div className={styles['status']}>
                              <p>Factoring company</p>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  className={`${styles['messageTwo-overlay']} ${styles[animationTwoStart ? 'show' : '']}`}
                  id="handTwoMessage"
                >
                  {animationTwoStart ? (
                    <div className={`${styles['message']}`}>
                      <p>
                        That's quite a haul you've got there! Headed to Texas by any chance? I've got a special freight
                        that needs a ride.
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openRegisterModal && (
        <Modal size="small" header={`I’m a ${activeCircle}`} onClose={onClose}>
          <RegisterModalContent onClick={onClick} activeCircle={activeCircle} />
        </Modal>
      )}
      {openSignUpModal && (
        <Modal size="large" header={`I’m a ${activeCircle}`} onClose={onClose}>
          <SignUpContent name={`${activeCircle}`} onClose={onClose} />
        </Modal>
      )}
    </>
  );
};

export default Car;
